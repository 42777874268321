/* eslint-disable import/no-cycle */
import { CareCoordinationReport, OralHealthCoachingReport } from '@brands/services/careCoordinationReport/types/types';

import { OptionCDT } from '../../../Utils/selectOptions';
import { Insurance, Organization, UserProfile } from '../../identity/types/UserProfile';
import { IOneReport } from '../../oneReport/types/IOneReport';
import { SmileScanReport } from './SmileScanReport';

export interface ConversationStats {
  conversation_sid: string;
  message_created_by: number;
  unread_message_count: number;
}

export interface VCStats {
  joined_at: string;
  left_at: string;
}

export interface Payment {
  id: string;
  amount: number;
  currency: string;
  note: null;
  status: string;
  completed_date: null;
  pricing: Pricing;
  payment_profile: PaymentProfile;
  created_at: Date;
}

export interface AdditionalInfo {
  additional_info: string;
  created_at: string;
}

export interface PaymentProfile {
  id: number;
  type: string;
  is_default: boolean;
  is_active: boolean;
}

export interface Pricing {
  code: string;
  label: string;
  amount: number;
  currency: string;
  case_types: string[];
  is_active: boolean;
  created_at: Date;
}

export interface InsuranceMembership {
  insurance_carrier: InsuranceCarrier;
  insurance_carrier_id: number;
  group_number: string;
  member_id: string;
}

export interface InsuranceCarrier {
  id: number;
  name: string;
}

export enum Status {
  New = 'new',
  AcceptedCareAdvisor = 'accepted_care_advisor',
  AcceptedProvider = 'accepted_provider',
  CompletedByProvider = 'completed_by_provider',
  InProgressCareAdvisor = 'in_progress_care_advisor',
  InProgressProvider = 'in_progress_provider',
  Completed = 'completed',
  Deleted = 'deleted',
  PaymentErrored = 'payment_errored',
  PaymentReauthorizationFailed = 'payment_reauthorization_failed',
  Canceled = 'canceled',
  Default = 'default',
}

export enum CaseType {
  messaging = 'messaging',
  video_call_instant = 'video_call_instant',
  video_call_scheduled = 'video_call_scheduled',
  one_report = 'one_report',
  second_opinion = 'second_opinion',
  smile_scan = 'smile_scan',
  phone_call_scheduled = 'phone_call_scheduled',
  care_coordination = 'care_coordination',
  oral_health_coaching = 'oral_health_coaching',
  unknown = '',
}

export enum CareCoordinationStatus {
  follow_up_required = 'Requires follow-up',
  follow_up_in_progress = 'Follow-up in progress',
  follow_up_completed = 'Follow-up complete',
}

export interface ICase {
  id: number;
  patient_id: number;
  care_advisor_id: number;
  provider_id: number;
  type: CaseType;
  status: Status;
  video_conference_date: string | null;
  oral_health_report_date: string | null;
  patient_care_advisor_conversation_sid: string;
  patient_provider_conversation_sid: string;
  provider_care_advisor_conversation_sid: string;
  patient_care_advisor_conversation_stats: ConversationStats[] | null;
  patient_provider_conversation_stats: ConversationStats[] | null;
  provider_care_advisor_conversation_stats: ConversationStats[] | null;
  created_at: string;
  started_at: string;
  updated_at: string | null;
  one_report: IOneReport;
  one_report_id: number;
  smile_scan_report: SmileScanReport | null;
  patient: UserProfile;
  organization: Organization;
  provider: UserProfile;
  care_advisor: UserProfile;
  day_range?: string[] | null;
  time_range?: string[] | null;
  purpose?: { name: string }[] | null;
  purpose_other?: string | null;
  in_pain?: boolean;
  pain_description?: string[] | null;
  pain_level?: string | null;
  provider_assigned_by_patient?: boolean;
  pain_time?: string | null;
  additional_info?: AdditionalInfo[] | null;
  video_conference_other_participants?: string | null;
  brandAssoc?: string;
  due_date?: string | null;
  completed_at: string;
  patient_vc_stats: VCStats | null;
  payment?: Payment;
  payment_profile: PaymentProfile;
  cdts?: OptionCDT[];
  insurance?: Insurance;
  insurance_id?: number;
  patient_current_state?: string;
  pricing?: Pricing;
  markers?: string[];
  patient_role?: string;
  payment_method?: string;
  patient_self_pay_cost?: string;
  insurance_carrier?: string;
  action?: any;
  caseType?: string;
  care_coordination_report?: CareCoordinationReport;
  oral_health_coaching_report?: OralHealthCoachingReport;
  follow_up_status?: string;
}

export interface RxPayload {
  api: {
    key: string;
    timestamp: string;
    signature: string;
  };
  location: {
    npi: string;
    practice_tax_id: string;
    name: string;
    practice_name: string;
    address: string;
    city: string;
    state: string;
    zip_code: string;
    phone: string;
    fax: string;
  };
  user: {
    email: string;
    first_name: string;
    last_name: string;
    role: string;
    npi: string;
    state_license_name: string;
    state_license_number: string;
    federal_drug_id: string;
  };
  patient: {
    id: number;
    first_name: string;
    last_name: string;
    dob: string;
    gender: string;
    phone: string;
    address: string;
    city: string;
    state: string;
    zip_code: string;
  };
}

/* eslint-disable no-case-declarations */
/* eslint-disable no-nested-ternary */
import { Marker, UserRoleName } from '@brands/services/identity/types/UserProfile';

import { CaseType, ICase, Status } from '../services/cases/types/ICase';
import { calculateCaseDueInMinutes } from './calculateCaseDue';
import { getFriendlyName } from './getFriendlyName';
import { getTextForStatus } from './getTextForStatus';

export type SortKeys = keyof ICase;
export type SortOrder = 'ascn' | 'desc';
export type Comparator<T> = (a: T, b: T) => number;

export const sortedDataFn = (allCases: ICase[], sortKey: SortKeys): ICase[] => {
  return allCases?.sort((a, b) => {
    return a[sortKey]! > b[sortKey]! ? 1 : -1;
  });
};

export const sortData = <T extends Record<string, any>>({
  tableData,
  sortKey,
  reverse,
  userRole,
}: {
  tableData: T[];
  sortKey: SortKeys;
  reverse: boolean;
  userRole: string;
}): T[] => {
  const sortedData = [...tableData].sort((a, b) => {
    let aValue;
    let bValue;
    switch (sortKey) {
      case 'id':
        aValue = a.id || '';
        bValue = b.id || '';
        break;
      case 'type':
        aValue =
          userRole === UserRoleName.Patient
            ? getFriendlyName(a.type)
            : userRole === UserRoleName.Provider || userRole === UserRoleName.CareAdvisor
            ? getFriendlyName(a.type)
            : '';
        bValue =
          userRole === UserRoleName.Patient
            ? getFriendlyName(b.type)
            : userRole === UserRoleName.Provider || userRole === UserRoleName.CareAdvisor
            ? getFriendlyName(b.type)
            : '';
        break;
      case 'follow_up_status':
        aValue = a.follow_up_status || '';
        bValue = b.follow_up_status || '';
        break;
      case 'caseType':
        aValue = getFriendlyName(a.type);
        bValue = getFriendlyName(b.type);
        break;
      case 'video_conference_date':
        const isAScheduled = a.status === CaseType.phone_call_scheduled || CaseType.video_call_scheduled;
        const isBScheduled = b.status === CaseType.phone_call_scheduled || CaseType.video_call_scheduled;

        if (isAScheduled && !isBScheduled) {
          return -1;
        }
        if (!isAScheduled && isBScheduled) {
          return 1;
        }

        aValue = a.video_conference_date ? new Date(a.video_conference_date).getTime() : null;
        bValue = b.video_conference_date ? new Date(b.video_conference_date).getTime() : null;
        break;
      case 'completed_at':
        const isACompleted = a.status === Status.Completed;
        const isBCompleted = b.status === Status.Completed;

        if (isACompleted && !isBCompleted) {
          return -1;
        }
        if (!isACompleted && isBCompleted) {
          return 1;
        }

        aValue = a.completed_at ? new Date(a.completed_at).getTime() : null;
        bValue = b.completed_at ? new Date(b.completed_at).getTime() : null;
        break;
      case 'patient_current_state':
        aValue = a.patient_current_state || '';
        bValue = b.patient_current_state || '';
        break;
      case 'patient_role':
        aValue = a.patient?.guardian_id === null ? 'Patient' : 'Dependent';
        bValue = b.patient?.guardian_id === null ? 'Patient' : 'Dependent';
        break;
      case 'updated_at':
        const isACanceled = a.status === Status.Canceled;
        const isBCanceled = b.status === Status.Canceled;

        if (isACanceled && !isBCanceled) {
          return -1;
        }
        if (!isACanceled && isBCanceled) {
          return 1;
        }

        aValue = a.updated_at ? new Date(a.updated_at).getTime() : null;
        bValue = b.updated_at ? new Date(b.updated_at).getTime() : null;
        break;
      case 'patient':
        aValue = a.patient?.full_name?.toLowerCase() || '';
        bValue = b.patient?.full_name?.toLowerCase() || '';
        break;
      case 'care_advisor':
        aValue = a.care_advisor?.full_name?.toLowerCase() || '';
        bValue = b.care_advisor?.full_name?.toLowerCase() || '';
        break;
      case 'provider':
        aValue = a.provider?.full_name?.toLowerCase() || '';
        bValue = b.provider?.full_name?.toLowerCase() || '';
        break;
      case 'payment_method':
        aValue = a.pricing?.amount && a.pricing.amount > 0 ? (a.insurance_id ? 'Insurance' : 'Self pay') : 'Free case';
        bValue = b.pricing?.amount && b.pricing.amount > 0 ? (b.insurance_id ? 'Insurance' : 'Self pay') : 'Free case';
        break;
      case 'patient_self_pay_cost':
        aValue = a.pricing?.amount && a.pricing.amount > 0 && a.insurance_id === null ? a.pricing.amount / 100 : 0;
        bValue = b.pricing?.amount && b.pricing.amount > 0 && b.insurance_id === null ? b.pricing.amount / 100 : 0;
        break;

      case 'insurance_carrier':
        aValue =
          a.pricing?.amount && a.pricing.amount > 0
            ? a.insurance_id !== null
              ? a.insurance?.insurance_membership?.insurance_carrier?.name
              : 'N/A'
            : 'N/A';
        bValue =
          b.pricing?.amount && b.pricing.amount > 0
            ? b.insurance_id !== null
              ? b.insurance?.insurance_membership?.insurance_carrier?.name
              : 'N/A'
            : 'N/A';
        break;

      case 'brandAssoc':
        [aValue] = a.organization.name;
        [bValue] = b.organization.name;
        break;
      case 'markers':
        aValue = a.patient?.markers?.map((marker: Marker) => marker.value).join(', ') || '';
        bValue = b.patient?.markers?.map((marker: Marker) => marker.value).join(', ') || '';
        break;
      case 'status':
        aValue = getTextForStatus(userRole, a.type, a.status);
        bValue = getTextForStatus(userRole, b.type, b.status);
        break;
      case 'created_at':
        aValue = new Date(a.created_at).getTime();
        bValue = new Date(b.created_at).getTime();
        break;
      case 'video_conference_date':
        aValue = new Date(a.video_conference_date).getTime() || '';
        bValue = new Date(b.video_conference_date).getTime() || '';
        break;
      case 'completed_at':
        aValue =
          (a.status === 'completed' || a.status === 'canceled'
            ? new Date(a.completed_at).getTime() || new Date(a.updated_at).getTime()
            : '') || '';
        bValue =
          (b.status === 'completed' || b.status === 'canceled'
            ? new Date(b.completed_at).getTime() || new Date(b.updated_at).getTime()
            : '') || '';
        break;
      case 'due_date':
        aValue = calculateCaseDueInMinutes(a.type, a.created_at, a.video_conference_date || '');
        bValue = calculateCaseDueInMinutes(b.type, b.created_at, b.video_conference_date || '');
        break;
      default:
        aValue = '';
        bValue = '';
    }
    if (sortKey === 'due_date') {
      if (a.type === 'video_call_instant' && b.type !== 'video_call_instant') {
        return reverse ? 1 : -1;
      }
      if (a.type !== 'video_call_instant' && b.type === 'video_call_instant') {
        return reverse ? -1 : 1;
      }
      if (a.type === 'video_call_instant' && b.type === 'video_call_instant') {
        // If both cases are video_call_instant type, sort by waiting time in reverse order
        if (a.id > b.id) {
          return 1;
        }
        if (a.id < b.id) {
          return -1;
        }
      }
    }
    if (aValue < bValue) {
      return reverse ? 1 : -1;
    }
    if (aValue > bValue) {
      return reverse ? -1 : 1;
    }
    return 0;
  });
  return sortedData;
};

import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { CaseType, ICase } from '../../../../services/cases/types/ICase';
import { rescheduleCaseAvailability } from '../../../../Utils/getFriendlyName';
import styles from '../appointments.module.scss';

interface Props {
  appointment: ICase;
  rescheduleAvailability: boolean;
}

const RescheduleButton = ({ appointment, rescheduleAvailability }: Props): JSX.Element => {
  const [rescheduleCaseAvailabilityFlag, setRescheduleCaseAvailabilityFlag] = useState(rescheduleAvailability);
  const navigate = useNavigate();

  useEffect(() => {
    let intervalId: NodeJS.Timeout;
    if (
      (appointment.type === 'video_call_scheduled' || appointment.type === CaseType.phone_call_scheduled) &&
      appointment.video_conference_date &&
      rescheduleCaseAvailabilityFlag
    ) {
      intervalId = setInterval(() => {
        const dueDate = rescheduleCaseAvailability(appointment);
        setRescheduleCaseAvailabilityFlag(!!dueDate);
      }, 10000);
    }
    return () => clearInterval(intervalId);
  }, [appointment]);

  return (
    <span>
      {rescheduleCaseAvailabilityFlag && (
        <button
          type="button"
          className={styles.cancelBtn}
          onClick={() =>
            navigate('/select-date-time', {
              state: {
                RescheduleCase: true,
                caseId: appointment.id,
                providerId: appointment?.provider?.id || 0,
                careAdvisorId: appointment?.care_advisor_id || 0,
                caseType: appointment?.type,
                patient_current_state: appointment.patient_current_state,
                RescheduleCaseSelectedCarrierId: appointment?.insurance?.insurance_membership?.insurance_carrier?.id || null,
              },
            })
          }
        >
          Reschedule
        </button>
      )}
    </span>
  );
};

export default RescheduleButton;

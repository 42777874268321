/* eslint-disable no-param-reassign */
import AddressSection from '@brands/Dashboard/Dashboard/SuperAdmin/Components/AddressSection/AddressSection';
import { updateUserEmail } from '@brands/services/identity/updateUserEmail';
import { selectAuth } from '@brands/store/selectors/auth';
import { setUser } from '@brands/store/slices/authSlice';
import { setProviderMyProfile } from '@brands/store/slices/providerMyProfileSlice';
import { trimStringFields } from '@brands/Utils/trimStringFields';
import { UnifiedProfileSubmit } from '@brands/Utils/types';
import { validateAddress, ValidationModalInterface } from '@brands/Utils/validateAddress';
import { yupResolver } from '@hookform/resolvers/yup';
import cn from 'classnames';
import { parsePhoneNumber } from 'libphonenumber-js';
import { isEqual } from 'lodash';
import moment from 'moment';
import React, { KeyboardEventHandler, useEffect, useMemo, useRef, useState } from 'react';
import { useForm, UseFormGetValues } from 'react-hook-form';
import { IoIosAddCircle } from 'react-icons/io';
import { TiDelete } from 'react-icons/ti';
import { MultiValue } from 'react-select';
import CreatableSelect from 'react-select/creatable';
import { toast } from 'react-toastify';

import Button from '../../../../Components/Button/Button';
import MultiselectInput from '../../../../Components/Inputs/MultiselectInput/MultiselectInput';
import PhoneInput from '../../../../Components/Inputs/PhoneInput/PhoneInput';
import SelectInput from '../../../../Components/Inputs/SelectInput/SelectInput';
import TextField from '../../../../Components/Inputs/TextField/TextField';
import { Loading } from '../../../../Components/LoadingSpinner/Loading';
import ProfileImage from '../../../../Components/ProfileImage/ProfileImage';
import SelectButtons from '../../../../Components/ProfileSelectButtons/SelectButtons';
import { useAppDispatch, useAppSelector } from '../../../../hooks/useReduxHook';
import { RegisterMediaPayload } from '../../../../services/forms/registerMediaPayloadType';
import { confirmAvatarMedia } from '../../../../services/identity/confirmAvatarMedia';
import { createBasicInformation } from '../../../../services/identity/createBasicInformation';
import { createProviderLicense } from '../../../../services/identity/createProviderLicense';
import { createProviderProfile } from '../../../../services/identity/createProviderProfile';
import { deleteAvatar } from '../../../../services/identity/deleteAvatar';
import { deleteProviderLicense } from '../../../../services/identity/deleteProviderLicense';
import { getCurrentProfile } from '../../../../services/identity/getCurrentProfile';
import { getUserById } from '../../../../services/identity/getUserById';
import { registerAvatarMedia } from '../../../../services/identity/registerAvatarMedia';
import { saveBasicInformation } from '../../../../services/identity/saveBasicInformation';
import { saveProviderProfile } from '../../../../services/identity/saveProviderProfile';
import {
  Address,
  BasicInfo,
  Language,
  ProviderProfile,
  UserProfile,
  UserRoleName,
} from '../../../../services/identity/types/UserProfile';
import { updateMe } from '../../../../services/identity/updateMe';
import { updateProviderLicense } from '../../../../services/identity/updateProviderLicense';
import { updateUserOrganization } from '../../../../services/identity/updateUserOrganization';
import { checkAndSetDate } from '../../../../Utils/checkAndSetDate';
import { multiSelectCommonCustomStylesLarge, selectCommonCustomStylesLarge } from '../../../../Utils/customStyles';
import { displayErrorDetails } from '../../../../Utils/displayError';
import { genderIdentity, genderOptions, pronounsOptions } from '../../../../Utils/selectButtonOptions';
import {
  licenseOptions,
  Option,
  OptionInsurance,
  OptionLanguage,
  specialtyOptions,
  stateOptions,
} from '../../../../Utils/selectOptions';
import { areArraysEqual } from '../../Patient/utils/utilsFunctions';
import styles from '../../styles.module.scss';
import { ProviderMyProfileSubmit } from '../utils/types';
import { validationSchema } from '../utils/validationSchema';

const components = {
  DropdownIndicator: null,
};

type EditProfileProps = {
  myProfileForm: ProviderMyProfileSubmit;
  setMyProfileForm: React.Dispatch<React.SetStateAction<ProviderMyProfileSubmit>>;
  setImage: React.Dispatch<React.SetStateAction<string | undefined>>;
  image: string | undefined;
  setIsEditProfile: React.Dispatch<React.SetStateAction<boolean>>;
  setCurrentAvatarURL: React.Dispatch<React.SetStateAction<string>>;
  currentAvatarURL: string;
  thisUser: UserProfile;
  setThisUser: React.Dispatch<React.SetStateAction<UserProfile>>;
  avatarAction: string;
  setAvatarAction: React.Dispatch<React.SetStateAction<string>>;
  reviewProfile: boolean | undefined;
  languageOptions: OptionLanguage[];
  loading: boolean;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
  userId: string;
  organizationOptions: Option[];
  insurancePlanName: OptionInsurance[];
  validationResponseId: string;
  setValidationResponseId: React.Dispatch<React.SetStateAction<string>>;
};

const EditProfile = ({
  myProfileForm,
  setMyProfileForm,
  setImage,
  image,
  setIsEditProfile,
  setCurrentAvatarURL,
  currentAvatarURL,
  thisUser,
  setThisUser,
  avatarAction,
  setAvatarAction,
  reviewProfile,
  languageOptions,
  loading,
  setLoading,
  userId,
  organizationOptions,
  insurancePlanName,
  validationResponseId,
  setValidationResponseId,
}: EditProfileProps): JSX.Element => {
  const dispatch = useAppDispatch();
  const { userInfo } = useAppSelector(selectAuth);
  const [inputValue, setInputValue] = useState('');
  const [avatarPayload, setAvatarPayload] = useState<RegisterMediaPayload | null>(null);
  const [selection, setSelection] = useState<[number | null, number | null] | null>(null);
  const [avatarFile, setAvatarFile] = useState<File | null>(null);
  const [isSavingProfile, setIsSavingProfile] = useState(false);
  const ref = useRef<HTMLInputElement>(null);
  const phoneInputRef = useRef<HTMLInputElement>(null);
  React.useLayoutEffect(() => {
    if (selection && ref.current) {
      [ref.current.selectionStart, ref.current.selectionEnd] = selection;
    }
  }, [selection]);
  const [showValidationAddressModal, setShowValidationAddressModal] = useState<ValidationModalInterface>({
    isOpen: false,
    title: '',
    whatYouEntered: '',
    recommended: false,
    recommendedAddress: '',
    onClose: undefined,
    onConfirmChange: undefined,
    onKeepCurrentAddress: undefined,
  });

  const isAdminRole = useMemo(
    () =>
      userInfo.role.name === UserRoleName.Admin ||
      userInfo.role.name === UserRoleName.SuperAdmin ||
      userInfo.role.name === UserRoleName.OrganizationAdmin,
    [userInfo.role.name],
  );

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
    getValues,
  } = useForm<ProviderMyProfileSubmit>({
    mode: 'onChange',
    resolver: yupResolver(validationSchema(reviewProfile, isAdminRole)),
  });

  const setTextValue = (e: React.ChangeEvent<HTMLInputElement>, name: string): void => {
    const { value } = e.target;
    setValue(name as keyof ProviderMyProfileSubmit, value, { shouldValidate: true });
    setSelection([e.target.selectionStart, e.target.selectionEnd]);
  };

  const triggerProfileAvatar = React.useCallback((avatarUrl: string) => {
    setValue('profile_img', avatarUrl, { shouldValidate: true });
    setMyProfileForm({
      ...getValues(),
      profile_img: avatarUrl,
    });
  }, []);

  const onCloseConfirmationModal = (): void => {
    setShowValidationAddressModal({
      isOpen: false,
      title: '',
      whatYouEntered: '',
      recommended: false,
      recommendedAddress: '',
      onClose: undefined,
      onConfirmChange: undefined,
      onKeepCurrentAddress: undefined,
    });
  };

  const addLicenseRow = (): void => {
    const largestId = myProfileForm.licences.reduce((maxId, licence) => {
      const currentId = licence.id ?? 0;
      return currentId > maxId ? currentId : maxId;
    }, 0);
    const newId = largestId + 1;

    const newLicense = {
      id: newId,
      state: '',
      number: '',
      expiry_date: '',
      insurance_carriers: [],
    };

    setMyProfileForm((prev) => ({
      ...getValues(),
      licences: [...prev.licences, newLicense],
    }));
  };

  const handleRemoveRow = (id: number): void => {
    const updatedLicences = myProfileForm.licences.filter((licence) => licence.id !== id);
    setMyProfileForm(() => ({
      ...getValues(),
      licences: updatedLicences,
    }));
  };

  const handleRemoveInsurancePlanRow = (id: number, index: number): void => {
    const updatedLicences = myProfileForm.licences.map((licence) => {
      if (licence.id === id) {
        const updatedInsurancePlans = licence.insurance_carriers.filter((_, i) => i !== index);
        return {
          ...licence,
          insurance_carriers: updatedInsurancePlans,
        };
      }
      return licence;
    });
    setMyProfileForm(() => ({
      ...getValues(),
      licences: updatedLicences,
    }));
  };

  const onCancelEditProfile = (): void => {
    const startingLicenses = thisUser?.provider_profile?.licenses?.map((licence) => ({
      id: licence.id ?? 0,
      state: licence.state ?? '',
      number: licence.number ?? '',
      expiry_date: moment(licence.expiry_date).format('MM/DD/YYYY') ?? '',
      insurance_carriers: licence.insurance_carriers.map((insuranceCarrier) => {
        return {
          insurance_carrier_id: insuranceCarrier?.insurance_carrier?.id,
          effective_date: moment(insuranceCarrier.effective_date).format('MM/DD/YYYY') ?? '',
          recredential_date: moment(insuranceCarrier.recredential_date).format('MM/DD/YYYY') ?? '',
        };
      }),
    }));

    setMyProfileForm({
      ...myProfileForm,
      providerID: thisUser?.id ?? '',
      first_name: thisUser?.basic_info?.first_name ?? '',
      middle_name: thisUser?.basic_info?.middle_name ?? '',
      last_name: thisUser?.basic_info?.last_name ?? '',
      email: thisUser?.email ?? '',
      phone_number: thisUser?.phone_number ?? '',
      address1: thisUser?.address?.address1 ?? '',
      address2: thisUser?.address?.address2 ?? '',
      zip_code: thisUser?.address?.zip_code ?? '',
      additional_credentials: thisUser?.provider_profile?.additional_credentials ?? '',
      state: thisUser?.address?.state ?? '',
      date_of_birth: moment(thisUser?.basic_info?.date_of_birth).format('MM/DD/YYYY') ?? '',
      languages: thisUser?.basic_info?.languages ?? [],
      language:
        (thisUser?.basic_info?.languages as Language[])?.map((language) => ({
          id: language.id,
          label: language.name,
          value: language.locale,
        })) ?? [],
      pronouns: thisUser?.basic_info?.pronouns ?? '',
      gender: thisUser?.basic_info?.gender ?? '',
      credentials: thisUser?.provider_profile?.credentials ?? '',
      dea_number: thisUser?.provider_profile?.dea_number ?? '',
      national_provider_id: thisUser?.provider_profile?.national_provider_id ?? '',
      education: thisUser?.provider_profile?.education ?? [],
      primary_specialty: specialtyOptions.filter(
        (specialty) => thisUser?.provider_profile?.primary_specialty?.includes(specialty.value) ?? false,
      ),
      secondary_specialty: specialtyOptions.filter(
        (specialty) => specialty.value === thisUser?.provider_profile?.secondary_specialty,
      ),
      biography: thisUser?.provider_profile?.biography ?? '',
      plan: thisUser?.provider_profile?.plan ?? [],
      city: thisUser?.address?.city ?? '',
      npi: thisUser?.provider_profile?.npi ?? '',
      organizations: thisUser?.organizations ?? [],
      licences: startingLicenses || [],
    });

    setImage(currentAvatarURL);
    setIsEditProfile(false);
  };

  const updateCurrentAddress = (standardizedAddress: any, postalAddress: any, addressComponents: any): void => {
    const streetNumber = addressComponents.find(
      (addressComponent: any) => addressComponent.componentType === 'street_number',
    );
    const address1 = addressComponents.find((addressComponent: any) => addressComponent.componentType === 'route');
    if (postalAddress) setValue('city', postalAddress.locality);
    if (streetNumber || address1) {
      setValue('address1', `${streetNumber.componentName.text} ${address1.componentName.text}`);
    }
    if (standardizedAddress?.state) setValue('state', standardizedAddress.state);
    if (postalAddress) setValue('zip_code', postalAddress.postalCode);
  };

  const callSaveProfile = async (profileData: UnifiedProfileSubmit): Promise<void> => {
    setIsSavingProfile(true);
    const { date_of_birth: dob, profile_img: profileImg, ...otherData } = profileData as ProviderMyProfileSubmit;
    try {
      if (avatarPayload && avatarFile && avatarAction === 'Upload') {
        const res = await registerAvatarMedia(avatarPayload);
        const mediaId = res.files[0].upload_id;
        await fetch(res.files[0].upload_url, {
          method: 'PUT',
          body: avatarFile,
          headers: { 'content-type': avatarFile.type },
        });
        await confirmAvatarMedia(mediaId, Number(thisUser.id));
        triggerProfileAvatar(res.files[0].upload_url);
      } else if (avatarAction === 'Remove') {
        await deleteAvatar(thisUser.id);
        triggerProfileAvatar('');
      }

      let updatedUser;

      if (thisUser?.email !== (profileData as UnifiedProfileSubmit).email) {
        updatedUser = await updateUserEmail(thisUser?.id || 0, {
          new_email: (profileData as UnifiedProfileSubmit).email as string,
        });
      }

      if (thisUser && thisUser.basic_info !== null) {
        updatedUser = await saveBasicInformation(
          {
            ...(otherData as BasicInfo),
            languages:
              otherData.language.length > 0 ? otherData.language.map((language) => language.id.toString()) : [],
          },
          thisUser.id,
        );
      } else {
        updatedUser = await createBasicInformation(
          {
            ...(otherData as BasicInfo),
            languages:
              otherData.language.length > 0 ? otherData.language.map((language) => language.id.toString()) : [],
          },
          thisUser.id,
        );
      }
      const updateProfileData = {
        education: (otherData as ProviderMyProfileSubmit).education,
        primary_specialty: ((otherData as ProviderMyProfileSubmit).primary_specialty || []).map(
          (option) => option.value,
        ),
        secondary_specialty: ((otherData as ProviderMyProfileSubmit).secondary_specialty || []).map(
          (option) => option.value,
        ),
        biography: (otherData as ProviderMyProfileSubmit).biography,
        additional_credentials: (otherData as ProviderMyProfileSubmit).additional_credentials,
        address: {
          address1: otherData.address1,
          address2: otherData.address2,
          city: otherData.city,
          state: otherData.state,
          zip_code: otherData.zip_code,
        },
        ...(reviewProfile && { national_provider_id: (otherData as ProviderMyProfileSubmit).national_provider_id }),
        ...(reviewProfile && { credentials: (otherData as ProviderMyProfileSubmit).credentials }),
      };
      updatedUser = await updateMe(
        {
          ...(otherData.phone_number
            ? {
                phone_number: parsePhoneNumber(otherData.phone_number, 'US')?.format('E.164') as string,
              }
            : { phone_number: null }),
          address: updateProfileData.address as Address,
        },
        thisUser.id,
      );
      if (thisUser && thisUser.provider_profile !== null) {
        updatedUser = await saveProviderProfile(updateProfileData as ProviderProfile, thisUser.id);
      } else {
        updatedUser = await createProviderProfile(updateProfileData as ProviderProfile, thisUser.id);
      }
      if (thisUser && reviewProfile) {
        // Create an array to hold the promises for API calls
        const promises: Promise<any>[] = [];
        const currentLicenses = thisUser.provider_profile
          ? thisUser.provider_profile.licenses
          : updatedUser.provider_profile?.licenses;

        if (currentLicenses) {
          // Loop over the updated licenses
          myProfileForm.licences.map((updatedLicense) => {
            const foundLicense = currentLicenses.find((license) => license.id === updatedLicense.id);
            if (foundLicense) {
              promises.push(
                updateProviderLicense(
                  {
                    state: updatedLicense.state,
                    number: updatedLicense.number,
                    expiry_date: updatedLicense.expiry_date,
                    insurance_carriers: updatedLicense?.insurance_carriers.map((plan) => {
                      return {
                        ...plan,
                        insurance_carriers: Number(plan.insurance_carrier_id),
                        effective_date: moment(plan.effective_date).format('YYYY-MM-DD'),
                        recredential_date: moment(plan.recredential_date).format('YYYY-MM-DD'),
                      };
                    }),
                  },
                  foundLicense.id!,
                  thisUser.id,
                ),
              );
            } else {
              // License doesn't exist in the current list, create it
              promises.push(
                createProviderLicense(
                  {
                    state: updatedLicense.state,
                    number: updatedLicense.number,
                    expiry_date: updatedLicense.expiry_date,
                    insurance_carriers: updatedLicense?.insurance_carriers.map((plan) => {
                      return {
                        ...plan,
                        insurance_carrier_id: Number(plan.insurance_carrier_id),
                        effective_date: moment(plan.effective_date).format('YYYY-MM-DD'),
                        recredential_date: moment(plan.recredential_date).format('YYYY-MM-DD'),
                      };
                    }),
                  },
                  thisUser.id,
                ),
              );
            }
          });
          // Loop over the current licenses
          for (const currentLicense of currentLicenses) {
            const foundLicense = myProfileForm.licences.find((license) => license.id === currentLicense.id);
            if (!foundLicense) {
              // License doesn't exist in the updated list, remove it
              promises.push(deleteProviderLicense(currentLicense.id!, thisUser.id));
            }
          }
        }
        if (
          (otherData as ProviderMyProfileSubmit).organization &&
          !areArraysEqual(thisUser.organizations, (otherData as ProviderMyProfileSubmit).organization!)
        ) {
          const updatedOrganizations = (otherData as ProviderMyProfileSubmit)?.organization;
          const organizationIdList: string[] = [];
          if (updatedOrganizations) {
            for (const updatedOrganization of updatedOrganizations) {
              organizationIdList.push(updatedOrganization.value as string);
            }
          }
          if (organizationIdList.length === 0) {
            organizationIdList.push('dentistry-one');
          }
          promises.push(
            updateUserOrganization(thisUser.id, {
              organization_ids: organizationIdList,
            }),
          );
        }
        // Execute all promises concurrently
        try {
          await Promise.all(promises);
        } catch (error: unknown) {
          displayErrorDetails(error);
        }
      }

      if (reviewProfile) {
        updatedUser = await getUserById(thisUser.id);
      } else {
        updatedUser = await getCurrentProfile();
      }

      setThisUser(updatedUser);
      if (updatedUser.provider_profile.licenses) {
        // Update the licenses list in the state
        const updatedLicences = updatedUser.provider_profile.licenses.map((license) => ({
          id: license.id,
          state: license.state,
          number: license.number,
          expiry_date: license.expiry_date,
          insurance_carriers: license.insurance_carriers,
        }));

        setMyProfileForm((prevForm) => ({
          ...prevForm,
          licenses: updatedLicences.slice().sort((a, b) => {
            return a.id! - b.id!;
          }),
        }));
      }

      if (!reviewProfile) {
        dispatch(
          setProviderMyProfile({
            ...(profileData as ProviderMyProfileSubmit),
            providerID: thisUser?.id || 0,
          }),
        );
        dispatch(setUser({ userInfo: updatedUser }));
      }
      setIsEditProfile(false);
    } catch (error: unknown) {
      displayErrorDetails(error);
    }
    setIsSavingProfile(false);
  };

  const onSubmit = async (profileData: ProviderMyProfileSubmit): Promise<void> => {
    profileData = trimStringFields(profileData);

    if (
      profileData.address1 !== '' &&
      profileData.city !== '' &&
      profileData.state !== '' &&
      profileData.zip_code !== ''
    ) {
      await validateAddress(
        profileData,
        getValues as UseFormGetValues<UnifiedProfileSubmit>,
        validationResponseId,
        callSaveProfile,
        setValidationResponseId,
        setShowValidationAddressModal,
        onCloseConfirmationModal,
        updateCurrentAddress,
      );
    } else {
      callSaveProfile(profileData);
    }
  };

  const handleKeyDown: KeyboardEventHandler = (event) => {
    if (!inputValue) return;
    switch (event.key) {
      case 'Enter':
        setValue('education', [...getValues('education'), inputValue], { shouldValidate: true });
        setInputValue('');
        event.preventDefault();
        return;
      case 'Tab':
        setValue('education', [...getValues('education'), inputValue], { shouldValidate: true });
        setInputValue('');
        event.preventDefault();
    }
  };

  const onInvalid = (errorsList: any): void => {
    const error = Object.values(errorsList)[0] as any;
    if (error) {
      toast.error(error.message as string);
    }
  };

  useEffect(() => {
    if (Object.keys(myProfileForm).length > 0) {
      Object.keys(myProfileForm).forEach((key: unknown) => {
        if (key === 'licenses') {
          setValue('licenses' as keyof ProviderMyProfileSubmit, myProfileForm.licences, {
            shouldValidate: !loading,
          });
        } else {
          setValue(key as keyof ProviderMyProfileSubmit, myProfileForm[key as keyof ProviderMyProfileSubmit], {
            shouldValidate: !loading,
          });
        }
      });
      let organizationsArray = myProfileForm.organizations?.map((organization) => ({
        value: organization.id?.toString() || '',
        label: organization.name || '',
        divisions: organization.divisions || [],
        practices: organization.practices || [],
      }));
      organizationsArray = organizationsArray?.filter((org) => organizationOptions.some((opt) => opt.id === org.value));

      setValue('organization', organizationsArray, { shouldValidate: true });
      const newOrganizationsArray = myProfileForm.organizations?.filter((org) =>
        organizationOptions.some((opt) => opt.id === org.id),
      );
      setValue('organizations', newOrganizationsArray, { shouldValidate: true });
      if (!reviewProfile) setLoading(false);
    }
  }, [myProfileForm, setValue]);

  useEffect(() => {
    if (image) triggerProfileAvatar(image);
  }, [image]);

  if (loading) return <Loading fullScreen />;

  return (
    <div className={styles.editProfileContainer}>
      <div className={styles.titleContainer}>
        <span className={styles.title}>Profile</span>
      </div>
      <div className={styles.editContainer}>
        <ProfileImage
          reviewProfile={reviewProfile}
          id={userId}
          setCurrentAvatarURL={setCurrentAvatarURL}
          setAvatarFile={setAvatarFile}
          setAvatarPayload={setAvatarPayload}
          setAvatarAction={setAvatarAction}
          triggerProfileAvatar={triggerProfileAvatar}
          setImage={setImage}
          image={image}
          errors={errors}
          size="80px"
        />
        <p className={styles.requiredIndicator}>
          Required <span>*</span>
        </p>
        <form className={styles.formContainer} onSubmit={handleSubmit(onSubmit, onInvalid)}>
          <div className={styles.row}>
            <div className={cn(styles.rowItem, styles.details)}>
              <div className={styles.caption}>First Name {reviewProfile ? <span>*</span> : ''}</div>
              <TextField
                {...register('first_name')}
                errors={errors}
                name="first_name"
                value={getValues('first_name')}
                onChange={(e) => setTextValue(e, 'first_name')}
                wrapperClassName={styles.inputWrapper}
                inputClass={styles.input}
                disabled={!reviewProfile}
              />
            </div>
            <div className={cn(styles.rowItem, styles.details)}>
              <div className={styles.caption}>Middle Name</div>
              <TextField
                {...register('middle_name')}
                errors={errors}
                name="middle_name"
                value={getValues('middle_name')}
                onChange={(e) => setTextValue(e, 'middle_name')}
                wrapperClassName={styles.inputWrapper}
                inputClass={styles.input}
                disabled={!reviewProfile}
              />
            </div>
            <div className={cn(styles.rowItem, styles.details)}>
              <div className={styles.caption}>Last Name {reviewProfile ? <span>*</span> : ''}</div>
              <TextField
                {...register('last_name')}
                errors={errors}
                name="last_name"
                value={getValues('last_name')}
                onChange={(e) => setTextValue(e, 'last_name')}
                wrapperClassName={styles.inputWrapper}
                inputClass={styles.input}
                disabled={!reviewProfile}
              />
            </div>
          </div>

          <div className={styles.row}>
            <div className={cn(styles.rowItem, styles.gender)}>
              <div className={styles.caption}>Gender assigned at birth {!reviewProfile ? <span>*</span> : ''}</div>
              <SelectButtons
                errors={errors}
                setValue={setValue}
                form={myProfileForm}
                value="gender"
                options={genderOptions}
              />
            </div>
          </div>
          <div className={styles.row}>
            <div className={cn('col-md-6', styles.rowItem, styles.details, styles.half)}>
              <div className={styles.caption}>
                Gender identity <span>{isAdminRole ? '' : '*'}</span>
              </div>
              <SelectInput
                {...register('gender_identity')}
                name="gender_identity"
                options={genderIdentity}
                containerClass={styles.inputWrapper}
                selectorClass={styles.selector}
                style={selectCommonCustomStylesLarge}
                errors={errors}
                selectedValue={getValues('gender_identity')}
                onChange={(value) => {
                  if (value) {
                    setValue('gender_identity', value.value, { shouldValidate: true });
                  }
                }}
              />
            </div>
            <div className={cn('col-md-6', styles.rowItem, styles.details, styles.half)}>
              <div className={styles.caption}>Pronouns</div>
              <SelectInput
                {...register('pronouns')}
                name="pronouns"
                options={pronounsOptions}
                containerClass={styles.inputWrapper}
                selectorClass={styles.selector}
                style={selectCommonCustomStylesLarge}
                errors={errors}
                selectedValue={getValues('pronouns')}
                onChange={(value) => {
                  if (value) {
                    setValue('pronouns', value.value, { shouldValidate: true });
                  }
                }}
              />
            </div>
          </div>
          <div className={styles.row}>
            <div className={cn(styles.rowItem, styles.details, styles.big, styles.languages)}>
              <div className={styles.caption}>Languages {!reviewProfile ? <span>*</span> : ''}</div>
              <MultiselectInput
                {...register('language')}
                name="language"
                placeholder="Please add any languages you are comfortable speaking during the consult"
                options={languageOptions}
                style={multiSelectCommonCustomStylesLarge}
                containerClass={styles.inputWrapper}
                selectorClass={styles.selector}
                errors={errors}
                selectedValue={getValues('language')}
                onChange={(value) => {
                  if (value) {
                    setValue('language', value as MultiValue<OptionLanguage>, {
                      shouldValidate: true,
                    });
                  }
                }}
              />
            </div>
          </div>
          <div className={styles.separator} />
          <div className={styles.row}>
            <AddressSection
              register={register}
              errors={errors}
              getValues={getValues}
              setTextValue={setTextValue}
              setValue={setValue}
              showValidationAddressModal={showValidationAddressModal}
              required={!reviewProfile}
            />
          </div>
          <div className={styles.separator} />
          <div className={styles.row}>
            <div className={styles.rowItem}>
              <div className={styles.caption}>Email Address {reviewProfile || isAdminRole ? <span>*</span> : ''}</div>
              <TextField
                {...register('email')}
                errors={errors}
                name="email"
                value={getValues('email')}
                onChange={(e) => setTextValue(e, 'email')}
                wrapperClassName={styles.inputWrapper}
                inputClass={styles.input}
                disabled={!reviewProfile || !isAdminRole}
              />
            </div>
            <div className={styles.rowItem}>
              <div className={styles.caption}>Phone Number {!reviewProfile ? <span>*</span> : ''}</div>
              <PhoneInput
                {...register('phone_number')}
                errors={errors}
                id="phone_number"
                value={getValues('phone_number')}
                onChange={(val) => {
                  setValue('phone_number', val as string, {
                    shouldValidate: true,
                  });
                }}
                wrapperClassName={styles.inputWrapper}
                inputClass={styles.input}
                ref={phoneInputRef}
              />
              <span className={styles.inputFocus} />
            </div>
          </div>
          <div className={styles.separator} />
          {reviewProfile && (
            <>
              <div className={`${styles.row} ${styles.licenceHeader}`}>
                <div className={styles.licenceInfo}>
                  <div className={cn(styles.rowItem, styles.details)}>
                    <div className={styles.caption}>
                      NPI# <span>*</span>
                    </div>
                    <TextField
                      {...register('national_provider_id')}
                      errors={errors}
                      name="npi"
                      value={getValues('national_provider_id')}
                      onChange={(e) => setTextValue(e, 'national_provider_id')}
                      wrapperClassName={styles.inputWrapper}
                      inputClass={styles.input}
                    />
                  </div>
                  <div className={cn(styles.rowItem, styles.details)}>
                    <div className={styles.caption}>
                      Credentials (DDS/DMD) <span>*</span>
                    </div>
                    <SelectInput
                      {...register('credentials')}
                      name="Credentials"
                      options={licenseOptions}
                      containerClass={styles.inputWrapper}
                      selectorClass={styles.selector}
                      style={selectCommonCustomStylesLarge}
                      errors={errors}
                      selectedValue={getValues('credentials')}
                      onChange={(value) => {
                        if (value) {
                          setValue('credentials', value.value, {
                            shouldValidate: true,
                          });
                        }
                      }}
                    />
                  </div>
                </div>
              </div>
              <div className={`${styles.row} ${styles.licencesRow}`}>
                <div className={styles.licencesRowHeader}>
                  Licensure
                  <button
                    type="button"
                    onClick={addLicenseRow}
                    style={{ color: '#3498db', marginRight: '15px', textAlign: 'center' }}
                  >
                    <IoIosAddCircle /> Add License
                  </button>
                </div>
                {reviewProfile && errors.licences && (
                  <div className={`${styles.invalidFeedback}`}>{errors.licences.message}</div>
                )}
                {myProfileForm.licences &&
                  myProfileForm.licences
                    .slice()
                    .sort((a, b) => Number(a.id?.valueOf() ?? Infinity) - Number(b.id?.valueOf() ?? Infinity))
                    .map((licence, index) => (
                      <div className={styles.rowItems}>
                        <div className={cn(styles.rowItem, styles.details)}>
                          <div className={styles.caption} style={{ display: index === 0 ? 'block' : 'none' }}>
                            State of License <span>*</span>
                          </div>
                          <SelectInput
                            {...register(`licences.${licence.id!}.state`)}
                            name="state"
                            options={stateOptions}
                            containerClass={styles.inputWrapper}
                            selectorClass={styles.selector}
                            style={selectCommonCustomStylesLarge}
                            errors={errors}
                            selectedValue={
                              stateOptions.find((option) => option.label.split(' - ')[1] === licence.state) ||
                              licence.state
                            }
                            onChange={(value) => {
                              if (value) {
                                setMyProfileForm((prev) => ({
                                  ...getValues(),
                                  licences: [
                                    ...prev.licences.map((l) => {
                                      if (l.id === licence.id) {
                                        return {
                                          ...l,
                                          state: value.value,
                                        };
                                      }

                                      return l;
                                    }),
                                  ],
                                }));
                              }
                            }}
                          />
                        </div>
                        <div className={cn(styles.rowItem, styles.details)}>
                          <div className={styles.caption} style={{ display: index === 0 ? 'block' : 'none' }}>
                            License # <span>*</span>
                          </div>
                          <TextField
                            {...register(`licences.${licence.id!}.number`)}
                            errors={errors}
                            name="licence_number"
                            value={licence.number}
                            onChange={(e) => {
                              const { value } = e.target;
                              setMyProfileForm((prev) => ({
                                ...getValues(),
                                licences: [
                                  ...prev.licences.map((l) => {
                                    if (l.id === licence.id) {
                                      return {
                                        ...l,
                                        number: value,
                                      };
                                    }
                                    return l;
                                  }),
                                ],
                              }));
                            }}
                            wrapperClassName={styles.inputWrapper}
                            inputClass={styles.input}
                          />
                        </div>
                        <div className={cn(styles.rowItem, styles.details, styles.dobContainer)}>
                          <div className={styles.caption} style={{ display: index === 0 ? 'block' : 'none' }}>
                            Expiry date <span>*</span>
                          </div>
                          <TextField
                            {...register(`licences.${licence.id!}.expiry_date`)}
                            errors={errors}
                            name={`licences.${licence.id!}.expiry_date`}
                            placeholder="MM / DD / YYYY"
                            onChange={(e) => {
                              setMyProfileForm((prev) => ({
                                ...getValues(),
                                licences: [
                                  ...prev.licences.map((l) => {
                                    if (l.id === licence.id) {
                                      return {
                                        ...l,
                                        expiry_date: checkAndSetDate(e),
                                      };
                                    }
                                    return l;
                                  }),
                                ],
                              }));
                            }}
                            value={licence.expiry_date}
                            maxLength={10}
                            wrapperClassName={styles.inputWrapper}
                            inputClass={styles.input}
                            enableCursorPos={false}
                          />
                        </div>
                        {myProfileForm.licences.length > 1 && (
                          <button
                            className={styles.removeBtm}
                            type="button"
                            onClick={() => handleRemoveRow(licence.id!)}
                            aria-label="Remove license"
                          >
                            <TiDelete style={{ marginTop: index === 0 ? '' : '0' }} />
                          </button>
                        )}
                      </div>
                    ))}
              </div>
              <div className={`${styles.row} ${styles.licencesRow}`}>
                <div className={styles.licencesRowHeader}>Enrollments</div>
                <div className={styles.rowItemsContainer}>
                  {myProfileForm.licences && myProfileForm.licences.filter((licence) => licence.state).length > 0 ? (
                    myProfileForm.licences
                      .slice() // Create a shallow copy of the array
                      .sort((a, b) => Number(a.id?.valueOf() ?? Infinity) - Number(b.id?.valueOf() ?? Infinity))
                      .map((licence) => (
                        <>
                          <div className={styles.licencesRowHeader} style={{ fontSize: '14px' }}>
                            {stateOptions.find((option) => option.value === licence.state)?.label.split(' - ')[1]}
                            <button
                              type="button"
                              style={{
                                color: '#3498db',
                                marginRight: '15px',
                                textAlign: 'center',
                              }}
                              onClick={() => {
                                const newInsurancePlan = {
                                  effective_date: '',
                                  recredential_date: '',
                                };
                                setMyProfileForm((prev) => ({
                                  ...getValues(),
                                  licences: [
                                    ...prev.licences.map((l) => {
                                      if (l.id === licence.id) {
                                        return {
                                          ...l,
                                          insurance_carriers: [...l.insurance_carriers, newInsurancePlan],
                                        };
                                      }
                                      return l;
                                    }),
                                  ],
                                }));
                              }}
                            >
                              <IoIosAddCircle /> Add Insurance
                            </button>
                          </div>
                          {licence.insurance_carriers?.map((insurancePlan, insurancePlanIndex) => (
                            <div className={styles.rowItems}>
                              <div className={cn(styles.rowItem, styles.details)}>
                                <div
                                  className={styles.caption}
                                  style={{ fontSize: '14px', display: insurancePlanIndex === 0 ? 'block' : 'none' }}
                                >
                                  Insurance Carrier <span>*</span>
                                </div>
                                <SelectInput
                                  {...register(
                                    `licences.${licence.id!}.insurance_carriers.${insurancePlanIndex}.insurance_carrier_id`,
                                  )}
                                  name="insurance_carrier_id"
                                  options={insurancePlanName}
                                  containerClass={styles.inputWrapper}
                                  selectorClass={styles.selector}
                                  style={selectCommonCustomStylesLarge}
                                  errors={errors}
                                  selectedValue={
                                    insurancePlanName.find(
                                      (option) => Number(option.value) === insurancePlan.insurance_carrier_id,
                                    ) || insurancePlan.insurance_carrier_id
                                  }
                                  onChange={(value) => {
                                    if (value) {
                                      setMyProfileForm((prev) => ({
                                        ...getValues(),
                                        licences: [
                                          ...prev.licences.map((l) => {
                                            if (l.id === licence.id) {
                                              return {
                                                ...l,
                                                insurance_carriers: [
                                                  ...l.insurance_carriers.map((plan, planIndex) => {
                                                    if (planIndex === insurancePlanIndex) {
                                                      return {
                                                        ...plan,
                                                        insurance_carrier_id: value.value,
                                                      };
                                                    }
                                                    return plan;
                                                  }),
                                                ],
                                              };
                                            }
                                            return l;
                                          }),
                                        ],
                                      }));
                                    }
                                  }}
                                />
                              </div>
                              <div className={cn(styles.rowItem, styles.details)}>
                                <div
                                  className={styles.caption}
                                  style={{ fontSize: '14px', display: insurancePlanIndex === 0 ? 'block' : 'none' }}
                                >
                                  Effective Date <span>*</span>
                                </div>
                                <TextField
                                  {...register(
                                    `licences.${licence.id!}.insurance_carriers.${insurancePlanIndex}.effective_date`,
                                  )}
                                  errors={errors}
                                  name="effective_date"
                                  placeholder="MM / DD / YYYY"
                                  onChange={(e) => {
                                    setMyProfileForm((prev) => ({
                                      ...getValues(),
                                      licences: [
                                        ...prev.licences.map((l) => {
                                          if (l.id === licence.id) {
                                            return {
                                              ...l,
                                              insurance_carriers: [
                                                ...l.insurance_carriers.map((plan, planIndex) => {
                                                  if (planIndex === insurancePlanIndex) {
                                                    return {
                                                      ...plan,
                                                      effective_date: checkAndSetDate(e),
                                                    };
                                                  }
                                                  return plan;
                                                }),
                                              ],
                                            };
                                          }
                                          return l;
                                        }),
                                      ],
                                    }));
                                  }}
                                  value={insurancePlan.effective_date}
                                  maxLength={10}
                                  wrapperClassName={styles.inputWrapper}
                                  inputClass={styles.input}
                                  enableCursorPos={false}
                                />
                              </div>
                              <div className={cn(styles.rowItem, styles.details, styles.dobContainer)}>
                                <div
                                  className={styles.caption}
                                  style={{ fontSize: '14px', display: insurancePlanIndex === 0 ? 'block' : 'none' }}
                                >
                                  Re-credentialing Date <span>*</span>
                                </div>
                                <TextField
                                  {...register(
                                    `licences.${licence.id!}.insurance_carriers.${insurancePlanIndex}.recredential_date`,
                                  )}
                                  errors={errors}
                                  name="recredential_date"
                                  placeholder="MM / DD / YYYY"
                                  onChange={(e) => {
                                    setMyProfileForm((prev) => ({
                                      ...getValues(),
                                      licences: [
                                        ...prev.licences.map((l) => {
                                          if (l.id === licence.id) {
                                            return {
                                              ...l,
                                              insurance_carriers: [
                                                ...l.insurance_carriers.map((plan, planIndex) => {
                                                  if (planIndex === insurancePlanIndex) {
                                                    return {
                                                      ...plan,
                                                      recredential_date: checkAndSetDate(e),
                                                    };
                                                  }
                                                  return plan;
                                                }),
                                              ],
                                            };
                                          }
                                          return l;
                                        }),
                                      ],
                                    }));
                                  }}
                                  value={insurancePlan.recredential_date}
                                  maxLength={10}
                                  wrapperClassName={styles.inputWrapper}
                                  inputClass={styles.input}
                                  enableCursorPos={false}
                                />
                              </div>
                              {licence.insurance_carriers.length > 0 && (
                                <button
                                  className={styles.removeBtm}
                                  type="button"
                                  onClick={() => handleRemoveInsurancePlanRow(licence.id!, insurancePlanIndex)}
                                  aria-label="Remove insurance plan"
                                >
                                  <TiDelete style={{ marginTop: insurancePlanIndex === 0 ? '' : '0' }} />
                                </button>
                              )}
                            </div>
                          ))}
                        </>
                      ))
                  ) : (
                    <div className={styles.rowItems}>-</div>
                  )}
                </div>
              </div>
              <div className={styles.separator} />
            </>
          )}
          <div className={styles.row}>
            <div className={cn(styles.rowItem, styles.details, styles.big)}>
              <div className={styles.caption}>Additional Credentials</div>
              <TextField
                {...register('additional_credentials')}
                errors={errors}
                name="additional_credentials"
                value={getValues('additional_credentials')}
                onChange={(e) => setTextValue(e, 'additional_credentials')}
                wrapperClassName={styles.inputWrapper}
                inputClass={styles.input}
              />
            </div>
            {reviewProfile && (
              <div className={cn(styles.rowItem, styles.details, styles.big)}>
                <div className={styles.caption}>
                  Organization <span>*</span>
                </div>
                <MultiselectInput
                  {...register('organization')}
                  name="organization"
                  options={organizationOptions as { value: string; label: string }[]}
                  containerClass={styles.inputWrapper}
                  selectorClass={styles.selector}
                  style={selectCommonCustomStylesLarge}
                  selectedValue={getValues('organization')}
                  errors={errors}
                  onChange={(value) => {
                    if (value) {
                      setValue('organization', value as MultiValue<Option>, { shouldValidate: true });
                    }
                  }}
                />
              </div>
            )}
          </div>
          <div className={styles.row}>
            <div className={cn(styles.rowItem, styles.details)}>
              <div className={styles.caption}>Primary Specialty {!reviewProfile ? <span>*</span> : ''}</div>
              <MultiselectInput
                {...register('primary_specialty')}
                name="primary_specialty"
                placeholder="Select up to 3"
                options={specialtyOptions as { value: string; label: string }[]}
                style={multiSelectCommonCustomStylesLarge}
                containerClass={styles.inputWrapper}
                selectorClass={styles.selector}
                errors={errors}
                selectedValue={getValues('primary_specialty')}
                onChange={(value) => {
                  if (value) {
                    setValue('primary_specialty', value as MultiValue<Option>, {
                      shouldValidate: true,
                    });
                  }
                }}
              />
            </div>
            <div className={cn(styles.rowItem, styles.details)}>
              <div className={styles.caption}>Secondary Specialty</div>
              <MultiselectInput
                {...register('secondary_specialty')}
                name="secondary_specialty"
                placeholder="Select up to 3"
                options={specialtyOptions as { value: string; label: string }[]}
                style={multiSelectCommonCustomStylesLarge}
                containerClass={styles.inputWrapper}
                selectorClass={styles.selector}
                errors={errors}
                selectedValue={getValues('secondary_specialty')}
                onChange={(value) => {
                  if (value) {
                    setValue('secondary_specialty', value as MultiValue<Option>, {
                      shouldValidate: true,
                    });
                  }
                }}
              />
            </div>
          </div>
          <div className={styles.row}>
            <div className={cn(styles.rowItem, styles.details, styles.big)}>
              <div className={styles.caption}>Education / Universities {!reviewProfile ? <span>*</span> : ''}</div>
              <div className={styles.inputWrapper}>
                <CreatableSelect
                  {...register('education')}
                  components={components}
                  inputValue={inputValue}
                  isMulti
                  className={errors.education ? 'is-invalid' : 'is-valid'}
                  menuIsOpen={false}
                  onKeyDown={handleKeyDown}
                  value={getValues('education')?.map((item) => ({ label: item, value: item }))}
                  styles={multiSelectCommonCustomStylesLarge}
                  onInputChange={(newValue) => setInputValue(newValue)}
                  onChange={(value) => {
                    if (value) {
                      setValue(
                        'education',
                        value.map((v) => v.value),
                        { shouldValidate: true },
                      );
                    }
                  }}
                  onBlur={() => {
                    if (inputValue) {
                      setValue('education', [...getValues('education'), inputValue], { shouldValidate: true });
                    }
                  }}
                />
                <div
                  className={`${styles.invalidFeedback} invalid-feedback`}
                  style={{ display: errors.education?.message ? 'block' : 'none' }}
                >
                  {errors.education?.message}
                </div>
              </div>
            </div>
          </div>
          <div className={styles.row}>
            <div className={styles.rowItem}>
              <div className={styles.caption}>Biography {!reviewProfile ? <span>*</span> : ''}</div>
              <textarea
                {...register('biography')}
                className={styles.textarea}
                onChange={(e) => {
                  setValue('biography', e.target.value, { shouldValidate: true });
                  setSelection([e.target.selectionStart, e.target.selectionEnd]);
                }}
                value={getValues('biography')}
                rows={6}
              />
              <div
                className={`${styles.invalidFeedback} invalid-feedback`}
                style={{ display: errors.biography?.message ? 'block' : 'none' }}
              >
                {errors.biography?.message}
              </div>
            </div>
          </div>
          <div className={styles.actionBtnContainer}>
            <Button type="button" className={styles.cancel} onClick={onCancelEditProfile}>
              Cancel
            </Button>
            <Button
              type="submit"
              className={styles.submit}
              disabled={
                (isEqual(myProfileForm, getValues()) &&
                  isEqual(avatarAction, '') &&
                  isEqual(
                    thisUser?.provider_profile?.licenses
                      ?.slice()
                      .sort((a, b) => a.id! - b.id!)
                      .map((license) => ({
                        ...license,
                        expiry_date: moment(license.expiry_date).format('YYYY-MM-DD'),
                        insurance_carriers: license.insurance_carriers.map((plan) => ({
                          insurance_carrier_id: plan.insurance_carrier?.id,
                          effective_date: moment(plan.effective_date).format('YYYY-MM-DD'),
                          recredential_date: moment(plan.recredential_date).format('YYYY-MM-DD'),
                        })),
                      })),
                    myProfileForm.licences
                      .slice()
                      .sort((a, b) => a.id! - b.id!)
                      .map((license) => ({
                        ...license,
                        expiry_date: moment(license.expiry_date).format('YYYY-MM-DD'),
                        insurance_carriers: license.insurance_carriers.map((plan) => ({
                          insurance_carrier_id: plan.insurance_carrier_id,
                          effective_date: moment(plan.effective_date).format('YYYY-MM-DD'),
                          recredential_date: moment(plan.recredential_date).format('YYYY-MM-DD'),
                        })),
                      })),
                  )) ||
                isSavingProfile
              }
            >
              Save
            </Button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default EditProfile;

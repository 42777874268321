import React, { useState, FC } from 'react';
import { DateRangePicker } from 'rsuite';
import 'rsuite/DateRangePicker/styles/index.css';
import './MinimalDateRangePicker.scss';

interface HandleDateRangeFilterChangeProps {
  handleDateRangeFilterChange: (key: string, value: [Date, Date] | null) => void;
  filterKey: string;
  selectedRange: [Date, Date] | null;
}

const MinimalDateRangePicker: FC<HandleDateRangeFilterChangeProps> = ({
  handleDateRangeFilterChange,
  filterKey,
  selectedRange,
}) => {
  const [dateRange, setDateRange] = useState<[Date, Date] | null>(selectedRange);

  const handleOk = (selectedRange: [Date, Date] | null) => {
    if (selectedRange) {
      const [startDate, endDate] = selectedRange;
      if (startDate && endDate && startDate <= endDate) {
        handleDateRangeFilterChange(filterKey, selectedRange);
      } else {
        console.error('Invalid date range: Start date must be before or equal to end date.');
      }
    } else {
      handleDateRangeFilterChange(filterKey, null);
    }
  };

  const handleDateRangeChange = (range: [Date, Date] | null) => {
    setDateRange(range);

    if (!range) {
      handleDateRangeFilterChange(filterKey, null);
    }
  };

  return (
    <div className="dateRangePickerDiv">
      <DateRangePicker
        value={dateRange}
        onChange={handleDateRangeChange}
        onOk={handleOk}
        format="MM/dd/yyyy"
        style={{ width: 280 }}
        ranges={[]}
        placeholder="Select date range"
        cleanable
      />
    </div>
  );
};

export default MinimalDateRangePicker;

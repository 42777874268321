import Container from '@brands/Components/Container/Container';
import Sidenav from '@brands/Dashboard/Sidenav/Sidenav';
import useWindowSize from '@brands/hooks/useWindowSize';
import React, { FC } from 'react';
import { AiOutlineQuestionCircle } from 'react-icons/ai';

import styles from './styles.module.scss';

const TermsOfService: FC = () => {
  const screenSize = useWindowSize();
  return (
    <>
      <Sidenav isVisibleOnMobile hideDefaultOptions authLoog>
        <a
          href="https://dentistryone.zendesk.com/hc/en-us/articles/11914427760151-How-to-change-your-password"
          target="_blank"
          className={styles.sidenavContainer}
          rel="noreferrer"
          style={{ textDecoration: 'none' }}
        >
          <div className={styles.sidenavToggleText}>Help</div>
          <div className={styles.sidenavToggle}>
            <AiOutlineQuestionCircle />
          </div>
        </a>
      </Sidenav>
      <Container
        isBGEclipse={false}
        patientDashboard
        childClassName={styles.dashboard}
        containerStyle={{
          justifyContent: screenSize.width >= 1289 ? 'center' : 'start',
          overflowY: screenSize.width >= 1289 ? 'hidden' : 'scroll',
        }}
      >
        <div className={styles.tOSBody}>
          <div id="content" className={styles.tOSBodySiteContent}>
            <main id="primary" className="site-main">
              <article id="post-889" className="post-889 page type-page status-publish hentry">
                <div className="entry-content">
                  <h2 style={{ marginBottom: '20px', display: 'flex', justifyContent: 'center' }}>
                    <strong>DENTISTRY.ONE AND “DENTISTRY ONE” TERMS OF SERVICE</strong>
                  </h2>

                  <p style={{ fontSize: '15px' }}>
                    Welcome to DENTISTRY.ONE. Please read this Agreement carefully before using the services offered by
                    Dentistry.One LLC (“D1”, “us”, “we”). These terms (the “Agreement”) set forth the terms and
                    conditions of our relationship and govern your (“you”, “yours”, “Users”,) use of the DENTISTRY.ONE
                    website, and various mobile and/or desktop applications (collectively, the “Platform”) and the
                    services, features, content, and applications offered by D1, which among other things provide
                    potential patients access to a network of dental practitioners, who will provide dental and related
                    services by and through an e-commerce marketplace platform (collectively, the “Services”). By
                    accessing and/or using the DENTISTRY.ONE website or the “Dentistry One” Platform and/or Services,
                    you agree to be bound by the terms of this Agreement, which also incorporate D1’s&nbsp;
                    <a
                      style={{ fontSize: 'inherit', color: '#2f64c1' }}
                      href={`${window.location.origin}/privacy-policy`}
                    >
                      Privacy Policy
                    </a>
                    .
                  </p>

                  <p style={{ fontWeight: '800', fontSize: '18px' }}>
                    ARBITRATION NOTICE: UNLESS YOU TIMELY OPT-OUT OF ARBITRATION IN ACCORDANCE WITH THESE TERMS AND
                    CONDITIONS, YOU AGREE THAT DISPUTES BETWEEN YOU AND US OR YOU AND THE&nbsp;
                    <a
                      style={{ fontSize: 'inherit', textDecoration: 'none' }}
                      href={`${window.location.origin}/affiliated-practice-list`}
                    >
                      DENTAL GROUPS
                    </a>
                    &nbsp; OR PROVIDERS ARISING OUT OF OR RELATED TO THESE TERMS AND CONDITIONS OR THE SERVICE WILL BE
                    RESOLVED BY BINDING, INDIVIDUAL ARBITRATION AND YOU WAIVE YOUR RIGHTS TO A JURY TRIAL AND TO
                    PARTICIPATE IN A CLASS ACTION LAWSUIT OR CLASS-WIDE ARBITRATION, AS FURTHER SET FORTH BELOW. WE
                    EXPLAIN SOME EXCEPTIONS AND HOW YOU CAN OPT OUT OF ARBITRATION BELOW.
                  </p>

                  <p style={{ fontWeight: '800', fontSize: '18px' }}>
                    IF YOU THINK YOU HAVE A MEDICAL OR DENTAL HEALTH EMERGENCY, OR IF AT ANY TIME YOU ARE CONCERNED
                    ABOUT YOUR CARE OR TREATMENT, CALL 911 OR GO TO THE NEAREST OPEN CLINIC OR EMERGENCY ROOM.
                  </p>

                  <p
                    style={{
                      marginLeft: '30px',
                      display: 'flex',
                      gap: '18px',
                      fontStyle: 'italic',
                      fontWeight: '800',
                      fontSize: '18px',
                    }}
                  >
                    <span>1.</span>{' '}
                    <span>
                      Your Relationship with D1,&nbsp;
                      <a
                        style={{ fontSize: 'inherit', textDecoration: 'none' }}
                        href={`${window.location.origin}/affiliated-practice-list`}
                      >
                        Dental Groups
                      </a>
                      , and Providers
                    </span>
                  </p>

                  <p style={{ marginLeft: '30px', display: 'flex', gap: '10px' }}>
                    <span>(a)</span>
                    <span>
                      D1 utilizes a network of dental health providers, the services for which are electronically made
                      available by and through the Internet to registered users of the Services and Platform (again,
                      “you”, “yours”, “Users”). In addition, by and through the Services and Platform, D1 provides
                      access to one or more of the following&nbsp;
                      <a
                        style={{ fontSize: 'inherit', textDecoration: 'none' }}
                        href={`${window.location.origin}/affiliated-practice-list`}
                      >
                        Dental Groups
                      </a>{' '}
                      which provide various dental care services through the Services and Platform (collectively, the
                      “&nbsp;
                      <a
                        style={{ fontSize: 'inherit', textDecoration: 'none', backgroundColor: '#ffff0078' }}
                        href={`${window.location.origin}/affiliated-practice-list`}
                      >
                        Dental Groups
                      </a>
                      ”). These&nbsp;
                      <a
                        style={{ fontSize: 'inherit', textDecoration: 'none' }}
                        href={`${window.location.origin}/affiliated-practice-list`}
                      >
                        Dental Groups
                      </a>{' '}
                      engage, employ, or otherwise contract with dentists, and related dental professionals who offer
                      certain dental healthcare services through the telehealth services offered through the Platform
                      (collectively, “Providers”).
                    </span>
                  </p>

                  <p style={{ marginLeft: '30px', display: 'flex', gap: '10px' }}>
                    <span>(b)</span>
                    <span>
                      Even where you are not establishing a dentist-patient, physician-patient, or other health care
                      provider-patient relationship with D1, by using the Services, you are establishing a customer
                      relationship with D1 to use the Services and the Platform.
                    </span>
                  </p>

                  <p style={{ marginLeft: '30px', display: 'flex', gap: '10px' }}>
                    <span>(c)</span>
                    <span>
                      You understand and agree that any dental care or services you receive from the&nbsp;
                      <a
                        style={{ fontSize: 'inherit', textDecoration: 'none' }}
                        href={`${window.location.origin}/affiliated-practice-list`}
                      >
                        Dental Groups
                      </a>{' '}
                      or Providers through the Platform and/or Services are subject to this Agreement in addition to any
                      relationship with the&nbsp;
                      <a
                        style={{ fontSize: 'inherit', textDecoration: 'none' }}
                        href={`${window.location.origin}/affiliated-practice-list`}
                      >
                        Dental Groups
                      </a>{' '}
                      or Providers. You acknowledge that your relationship for dental health care services is with your
                      Provider. You understand that Providers are neither employees nor agents nor representatives of D1
                      and that any dental advice obtained through the Platform and/or Services are not provided by your
                      Provider, not D1. D1 does not control the practice of dental health care by the&nbsp;
                      <a
                        style={{ fontSize: 'inherit', textDecoration: 'none' }}
                        href={`${window.location.origin}/affiliated-practice-list`}
                      >
                        Dental Groups
                      </a>{' '}
                      or any Providers, each of which is solely responsible for directing the dental health care, and/or
                      treatment they provide to you. However, by using the Services, users could be entering into a
                      dentist-patient, or other healthcare provider-patient relationship with the Dental Group and/or
                      one or more Providers.
                    </span>
                  </p>

                  <p style={{ marginLeft: '30px', display: 'flex', gap: '10px' }}>
                    <span>(d)</span>
                    <span>
                      You understand and agree that the&nbsp;
                      <a
                        style={{ fontSize: 'inherit', textDecoration: 'none' }}
                        href={`${window.location.origin}/affiliated-practice-list`}
                      >
                        Dental Groups
                      </a>{' '}
                      or Providers even though they are not parties to this Agreement are still third-party
                      beneficiaries of this Agreement, and by accepting the terms of this Agreement, as a result, you
                      understand and agree that D1 is not itself a medical nor dental service provider and D1 does not
                      render nor provide medical care/services, dental health care/services, or other professional
                      services through Platform and/or Services the Platform and/or Services. D1 does not engage in the
                      practice of medicine or dentistry. Also, you understand that D1 is not in the business of health
                      insurance and is not a health insurance company or provider and D1 is not a prescription
                      fulfillment warehouse.
                    </span>
                  </p>

                  <p style={{ marginLeft: '30px', display: 'flex', gap: '10px' }}>
                    <span>(e)</span>
                    <span>
                      By accepting this Agreement and/or using the Platform and/or the Services, you acknowledge and
                      agree that you may receive information, reports, messages, emails, and/or other communications via
                      the Platform and/or the Services regarding your dental care and/or treatment by the Dental Group
                      and/or Providers. You understand and agree that D1 is not responsible for the security or privacy
                      of communications services you use (such as your personal email, your ISP, etc.) to receive any of
                      the foregoing information, reports, messages, emails, and/or other communications transmitted by
                      and through the Platform and/or the Services. You further understand and agree that it is your
                      responsibility to watch for, monitor, process, and respond to this information, reports, messages,
                      emails, and/or other communications and that neither D1 nor any applicable Dental Group nor any
                      Provider shall bear responsibility nor liability in any way and you will not hold D1, the
                      applicable Dental Group or any Provider liable for any injury, loss, or claims of any nature that
                      results or stems from your failure to monitor, read, or respond to this information, reports,
                      messages, emails, and/or other communications or for your failure to comply with any care,
                      diagnosis, or treatment instructions or recommendations from the applicable Dental Group or your
                      Provider(s).
                    </span>
                  </p>

                  <p style={{ marginLeft: '30px', display: 'flex', gap: '10px' }}>
                    <span>(f)</span>
                    <span>
                      To the extent dental advice is provided to a User by a Provider through the Platform and/or the
                      Services, such dental advice is based on your relationship with the Provider. The dental advice
                      provided by any Provider is not under the control of D1.
                    </span>
                  </p>

                  <p style={{ marginLeft: '30px', display: 'flex', gap: '10px' }}>
                    <span>(g)</span>
                    <span>
                      You are solely responsible for all Submissions from your account. “Submissions” means anything
                      that a user transmits to the Platform and/or Services or to another in the course of using the
                      Platform and/or Services. You shall remain at all times solely responsible for the full
                      functionality, accuracy, reliability, integrity, quality, or validity of any statement, data,
                      facts, information, or any other detail contained in the Submissions.
                    </span>
                  </p>

                  <p style={{ marginLeft: '30px', display: 'flex', gap: '10px' }}>
                    <span>(h)</span>
                    <span>
                      While accessing and/or using the Platform and/or Services, we may obtain information about you or
                      you may be required to provide certain information to us. All uses of your information will be
                      treated in accordance with our&nbsp;
                      <a
                        style={{ fontSize: 'inherit', color: '#2f64c1', backgroundColor: '#ffff0078' }}
                        href={`${window.location.origin}/privacy-policy`}
                      >
                        Privacy Policy
                      </a>
                      , which forms an integral part of this Agreement. If you use the Platform and/or Services, and/or
                      if you register for an account, you are accepting our&nbsp;
                      <a
                        style={{ fontSize: 'inherit', textDecoration: 'none' }}
                        href={`${window.location.origin}/privacy-policy`}
                      >
                        Privacy Policy
                      </a>
                      , as may be amended from time to time. If you do not agree to have your information used in any of
                      the ways described in the&nbsp;
                      <a
                        style={{ fontSize: 'inherit', textDecoration: 'none' }}
                        href={`${window.location.origin}/privacy-policy`}
                      >
                        Privacy Policy
                      </a>
                      , you must discontinue use of the Platform and/or Services. Please also refer to our&nbsp;
                      <a
                        style={{ fontSize: 'inherit', color: '#2f64c1', backgroundColor: '#ffff0078' }}
                        href={`${window.location.origin}/privacy-policy`}
                      >
                        Privacy Policy
                      </a>
                      &nbsp;and the “Personal Information and Protected Health Information” section below for additional
                      information.
                    </span>
                  </p>

                  <p
                    style={{
                      marginLeft: '30px',
                      display: 'flex',
                      gap: '18px',
                      fontStyle: 'italic',
                      fontWeight: '800',
                      fontSize: '18px',
                    }}
                  >
                    <span>2.</span> <span>Your Financial Responsibility for the Services</span>
                  </p>

                  <p style={{ marginLeft: '30px' }}>
                    You agree that you are primarily responsible for payment and agree to pay for Services regardless of
                    whether your insurance or another health care program covers the costs. Neither D1 nor the&nbsp;
                    <a
                      style={{ fontSize: 'inherit', textDecoration: 'none' }}
                      href={`${window.location.origin}/affiliated-practice-list`}
                    >
                      Dental Groups
                    </a>{' '}
                    are generally enrolled with, and are generally not participating providers with, any state, federal,
                    or local healthcare payment and insurance programs (including but not limited to Medicare or
                    Medicaid) for the provision of any dental care services or products. Accordingly, you may not have
                    coverage or be reimbursed for your payments for Services.
                  </p>

                  <p
                    style={{
                      marginLeft: '30px',
                      display: 'flex',
                      gap: '18px',
                      fontStyle: 'italic',
                      fontWeight: '800',
                      fontSize: '18px',
                    }}
                  >
                    <span>3.</span> <span>Conditions of Telehealth/Telemedicine</span>
                  </p>

                  <p style={{ marginLeft: '30px', display: 'flex', gap: '10px' }}>
                    <span>(a)</span>
                    <span>
                      “Telehealth/telemedicine” is the mode of delivering health care services and public health via
                      information and communication technologies to facilitate the diagnosis, consultation, treatment,
                      education, care management, and self-management of a patient’s health care while the patient is at
                      the originating site and the health care provider is at a distant site. Telehealth/telemedicine
                      includes synchronous interactions and asynchronous store-and-forward transfers. “Asynchronous
                      store and forward” means the transmission of a patient’s information from an originating site to
                      the health care provider at a distant site without the presence of the patient.
                    </span>
                  </p>

                  <p style={{ marginLeft: '30px', display: 'flex', gap: '10px' }}>
                    <span>(b)</span>
                    <span>
                      You understand that D1 provides telehealth/telemedicine communications services, by which the
                      primary means of contact between a User and Provider is audio and video, and the Provider and a
                      User are physically in different locations. During your telehealth/telemedicine session,
                      information about your health and/or personal health information may be disclosed. You understand
                      that while you may receive the intended benefit of engaging in telehealth with the Provider,
                      results are not guaranteed. While telehealth and telemedicine products perform some of the same
                      functions of in-person counseling, they are not the same, and therefore you should evaluate
                      whether telehealth/telemedicine is right for you prior to engaging with a Provider.
                    </span>
                  </p>

                  <p style={{ marginLeft: '30px', display: 'flex', gap: '10px' }}>
                    <span>(c)</span>
                    <span>
                      Generally, a licensed dental professional practitioner, provider, or office who provides services
                      via telehealth/telemedicine, such as the Providers, is subject to the requirements and definitions
                      set forth in the laws, rules, or regulations adopted by the state and applicable medical and/or
                      dentistry boards (collectively, the “Laws”). Under the Laws, a healthcare provider may be
                      obligated to inform you telehealth/telemedicine may be used and obtain verbal and/or written
                      consent from the patient for this use (collectively, “Consent Instruments”).
                    </span>
                  </p>

                  <p style={{ marginLeft: '30px', display: 'flex', gap: '10px' }}>
                    <span>(d)</span>
                    <span>
                      Any rights, duties, and obligations arising under any applicable Consent Instruments are strictly
                      between yourself and the Providers, including any Consent Instruments you may electronically
                      and/or digitally sign or execute using any Services, that establishes the legal relationship
                      between you and the applicable Providers. It is the obligation of the Providers to use and
                      disclose any of your information in accordance with applicable state and federal laws, including,
                      without limitation, obtaining any consents or authorizations that may be required for your
                      information to be shared with third parties.
                    </span>
                  </p>

                  <p
                    style={{
                      marginLeft: '30px',
                      display: 'flex',
                      gap: '18px',
                      fontStyle: 'italic',
                      fontWeight: '800',
                      fontSize: '18px',
                    }}
                  >
                    <span>4.</span> <span>Personal Information and Protected Health Information</span>
                  </p>

                  <p style={{ marginLeft: '30px' }}>
                    You understand, and as mentioned above, that when you register an account with D1, you establish a
                    customer relationship with D1, which provides you the tools to access and use the Platform and the
                    Services as a User. The information we collect, like registration information, is generally governed
                    by our&nbsp;
                    <a
                      style={{ fontSize: 'inherit', textDecoration: 'none' }}
                      href={`${window.location.origin}/privacy-policy`}
                    >
                      Privacy Policy
                    </a>
                    . If you contract with us for clinical services that involve or require a human provider, your
                    health, identification, insurance, and payment information will be governed by our&nbsp;
                    <a
                      style={{ fontSize: 'inherit', color: '#2f64c1' }}
                      href={`${window.location.origin}/notice-of-privacy-practices`}
                    >
                      Notice of Privacy Practices
                    </a>{' '}
                    under the Health Insurance Portability and Accountability Act of 1996, Public Law 104-191, and any
                    associated regulations and amendments thereto from time to time (collectively, “HIPAA”). If you
                    provide Protected Health Information (“PHI”), as defined by HIPAA, directly to a Dental Group or
                    Provider, D1 may in some cases be a “business associate” of a Dental Group or a Provider, but in
                    such a case, the Dental Group or Provider would be primarily responsible for your PHI.
                  </p>

                  <p
                    style={{
                      marginLeft: '30px',
                      display: 'flex',
                      gap: '18px',
                      fontStyle: 'italic',
                      fontWeight: '800',
                      fontSize: '18px',
                    }}
                  >
                    <span>5.</span> <span>Electronic Communications</span>
                  </p>
                  <p style={{ marginLeft: '30px', display: 'flex', gap: '10px' }}>
                    <span>(a)</span>
                    <span>
                      When using, accessing, or otherwise communicating with the Platform and/or Services, which may
                      include sending emails or SMS messages to us, any Dental Group, or any Providers, you are
                      communicating with D1, the Dental Group, and Providers electronically.
                    </span>
                  </p>

                  <p style={{ marginLeft: '30px', display: 'flex', gap: '10px' }}>
                    <span>(b)</span>
                    <span>
                      You understand, agree, and hereby consent to send and receive any and all such electronic
                      communications from us, the Dental Group, and any Providers, including any notices required under
                      federal or state privacy laws or HIPAA. We will communicate with you via email, SMS messaging, or
                      through the Platform and/or Services.
                    </span>
                  </p>

                  <p style={{ marginLeft: '30px', display: 'flex', gap: '10px' }}>
                    <span>(c)</span>
                    <span>
                      You further understand, agree, and hereby consent that all disclosures, agreements, notices, and
                      other communications that we electronically provide to you meet and fully satisfy any legal
                      requirement that such communications be in “writing.” You further agree that any notices provided
                      by us electronically are deemed to be given and received on the date we transmit any such
                      electronic communication as described in this Agreement.
                    </span>
                  </p>

                  <p
                    style={{
                      marginLeft: '30px',
                      display: 'flex',
                      gap: '18px',
                      fontStyle: 'italic',
                      fontWeight: '800',
                      fontSize: '18px',
                    }}
                  >
                    <span>6.</span> <span>Duty to Provide Internet Connection and Hardware Equipment</span>
                  </p>

                  <p style={{ marginLeft: '30px' }}>
                    You understand that you are responsible for providing and maintaining, at your own expense,
                    appropriate software and hardware equipment (as appropriate for any technical, quality, or other
                    requirements set forth by us in order to utilize the Services) to enable use of the Services,
                    including but not limited to, a computer or mobile device with a video camera and Internet access.
                    You also understand that you bear sole responsibility for any costs, fees, and charges, that you
                    incur when using or accessing the Services including Internet connection or mobile fees. From time
                    to time, we may have to change the requirements to utilize the Services, including any software,
                    hardware, or other requirements and we reserve the right at any time without prior notice.
                  </p>

                  <p
                    style={{
                      marginLeft: '30px',
                      display: 'flex',
                      gap: '18px',
                      fontStyle: 'italic',
                      fontWeight: '800',
                      fontSize: '18px',
                    }}
                  >
                    <span>7.</span> <span>Content and Services Accessed Through the Services</span>
                  </p>

                  <p style={{ marginLeft: '30px' }}>
                    You may choose to allow a third-party service provider (such as a personal health record provider,
                    health care providers, and any other entity chosen by You) to retrieve, provide, modify, or
                    otherwise use health and other information in Your account or otherwise share Your information with
                    the service provider. Once You permit a specific third-party service provider to access Your
                    account, such service provider may continue to access Your account until You affirmatively disable
                    access. It is Your sole responsibility to review and approve each such third-party service before
                    sharing Your information through or otherwise accessing it. USE OF THESE SERVICES AND RELIANCE ON
                    THIS CONTENT IS SOLELY AT YOUR OWN RISK. D1 MAY NOT BE HELD LIABLE FOR ANY DAMAGES ARISING OUT OF OR
                    RELATED TO YOUR USE OF ANY THIRD-PARTY SERVICE OR CONTENT.
                  </p>

                  <p
                    style={{
                      marginLeft: '30px',
                      display: 'flex',
                      gap: '18px',
                      fontStyle: 'italic',
                      fontWeight: '800',
                      fontSize: '18px',
                    }}
                  >
                    <span>8.</span> <span>You are Responsible for Reviewing Changes to this Agreement</span>
                  </p>
                  <p style={{ marginLeft: '30px', display: 'flex', gap: '10px' }}>
                    <span>(a)</span>
                    <span>
                      This Agreement applies to all users of the Platform and/or Services, including Users and
                      Providers.
                    </span>
                  </p>
                  <p style={{ marginLeft: '30px', display: 'flex', gap: '10px' }}>
                    <span>(b)</span>
                    <span>
                      This Agreement supersedes all prior or contemporaneous agreements, representations, warranties,
                      and understandings with respect to the Platform and/or Services, the content, products, or
                      services provided by or through the Platform and/or Services, and the subject matter of this
                      Agreement.
                    </span>
                  </p>
                  <p style={{ marginLeft: '30px', display: 'flex', gap: '10px' }}>
                    <span>(c)</span>
                    <span>
                      D1 may make changes to this Agreement from time to time without specifically notifying you. If D1
                      has your email address on file, it will alert you to updates.
                    </span>
                  </p>
                  <p style={{ marginLeft: '30px', display: 'flex', gap: '10px' }}>
                    <span>(d)</span>
                    <span>
                      D1 will post the latest Agreement on the Platform or within its various applications facilitating
                      the Services, but it is up to you to review it before using the Platform and/or Services.
                    </span>
                  </p>
                  <p style={{ marginLeft: '30px', display: 'flex', gap: '10px' }}>
                    <span>(e)</span>
                    <span>
                      If you continue to use the Platform and/or Services after any of these changes, your continued use
                      will mean that you have accepted any changes to the Agreement.
                    </span>
                  </p>
                  <p style={{ marginLeft: '30px', display: 'flex', gap: '10px' }}>
                    <span>(f)</span>
                    <span>
                      In addition, some services offered through the Platform and/or Services may be subject to
                      additional terms and conditions specified by D1 from time to time and your use of such services is
                      subject to those additional terms and conditions, which are incorporated into this Agreement by
                      this reference.
                    </span>
                  </p>

                  <p
                    style={{
                      marginLeft: '30px',
                      display: 'flex',
                      gap: '18px',
                      fontStyle: 'italic',
                      fontWeight: '800',
                      fontSize: '18px',
                    }}
                  >
                    <span>9.</span>{' '}
                    <span>D1 May Edit or Modify Anything on the Platform and/or Services without Notice</span>
                  </p>

                  <p style={{ marginLeft: '30px' }}>
                    D1 is committed to delivering a positive user experience and you understand that D1 reserves the
                    right (but without undertaking any duty) to edit, monitor, review, delete, modify, or move any
                    content or material provided or placed on or through the Platform and/or Services in its sole
                    discretion, without notice and without undertaking any duty to do so. In the event that such a
                    change materially impacts prepaid Services, we will either, at our sole option, credit the unused
                    portion pro-rata or offer substitute services, for the remainder of the prepaid period.
                  </p>

                  <p
                    style={{
                      marginLeft: '30px',
                      display: 'flex',
                      gap: '18px',
                      fontStyle: 'italic',
                      fontWeight: '800',
                      fontSize: '18px',
                    }}
                  >
                    <span>10.</span>{' '}
                    <span>You are Responsible for Accepting Updated Versions of the Platform and/or Services</span>
                  </p>
                  <p style={{ marginLeft: '30px', display: 'flex', gap: '10px' }}>
                    <span>(a)</span>
                    <span>
                      If D1 provides updated versions of the Platform and/or Services and you do not accept these
                      updated versions, you may not enjoy the most recent content, feature sets, and materials.
                    </span>
                  </p>
                  <p style={{ marginLeft: '30px', display: 'flex', gap: '10px' }}>
                    <span>(b)</span>
                    <span>
                      If you do not accept updated versions of the Platform and/or Services, D1 shall not bear any
                      responsibility or liability for your decision.
                    </span>
                  </p>

                  <p
                    style={{
                      marginLeft: '30px',
                      display: 'flex',
                      gap: '18px',
                      fontStyle: 'italic',
                      fontWeight: '800',
                      fontSize: '18px',
                    }}
                  >
                    <span>11.</span> <span>What Laws and Rules You Are Responsible for Following</span>
                  </p>

                  <p style={{ marginLeft: '30px', display: 'flex', gap: '10px' }}>
                    <span>(a)</span>
                    <span>
                      By visiting, accessing, registering with, or using the Service, you are (a) representing and
                      warranting to us that you are either (1) at least eighteen (18) years of age or older or at least
                      the age of majority in your jurisdiction of residence, if higher than eighteen (18), or (2) at
                      least thirteen (13) years of age, have obtained consent from your parent or legal guardian to use
                      the Service, and will use the Service for the sole purpose of obtaining a medical consultation for
                      the treatment of acne; and (b) if you are a parent or legal guardian providing consent for an
                      individual minor who is under the age of eighteen (18) (or such higher age of majority under
                      applicable state law), representing and warranting to us that you are the parent or legal guardian
                      for such minor and have all legal power and authority to provide consent for the use of the
                      Platform and/or the Services by such minor.
                    </span>
                  </p>

                  <p style={{ marginLeft: '30px', display: 'flex', gap: '10px' }}>
                    <span>(b)</span>
                    <span>
                      You promise not to use the Platform and/or Services for any purpose that is prohibited by this
                      Agreement or applicable law.
                    </span>
                  </p>

                  <p style={{ marginLeft: '30px', display: 'flex', gap: '10px' }}>
                    <span>(c)</span>
                    <span>
                      You are responsible for all of your activity in connection with the Platform and/or Services.
                    </span>
                  </p>

                  <p style={{ marginLeft: '30px', display: 'flex', gap: '10px' }}>
                    <span>(d)</span>
                    <span>
                      You understand that you will be fully responsible for all activities that occur under your
                      account, user name, and/or password.
                    </span>
                  </p>

                  <p style={{ marginLeft: '30px', display: 'flex', gap: '10px' }}>
                    <span>(e)</span>
                    <span>
                      You agree not to allow someone who is not you to use your account, username, or password at any
                      time.
                    </span>
                  </p>

                  <p style={{ marginLeft: '30px', display: 'flex', gap: '10px' }}>
                    <span>(f)</span>
                    <span>
                      You agree to immediately notify D1 of any unauthorized use of your password or your user name.
                    </span>
                  </p>

                  <p style={{ marginLeft: '30px', display: 'flex', gap: '10px' }}>
                    <span>(g)</span>
                    <span>
                      We strongly encourage you to change your password at least once a month and you understand that D1
                      will not be liable for any loss that you incur as a result of someone else using your account or
                      your password.
                    </span>
                  </p>

                  <p style={{ marginLeft: '30px', display: 'flex', gap: '10px' }}>
                    <span>(h)</span>
                    <span>
                      Our Platform and the Services are subject to state regulations and may change from time to time
                      due to changes in applicable regulatory requirements.
                    </span>
                  </p>

                  <p
                    style={{
                      marginLeft: '30px',
                      display: 'flex',
                      gap: '18px',
                      fontStyle: 'italic',
                      fontWeight: '800',
                      fontSize: '18px',
                    }}
                  >
                    <span>12.</span>{' '}
                    <span>
                      It is your responsibility to make sure this Agreement and your use of the Platform and/or Services
                      comply with all laws applicable to you
                    </span>
                  </p>

                  <p style={{ marginLeft: '30px', display: 'flex', gap: '10px' }}>
                    <span>(a)</span>
                    <span>
                      You understand D1 may, in its sole discretion, refuse to offer the Platform and/or Services to any
                      person or entity and change its eligibility criteria at any time and that D1 reserves the right
                      (without undertaking any duty) to use “geo-filtering” or other technologies to block access to
                      certain jurisdictions, in its sole discretion, without notice.
                    </span>
                  </p>

                  <p style={{ marginLeft: '30px', display: 'flex', gap: '10px' }}>
                    <span>(b)</span>
                    <span>
                      You are solely responsible for ensuring that this Agreement complies with all laws, rules, and
                      regulations applicable to you.
                    </span>
                  </p>

                  <p style={{ marginLeft: '30px', display: 'flex', gap: '10px' }}>
                    <span>(c)</span>
                    <span>
                      You understand that your right to access the Platform and/or Services will be revoked where this
                      Agreement or use of the Platform and/or Services is prohibited and, if that is the case, you agree
                      not to use or access the Platform and/or Services in any way.
                    </span>
                  </p>

                  <p
                    style={{
                      marginLeft: '30px',
                      display: 'flex',
                      gap: '18px',
                      fontStyle: 'italic',
                      fontWeight: '800',
                      fontSize: '18px',
                    }}
                  >
                    <span>13.</span>{' '}
                    <span>
                      D1 may terminate your use of the Platform and/or Services with or without cause or prior Notice to
                      You
                    </span>
                  </p>

                  <p style={{ marginLeft: '30px', display: 'flex', gap: '10px' }}>
                    <span>(a)</span>
                    <span>
                      While D1 respects its Users, you agree that D1 may terminate your membership or use of the
                      Platform and/or Services and prohibit you from accessing the Platform and/or Services, at any
                      time, without cause, and with or without notice. If you have contracted for prepaid Services, and
                      we terminate under this subsection we will either, at our sole option, credit the unused portion
                      pro-rata or offer substitute services, for the remainder of the prepaid period.
                    </span>
                  </p>
                  <p style={{ marginLeft: '30px', display: 'flex', gap: '10px' }}>
                    <span>(b)</span>
                    <span>
                      If in our sole discretion, you fail to comply with any term or provision of this Agreement, we may
                      terminate this Agreement at any time and may do so immediately without notice, and deny you access
                      to the Platform and/or Services.
                    </span>
                  </p>
                  <p style={{ marginLeft: '30px', display: 'flex', gap: '10px' }}>
                    <span>(c)</span>
                    <span>
                      Upon any termination of this Agreement by either you or D1, the following sections will survive
                      any such termination: “Your Relationship with D1,&nbsp;
                      <a
                        style={{ fontSize: 'inherit', textDecoration: 'none' }}
                        href={`${window.location.origin}/affiliated-practice-list`}
                      >
                        Dental Groups
                      </a>
                      , and Providers,” “Your Financial Responsibility for the Services,” “Conditions of
                      Telehealth/Telemedicine”, “Personal Information and Protected Health Information”, “D1 may edit or
                      modify anything on the Platform and/or Services without Notice,” “Intellectual Property,” You Must
                      Have Necessary Rights to Share Content and Materials,” “D1 may terminate your use of the Platform
                      and/or Services with or without cause or prior notice to You,” “Legal Conditions and Limitation of
                      D1’s Liability,” “General Disclaimer and Limitation of Liability,” “You agree to indemnify D1, the
                      &nbsp;
                      <a
                        style={{ fontSize: 'inherit', textDecoration: 'none' }}
                        href={`${window.location.origin}/affiliated-practice-list`}
                      >
                        Dental Groups
                      </a>
                      , and the Providers” and “Binding Arbitration Agreement / Class Waiver.”
                    </span>
                  </p>

                  <p
                    style={{
                      marginLeft: '30px',
                      display: 'flex',
                      gap: '18px',
                      fontStyle: 'italic',
                      fontWeight: '800',
                      fontSize: '18px',
                    }}
                  >
                    <span>14.</span>{' '}
                    <span>You agree to comply with D1’s conduct policies when using the Platform and/or Services</span>
                  </p>
                  <span
                    style={{
                      marginLeft: '30px',
                      display: 'block',
                    }}
                  >
                    We do our best to keep the Platform and/or Services safe and operational, but we cannot guarantee
                    it. We need your help to do that, which includes the following commitments:
                  </span>

                  <p style={{ marginLeft: '30px', display: 'flex', gap: '10px' }}>
                    <span>(a)</span>
                    <span>
                      You will not modify, adapt, appropriate, reproduce, distribute, translate, create derivative works
                      or adaptations of, publicly display, republish, repurpose, sell, trade, or in any way exploit the
                      Platform and/or Services except as expressly authorized by D1;
                    </span>
                  </p>

                  <p style={{ marginLeft: '30px', display: 'flex', gap: '10px' }}>
                    <span>(b)</span>
                    <span>
                      You will not take any action that imposes or may impose (as determined by D1 in its sole
                      discretion) an unreasonable or disproportionately large load on D1’s (or its third-party
                      providers’) infrastructure.
                    </span>
                  </p>

                  <p style={{ marginLeft: '30px', display: 'flex', gap: '10px' }}>
                    <span>(c)</span>
                    <span>
                      You will not decipher, decompile, disassemble, reverse engineer, or otherwise attempt to derive
                      any source code or underlying ideas or algorithms of any part of the Services, except to the
                      limited extent applicable laws specifically prohibit such restriction;
                    </span>
                  </p>

                  <p style={{ marginLeft: '30px', display: 'flex', gap: '10px' }}>
                    <span>(d)</span>
                    <span>
                      You will not interfere or attempt to interfere with the proper working of the Platform and/or
                      Services or any activities conducted on the Platform and/or Services;
                    </span>
                  </p>

                  <p style={{ marginLeft: '30px', display: 'flex', gap: '10px' }}>
                    <span>(e)</span>
                    <span>
                      You will not bypass any measures D1 may use to prevent or restrict access to the Platform and/or
                      Services (or other accounts, computer systems, or networks connected to the Platform and/or
                      Services);
                    </span>
                  </p>

                  <p style={{ marginLeft: '30px', display: 'flex', gap: '10px' }}>
                    <span>(f)</span>
                    <span>You will not run any form of auto-responder or “spam” on the Platform and/or Services;</span>
                  </p>

                  <p style={{ marginLeft: '30px', display: 'flex', gap: '10px' }}>
                    <span>(g)</span>
                    <span>
                      You will not use manual or automated software, devices, or other processes to “crawl” or “spider”
                      any page of the Platform and/or Services;
                    </span>
                  </p>

                  <p style={{ marginLeft: '30px', display: 'flex', gap: '10px' }}>
                    <span>(h)</span>
                    <span>
                      You will not harvest or scrape any content or materials from the Platform and/or Services;
                    </span>
                  </p>

                  <p style={{ marginLeft: '30px', display: 'flex', gap: '10px' }}>
                    <span>(i)</span>
                    <span>
                      You will not copy, rent, lease, distribute, or otherwise transfer any of the rights that you
                      receive hereunder;
                    </span>
                  </p>

                  <p style={{ marginLeft: '30px', display: 'flex', gap: '10px' }}>
                    <span>(j)</span>
                    <span>You will not threaten, intimidate, or harass another user, a User, or any Provider;</span>
                  </p>

                  <p style={{ marginLeft: '30px', display: 'flex', gap: '10px' }}>
                    <span>(k)</span>
                    <span>
                      You will not solicit passwords or personally identifying information (this includes, but is not
                      limited to, someone else’s name, birthdate, home address, IP address, social security number, or
                      credit card number) for commercial or unlawful purposes;
                    </span>
                  </p>

                  <p style={{ marginLeft: '30px', display: 'flex', gap: '10px' }}>
                    <span>(l)</span>
                    <span>
                      You will not upload, post, transmit, share, store, or otherwise make available any material that
                      contains software viruses or any other computer code, files, or programs designed to interrupt,
                      destroy, or limit the functionality of any computer software or hardware or telecommunications
                      equipment;
                    </span>
                  </p>

                  <p style={{ marginLeft: '30px', display: 'flex', gap: '10px' }}>
                    <span>(m)</span>
                    <span>
                      You will not upload, post, transmit, share, store, or otherwise make available any content (such
                      as a picture on a profile page) other than those of a personal nature that: (i) are of you (ii)
                      are taken by you or your friends, or (iii) are original content created by you;
                    </span>
                  </p>

                  <p style={{ marginLeft: '30px', display: 'flex', gap: '10px' }}>
                    <span>(n)</span>
                    <span>
                      You will not knowingly transmit false or misleading information through the Platform and/or
                      Services.
                    </span>
                  </p>

                  <p style={{ marginLeft: '30px', display: 'flex', gap: '10px' }}>
                    <span>(o)</span>
                    <span>
                      You will not use the Platform and/or Services in any manner or transmit any Submission that:
                      infringes (or results in the infringement of) D1’s or any third party’s intellectual property or
                      other rights; is (or you reasonably believe to be) illegal, fraudulent, or unauthorized, or in
                      furtherance of any illegal, counterfeiting, fraudulent, pirating, unauthorized, or violent
                      activity, or that involves (or you reasonably believe to involve) any stolen, illegal,
                      counterfeit, fraudulent, pirated, or unauthorized material; does not comply with all applicable
                      laws, rules, or regulations, including obtaining all necessary permits, licenses, registrations,
                      etc. In the case of any proposed or actual transaction, “applicable” refers to both your own
                      location and to the location(s) of all other parties to the transaction; or would cause D1 to be
                      in violation of any law or regulation, or to infringe any right of any third party.
                    </span>
                  </p>

                  <p style={{ marginLeft: '30px', display: 'flex', gap: '10px' }}>
                    <span>(p)</span>
                    <span>
                      You will not publish falsehoods or misrepresentations that may damage D1 or any third party.
                    </span>
                  </p>

                  <p style={{ marginLeft: '30px', display: 'flex', gap: '10px' }}>
                    <span>(q)</span>
                    <span>
                      You will not transmit any Submission and will not use the Platform and/or Services to post, store,
                      transmit, offer, or solicit anything that contains the following, or that you know contains links
                      to the following or to locations that in turn contain links to the following: material that we
                      determine to be offensive (including material promoting or glorifying hate, violence, bigotry, or
                      any entity (past or present) principally dedicated to such causes, or items associated with such
                      an entity); material that is racially or ethnically insensitive, material that is defamatory,
                      harassing or threatening; pornography (including any obscene material, and anything depicting
                      children in sexually suggestive situations whether or not depicting actual children) or may be
                      harmful to a minor; any virus, worm, Trojan horse, or other harmful or disruptive component; or
                      anything that encourages conduct that would be considered a criminal offense, give rise to civil
                      liability, violate any law or regulation or is otherwise inappropriate.
                    </span>
                  </p>

                  <p style={{ marginLeft: '30px', display: 'flex', gap: '10px' }}>
                    <span>(r)</span>
                    <span>
                      You will not use the Platform and/or Services to post, store, or transmit advertising, promotional
                      material, or solicitations of goods or services, except in areas of the Platform and/or Services
                      designated for the purpose.
                    </span>
                  </p>

                  <p style={{ marginLeft: '30px', display: 'flex', gap: '10px' }}>
                    <span>(s)</span>
                    <span>You will not otherwise take any action in violation of D1’s guidelines and policies.</span>
                  </p>

                  <p
                    style={{
                      marginLeft: '30px',
                      display: 'flex',
                      gap: '18px',
                      fontStyle: 'italic',
                      fontWeight: '800',
                      fontSize: '18px',
                    }}
                  >
                    <span>15.</span>{' '}
                    <span>
                      D1 is not responsible for what happens outside of the Platform and/or Services, including
                      third-party websites that the Platform and/or Services may link to or advertise on behalf of D1
                    </span>
                  </p>

                  <p style={{ marginLeft: '30px', display: 'flex', gap: '10px' }}>
                    <span>(a)</span>
                    <span>
                      D1 or its business partners may present advertisements or promotional materials via the Platform
                      and/or Services.
                    </span>
                  </p>

                  <p style={{ marginLeft: '30px', display: 'flex', gap: '10px' }}>
                    <span>(b)</span>
                    <span>
                      Your dealings with, or participation in promotions of any third-party advertisers via the Platform
                      and/or Service are solely between you and such third party and your participation is subject to
                      the terms and conditions associated with that advertisement or promotion.
                    </span>
                  </p>

                  <p style={{ marginLeft: '30px', display: 'flex', gap: '10px' }}>
                    <span>(c)</span>
                    <span>
                      You agree that D1 is not responsible or liable for any loss or damage of any sort incurred as the
                      result of any such dealings or as the result of the presence of such third parties via the
                      Services.
                    </span>
                  </p>

                  <p style={{ marginLeft: '30px', display: 'flex', gap: '10px' }}>
                    <span>(d)</span>
                    <span>
                      You understand the Platform and/or Services may contain (or you may receive from D1, third parties
                      or users) links to other websites (“Third-Party Platforms”) or content posted, owned, or
                      originating from third parties such as, by way of example only, pictures, designs, photographs,
                      graphics, text, sound, recipes, video, information, software, applications and any other content
                      (“Third-Party Content”).
                    </span>
                  </p>

                  <p style={{ marginLeft: '30px', display: 'flex', gap: '10px' }}>
                    <span>(e)</span>
                    <span>
                      You understand and agree that D1 is not responsible for, and does not control, Third-Party
                      Platforms and Third-Party Content. You also understand and agree that D1 is not responsible for
                      the availability of such external sites or resources and does not endorse and is not responsible
                      or liable for any content, advertising, products, or other materials on or available from such
                      Third-Party Platforms or Third-Party Content.
                    </span>
                  </p>

                  <p style={{ marginLeft: '30px', display: 'flex', gap: '10px' }}>
                    <span>(f)</span>
                    <span>
                      You acknowledge and agree that D1 shall not be responsible or liable, directly or indirectly, for
                      any damage or loss caused or alleged to be caused by or in connection with the use of, or reliance
                      upon, any such content, goods or services available on or through any such Third-Party Platform or
                      Third-Party Content.
                    </span>
                  </p>

                  <p style={{ marginLeft: '30px', display: 'flex', gap: '10px' }}>
                    <span>(g)</span>
                    <span>
                      Your interactions with Third-Party Platforms are subject to the terms of service and privacy
                      statements/policies/disclosures of any such Third-Party Platforms.
                    </span>
                  </p>

                  <p
                    style={{
                      marginLeft: '30px',
                      display: 'flex',
                      gap: '18px',
                      fontStyle: 'italic',
                      fontWeight: '800',
                      fontSize: '18px',
                    }}
                  >
                    <span>16.</span> <span>Intellectual Property</span>
                  </p>
                  <p style={{ marginLeft: '30px', display: 'flex', gap: '10px' }}>
                    <span>(a)</span>
                    <span>
                      You agree that the content, organization, graphics, and design of the Platform and/or Services
                      (“Copyright Content”) are the property of D1 or may be, to a certain extent, the property of its
                      content suppliers, including but not limited to Providers, and are protected under applicable
                      copyright law, trademark law, and other proprietary rights. You may not copy, redistribute, use,
                      or publish any part of the Platform and/or Services, except as allowed by this Agreement. You do
                      not acquire ownership rights to any content, documents, or other materials viewed through the
                      Platform and/or Services.
                    </span>
                  </p>

                  <p style={{ marginLeft: '30px', display: 'flex', gap: '10px' }}>
                    <span>(b)</span>
                    <span>
                      You agree that MOUTHWATCH, MOUTHWATCH.COM, DENTISTRY ONE, DENTISTRY ONE.COM, and D1 graphics,
                      logos, page headers, button icons, scripts, and service names are trademarks, registered
                      trademarks, or trade dress of D1 or its affiliates (“Trademark Content”).
                    </span>
                  </p>

                  <p style={{ marginLeft: '30px', display: 'flex', gap: '10px' }}>
                    <span>(c)</span>
                    <span>
                      D1 trademarks and trade dress may not be used in connection with any product or service that is
                      not D1’s, in any manner that is likely to cause confusion among customers, or in any manner that
                      disparages or discredits D1.
                    </span>
                  </p>

                  <p style={{ marginLeft: '30px', display: 'flex', gap: '10px' }}>
                    <span>(d)</span>
                    <span>
                      All other trademarks not owned by D1 or its affiliates that may appear on this Platform and/or
                      Services are the property of their respective owners, who may or may not be affiliated with,
                      connected to, or sponsored by D1 or its affiliates.
                    </span>
                  </p>

                  <p style={{ marginLeft: '30px', display: 'flex', gap: '10px' }}>
                    <span>(e)</span>
                    <span>
                      You may use the D1 Platform and/or Services for limited, noncommercial purposes D1 grants you a
                      limited license to access and make personal use of the Platform and/or Services. D1 does not grant
                      you the right to download (other than page caching) or modify the Platform and/or Services, or any
                      portion of the Platform and/or Services. You understand D1 does not grant you the right to resell
                      or make commercial use (except as provided herein or in the applicable Provider’s Terms and
                      Conditions) of the Platform and/or Services or their contents; make any derivative use of the
                      Platform and/or Services or their contents; any downloading or copying of account information for
                      the benefit of a third party, or merchant; or any use of data mining, robots, or similar data
                      gathering and extraction tools.
                    </span>
                  </p>

                  <p style={{ marginLeft: '30px', display: 'flex', gap: '10px' }}>
                    <span>(f)</span>
                    <span>
                      You understand the Platform and/or Services or any portion of Platform and/or Services may not be
                      reproduced, duplicated, copied, sold, resold, visited, or otherwise exploited for any commercial
                      purpose.
                    </span>
                  </p>

                  <p style={{ marginLeft: '30px', display: 'flex', gap: '10px' }}>
                    <span>(g)</span>
                    <span>
                      You may not frame or utilize framing techniques to enclose any trademark, logo, or other
                      proprietary information of D1 and our affiliates without express written consent.
                    </span>
                  </p>

                  <p style={{ marginLeft: '30px', display: 'flex', gap: '10px' }}>
                    <span>(h)</span>
                    <span>
                      You may not use any meta tags or any other “hidden text” utilizing D1’s name or trademarks without
                      the express written consent of D1. Any unauthorized use terminates the permission or license
                      granted by D1.
                    </span>
                  </p>

                  <p style={{ marginLeft: '30px', display: 'flex', gap: '10px' }}>
                    <span>(i)</span>
                    <span>
                      You may not engage in the copying, reproduction, publication, rearrangement, redistribution,
                      modification, revision, alteration, or reverse engineering, of the Services.
                    </span>
                  </p>

                  <p style={{ marginLeft: '30px', display: 'flex', gap: '10px' }}>
                    <span>(j)</span>
                    <span>
                      You are granted a limited, revocable, and nonexclusive right to create a hyperlink, or at D1’s
                      sole discretion other items such as widgets, buttons, or other web page elements to the home page
                      of D1 so long as the link does not portray D1, its affiliates, or their products or services in a
                      false, misleading, derogatory, or otherwise offensive matter.
                    </span>
                  </p>

                  <p style={{ marginLeft: '30px', display: 'flex', gap: '10px' }}>
                    <span>(k)</span>
                    <span>
                      You may not use any D1 logo or other proprietary graphic or trademark as part of the link without
                      express written permission.
                    </span>
                  </p>
                  <p
                    style={{
                      marginLeft: '30px',
                      display: 'flex',
                      gap: '18px',
                      fontStyle: 'italic',
                      fontWeight: '800',
                      fontSize: '18px',
                    }}
                  >
                    <span>17.</span> <span>You Must Have Necessary Rights to Share Content and Materials</span>
                  </p>
                  <p style={{ marginLeft: '30px', display: 'flex', gap: '10px' }}>
                    <span>(a)</span>
                    <span>
                      You understand that you may be invited to participate in blogs, online forums, promotions,
                      surveys, and other such items and that, accordingly, we may provide you with the opportunity, to
                      create, submit, post, transmit, or distribute content and materials to us via the Platform, such
                      as text, drawings, icons, photos, etc. (collectively, “User Content”).
                    </span>
                  </p>

                  <p style={{ marginLeft: '30px', display: 'flex', gap: '10px' }}>
                    <span>(b)</span>
                    <span>
                      You warrant that either: (a) your User Content is wholly original to you and you exclusively own
                      the rights to your User Content, including the right to grant all of the rights and licenses in
                      this Agreement; or (b) all parties whose materials are included in your User Content, or who
                      contributed in any way, are depicted in, or have any rights to your User Content, have granted you
                      permission to submit and license your User Content to D1 as set forth herein, with full knowledge
                      that D1 may exploit it in any manner whatsoever. You make such warranties without D1 incurring any
                      third-party obligations or liability arising out of its exercise of the rights thereto granted
                      herein by you.
                    </span>
                  </p>

                  <p style={{ marginLeft: '30px', display: 'flex', gap: '10px' }}>
                    <span>(c)</span>
                    <span>
                      You grant to D1 the non-exclusive, unrestricted, unconditional, unlimited, worldwide, irrevocable,
                      perpetual and royalty-free right and license to host, use, copy, distribute, reproduce, disclose,
                      sell, resell, sub-license, display, perform, transmit, publish, broadcast, modify, make derivative
                      works from, re-title, reformat, translate, archive, store, cache or otherwise exploit in any
                      manner whatsoever, all or any portion of your User Content, for any purpose whatsoever, in any and
                      all formats; on or through any and all media, software, formula or medium now known or hereafter
                      known; and with any technology or devices now known or hereafter developed and to advertise,
                      market and promote same.
                    </span>
                  </p>

                  <p style={{ marginLeft: '30px', display: 'flex', gap: '10px' }}>
                    <span>(d)</span>
                    <span>
                      You further agree that D1 is free to use any ideas, concepts, know-how, or techniques contained in
                      any User Content you send to the Platform or D1, for any purposes whatsoever, including, without
                      limitation, developing, producing, marketing, and otherwise exploiting products and/or services
                      using such User Content, and without remuneration of any kind.
                    </span>
                  </p>

                  <p style={{ marginLeft: '30px', display: 'flex', gap: '10px' }}>
                    <span>(e)</span>
                    <span>
                      You further perpetually and irrevocably grant D1 the unconditional right to use and exploit your
                      name, persona, and likeness included in any User Content.
                    </span>
                  </p>

                  <p style={{ marginLeft: '30px', display: 'flex', gap: '10px' }}>
                    <span>(f)</span>
                    <span>
                      You also grant to D1 the right to sub-license and authorize others to exercise any of the rights
                      granted to D1.
                    </span>
                  </p>

                  <p style={{ marginLeft: '30px', display: 'flex', gap: '10px' }}>
                    <span>(g)</span>
                    <span>
                      You authorize D1 to publish your User Content in a searchable format that may be accessed by users
                      of the Platform and the Internet, and you waive any rights of attribution and/or any so-called
                      moral rights you may have in any User Content you submit, even if such User Content is altered or
                      changed in a manner not agreeable to you.
                    </span>
                  </p>
                  <p
                    style={{
                      marginLeft: '30px',
                      display: 'flex',
                      gap: '18px',
                      fontStyle: 'italic',
                      fontWeight: '800',
                      fontSize: '18px',
                    }}
                  >
                    <span>18.</span> <span>Feedback</span>
                  </p>
                  <p
                    style={{
                      marginLeft: '30px',
                      display: 'block',
                    }}
                  >
                    If you send or transmit any communications, comments, questions, suggestions, or related materials
                    to D1, whether by letter, email, telephone, or otherwise (collectively, “Feedback”), suggesting or
                    recommending changes to the Platform and/or Services, including, without limitation, new features or
                    functionality relating thereto, all such Feedback is, and will be treated as, non-confidential and
                    non-proprietary. You hereby assign all rights, title, and interest in, and D1 is free to use,
                    without any attribution or compensation to you, any ideas, know-how, concepts, techniques, or other
                    intellectual property and proprietary rights contained in the Feedback, whether or not patentable,
                    for any purpose whatsoever, including but not limited to, developing, manufacturing, having
                    manufactured, licensing, marketing, and selling, directly or indirectly, products and services using
                    such Feedback. You understand and agree that D1 is not obligated to use, display, reproduce, or
                    distribute any such ideas, know-how, concepts, or techniques contained in the Feedback, and you have
                    no right to compel such use, display, reproduction, or distribution.
                  </p>
                  <p
                    style={{
                      marginLeft: '30px',
                      display: 'flex',
                      gap: '18px',
                      fontStyle: 'italic',
                      fontWeight: '800',
                      fontSize: '18px',
                    }}
                  >
                    <span>19.</span> <span>Copyright Policy</span>
                  </p>

                  <p
                    style={{
                      marginLeft: '30px',
                      display: 'block',
                    }}
                  >
                    D1 takes the intellectual property of others seriously. You understand that D1 at its sole
                    discretion, may terminate the account or access of users who infringe the intellectual property
                    rights of others. If you believe that your work has been copied in a manner that results in
                    copyright infringement, please contact D1’s Copyright Agent. Our designated agent for notice of
                    alleged copyright infringement on the Platform and/or Services is:
                  </p>
                  <p>
                    <span style={{ textWrap: 'nowrap', display: 'block', marginLeft: '30px', maxWidth: '25%' }}>
                      D1 – Copyright Department
                    </span>
                    <span style={{ textWrap: 'nowrap', display: 'block', marginLeft: '50px', maxWidth: '25%' }}>
                      460 Main Street, Ste B
                    </span>
                    <span style={{ textWrap: 'nowrap', display: 'block', marginLeft: '50px', maxWidth: '25%' }}>
                      Metuchen, NJ 08840&nbsp;
                    </span>
                    <span style={{ textWrap: 'nowrap', display: 'block', marginLeft: '30px', maxWidth: '25%' }}>
                      By Email: copyright@dentistry.one
                    </span>
                  </p>

                  <p
                    style={{
                      marginLeft: '30px',
                      display: 'flex',
                      gap: '18px',
                      fontStyle: 'italic',
                      fontWeight: '800',
                      fontSize: '18px',
                    }}
                  >
                    <span>20.</span> <span>Additional Terms for Mobile Applications</span>
                  </p>
                  <p style={{ marginLeft: '30px', display: 'flex', gap: '10px' }}>
                    <span>(a)</span>
                    <span>
                      D1 may make available a mobile application to access the Platform via a mobile device (“Mobile
                      Application”). To use the Mobile Application, you must have a mobile device that is compatible
                      with the mobile service. D1 does not warrant that the Mobile Application will be compatible with
                      any particular mobile device or software configuration.
                    </span>
                  </p>

                  <p style={{ marginLeft: '30px', display: 'flex', gap: '10px' }}>
                    <span>(b)</span>
                    <span>
                      D1 hereby grants to you a non-exclusive, non-transferable, revocable license to use an object code
                      copy of the Mobile Application for one registered account on one mobile device owned or leased
                      solely by you, for your personal use, in accord with these terms and those of the other applicable
                      Terms of Service.
                    </span>
                  </p>

                  <p style={{ marginLeft: '30px', display: 'flex', gap: '10px' }}>
                    <span>(c)</span>
                    <span>
                      You acknowledge that the Mobile Application is subject to the U.S. Export Administration
                      Regulations (the “EAR”) and that You will comply with the EAR. You will not export or re-export
                      the Mobile Application, directly or indirectly, to (a) any country that is subject to U.S. export
                      restrictions, (b) any end-user whom You know or have reason to know will utilize them in the
                      design, development, or production of nuclear, chemical or biological weapons; rocket systems,
                      space launch vehicles, sounding rockets, or unmanned air vehicle systems; or (c) any end-user who
                      has been prohibited from participation in U.S. export transactions by any federal agency of the
                      U.S. government. You are also responsible for obeying any applicable local laws.
                    </span>
                  </p>

                  <p style={{ marginLeft: '30px', display: 'flex', gap: '10px' }}>
                    <span>(d)</span>
                    <span>
                      If you are a government end-user, the Mobile Application is a “Commercial Item” under 48 C.F.R.
                      §2.101, consisting of “Commercial Computer Software” and “Commercial Computer Software
                      Documentation”, as defined by 48 C.F.R. §12.212 and/or 48 C.F.R. §227.7202. Under 48 C.F.R.
                      §12.212 and 48 C.F.R. §227.7202-1 to -4, as applicable, the Commercial Computer Software and
                      Commercial Computer Software Documentation are licensed to U.S. Government end users (a) solely as
                      Commercial Items and (b) with no greater rights than granted other end-users under the license
                      granted in this section (this “License”). D1 reserves any rights not granted.
                    </span>
                  </p>

                  <p style={{ marginLeft: '30px', display: 'flex', gap: '10px' }}>
                    <span>(e)</span>
                    <span>
                      You represent and warrant that you are not located in a country that is subject to a United States
                      government embargo nor that has been designated a “terrorist supporting” country. You also
                      represent and warrant that you are not included on any list of parties restricted, prohibited, or
                      sanctioned by the United States government.
                    </span>
                  </p>

                  <p style={{ marginLeft: '30px', display: 'flex', gap: '10px' }}>
                    <span>(f)</span>
                    <span>
                      Your acquisition and use of the Mobile Application is also subject to the terms of any
                      marketplace, such as the Apple App Store or Google Play, from which you obtained the Mobile
                      Application. It is also subject to applicable terms and conditions between you and mobile device
                      manufacturers and operating system providers, network providers, or other third parties You
                      utilize in operating the Mobile Application or obtaining the Services.
                    </span>
                  </p>

                  <p style={{ marginLeft: '30px', display: 'flex', gap: '10px' }}>
                    <span>(g)</span>
                    <span>
                      You may not: (i) modify, disassemble, decompile or reverse engineer the Mobile Application, except
                      to the extent that such restriction is expressly prohibited by law; (ii) rent, lease, loan,
                      resell, sublicense, distribute or otherwise transfer the Mobile Application to any third party or
                      use the Mobile Application to provide time sharing or similar services for any third-party; (iii)
                      make any copies of the Mobile Application; (iv) remove, circumvent, disable, damage or otherwise
                      interfere with security-related features of the Mobile Application, features that prevent or
                      restrict use or copying of any content accessible through the Mobile Application, or features that
                      enforce limitations on use of the Mobile Application; or (v) delete the copyright and other
                      proprietary rights notices on the Mobile Application. You acknowledge that D1 may from time to
                      time issue upgraded versions of the Mobile Application and may automatically electronically
                      upgrade the version of the Mobile Application that you are using on your mobile device. You
                      consent to such automatic upgrading on your mobile device and agree that this Agreement will apply
                      to all such upgrades. The foregoing license grant is not a sale of the Mobile Application or any
                      copy thereof, and D1 and its third-party licensors or suppliers retain all right, title, and
                      interest in and to the Mobile Application (and any copy of the Mobile Application). Standard
                      carrier data charges may apply to your use of the Mobile Application.
                    </span>
                  </p>

                  <p style={{ marginLeft: '30px', display: 'flex', gap: '10px' }}>
                    <span>(h)</span>
                    <span>
                      If you opt into diagnostic and usage collection, you agree that we can collect anonymous data
                      relevant to the performance of the Mobile Application, including your mobile device’s hardware
                      type, operating system version, other installed software or applications, error codes generated by
                      the Mobile Application or its operation, and information relevant to whether you are using the
                      Mobile Application consistently with this License. We may use this information, in a form that
                      does not identify you personally, to improve the Mobile Application.
                    </span>
                  </p>

                  <p style={{ marginLeft: '30px', display: 'flex', gap: '10px' }}>
                    <span>(i)</span>
                    <span>
                      By enabling and or using any location-based services or features provided through the Mobile
                      Application, you agree and consent to D1 and its partners and licensees collecting, using,
                      processing, and maintaining information related to your account, and any devices or computers
                      registered thereunder, to provide location-based services or features to you. This information may
                      include, but is not limited to, your account ID and name, device and/or computer ID and name,
                      device and/or computer type, and geographic location of your device, vehicle, or personal
                      computer. You can withdraw your consent at any time by disabling Location Services on your device,
                      adjusting the permissions given to the Mobile Application, or not using the Mobile Application.
                    </span>
                  </p>

                  <p style={{ marginLeft: '30px', display: 'flex', gap: '10px', flexDirection: 'column' }}>
                    <p>
                      <span>(j)</span>
                      <span style={{ marginLeft: '10px' }}>
                        The following shall apply with regard to warranties and liability for the Mobile Application:
                      </span>
                    </p>

                    <p style={{ display: 'flex', flexDirection: 'column' }}>
                      <p style={{ marginLeft: '30px', display: 'flex', gap: '10px' }}>
                        <span>ⅰ.</span>
                        <span>
                          TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, THE MOBILE APPLICATION AND SERVICES ARE
                          PROVIDED “AS-IS” AND “AS AVAILABLE”, WITH ANY AND ALL FAULTS AND WITHOUT WARRANTIES OF ANY
                          KIND, AND D1 AND D1’S LICENSORS HEREBY DISCLAIM ALL WARRANTIES AND CONDITIONS CONCERNING THE
                          MOBILE APPLICATION AND SERVICES, EITHER EXPRESS, IMPLIED OR STATUTORY, INCLUDING, BUT NOT
                          LIMITED TO, THE IMPLIED WARRANTIES AND/OR CONDITIONS OF MERCHANTABILITY, OF SATISFACTORY
                          QUALITY, OF FITNESS FOR A PARTICULAR PURPOSE, OF ACCURACY, OF QUIET ENJOYMENT, AND
                          NON-INFRINGEMENT OF THIRD-PARTY RIGHTS.
                        </span>
                      </p>

                      <p style={{ marginLeft: '30px', display: 'flex', gap: '10px' }}>
                        <span>ⅰⅰ.</span>
                        <span>
                          YOU EXPRESSLY ACKNOWLEDGE AND AGREE THAT, TO THE EXTENT PERMITTED BY APPLICABLE LAW, USE OF
                          THE MOBILE APPLICATION AND ANY SERVICES PERFORMED BY OR ACCESSED THROUGH THE MOBILE
                          APPLICATION IS AT YOUR SOLE RISK AND THAT THE ENTIRE RISK AS TO THE SATISFACTORY QUALITY,
                          PERFORMANCE, ACCURACY, AND EFFORT IS WITH YOU.
                        </span>
                      </p>

                      <p style={{ marginLeft: '30px', display: 'flex', gap: '10px' }}>
                        <span>ⅰⅰⅰ.</span>
                        <span>
                          YOU AGREE THAT D1 IS NOT RESPONSIBLE FOR CHANGES MADE TO MOBILE DEVICE OPERATING SYSTEMS,
                          OTHER SOFTWARE, SOFTWARE MARKETPLACES, OR HANDHELD DEVICE HARDWARE THAT MAKE THE MOBILE
                          APPLICATION UNAVAILABLE, MAKE IT INOPERATIVE, OR OTHERWISE NEGATIVELY IMPACT ITS
                          FUNCTIONALITY. D1 MAKES NO REPRESENTATION OR WARRANTY THAT THE MOBILE APPLICATION WILL BE
                          AVAILABLE FOR DOWNLOAD OR INSTALLATION INDEFINITELY.
                        </span>
                      </p>

                      <p style={{ marginLeft: '30px', display: 'flex', gap: '10px' }}>
                        <span>ⅰv.</span>
                        <span>
                          YOU ACKNOWLEDGE THAT YOU ARE RESPONSIBLE FOR THE SAFE OPERATION OF THE MOBILE APPLICATION. YOU
                          AGREE TO REFRAIN FROM USING THE MOBILE APPLICATION IN SITUATIONS WHERE DOING SO COULD, WHETHER
                          DIRECTLY OR THROUGH DISTRACTION OR OTHER MEANS, ENDANGER LIFE OR PROPERTY.
                        </span>
                      </p>

                      <p style={{ marginLeft: '30px', display: 'flex', gap: '10px' }}>
                        <span>v.</span>
                        <span>
                          YOU ARE RESPONSIBLE FOR COMPLYING WITH APPLICABLE LAWS AND REGULATIONS REGARDING THE OPERATION
                          OF YOUR MOBILE DEVICE IN YOUR VEHICLE.
                        </span>
                      </p>

                      <p style={{ marginLeft: '30px', display: 'flex', gap: '10px' }}>
                        <span>vⅰ.</span>
                        <span>
                          YOU AGREE THAT YOU ARE SOLELY RESPONSIBLE FOR ANY CELLULAR OR OTHER METERED DATA CHARGES
                          ASSOCIATED WITH YOUR USE OF THE MOBILE APPLICATION.
                        </span>
                      </p>
                    </p>
                  </p>

                  <p style={{ marginLeft: '30px', display: 'flex', gap: '10px', flexDirection: 'column' }}>
                    <p>
                      <span>(k)</span>
                      <span style={{ marginLeft: '10px' }}>D1 DOES NOT WARRANT THAT:</span>
                    </p>

                    <p style={{ display: 'flex', flexDirection: 'column' }}>
                      <p style={{ marginLeft: '30px', display: 'flex', gap: '10px' }}>
                        <span>ⅰ.</span>
                        <span>
                          THERE WILL BE NO INTERFERENCE WITH YOUR ENJOYMENT OF THE MOBILE APPLICATION AND SERVICES;
                        </span>
                      </p>

                      <p style={{ marginLeft: '30px', display: 'flex', gap: '10px' }}>
                        <span>ⅰⅰ.</span>
                        <span>
                          THE FUNCTIONS CONTAINED IN, OR SERVICES PERFORMED OR PROVIDED BY, THE MOBILE APPLICATION WILL
                          MEET YOUR REQUIREMENTS;
                        </span>
                      </p>

                      <p style={{ marginLeft: '30px', display: 'flex', gap: '10px' }}>
                        <span>ⅰⅰⅰ.</span>
                        <span>
                          OPERATION OF THE MOBILE APPLICATION OR ANY ASSOCIATED SERVICES WILL BE UNINTERRUPTED OR
                          ERROR-FREE;
                        </span>
                      </p>

                      <p style={{ marginLeft: '30px', display: 'flex', gap: '10px' }}>
                        <span>ⅰv.</span>
                        <span>THE SERVICES WILL CONTINUE TO BE MADE AVAILABLE;</span>
                      </p>

                      <p style={{ marginLeft: '30px', display: 'flex', gap: '10px' }}>
                        <span>v.</span>
                        <span>
                          THE MOBILE APPLICATION OR SERVICES WILL BE COMPATIBLE OR WORK WITH ANY THIRD-PARTY SOFTWARE,
                          APPLICATIONS, OR THIRD-PARTY SERVICES;
                        </span>
                      </p>

                      <p style={{ marginLeft: '30px', display: 'flex', gap: '10px' }}>
                        <span>vⅰ.</span>
                        <span>THE MOBILE APPLICATION WILL WORK WITH FUTURE VERSIONS OF MOBILE OPERATING SYSTEMS;</span>
                      </p>

                      <p style={{ marginLeft: '30px', display: 'flex', gap: '10px' }}>
                        <span>vⅰⅰ.</span>
                        <span>ANY DEFECTS IN THE MOBILE APPLICATION OR SERVICES WILL BE DETECTED OR CORRECTED; OR</span>
                      </p>

                      <p style={{ marginLeft: '30px', display: 'flex', gap: '10px' }}>
                        <span>vⅰⅰⅰ.</span>
                        <span>
                          INSTALLATION OF THE MOBILE APPLICATION WILL NOT AFFECT THE USABILITY OF THIRD-PARTY SOFTWARE,
                          APPLICATIONS, OR THIRD-PARTY SERVICES.
                        </span>
                      </p>
                    </p>
                  </p>

                  <p style={{ marginLeft: '30px', display: 'flex', gap: '10px' }}>
                    <span>(l)</span>
                    <span style={{ display: 'block' }}>
                      YOU FURTHER ACKNOWLEDGE THAT THE MOBILE APPLICATION AND SERVICES ARE NOT INTENDED OR SUITABLE FOR
                      USE IN SITUATIONS WHERE THE FAILURE OF THE MOBILE APPLICATION, INTERRUPTED DATA COMMUNICATIONS
                      BETWEEN D1’S SYSTEMS AND THE MOBILE APPLICATION, OR INACCURATE INFORMATION PROVIDED BY THE MOBILE
                      APPLICATION OR ASSOCIATED SERVICES MIGHT LEAD TO DEATH, PERSONAL INJURY, OR PROPERTY DAMAGE.
                    </span>
                  </p>

                  <p style={{ marginLeft: '30px', display: 'flex', gap: '10px' }}>
                    <span>(m)</span>
                    <span>
                      D1 PERSONNEL ARE NOT AUTHORIZED TO GIVE OR EXPAND WARRANTIES CONCERNING THE MOBILE APPLICATION
                      OUTSIDE OF THIS LICENSE.
                    </span>
                  </p>

                  <p style={{ marginLeft: '30px', display: 'flex', gap: '10px' }}>
                    <span>(n)</span>
                    <span>
                      THE ABOVE EXCLUSIONS AND LIMITATIONS MAY NOT APPLY TO YOU IF YOU ARE A RESIDENT OF A STATE OR
                      TERRITORY THAT DOES NOT ALLOW THE EXCLUSION OF IMPLIED WARRANTIES OR LIMITATIONS, BASED ON
                      APPLICABLE STATUTORY RIGHTS OF A CONSUMER.
                    </span>
                  </p>

                  <p style={{ marginLeft: '30px', display: 'flex', gap: '10px', flexDirection: 'column' }}>
                    <p>
                      <span>(o)</span>
                      <span style={{ marginLeft: '10px' }}>
                        The following additional terms and conditions apply with respect to any Mobile Application that
                        D1 provides to you designed for use on an Apple mobile device and that you obtain from the Apple
                        App Store (an “iOS App”):
                      </span>
                    </p>

                    <p style={{ display: 'flex', flexDirection: 'column' }}>
                      <p style={{ marginLeft: '30px', display: 'flex', gap: '10px' }}>
                        <span>&bull;</span>
                        <span>
                          You acknowledge that this Agreement is between you and D1 only, and not with Apple, Inc.
                          (“Apple”).
                        </span>
                      </p>

                      <p style={{ marginLeft: '30px', display: 'flex', gap: '10px' }}>
                        <span>&bull;</span>
                        <span>
                          Your use of D1’s iOS App must comply with Apple’s then-current App Store Terms of Service.
                        </span>
                      </p>

                      <p style={{ marginLeft: '30px', display: 'flex', gap: '10px' }}>
                        <span>&bull;</span>
                        <span>
                          D1, and not Apple, are solely responsible for our iOS App and the Services and Content
                          available thereon. You acknowledge that Apple has no obligation to provide maintenance and
                          support services with respect to our iOS App. To the maximum extent permitted by applicable
                          law, Apple will have no warranty obligation whatsoever with respect to our iOS App.
                        </span>
                      </p>

                      <p style={{ marginLeft: '30px', display: 'flex', gap: '10px' }}>
                        <span>&bull;</span>
                        <span>
                          You agree that D1, and not Apple, are responsible for addressing any claims by you or any
                          third-party relating to our iOS App or your possession and/or use of our iOS App, including,
                          but not limited to: (i) product liability claims; (ii) any claim that the iOS App fails to
                          conform to any applicable legal or regulatory requirement; and (iii) claims arising under
                          consumer protection or similar legislation, and all such claims are governed solely by this
                          Agreement and any law applicable to us as provider of the iOS App.
                        </span>
                      </p>

                      <p style={{ marginLeft: '30px', display: 'flex', gap: '10px' }}>
                        <span>&bull;</span>
                        <span>
                          You agree that D1, and not Apple, shall be responsible, to the extent required by this
                          Agreement, for the investigation, defense, settlement, and discharge of any third-party
                          intellectual property infringement claim related to our iOS App or your possession and use of
                          our iOS App.
                        </span>
                      </p>

                      <p style={{ marginLeft: '30px', display: 'flex', gap: '10px' }}>
                        <span>&bull;</span>
                        <span>
                          You represent and warrant that (i) you are not located in a country that is subject to a U.S.
                          Government embargo, or that has been designated by the U.S. Government as a “terrorist
                          supporting” country; and (ii) You are not listed on any U.S. Government list of prohibited or
                          restricted parties.
                        </span>
                      </p>

                      <p style={{ marginLeft: '30px', display: 'flex', gap: '10px' }}>
                        <span>&bull;</span>
                        <span>
                          You agree to comply with all applicable third-party terms of agreement when using our iOS App
                          (e.g., you must not be in violation of your wireless data service terms of agreement when
                          using the iOS App).
                        </span>
                      </p>

                      <p style={{ marginLeft: '30px', display: 'flex', gap: '10px' }}>
                        <span>&bull;</span>
                        <span>
                          The parties agree that Apple and Apple’s subsidiaries are third-party beneficiaries to this
                          Agreement as they relate to your license of D1’s iOS App. Upon your acceptance of this
                          Agreement, Apple will have the right (and will be deemed to have accepted the right) to
                          enforce this Agreement against you as they relate to your license of the iOS App as a
                          third-party beneficiary thereof.
                        </span>
                      </p>
                    </p>
                  </p>

                  <p
                    style={{
                      marginLeft: '30px',
                      display: 'flex',
                      gap: '18px',
                      fontStyle: 'italic',
                      fontWeight: '800',
                      fontSize: '18px',
                    }}
                  >
                    <span>21.</span> <span>Legal Conditions and Limitation of D1’s Liability</span>
                  </p>
                  <p style={{ marginLeft: '30px', display: 'flex', gap: '10px' }}>
                    <span>(a)</span>
                    <span>
                      D1 is not responsible for the conduct, whether online or offline, of any user of the Platform
                      and/or Services or any user, a User, or Provider.
                    </span>
                  </p>

                  <p style={{ marginLeft: '30px', display: 'flex', gap: '10px' }}>
                    <span>(b)</span>
                    <span>
                      D1 is not responsible for any Third-Party Platforms, Third-Party Content, or any other content
                      posted on the Platform and/or Services, whether caused by users of the Platform and/or Services,
                      D1, third parties, or by any of the equipment or programming associated with or utilized in the
                      Platform and/or Services.
                    </span>
                  </p>
                  <p style={{ marginLeft: '30px', display: 'flex', gap: '10px' }}>
                    <span>(c)</span>
                    <span>
                      D1 assumes no responsibility for any error, omission, interruption, deletion, defect, delay in
                      operation or transmission, communications line failure, theft or destruction or unauthorized
                      access to, or alteration of a User communications or Provider communications.
                    </span>
                  </p>
                  <p style={{ marginLeft: '30px', display: 'flex', gap: '10px' }}>
                    <span>(d)</span>
                    <span>
                      D1 is not responsible for any problems or technical malfunction of any telephone network or lines,
                      cellular data provider or network, computer online systems, servers or providers, computer
                      equipment, software, failure of email or players on account of technical problems or traffic
                      congestion on the Platform and/or Services, including injury or damage to users or to any other
                      person’s computer, and/or mobile device.
                    </span>
                  </p>

                  <p style={{ marginLeft: '30px', display: 'flex', gap: '10px' }}>
                    <span>(e)</span>
                    <span>
                      Neither D1 nor any of its affiliates, advertisers, promoters, or distribution partners shall be
                      responsible for any loss or damage, including personal injury or death, resulting from anyone’s
                      use of the Platform and/or Services, any content posted on the Platform and/or Services or
                      transmitted to users, or any interactions between users of the Platform and/or Services and
                      between users of the Platform and/or Services and Providers, whether online or offline.
                    </span>
                  </p>
                  <p
                    style={{
                      marginLeft: '30px',
                      display: 'flex',
                      gap: '18px',
                      fontStyle: 'italic',
                      fontWeight: '800',
                      fontSize: '18px',
                    }}
                  >
                    <span>22.</span> <span>General Disclaimer and Limitation of Liability</span>
                  </p>
                  <p style={{ marginLeft: '30px', display: 'block', gap: '10px' }}>
                    WE TRY TO KEEP THE PLATFORM AND/OR SERVICES UP, BUG-FREE AND SAFE, BUT YOU USE THEM AT YOUR OWN
                    RISK. THE INFORMATION FROM OR THROUGH THE PLATFORM AND/OR SERVICES IS PROVIDED “AS IS,” “AS
                    AVAILABLE,” AND ALL WARRANTIES, EXPRESS OR IMPLIED, ARE DISCLAIMED. THE INFORMATION, PLATFORM,
                    AND/OR SERVICES MAY CONTAIN VIRUSES, BUGS, ERRORS, PROBLEMS OR OTHER LIMITATIONS. IN NO EVENT WILL
                    D1 INCLUDING ITS DIRECTORS, EMPLOYEES, OR AGENTS, NOR ANY THIRD-PARTY OFFERING PRODUCTS OR SERVICES
                    THROUGH THE PLATFORM AND/OR SERVICES, INCLUDING THE&nbsp;
                    <a
                      style={{ fontSize: 'inherit', textDecoration: 'none' }}
                      href={`${window.location.origin}/affiliated-practice-list`}
                    >
                      DENTAL GROUPS
                    </a>
                    , AND THE PROVIDERS, HAVE ANY LIABILITY WHATSOEVER FOR YOUR USE OF ANY INFORMATION OR SERVICE, OR
                    THE PLATFORM.
                  </p>

                  <p style={{ marginLeft: '30px', display: 'block', gap: '10px' }}>
                    WE ARE NOT LIABLE FOR ANY INDIRECT, SPECIAL, INCIDENTAL, OR CONSEQUENTIAL DAMAGES (INCLUDING DAMAGES
                    FOR LOSS OF BUSINESS, LOSS OF PROFITS, LITIGATION, OR THE LIKE), WHETHER BASED ON BREACH OF
                    CONTRACT, BREACH OF WARRANTY, TORT (INCLUDING NEGLIGENCE), PRODUCT LIABILITY OR OTHERWISE, EVEN IF
                    ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. NO ADVICE OR INFORMATION OBTAINED BY YOU FROM US THROUGH
                    THE PLATFORM/SERVICE SHALL CREATE ANY WARRANTY, REPRESENTATION, OR GUARANTEE NOT EXPRESSLY STATED IN
                    THIS AGREEMENT.
                  </p>

                  <p style={{ marginLeft: '30px', display: 'block', gap: '10px' }}>
                    D1’S LIABILITY TO YOU FOR ANY CAUSE WHATSOEVER, AND REGARDLESS OF THE CAUSE OF ACTION, WILL AT ALL
                    TIMES BE LIMITED TO THE AMOUNT PAID, IF ANY, BY YOU TO US FOR THE SERVICE AND USE OF THE PLATFORM,
                    BUT IN NO CASE WILL OUR LIABILITY TO YOU EXCEED $50. YOU ACKNOWLEDGE THAT IF NO FEES ARE PAID TO US
                    FOR THE PLATFORM AND/OR SERVICES, YOU SHALL BE LIMITED TO INJUNCTIVE RELIEF ONLY, UNLESS OTHERWISE
                    PERMITTED BY LAW, AND SHALL NOT BE ENTITLED TO DAMAGES OF ANY KIND FROM US, REGARDLESS OF THE CAUSE
                    OF ACTION.
                  </p>

                  <p style={{ marginLeft: '30px', display: 'block', gap: '10px' }}>
                    IF YOU ARE A CALIFORNIA RESIDENT, YOU WAIVE CALIFORNIA CIVIL CODE SECTION 1542, WHICH STATES, IN
                    PART: “A GENERAL RELEASE DOES NOT EXTEND TO CLAIMS WHICH THE CREDITOR DOES NOT KNOW OR SUSPECT TO
                    EXIST IN HIS FAVOR AT THE TIME OF EXECUTING THE RELEASE, WHICH IF KNOWN BY HIM MUST HAVE MATERIALLY
                    AFFECTED HIS SETTLEMENT WITH THE DEBTOR.”
                  </p>

                  <p
                    style={{
                      marginLeft: '30px',
                      display: 'flex',
                      gap: '18px',
                      fontStyle: 'italic',
                      fontWeight: '800',
                      fontSize: '18px',
                    }}
                  >
                    <span>23.</span> <span>No Agency Relationship</span>
                  </p>
                  <p style={{ marginLeft: '30px', display: 'block', gap: '10px' }}>
                    Any use of the Platform and/or the Services by you and/or this Agreement, including any content,
                    materials, or features of the Platform does not create nor establish any joint venture, partnership
                    employment, or other agency relationship between you and D1, the&nbsp;
                    <a
                      style={{ fontSize: 'inherit', textDecoration: 'none' }}
                      href={`${window.location.origin}/affiliated-practice-list`}
                    >
                      Dental Groups
                    </a>
                    , or the Providers. You further understand that you may not enter into any contract on behalf of D1,
                    the&nbsp;
                    <a
                      style={{ fontSize: 'inherit', textDecoration: 'none' }}
                      href={`${window.location.origin}/affiliated-practice-list`}
                    >
                      Dental Groups
                    </a>
                    , or the Providers or bind them in any way.
                  </p>
                  <p
                    style={{
                      marginLeft: '30px',
                      display: 'flex',
                      gap: '18px',
                      fontStyle: 'italic',
                      fontWeight: '800',
                      fontSize: '18px',
                    }}
                  >
                    <span>24.</span> <span>Third-Party Beneficiaries</span>
                  </p>
                  <p style={{ marginLeft: '30px', display: 'block', gap: '10px' }}>
                    As mentioned elsewhere, your use of any third-party software provided in connection with the
                    Platform and/or Services, or any Third-Party Content or Third-Party Platforms, are controlled and
                    governed by the applicable third-party’s terms and conditions, terms of use/services, or license or,
                    if any, or in the absence of any such items by the terms and conditions of this Agreement. You
                    understand and hereby agree that all parties to this Agreement have the mutual intent to avoid
                    creating or establishing any third-party beneficiary status or rights in any person, party, vendor,
                    or third party, except as specifically set forth in this Agreement (including without limitation any
                    agreements for arbitration and indemnification set forth in this Agreement that benefit the
                    Providers and&nbsp;
                    <a
                      style={{ fontSize: 'inherit', textDecoration: 'none' }}
                      href={`${window.location.origin}/affiliated-practice-list`}
                    >
                      Dental Groups
                    </a>
                    ), and that no third party shall have any right to enforce nor avail itself of any right or benefit
                    that is created or established under this Agreement.
                  </p>
                  <p
                    style={{
                      marginLeft: '30px',
                      display: 'flex',
                      gap: '18px',
                      fontStyle: 'italic',
                      fontWeight: '800',
                      fontSize: '18px',
                    }}
                  >
                    <span>25.</span>{' '}
                    <span>
                      You agree to indemnify D1, the&nbsp;
                      <a
                        style={{ fontSize: 'inherit', textDecoration: 'none' }}
                        href={`${window.location.origin}/affiliated-practice-list`}
                      >
                        Dental Groups
                      </a>
                      , and the Providers
                    </span>
                  </p>
                  <p style={{ marginLeft: '30px', display: 'block', gap: '10px' }}>
                    You agree to indemnify, defend, and hold harmless D1, its contractors, licensors, subsidiaries and
                    affiliates, and their respective partners, directors, officers, employees, and agents (inclusive of
                    any&nbsp;
                    <a
                      style={{ fontSize: 'inherit', textDecoration: 'none' }}
                      href={`${window.location.origin}/affiliated-practice-list`}
                    >
                      Dental Groups
                    </a>{' '}
                    and Providers) from and against any and all claims and expenses, including any and all losses,
                    costs, liabilities, and attorneys’ fees, arising out of or in connection with: (1) your use of the
                    Platform and/or Services, (2) any User Content, Third-Party Content, Third-Party Platforms, and any
                    other content, (3) your violation of this Agreement, or of any law or the rights of any third party,
                    and (4) your breach of this Agreement and/or any breach of your representations and warranties set
                    forth herein.
                  </p>
                  <p
                    style={{
                      marginLeft: '30px',
                      display: 'flex',
                      gap: '18px',
                      fontStyle: 'italic',
                      fontWeight: '800',
                      fontSize: '18px',
                    }}
                  >
                    <span>26.</span> <span>Your general representation and warranty</span>
                  </p>
                  <p style={{ marginLeft: '30px', display: 'flex', gap: '10px' }}>
                    <span>(a)</span>
                    <span>
                      You represent and warrant that you will use the Platform and/or Services in accordance with the
                      &nbsp;
                      <a
                        style={{ fontSize: 'inherit', color: '#2f64c1', textDecoration: 'none' }}
                        href={`${window.location.origin}/privacy-policy`}
                      >
                        Privacy Policy
                      </a>
                      , with this Agreement, and with all applicable laws and regulations (including without limitation
                      any local laws or regulations in your country, state, city, or other governmental area, regarding
                      online conduct and acceptable content, and including all applicable laws regarding the
                      transmission of technical data exported from the United States or the country in which you
                      reside).
                    </span>
                  </p>
                  <p style={{ marginLeft: '30px', display: 'flex', gap: '10px' }}>
                    <span>(b)</span>
                    <span>
                      You represent and warrant that you will use the Platform and/or Services so as not to infringe or
                      misappropriate the intellectual property rights of any third party.
                    </span>
                  </p>
                  <p
                    style={{
                      marginLeft: '30px',
                      display: 'flex',
                      gap: '18px',
                      fontStyle: 'italic',
                      fontWeight: '800',
                      fontSize: '18px',
                    }}
                  >
                    <span>27.</span> <span>Binding Arbitration Agreement / Class Waiver</span>
                  </p>

                  <p style={{ marginLeft: '30px', display: 'block', gap: '10px' }}>
                    EXCEPT FOR DISPUTES THAT QUALIFY FOR SMALL CLAIMS COURT, ALL DISPUTES ARISING OUT OF OR RELATED TO
                    THIS AGREEMENT OR YOUR USE OF THE SERVICE, WHETHER BASED IN CONTRACT, TORT, STATUTE, FRAUD,
                    MISREPRESENTATION, OR ANY OTHER LEGAL THEORY, WILL BE RESOLVED THROUGH FINAL AND BINDING ARBITRATION
                    BEFORE A NEUTRAL ARBITRATOR INSTEAD OF IN A COURT BY A JUDGE OR JURY AND YOU AGREE THAT D1 AND YOU
                    ARE EACH WAIVING THE RIGHT TO TRIAL BY A JURY. YOU AGREE THAT ANY ARBITRATION UNDER THIS AGREEMENT
                    WILL TAKE PLACE ON AN INDIVIDUAL BASIS; CLASS ARBITRATIONS AND CLASS ACTIONS ARE NOT PERMITTED AND
                    YOU ARE AGREEING TO GIVE UP THE ABILITY TO PARTICIPATE IN A CLASS ACTION.
                  </p>

                  <p style={{ marginLeft: '30px', display: 'flex', gap: '10px' }}>
                    <span>(a)</span>
                    <span>
                      In the event there is a dispute between us, D1 has a firm commitment to work with you to reach a
                      resolution. As a result, you understand and agree that good faith informal efforts to resolve any
                      such disputes may lead to and produce a fair and mutually beneficial outcome. As a result, we
                      understand and agree that prior to either party communing any arbitration proceeding against the
                      other (or any proceeding in small claims court), we will attempt in good faith to meet in person
                      or using electronic or remote conferencing means, so as to informally resolve any such dispute
                      that may be subject to this Arbitration Agreement (“Informal Dispute Conference”). If you are
                      represented by counsel, your counsel may participate in the conference, but you will also
                      participate in the conference.
                    </span>
                  </p>

                  <p style={{ marginLeft: '30px', display: 'flex', gap: '10px' }}>
                    <span>(b)</span>
                    <span>
                      If we are unable to resolve a dispute within thirty (30) days after the Informal Dispute
                      Conference, either party may commence arbitration. The arbitration will be administered by the
                      American Arbitration Association (“AAA”) under its Consumer Arbitration Rules (currently available
                      at https://www.adr.org/aaa/faces/rules/searchrules/rulesdetail?doc=ADRSTAGE2021424), as amended by
                      this Agreement. The arbitrator will conduct hearings, if any, by teleconference or
                      videoconference, rather than by personal appearances, unless the arbitrator determines upon
                      request by you or by us that an in-person hearing is appropriate. Any in-person appearances will
                      be held at a location that is reasonably convenient to both parties with due consideration of
                      their ability to travel and other pertinent circumstances. If the parties are unable to agree on a
                      location, such determination should be made by the AAA or by the arbitrator.
                    </span>
                  </p>

                  <p style={{ marginLeft: '30px', display: 'flex', gap: '10px' }}>
                    <span>(c)</span>
                    <span>
                      Payment of arbitration costs will be governed by the AAA’s fee schedule. Each party agrees to pay
                      its own attorneys’ fees and expenses unless there is a governing statutory provision that requires
                      the prevailing party to be paid attorneys’ fees and expenses. If you are able to demonstrate that
                      the costs of arbitration will be prohibitive as compared to the costs of litigation, D1 will pay
                      as much of your filing and hearing fees in connection with the arbitration as the arbitrator deems
                      necessary to prevent the arbitration from being cost-prohibitive. The arbitrator’s decision will
                      follow the terms of this Agreement and will be final and binding. The arbitrator will have
                      authority to award temporary, interim, or permanent injunctive relief, or relief providing for
                      specific performance of this Agreement, but only to the extent necessary to provide relief
                      warranted by the individual claim before the arbitrator. The award rendered by the arbitrator may
                      be confirmed and enforced in any court having jurisdiction thereof. Notwithstanding any of the
                      foregoing, nothing in this Agreement will preclude you from bringing issues to the attention of
                      federal, state, or local agencies and, if the law allows, they can seek relief against us for you.
                    </span>
                  </p>

                  <p style={{ marginLeft: '30px', display: 'flex', gap: '10px' }}>
                    <span>(d)</span>
                    <span>
                      With the exception of any of the language above in this Binding Arbitration Agreement relating to
                      the waiver of class and representative actions, if a court decides that any part of this Binding
                      Arbitration Agreement is invalid or unenforceable, the other parts of this Binding Arbitration
                      Agreement shall still apply. If a court decides that any aspect of the language above in this
                      Binding Arbitration Agreement relating to the waiver of class and representative actions is
                      invalid or unenforceable, then the entirety of this Binding Arbitration Agreement shall be null
                      and void. The remainder of the Agreement will continue to apply and be unaffected by this
                      severability provision.
                    </span>
                  </p>

                  <p style={{ marginLeft: '30px', display: 'flex', gap: '10px' }}>
                    <span>(e)</span>
                    <span>
                      YOU AND D1 WAIVE ANY CONSTITUTIONAL AND STATUTORY RIGHTS TO SUE IN COURT AND RECEIVE A JUDGE OR
                      JURY TRIAL. You and the D1 are instead electing to have claims and disputes resolved by
                      arbitration, except as specified above. An arbitrator can award on an individual basis the same
                      damages and relief as a court and must follow this Agreement as a court would. However, there is
                      no judge or jury in arbitration, and court review of an arbitration award is limited.
                    </span>
                  </p>

                  <p style={{ marginLeft: '30px', display: 'flex', gap: '10px' }}>
                    <span>(f)</span>
                    <span>
                      ALL CLAIMS AND DISPUTES WITHIN THE SCOPE OF THIS ARBITRATION AGREEMENT MUST BE ARBITRATED ON AN
                      INDIVIDUAL BASIS AND NOT ON A CLASS BASIS. CLAIMS OF MORE THAN ONE USER CANNOT BE ARBITRATED OR
                      LITIGATED JOINTLY OR CONSOLIDATED WITH THOSE OF ANY OTHER USER. If, however, this waiver of class
                      or consolidated actions is deemed invalid or unenforceable, neither you nor D1 is entitled to
                      arbitration. Instead, all claims and disputes will then be resolved in a court as set forth in the
                      section below entitled “Governing Law; Venue; Assignment; Miscellaneous.”
                    </span>
                  </p>

                  <p style={{ marginLeft: '30px', display: 'flex', gap: '10px' }}>
                    <span>(g)</span>
                    <span>
                      This Arbitration Agreement will survive any termination of your relationship with the D1.
                    </span>
                  </p>

                  <p style={{ marginLeft: '30px', display: 'flex', gap: '10px' }}>
                    <span>(h)</span>
                    <span>
                      Notwithstanding any provision in this Agreement to the contrary, we agree that if D1 makes any
                      future material change to this Arbitration Agreement, it will not apply to any individual claim(s)
                      that you had already provided notice of to us. D1 will provide thirty (30) days’ notice of any
                      changes affecting the substance of this Arbitration Agreement by posting on this Agreement,
                      sending you a message, or otherwise notifying you when you are logged into your account.
                      Amendments will become effective thirty (30) days after they are posted on this Agreement or sent
                      to you.
                    </span>
                  </p>

                  <p
                    style={{
                      marginLeft: '30px',
                      display: 'flex',
                      gap: '18px',
                      fontStyle: 'italic',
                      fontWeight: '800',
                      fontSize: '18px',
                    }}
                  >
                    <span>28.</span> <span>Governing Law; Venue; Assignment; Miscellaneous</span>
                  </p>

                  <p style={{ marginLeft: '30px', display: 'flex', gap: '10px' }}>
                    <span>(a)</span>
                    <span>
                      The Platform and Services are operated and managed by D1 from our offices within New York and New
                      Jersey. Users who access the Platform and/or Services from other locations have chosen to do so of
                      their own volition and therefore must comply with local laws, if and to the extent such laws
                      apply. Use and/or access to the Platform and/or Services by and through jurisdictions where the
                      Platform and/or Services (and/or any materials, content, or functionalities made available by and
                      through the Platform and/or Services) are illegal, unlawful, or subject to penalty is prohibited.
                      Subject to the above Arbitration Agreement, if there is any dispute arising out of the Platform
                      and/or Services, by using the Platform and/or Services, you expressly agree that any such dispute
                      shall be governed by the laws of the State of New York, without regard to its conflict of law
                      provisions, and you expressly agree and consent to the exclusive jurisdiction and venue of the
                      state and federal courts of the State of New York, in New York County, for the resolution of any
                      such dispute.
                    </span>
                  </p>

                  <p style={{ marginLeft: '30px', display: 'flex', gap: '10px' }}>
                    <span>(b)</span>
                    <span>
                      Our failure to exercise or enforce any right or provision of this Agreement shall not constitute a
                      waiver of such right or provision.
                    </span>
                  </p>

                  <p style={{ marginLeft: '30px', display: 'flex', gap: '10px' }}>
                    <span>(c)</span>
                    <span>
                      Subject to the above Arbitration Agreement, if any provision of this Agreement is found by a court
                      of competent jurisdiction to be invalid, the parties nevertheless agree that the court should
                      endeavor to give effect to the parties’ intentions as reflected in the provision, and the other
                      provisions of this Agreement shall remain in full force and effect.
                    </span>
                  </p>

                  <p style={{ marginLeft: '30px', display: 'flex', gap: '10px' }}>
                    <span>(d)</span>
                    <span>
                      You agree that regardless of any statute or law to the contrary, any claim or cause of action
                      arising out of or related to use of the Platform or this Agreement must be filed within one (1)
                      year after such claim or cause of action arose or be forever barred.
                    </span>
                  </p>

                  <p style={{ marginLeft: '30px', display: 'flex', gap: '10px' }}>
                    <span>(e)</span>
                    <span>
                      This Agreement and any other agreements D1 may post on the Platform and/or the Services or that
                      you and D1 may execute from to time constitute the entire agreement between D1 and you in
                      connection with your use of the Platform and/or the Services and supersede any prior agreements
                      between D1 and you regarding use of the Platform and/or the Services, including prior versions of
                      this Agreement.
                    </span>
                  </p>

                  <p style={{ marginLeft: '30px', display: 'flex', gap: '10px' }}>
                    <span>(f)</span>
                    <span>
                      You may not assign any of your rights under this Agreement, and any such attempt will be null and
                      void. D1 may, in its sole discretion, assign or transfer, without further consent or notification,
                      this Agreement or any or all of the contractual rights and obligations pursuant to this Agreement,
                      in whole or in part, to any affiliate of D1 or to a third party in the event that some or all of
                      the business of D1 is transferred to such other third party by way of merger, sale of its assets
                      or otherwise.
                    </span>
                  </p>
                </div>
              </article>
            </main>
          </div>
        </div>
      </Container>
    </>
  );
};

export default TermsOfService;

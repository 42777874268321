import qs from 'qs';

import { client } from '../client';
import { Meta } from '../communication/types/Meta';
import { ICase } from './types/ICase';

export interface IListCases {
  nodes: ICase[];
  total: number;
  meta: Meta;
}

export interface IListCasesParams {
  case_id?: number;
  type?: string[];
  status?: string | string[];
  consultation_date?: string;
  completed_date?: string;
  canceled_date?: string;
  case_state?: string[];
  patient_user_type?: string;
  client_tag?: string;
  patient_full_name?: string;
  care_advisor_full_name?: string;
  provider_full_name?: string;
  payment_method?: string;
  patient_self_pay_cost?: number;
  insurance_carrier?: number[];
  limit?: number;
  sort_by?: 'created_at' | 'updated_at';
  sort_direction?: 'asc' | 'desc';
  cursor?: string;
  cursor_direction?: 'next' | 'previous';
  care_advisor_id?: number;
  provider_id?: number;
  patient_id?: number;
  dependent_id?: number;
  include_patient_care_advisor_conversation?: boolean;
  include_patient_provider_conversation?: boolean;
  include_provider_care_advisor_conversation?: boolean;
  include_vc_stats?: boolean;
  include_dependents_cases?: boolean;
  provider_email?: string;
  care_advisor_email?: string;
  patient_email?: string;
  follow_up_status?: string;
  consultation_date_start?: string;
  consultation_date_end?: string;
  completed_date_start?: string;
  completed_date_end?: string;
  canceled_date_start?: string;
  canceled_date_end?: string;
}

export const listAllCases = async (params?: IListCasesParams | string): Promise<IListCases> => {
  let queryString = '';
  if (params && typeof params === 'object' && params instanceof Object) {
    queryString = `?${qs.stringify(params, { skipNulls: true })}`;
  } else if (params && typeof params === 'string') {
    queryString = `?${params}`;
  }
  const { data } = await client.get(`/cases${queryString}`);
  return data;
};

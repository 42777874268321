/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable no-restricted-globals */
import Sidenav from '@brands/Dashboard/Sidenav/Sidenav';
import { getUserByHash, HashedUser } from '@brands/services/auth/getUserByHash';
import { login } from '@brands/services/auth/login';
import { signup } from '@brands/services/auth/signup';
import { getPricings } from '@brands/services/identity/getPricings';
import { setUser } from '@brands/store/slices/authSlice';
import { setPatientState } from '@brands/store/slices/currentPatientState';
import { setShowStateModal } from '@brands/store/slices/location';
import { setOrganizationPrices } from '@brands/store/slices/organizationPricingSlice';
import { setOrganization } from '@brands/store/slices/organizationSlice';
import { yupResolver } from '@hookform/resolvers/yup';
import Cookies from 'js-cookie';
import moment from 'moment';
import React, { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { AiOutlineQuestionCircle } from 'react-icons/ai';
import { TbEye } from 'react-icons/tb';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';

import Button from '../../Components/Button/Button';
import TextField from '../../Components/Inputs/TextField/TextField';
import PWDRequisite from '../../Components/PasswordValidation/PasswordValidation';
import { config } from '../../config/config';
import { useLocalStorageState } from '../../hooks';
import { useAppDispatch } from '../../hooks/useReduxHook';
import { getStateFromGeocode } from '../../services/geolocation/getStateFromGeocode';
import { getCurrentOrganization } from '../../services/identity/getCurrentOrganization';
import { getCurrentProfile } from '../../services/identity/getCurrentProfile';
import { UserProfile } from '../../services/identity/types/UserProfile';
import { displayErrorDetails } from '../../Utils/displayError';
import { getSecondaryColor } from '../../Utils/getPrimaryColor';
import { DEFAULT_PATIENT_LOCATION_STATE, getCurrentPState } from '../../Utils/localStorage';
import { stateOptions } from '../../Utils/selectOptions';
import { hasCapsLetter, hasNumber, hasSpecialChar } from '../../Utils/validation';
import ConfirmOTP from '../ConfirmOTP/ConfirmOTP';
import styles from './styles.module.scss';
import signUpArt from './utils/assets/signupArt.svg';
import { UserSubmitForm, validationSchema } from './utils/validationSchema';

type AuthJWT = {
  isAuthenticated: boolean;
};

const SignUp: FC = () => {
  const username = useMemo(() => uuidv4(), []);
  const dispatch = useAppDispatch();
  const [passwordShown, setPasswordShown] = useState(false);
  const [repeatPasswordShown, setRepeatPasswordShown] = useState(false);
  const [email, setEmail] = React.useState<UserSubmitForm>({ email: '' });
  const [loading, setLoading] = useState(false);
  const [phoneNumber, setPhoneNumber] = React.useState<UserSubmitForm>({
    phoneNumber: '',
  });
  const [displayAccessCodeInput, setDisplayAccessCodeInput] = useState(false);
  const [passwordStr, setPasswordStr] = useState('');
  const [otpModal, setOtpModal] = useState(false);
  const [, setAuthJWT] = useLocalStorageState<AuthJWT>('isAuthenticated', {
    isAuthenticated: false,
  });
  const [, setAuth] = useState<string>('');
  const [, setUserInfo] = useState<UserProfile>(null!);
  const togglePassword = (): void => {
    setPasswordShown(!passwordShown);
  };
  const toggleRepeatPassword = (): void => {
    setRepeatPasswordShown(!repeatPasswordShown);
  };
  const [invitedUser, setInvitedUser] = useState<HashedUser | null>(null);
  const [defaultUser, setDefaultUser] = useState<{ email: string } | null>(null);
  const [PWDRequisiteFlag, setPWDRequisiteFlag] = useState(false);
  const [checks, setChecks] = useState({
    capsLetterCheck: false,
    numberCheck: false,
    pwdLengthCheck: false,
    specialFlag: false,
  });

  const [searchParams] = useSearchParams();
  const hash = searchParams.get('hash');
  const client = searchParams.get('client');

  const navigate = useNavigate();
  const goToDashboard = useCallback(
    (role = 'Patient') => {
      if (role === 'Admin' || role === 'SuperAdmin' || role === 'OrganizationAdmin') {
        navigate('/admin/dashboard');
        return;
      }
      navigate('/dashboard');
    },
    [navigate],
  );

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    getValues,
    trigger,
    clearErrors,
    setError,
  } = useForm<UserSubmitForm>({
    mode: 'onChange',
    resolver: yupResolver(validationSchema(setPhoneNumber, setEmail, hash, displayAccessCodeInput)),
  });

  useEffect(() => {
    (async () => {
      const emailParam = searchParams.get('email');
      if (hash) {
        await getUserByHash(hash)
          .then((response) => {
            setInvitedUser(response);
            setValue('email', response.email);
            setValue('phoneNumber', response.phone_number);
          })
          .catch((error) => {
            displayErrorDetails(error);
          });
      }

      if (emailParam) {
        const correctedEmail = emailParam.trim().replace(/\s/g, '+');

        const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
        const emailValid = emailRegex.test(correctedEmail);
        if (emailValid) {
          setValue('email', correctedEmail);
          setDefaultUser({ email: correctedEmail });
        }
      }
    })();
  }, [hash, searchParams, setValue]);

  const onSubmit = async (profileData: UserSubmitForm): Promise<void> => {
    setLoading(true);
    try {
      await signup({
        username,
        email: profileData.email?.toLowerCase() || '',
        password: profileData.password || '',
        attributes: {
          email: profileData.email?.toLowerCase() || '',
          phone_number: profileData.phoneNumber,
        },
        validation_data: {
          organization_id: config.organization_id,
          ...(client && { client }),
          ...(getValues('d1Code') && { access_code: getValues('d1Code') }),
          ...(config.division_id && { division_id: config.division_id }),
          ...(config.practice_id && { practice_id: config.practice_id }),
          invitation_hash: hash || '',
        },
      });
      setOtpModal(true);
    } catch (error: unknown) {
      if (error instanceof Error && error.message.includes('Email already exists')) {
        setError('email', {
          type: 'manual',
          message: 'Email already exist, please login to your account',
        });
      } else if (error instanceof Error && error.message.includes('[10000-0] Something went wrong.')) {
        setError('d1Code', {
          type: 'manual',
          message: 'Invalid Dentistry.One Code',
        });
        displayErrorDetails('Invalid Dentistry.One Code');
      } else {
        displayErrorDetails(error);
      }
    }
    setLoading(false);
  };

  const handleOnFocus = (): void => {
    if (passwordStr.trim().length !== 0) {
      setPWDRequisiteFlag(true);
    } else {
      setPWDRequisiteFlag(false);
    }
  };

  const handleOnBlur = (): void => {
    if (passwordStr.trim().length !== 0) {
      setPWDRequisiteFlag(true);
    } else {
      setPWDRequisiteFlag(false);
    }
  };

  const handleOnChangeConfirm = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const { value } = e.target;
    clearErrors('confirmPassword');
    setValue('confirmPassword', value);
  };

  const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    clearErrors('password');
    setPWDRequisiteFlag(true);
    const { value } = e.target;
    setValue('password', value);
    const capsLetterCheck = hasCapsLetter(value);
    const numberCheck = hasNumber(value);
    const specialFlag = hasSpecialChar(value);
    const pwdLengthCheck = value.length >= 8;
    setChecks({
      capsLetterCheck,
      numberCheck,
      pwdLengthCheck,
      specialFlag,
    });
    setPasswordStr(value);
  };

  const getCurrentLocation = (userInfo: UserProfile): void => {
    const storedLocationState = getCurrentPState(userInfo.id);
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        async (position) => {
          const lat = position.coords.latitude;
          const lng = position.coords.longitude;

          let currentState = DEFAULT_PATIENT_LOCATION_STATE;
          try {
            const response = await getStateFromGeocode(lat, lng);
            if (response) {
              const stateOption = stateOptions.find((option) => option.label.includes(response.state));
              if (stateOption && stateOption !== null) {
                currentState = stateOption.value;
              } else {
                currentState = DEFAULT_PATIENT_LOCATION_STATE;
              }
            }
          } catch (err) {
            currentState = DEFAULT_PATIENT_LOCATION_STATE;
          }
          dispatch(setPatientState({ userId: userInfo.id, state: currentState }));
          dispatch(setShowStateModal({ showStateModal: currentState !== storedLocationState }));
        },
        () => {
          const currentState = storedLocationState || DEFAULT_PATIENT_LOCATION_STATE;
          dispatch(setPatientState({ userId: userInfo.id, state: currentState }));
          dispatch(setShowStateModal({ showStateModal: currentState !== storedLocationState }));
        },
        { timeout: 10000, enableHighAccuracy: true },
      );
    } else {
      const currentState = storedLocationState || DEFAULT_PATIENT_LOCATION_STATE;
      dispatch(setPatientState({ userId: userInfo.id, state: currentState }));
      dispatch(setShowStateModal({ showStateModal: currentState !== storedLocationState }));
    }
  };

  const dispatchSuccessEvent = (userId: string): void => {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: 'sign_up',
      userId,
    });
  };

  const onConfirmedOTP = async (): Promise<void> => {
    try {
      const loginResponse = await login({
        email: email.email?.toLowerCase() || phoneNumber.phoneNumber || '',
        password: passwordStr,
        organization_id: config.organization_id,
        ...(config.division_id && { division_id: config.division_id }),
        ...(config.practice_id && { practice_id: config.practice_id }),
        ...(client && { client }),
        // ...(displayAccessCodeInput && getValues('d1Code') && { access_code: getValues('d1Code') }),
      });

      const accessToken = loginResponse.access_token;
      const refreshToken = loginResponse.refresh_token;
      Cookies.remove('jwtToken', { domain: config.domain_name });
      Cookies.remove('refreshToken', { domain: config.domain_name });
      Cookies.remove('jwtTokenExpire', { domain: config.domain_name });

      Cookies.set('jwtToken', accessToken as string, {
        domain: config.domain_name,
        secure: true,
        sameSite: 'strict',
      });
      Cookies.set('refreshToken', refreshToken as string, {
        domain: config.domain_name,
        secure: true,
        sameSite: 'strict',
      });

      // Need to be removed so that we can handle token expiration in a better way
      Cookies.set('jwtTokenExpire', moment().add(20, 'hours').valueOf().toString(), {
        domain: config.domain_name,
        secure: true,
        sameSite: 'strict',
      });
      const newJWT: AuthJWT = {
        isAuthenticated: true,
      };
      setAuthJWT(newJWT);
      const response = await getCurrentProfile();
      try {
        const currentOrganization = await getCurrentOrganization();
        dispatch(setUser({ userInfo: response }));
        dispatch(setOrganization({ currentOrganization }));
        if (response.role.name === 'Patient') {
          const currentOrganizationPrices = await getPricings();
          dispatch(setOrganizationPrices({ currentOrganizationPrices }));
        }
      } catch (error: unknown) {
        displayErrorDetails(error);
      }
      setUserInfo(response);
      setAuth(accessToken as string);
      dispatchSuccessEvent(response.id.toString());
      getCurrentLocation(response);
      setOtpModal(false);
      goToDashboard(response.role.name);
    } catch (error: unknown) {
      displayErrorDetails(error);
    }
  };

  const ref = useRef(null);

  const isAllFieldsFilled = useMemo(() => {
    const { password, confirmPassword, isTermsAccepted, d1Code } = getValues();

    return (
      !password ||
      !confirmPassword ||
      !isTermsAccepted ||
      ((!d1Code || d1Code?.trim() === '') && displayAccessCodeInput) ||
      !checks.capsLetterCheck ||
      !checks.numberCheck ||
      !checks.pwdLengthCheck ||
      !checks.specialFlag ||
      password !== confirmPassword
    );
  }, [getValues(), checks]);

  useEffect(() => {
    const { password, confirmPassword } = getValues();
    if (password && confirmPassword && password !== confirmPassword) {
      setError('confirmPassword', {
        type: 'manual',
        message: 'Passwords do not match',
      });
    }
    if (password && confirmPassword && password === confirmPassword) {
      clearErrors('confirmPassword');
    }
  }, [getValues('password'), getValues('confirmPassword')]);

  useEffect(() => {
    if (errors.email && errors.email.message === 'This email already exists') {
      const params = new URLSearchParams(location.search);
      const client = params.get('client');

      if (client && email) {
        navigate(`/existing-account?client=${client}&email=${encodeURIComponent(getValues('email') as string)}`);
      }
    }
  }, [errors.email]);

  if (otpModal && email.email) {
    return <ConfirmOTP username={email.email.toLowerCase()} onConfirmed={onConfirmedOTP} />;
  }
  return (
    <>
      <Sidenav isVisibleOnMobile hideDefaultOptions authLoog>
        <a
          href="https://dentistryone.zendesk.com/hc/en-us/articles/11914427760151-How-to-change-your-password"
          target="_blank"
          className={styles.sidenavContainer}
          rel="noreferrer"
          style={{ textDecoration: 'none' }}
        >
          <div className={styles.sidenavToggleText}>Help</div>
          <div className={styles.sidenavToggle}>
            <AiOutlineQuestionCircle />
          </div>
        </a>
      </Sidenav>
      <div className={styles.body}>
        <div className={styles.leftSection}>
          <div className={styles.container}>
            <form className={styles.formContainer} onSubmit={handleSubmit(onSubmit)}>
              <span className={styles.formTitle}>
                <span className={styles.formTitleWelcome}>
                  {`${!invitedUser ? 'Create an account' : 'Set Your Password'}`}
                </span>
                <div className={styles.formTitleLink}>
                  <span className={styles.txt1}>Already have an account?</span>
                  &nbsp;
                  <a style={{ cursor: 'pointer' }} className={`txt2 ${styles.txt2}`} onClick={() => navigate('/')}>
                    Login here.
                  </a>
                </div>
              </span>
              {!invitedUser && !defaultUser ? (
                <div className={styles.inputWrap}>
                  <span>Email</span>
                  <TextField
                    {...register('email')}
                    errors={errors.email ? errors : {}}
                    name="email"
                    onChange={(e) => {
                      if (errors.email) clearErrors('email');
                      setValue('email', e.target.value);
                    }}
                    wrapperStyle={{ marginTop: '0', height: 'fit-content', borderBottom: 'none' }}
                    inputClass={styles.profileInputClass}
                    isUnderlined={false}
                    ref={ref}
                  />
                </div>
              ) : (
                <div className={styles.inputWrap}>
                  <span>Email</span>
                  <TextField
                    {...register('email')}
                    errors={errors.email ? errors : {}}
                    name="email"
                    value={invitedUser?.email || defaultUser?.email}
                    wrapperStyle={{ marginTop: '0', height: 'fit-content', borderBottom: 'none' }}
                    inputClass={styles.profileInputClass}
                    ref={ref}
                    disabled
                  />
                </div>
              )}
              <div className={styles.inputWrap} style={{ paddingBottom: '20px' }}>
                <span>Password</span>
                <div className={styles.inputWrapper}>
                  <span className={styles.btnShowPass}>
                    {passwordShown ? (
                      <TbEye
                        className={styles.eyeIcon}
                        style={{ color: '#276fe7' }}
                        title="Hide Password"
                        onClick={togglePassword}
                      />
                    ) : (
                      <TbEye
                        className={styles.eyeIcon}
                        style={{ color: getSecondaryColor() }}
                        title="Show Password"
                        onClick={togglePassword}
                      />
                    )}
                  </span>
                  <input
                    {...register('password')}
                    className={`${styles.input100} ${styles.profileInputClass} ${errors.email ? 'is-invalid' : ''}`}
                    type={passwordShown ? 'text' : 'password'}
                    name="password"
                    onChange={handleOnChange}
                    onFocus={handleOnFocus}
                    onBlur={handleOnBlur}
                    style={{ paddingRight: '24px' }}
                    autoComplete="new-password"
                  />
                  {PWDRequisiteFlag ? (
                    <PWDRequisite
                      capsLetterFlag={checks.capsLetterCheck ? 'valid' : 'invalid'}
                      numberFlag={checks.numberCheck ? 'valid' : 'invalid'}
                      pwdLengthFlag={checks.pwdLengthCheck ? 'valid' : 'invalid'}
                      specialFlag={checks.specialFlag ? 'valid' : 'invalid'}
                    />
                  ) : (
                    <>
                      <div className={`${styles.invalidFeedback} invalid-feedback`}>Password is required</div>
                      {!errors.password?.message && (
                        <p className={styles.passwordHint}>
                          Use 8 or more characters with a mix of letters, numbers & symbols
                        </p>
                      )}
                    </>
                  )}
                </div>
              </div>
              <div className={styles.inputWrap}>
                <span>Confirm Password</span>
                <div className={styles.inputWrapper} data-validate="Enter password">
                  <span className={styles.btnShowPass}>
                    {repeatPasswordShown ? (
                      <TbEye
                        className={styles.eyeIcon}
                        style={{ color: '#276fe7' }}
                        title="Hide Password"
                        onClick={toggleRepeatPassword}
                      />
                    ) : (
                      <TbEye
                        className={styles.eyeIcon}
                        style={{ color: getSecondaryColor() }}
                        title="Show Password"
                        onClick={toggleRepeatPassword}
                      />
                    )}
                  </span>
                  <TextField
                    {...register('confirmPassword')}
                    errors={errors.confirmPassword ? errors : {}}
                    name="confirmPassword"
                    type={repeatPasswordShown ? 'text' : 'password'}
                    onChange={handleOnChangeConfirm}
                    wrapperStyle={{ marginTop: '0', height: 'fit-content', borderBottom: 'none' }}
                    inputClass={styles.profileInputClass}
                    isUnderlined={false}
                    style={{ paddingRight: '24px' }}
                    ref={ref}
                  />
                </div>
              </div>
              {config.organization_id === 'dentistry-one' && (!invitedUser || invitedUser?.role.name === 'Patient') && (
                <div className={styles.isTermAccepted}>
                  <div className="form-check m-0 mt-2">
                    <input
                      type="checkbox"
                      id="accessCode"
                      onChange={() => {
                        setDisplayAccessCodeInput(!displayAccessCodeInput);
                      }}
                      checked={displayAccessCodeInput}
                      className={`form-check-input ${styles.isTermAcceptedInput} ${
                        errors.isTermsAccepted ? 'is-invalid' : ''
                      }`}
                    />
                    <label className={`${styles.isTermAcceptedLabel}`} htmlFor="accessCode">
                      I have a Dentistry.One code
                    </label>
                  </div>
                </div>
              )}
              {displayAccessCodeInput && (
                <div className={styles.inputWrap}>
                  <span>Dentistry.One Code</span>
                  <TextField
                    {...register('d1Code')}
                    name="d1Code"
                    errors={errors.d1Code ? errors : {}}
                    onChange={(e) => {
                      if (errors.email) clearErrors('d1Code');
                      setValue('d1Code', e.target.value, { shouldValidate: true });
                    }}
                    wrapperStyle={{ marginTop: '0', height: 'fit-content', borderBottom: 'none' }}
                    inputClass={styles.profileInputClass}
                    isUnderlined={false}
                    ref={ref}
                  />
                </div>
              )}
              <div className={styles.isTermAccepted}>
                <div className="form-check m-0 mt-2">
                  <input
                    {...register('isTermsAccepted')}
                    type="checkbox"
                    id="isTermsAccepted"
                    onChange={(e) => {
                      setValue('isTermsAccepted', e.target.checked);
                      trigger('isTermsAccepted');
                    }}
                    checked={getValues('isTermsAccepted')}
                    className={`form-check-input ${styles.isTermAcceptedInput} ${
                      errors.isTermsAccepted ? 'is-invalid' : ''
                    }`}
                  />
                  <label className={`${styles.isTermAcceptedLabel}`} htmlFor="isTermsAccepted">
                    By checking this box, I agree to the{' '}
                    <a href="./terms-of-service" target="_blank" rel="noreferrer">
                      terms of service
                    </a>
                    , the{' '}
                    <a href="./consent-policy" target="_blank" rel="noreferrer">
                      consent policy
                    </a>
                    , the{' '}
                    <a href="./privacy-policy" target="_blank" rel="noreferrer">
                      privacy policy
                    </a>{' '}
                    and the{' '}
                    <a href="./notice-of-privacy-practices" target="_blank" rel="noreferrer">
                      notice of privacy practices
                    </a>{' '}
                    and if I am a Washington state resident I agree with the
                    <a href="./washington_my_health_my_data_privacy_notice" target="_blank" rel="noreferrer">
                      {' '}
                      Washington My Health My Data Privacy Notice
                    </a>{' '}
                  </label>
                  <div className={`${styles.invalidFeedback} invalid-feedback`} style={{ marginLeft: '0px' }}>
                    {errors.isTermsAccepted?.message}
                  </div>
                </div>
              </div>
              <Button
                type="submit"
                style={{ borderRadius: '4px', height: '56px' }}
                disabled={loading || isAllFieldsFilled}
                auth
              >
                {`${!invitedUser ? 'Sign Up' : 'Save'}`}
              </Button>
            </form>
            {false && (
              <>
                <div className={styles.socialMediaSeparator}>
                  <span className={styles.signUpWith}>Or sign up with</span>
                </div>
                <div className={`${styles.row} ${styles.signUpOptions}`}>
                  <div className={`col-sm-4 ${styles.signUpOption} ${styles.apple}`} />
                  <div className={`col-sm-4 ${styles.signUpOption} ${styles.google}`} />
                  <div className={`col-sm-4 ${styles.signUpOption} ${styles.facebook}`} />
                </div>
              </>
            )}
          </div>
        </div>
        <div className={styles.rightImage}>
          <div className={`${styles.cardImage} ${styles.signinArt}`} style={{ backgroundImage: `url(${signUpArt})` }} />
        </div>
      </div>
    </>
  );
};

export default SignUp;

import Container from '@brands/Components/Container/Container';
import Sidenav from '@brands/Dashboard/Sidenav/Sidenav';
import useWindowSize from '@brands/hooks/useWindowSize';
import React, { FC } from 'react';
import { AiOutlineQuestionCircle } from 'react-icons/ai';

import styles from './styles.module.scss';

const PrivacyPolicy: FC = () => {
  const screenSize = useWindowSize();
  return (
    <>
      <Sidenav isVisibleOnMobile hideDefaultOptions authLoog>
        <a
          href="https://dentistryone.zendesk.com/hc/en-us/articles/11914427760151-How-to-change-your-password"
          target="_blank"
          className={styles.sidenavContainer}
          rel="noreferrer"
          style={{ textDecoration: 'none' }}
        >
          <div className={styles.sidenavToggleText}>Help</div>
          <div className={styles.sidenavToggle}>
            <AiOutlineQuestionCircle />
          </div>
        </a>
      </Sidenav>
      <Container
        isBGEclipse={false}
        patientDashboard
        childClassName={styles.dashboard}
        containerStyle={{
          justifyContent: screenSize.width >= 1289 ? 'center' : 'start',
          overflowY: screenSize.width >= 1289 ? 'hidden' : 'scroll',
        }}
      >
        <div className={styles.tOSBody}>
          <div id="content" className={styles.tOSBodySiteContent}>
            <main id="primary" className="site-main">
              <article id="post-929" className="post-929 page type-page status-publish hentry">
                <div className={`entry-content ${styles.privacyPolicyContent}`}>
                  <h2
                    className="wp-block-heading"
                    style={{ display: 'flex', justifyContent: 'center' }}
                    data-block-type="core"
                  >
                    PRIVACY POLICY
                  </h2>

                  <h4
                    className="wp-block-heading"
                    style={{ fontWeight: '400', margin: '10px 0 25px' }}
                    data-block-type="core"
                  >
                    Last updated on [12/16/2024]
                  </h4>

                  <p>
                    Dentistry.One LLC (collectively referred to as "D1", "we", "us", or "our"), provides certain
                    websites and various mobile and/or desktop applications (collectively, the “Platform”) as well as
                    services, features, content, and applications, which among other things provide potential patients
                    access to a network of dental practitioners, who will provide dental and related services by and
                    through the Platform (collectively, the “Services”). This Privacy Policy (the “Policy”) is not a
                    contract and does not create any contractual rights or obligations.
                  </p>

                  <h3
                    className="wp-block-heading"
                    style={{ fontWeight: '400', margin: '10px 0 25px' }}
                    data-block-type="core"
                  >
                    PRIVACY IS IMPORTANT TO D1
                  </h3>

                  <p>
                    Here at D1, your privacy is important to us and this Policy provides an overview of how we employ,
                    disclose, and process your personal information. This Policy applies to visitors of the websites
                    dentistry.one, the sub domain app.dentistry.one and any other publicly available associated domains
                    and mobile applications owned and operated by D1 (again, the “Platform”).
                  </p>

                  <p>
                    For the purposes of this Policy, "you" and "your" means you as the visitor or user of the Platform.
                    If the person accessing the Platform or engaging with our Services does so on behalf of, or for the
                    purposes of, another person, including a business or other organization, “you” or “your” also means
                    that other person, including a business organization, if applicable. This Policy describes the types
                    of personal information we collect from visitors and users of our Platform and our practices for
                    using, maintaining, sharing, and protecting it. It also describes how you may contact us.
                  </p>

                  <span>The Policy consists of the following sections:</span>
                  <ul className={styles.list} style={{ marginLeft: '30px' }} data-block-type="core">
                    <li data-block-type="core">Updates To This Policy</li>
                    <li data-block-type="core">Personal Information We Collect And What We Do With It</li>
                    <li data-block-type="core">Protected Health Information</li>
                    <li data-block-type="core">Use By Minors</li>
                    <li data-block-type="core">Third-Party Websites And Links And Social Media</li>
                    <li data-block-type="core">Cookies And Online Technologies Policy</li>
                    <li data-block-type="core">How We Protect Your Information</li>
                    <li data-block-type="core">Marketing</li>
                    <li data-block-type="core">Data Retention</li>
                    <li data-block-type="core">Notice To International Users</li>
                    <li data-block-type="core">How To Contact Us</li>
                  </ul>

                  <h3
                    className="wp-block-heading"
                    style={{ fontWeight: '400', margin: '10px 0 25px' }}
                    data-block-type="core"
                  >
                    UPDATES TO THIS POLICY
                  </h3>

                  <p>
                    This Policy may be updated periodically to reflect changes in our privacy practices. It is your
                    responsibility to review the Policy from time to time to view any such changes. Changes to this
                    policy become effective immediately upon publication. Please consult this space for updates.
                  </p>

                  <h3
                    className="wp-block-heading"
                    style={{ fontWeight: '400', margin: '10px 0 25px' }}
                    data-block-type="core"
                  >
                    PERSONAL INFORMATION WE COLLECT AND WHAT WE DO WITH IT
                  </h3>
                  <p>
                    <span style={{ fontWeight: 'bold', fontSize: '17px' }}>When you visit the Platform</span>, we may
                    collect your:
                  </p>

                  <ul className={styles.list} style={{ marginLeft: '30px' }} data-block-type="core">
                    <li data-block-type="core">
                      Online identifier information, such as your internet protocol (IP) address and other unique
                      identifiers (like cookies);
                    </li>
                    <li data-block-type="core">Geolocation information;</li>
                    <li data-block-type="core">
                      Internet activity information, such as the referring website, time spent on the Platform, pages
                      you look at and click, forms you look at and interact with, links that you click and generally how
                      you interact with our Platform; and
                    </li>
                    <li data-block-type="core">
                      Device information, such as your operating system, browser and other technology on the devices
                      that you use to access the Platform.
                    </li>
                  </ul>
                  <p style={{ marginLeft: '10px' }}>We collect this information in order to:</p>
                  <ul className={styles.list} style={{ marginLeft: '30px' }} data-block-type="core">
                    <li data-block-type="core">
                      Provide you with access to the Platform, including personalized features;
                    </li>
                    <li data-block-type="core">Secure our networks, systems and databases against external threats;</li>
                    <li data-block-type="core">Perform general Platform analytics and monitor Platform usage;</li>
                    <li data-block-type="core">Develop, maintain and enhance our Platform and Services; and</li>
                    <li data-block-type="core">
                      To advertise our Services, including through targeted or interest based advertising. You can read
                      more about our use of tracking technologies here: Cookies and Online Technologies Policy.
                    </li>
                  </ul>
                  <p style={{ marginLeft: '10px' }}>We may disclose this information to our:</p>
                  <ul className={styles.list} style={{ marginLeft: '30px' }} data-block-type="core">
                    <li data-block-type="core">
                      Service providers (marketing services vendors, customer support and chat providers, IT support
                      vendors, security monitoring providers, data analytics providers, practice management software
                      providers, database and hosting vendors);
                    </li>
                    <li data-block-type="core">
                      Entities that operate plug-ins or social media features on our Platform; and
                    </li>
                    <li data-block-type="core">Online advertising and marketing partners.</li>
                  </ul>

                  <p>
                    <span style={{ fontWeight: 'bold', fontSize: '17px' }}>
                      When you fill out a web form on our Platform
                    </span>
                    , we may collect your:
                  </p>

                  <ul className={styles.list} style={{ marginLeft: '30px' }} data-block-type="core">
                    <li data-block-type="core">Identifier information, such as your full name and date of birth;</li>
                    <li data-block-type="core">
                      Personal or business contact information, such as your phone number and e-mail;
                    </li>
                    <li data-block-type="core">Business information, such as your company name and specialty;</li>
                    <li data-block-type="core">
                      Licensure information, such as your professional license information, NPI number, specialty and
                      states of licensure; and
                    </li>
                    <li data-block-type="core">Any other information you choose to provide through the form.</li>
                  </ul>
                  <p style={{ marginLeft: '10px' }}>We collect this information in order to:</p>
                  <ul className={styles.list} style={{ marginLeft: '30px' }} data-block-type="core">
                    <li data-block-type="core">Respond to your requests and inquiries;</li>
                    <li data-block-type="core">Provide you with the Platform requested Services;</li>
                    <li data-block-type="core">
                      Develop, maintain and enhance our Platform and Services, including by analyzing how users interact
                      with our web forms;
                    </li>
                    <li data-block-type="core">Perform analytics, derive metrics and perform research;</li>
                    <li data-block-type="core">Process transactions with you;</li>
                    <li data-block-type="core">Market our Services to you; and</li>
                    <li data-block-type="core">Contact you with information about our Services.</li>
                  </ul>
                  <p style={{ marginLeft: '10px' }}>We may disclose this information to our:</p>
                  <ul className={styles.list} style={{ marginLeft: '30px' }} data-block-type="core">
                    <li data-block-type="core">
                      Service providers (webform and survey providers, customer support and chat providers, IT support
                      vendors, data analytics providers, practice management software providers, database vendors and
                      our text/email communication vendors);
                    </li>
                    <li data-block-type="core">Advertising and marketing partners; and</li>
                    <li data-block-type="core">Providers with whom you are connected.</li>
                  </ul>

                  <p>
                    <span style={{ fontWeight: 'bold', fontSize: '17px' }}>
                      When you sign-up for the Platform or engage with our Services through the Platform
                    </span>
                    , we may collect your (or your dependents’):
                  </p>

                  <ul className={styles.list} style={{ marginLeft: '30px' }} data-block-type="core">
                    <li data-block-type="core">
                      Identifier information, such as your full name, date of birth, pronouns, gender, account name and
                      password;
                    </li>
                    <li data-block-type="core">Contact information, such as your address, phone number and e-mail;</li>
                    <li data-block-type="core">
                      Payment information, such as your payment card number, billing address, payment or banking
                      information;
                    </li>
                    <li data-block-type="core">Image information, such as photos of your mouth and teeth;</li>
                    <li data-block-type="core">
                      Insurance information, such as your coverage details, member ID, group number, health plan
                      information and carrier information;
                    </li>
                    <li data-block-type="core">Pharmacy information;</li>
                    <li data-block-type="core">
                      Medical and health information, such as your medical and dental history, medical conditions,
                      height and weight, allergies, pain levels, medication and drug use; and
                    </li>
                    <li data-block-type="core">
                      Any information you provide to our AI chat bot or customer service interface.
                    </li>
                  </ul>
                  <p style={{ marginLeft: '10px' }}>We collect this information in order to:</p>
                  <ul className={styles.list} style={{ marginLeft: '30px' }} data-block-type="core">
                    <li data-block-type="core">Connect you with providers, including scheduling appointments;</li>
                    <li data-block-type="core">Provide you with the Platform requested Services;</li>
                    <li data-block-type="core">Develop, maintain and enhance our Services;</li>
                    <li data-block-type="core">Perform analytics, derive metrics and perform research;</li>
                    <li data-block-type="core">Process transactions with you; and</li>
                    <li data-block-type="core">Contact you with information about our Services.</li>
                  </ul>
                  <p style={{ marginLeft: '10px' }}>We may disclose this information to our:</p>
                  <ul className={styles.list} style={{ marginLeft: '30px' }} data-block-type="core">
                    <li data-block-type="core">
                      Service providers (payment processors, IT support vendors, customer support and chat providers,
                      e-prescription providers, data analytics providers, practice management software providers,
                      database and hosting vendors and text/email communication vendors);
                    </li>
                    <li data-block-type="core">
                      Imaging scanning provider (which uses artificial intelligence to scan your mouth photos);
                    </li>
                    <li data-block-type="core">Eligibility and claim clearinghouses; and</li>
                    <li data-block-type="core">Providers with whom you are connected.</li>
                  </ul>
                  <p>
                    In addition, any information you provide may be disclosed to other entities in the circumstances
                    described below:
                  </p>
                  <ul className={styles.list} style={{ marginLeft: '30px' }} data-block-type="core">
                    <li data-block-type="core">
                      <span style={{ fontWeight: 'bold' }}>To our auditors.&nbsp;</span>&nbsp;We may be subject to
                      audits from a number of entities as well as due to our own internal auditing policies. In order to
                      accomplish an effective audit, we must provide information, which may include your personal
                      information, to external auditors. We always ensure that your information is safely disclosed and
                      stored and that auditors can only use your information for the purposes of completing an audit.
                    </li>
                    <li data-block-type="core">
                      <span style={{ fontWeight: 'bold' }}>To individuals or entities you authorize.&nbsp;</span>
                      &nbsp;We may disclose your personal information to individuals or entities at your direction.
                    </li>
                    <li data-block-type="core">
                      <span style={{ fontWeight: 'bold' }}>To our affiliates.&nbsp;</span>&nbsp;We may share your
                      personal information with our affiliates for the purposes of administering our business and
                      providing our Services.
                    </li>
                    <li data-block-type="core">
                      <span style={{ fontWeight: 'bold' }}>In corporate transactions.&nbsp;</span>&nbsp;We may share all
                      or part of your personal information with other entities in connection with the sale, assignment,
                      merger or other transfer of all or a portion of our organization or assets to such entities
                      (including due to a sale in connection with a bankruptcy).
                    </li>
                    <li data-block-type="core">
                      <span style={{ fontWeight: 'bold' }}>For legal purposes.&nbsp;</span>&nbsp;We may disclose all or
                      part of your personal information to courts, litigants, regulators, arbitrators, administrative
                      bodies or law enforcement when we have reason to believe that disclosing this information is
                      necessary to resolve actual or suspected claims. We may also disclose your personal information in
                      order to identify, contact or bring legal action against someone who may be violating any
                      agreement with us, or may be causing injury to or interference with (either intentionally or
                      unintentionally) our rights or property, other users of the Platform, or anyone else that could be
                      harmed by such activities. We may disclose information in response to a subpoena, search warrant,
                      in connection with judicial proceedings, or pursuant to court orders, legal process or other law
                      enforcement measures.
                    </li>
                  </ul>
                  <p>
                    Finally, we may process your personal information into aggregated, anonymized or de-identified form
                    for any purpose. Aggregated, anonymized or de-identified information is information that can no
                    longer reasonably identify a specific individual and is no longer “personal information.”
                  </p>
                  <h3
                    className="wp-block-heading"
                    style={{ fontWeight: '400', margin: '10px 0 25px' }}
                    data-block-type="core"
                  >
                    PROTECTED HEALTH INFORMATION
                  </h3>

                  <p>
                    This Policy applies to your interactions with the Platform leading up to, or separate from, a
                    relationship with a dental practice. D1 does not provide medical care under the Health Insurance
                    Portability and Accountability Act of 1996 and its related regulations and amendments from time to
                    time (collectively, "HIPAA").
                  </p>
                  <p>
                    Once you are in contact with one of our practices, any data collected through your interactions with
                    the Platform for purposes of scheduling services with a dental practice, arranging telehealth
                    visits, viewing/paying invoices, or related activities, is controlled by your relationship with the
                    practice and the practice’s HIPAA Notice of Privacy Practices and/or its general privacy policy.
                  </p>
                  <p>
                    When you set up an account with the Platform, that creates a customer relationship between you and
                    D1, and that relationship provides you access as user and the opportunity to use the Platform’s
                    functionalities as well as the Services. To the relationship, from time to time you will provide
                    various information to D1, including but not limited to, your name, email address, payment
                    information, phone number and certain other information, that D1 does NOT consider to be or deem
                    "protected health information" or "medical information".
                  </p>
                  <p>
                    Nonetheless, while using different features and components of the Platform and/or the Services, from
                    time to time you may also provide certain health or medical information, and such information may be
                    protected under applicable laws. Please note and please be aware that D1 is not a "covered entity"
                    under the Health Insurance Portability and Accountability Act of 1996, Public Law 104-191, and its
                    related regulations and amendments from time to time (collectively, "HIPAA"). However, it is
                    possible that one or more of the Dental Groups (as defined in our Terms of Service) may (or may not)
                    be considered or deemed a "covered entity" or "business associate" under HIPAA. In addition, under
                    certain circumstances and some cases, D1 may be considered or deemed a "business associate" of a
                    Dental Group.
                  </p>
                  <p>
                    Please be advised that HIPAA does not necessarily nor automatically apply to an entity or person
                    merely because there may be health information involved or communicated, and that as a result HIPAA
                    may not apply to your transactions or communications with D1 and the Dental Groups. On the other
                    hand, strictly and solely to the extent that D1 is potentially deemed a "business associate", D1, in
                    those instances may be subject to certain terms and conditions of HIPAA with respect to "protected
                    health information," as defined under HIPAA ("PHI"), that you provide to D1, the applicable Dental
                    Group or the providers.{' '}
                  </p>
                  <p>
                    Moreover, any medical or health information provided by you may benefit from certain protections
                    under applicable state laws (“State Protected Information”). However, any information that does not
                    constitute PHI or State Protected Information under applicable laws may be used or disclosed in any
                    manner permitted under our Privacy Policy. PHI or State Protected Information shall not be deemed to
                    include information that has been de-identified in accordance with HIPAA.
                  </p>
                  <p>
                    The Dental Groups and providers have adopted a Notice of Privacy Practices that describes how they
                    use and disclose Protected Information. By accessing or using any part of the Platform or the
                    Service, you acknowledge receipt of the Notice of Privacy Practices from your Medical Group and
                    provider(s).
                  </p>
                  <p>
                    Finally, you understand and agree that by using the Platform and/or the Services, even in those
                    cases where HIPAA does apply to D1, the Dental Groups, the Providers, any and all information that
                    you submit to D1 that is not intended for and used solely in connection with the provision of dental
                    care and treatment by the Dental Group and providers, is not considered Protected Health
                    Information, and such information shall only be subject to and governed by D1’s Privacy Policy and
                    applicable state privacy laws. For purposes of clarity, information you provide to D1 in order to
                    register and set up an account on the Platform, including name, username, email address, and phone
                    number, or other related contact or payment information, are not considered Protected Health
                    Information.
                  </p>
                  <h3
                    className="wp-block-heading"
                    style={{ fontWeight: '400', margin: '10px 0 25px' }}
                    data-block-type="core"
                  >
                    USE BY MINORS
                  </h3>
                  <p>
                    The D1 Platform and Service is generally for use by individuals who are at least eighteen (18) years
                    of age or such older age as required by applicable state law per the locale or jurisdiction in which
                    the individual is using the Service. Individuals who are between the ages of thirteen (13) and
                    eighteen (18) (or such older age of majority) may use the Service for the sole purpose of obtaining
                    a dental consultation if a parent or legal guardian provides consent to such use in accordance with
                    the requirements set forth in our Terms and Service. Nothing contained within the Platform and/or
                    the Service is intended to address, and/or is directed to, any children under the age of thirteen
                    (13). To the extent that D1 has actual knowledge that we have collected personal information through
                    the Platform from a person under thirteen (13) years of age, we will use reasonable efforts to
                    refrain from further using such personal information or maintaining it in retrievable form.
                  </p>
                  <p>
                    In addition, if you are under sixteen (16) years of age, then you (or, in the alternative, your
                    parent or legal guardian if you are under age 13) may request at any time that D1 removes content or
                    information about you that may be accessible or posted on the Platform. In order to do so you must
                    please submit any such request ("Minor Information Removal Request") to either of the following:
                  </p>

                  <ul className={styles.list} style={{ marginLeft: '30px' }} data-block-type="core">
                    <li data-block-type="core">
                      By mail: Dentistry.One, Attn: Privacy Requests, 460 Main Street, Ste B, Metuchen, NJ 08840
                    </li>
                    <li data-block-type="core">
                      By email: privacy@dentistry.one, using the subject line of "Minor Information Removal Request"
                    </li>
                  </ul>
                  <p>
                    With each Minor Information Removal Request, please also specifically provide the following
                    information in the body of the request:
                  </p>
                  <ul className={styles.list} style={{ marginLeft: '30px' }} data-block-type="core">
                    <li data-block-type="core">The basis and description of your request;</li>
                    <li data-block-type="core">
                      The specific nature identity and of the content or information to be removed;
                    </li>
                    <li data-block-type="core">
                      The specific location of the content or information on the Platform (e.g. , the applicable link or
                      URL);
                    </li>
                    <li data-block-type="core">
                      the specific statement that the request is in connection with "Minor Information Removal Request";
                      and
                    </li>
                    <li data-block-type="core">
                      Your name, street address, city, state, zip code and email address, and whether you prefer to
                      receive a response to your request by mail or email.
                    </li>
                  </ul>
                  <p>
                    Please be aware that we do not accept any Minor Information Removal Request by and through telephone
                    or facsimile. D1 is not responsible for failing to comply with any Minor Information Removal Request
                    that is incomplete, incorrectly or improperly labeled or incorrectly transmitted.
                  </p>
                  <p>
                    Please note that we are not required to erase or otherwise eliminate, or facilitate erasure or
                    elimination of such content or information in certain cases and circumstances, including for
                    example, in those circumstances where an international, federal, state, or local law, rule or
                    regulation mandates that D1 to maintain the content or information; when D1 maintains the content or
                    information on behalf of your Providers (as defined in our Terms of Services) as part of your
                    electronic medical record; when the content or information is stored on or posted to the Platform by
                    a third party other than you (including any content or information posted by you that was stored,
                    republished or reposted by the third party); when D1 anonymizes the content or information, so that
                    you cannot be individually identified; when you do not follow the above described instructions for
                    requesting the removal of the content or information; and when you have received compensation or
                    other consideration for providing the content or information.
                  </p>
                  <p>
                    The above description of D1's practices concerning the collection of personal information through
                    the Platform and/or Service from certain persons or individuals under the age of 18, is not an
                    admission and is not intended to be an admission that D1 is subject to or regulated by the
                    Children's Online Privacy Protection Act, the Federal Trade Commission's Children's Online Privacy
                    Protection Rule(s), or any related or similar international, federal, state, or local laws, rules,
                    or regulations.
                  </p>
                  <h3
                    className="wp-block-heading"
                    style={{ fontWeight: '400', margin: '10px 0 25px' }}
                    data-block-type="core"
                  >
                    THIRD PARTY WEBSITES AND LINKS AND SOCIAL MEDIA
                  </h3>
                  <p>
                    Certain features of the Platform permit you to initiate interactions between the Platform and
                    third-party services or platforms, such as social networks ("Social Media"). Social Media include
                    features that allow you to click and access D1's pages on certain third-party platforms, such as
                    Facebook, Twitter and LinkedIn as applicable, and from there to "like" or "share" our content on
                    those platforms. Use of Social Media may entail a third party's collection and/or use of your data.
                    If you use Social Media or similar third-party services, information you post or otherwise make
                    accessible may be publicly displayed by the third-party service you are using. Both D1 and the third
                    party may have access to information about you and your use of both the Platform and the third-party
                    service.
                  </p>
                  <p>
                    Our Platform may contain links to other online platforms operated by third parties. We do not
                    control such other online platforms and are not responsible for their content, their privacy
                    policies, or their use of your information. Information you provide on public or semi-public venues,
                    including information you share on third-party social networking platforms may also be viewable by
                    other users of the Platform and/or users of those third-party online platforms without limitation as
                    to its use by us or by a third party. Our inclusion of such links does not, by itself, imply any
                    endorsement of the content on such platforms or of their owners or operators except as disclosed on
                    the Platform. We expressly disclaim any and all liability for the actions of third parties,
                    including but without limitation to actions relating to the use and/or disclosure of personal
                    information by third parties. Any information submitted by you directly to these third parties is
                    subject to that third party's privacy policy.
                  </p>
                  <h3
                    className="wp-block-heading"
                    style={{ fontWeight: '400', margin: '10px 0 25px' }}
                    data-block-type="core"
                  >
                    COOKIES AND ONLINE TECHNOLOGIES POLICY
                  </h3>
                  <h4
                    className="wp-block-heading"
                    style={{ fontWeight: '400', margin: '10px 0 25px' }}
                    data-block-type="core"
                  >
                    What is a cookie?
                  </h4>
                  <p>
                    A cookie is a small text file that is placed on your hard drive by a web page server. Cookies
                    contain information that can later be read by a web server in the domain that issued the cookie to
                    you. There are several types of cookies:
                  </p>
                  <ul className={styles.list} style={{ marginLeft: '30px' }} data-block-type="core">
                    <li data-block-type="core">
                      <span style={{ fontWeight: 'bold' }}>Session cookies&nbsp;</span>
                      &nbsp;provide information about how a website is used during a single browser session while a user
                      is visiting a website. Session cookies usually expire after the browser is closed.
                    </li>
                    <li data-block-type="core">
                      <span style={{ fontWeight: 'bold' }}>Persistent cookies&nbsp;</span>&nbsp;remain on your device
                      between different browser sessions for a set amount of time in order to enable the website to
                      remember user preferences, settings, or actions across other sites. A persistent cookie will
                      remain on a user’s device for a set period of time specified in the cookie.
                    </li>
                    <li data-block-type="core">
                      <span style={{ fontWeight: 'bold' }}>First-party cookies&nbsp;</span>&nbsp;are cookies set by the
                      operator of the website you are visiting.
                    </li>
                    <li data-block-type="core">
                      <span style={{ fontWeight: 'bold' }}>Third-party cookies&nbsp;</span>&nbsp;are cookies set by
                      third parties that are different from the operator of the website you are visiting.
                    </li>
                  </ul>
                  <p>
                    <span style={{ fontWeight: 'bold', fontSize: '17px' }}>Our collection of cookies</span>
                  </p>
                  <p>
                    We, our marketing partners, affiliates, and analytics or service providers use cookies and other
                    similar technologies. We group the cookies that we collect into the following categories based upon
                    their function (note that all types of cookies, as described above, may be found in each category):
                  </p>
                  <table style={{ borderCollapse: 'collapse', width: '100%', border: '1px solid black' }}>
                    <thead>
                      <tr>
                        <th
                          style={{
                            border: '1px solid black',
                            padding: '8px',
                            textAlign: 'center',
                            backgroundColor: '#dddddd',
                          }}
                        >
                          Category
                        </th>
                        <th
                          style={{
                            border: '1px solid black',
                            padding: '8px',
                            textAlign: 'center',
                            backgroundColor: '#dddddd',
                          }}
                        >
                          Description
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td
                          style={{
                            border: '1px solid black',
                            padding: '8px',
                            textAlign: 'left',
                            alignContent: 'flex-start',
                          }}
                        >
                          Essential Cookies
                        </td>
                        <td style={{ border: '1px solid black', padding: '8px', textAlign: 'left' }}>
                          Essential cookies are sometimes called “strictly necessary” as without them we cannot operate
                          and administer the Platform.
                        </td>
                      </tr>
                      <tr>
                        <td
                          style={{
                            border: '1px solid black',
                            padding: '8px',
                            textAlign: 'left',
                            alignContent: 'flex-start',
                          }}
                        >
                          Analytics Cookies
                        </td>
                        <td style={{ border: '1px solid black', padding: '8px', textAlign: 'left' }}>
                          These cookies track information about visits to the Platform so that we can make improvements
                          and report our performance. For example: analyze visitor and user behavior so as to provide
                          more relevant content or suggest certain activities. They collect information about how
                          visitors use the Platforms, which site the user came from, the number of each user’s visits
                          and how long a user stays on the Platform. We might also use analytics cookies to test new
                          ads, pages, or features to see how users react to them.
                        </td>
                      </tr>
                      <tr>
                        <td
                          style={{
                            border: '1px solid black',
                            padding: '8px',
                            textAlign: 'left',
                            alignContent: 'flex-start',
                          }}
                        >
                          Personalization Cookies
                        </td>
                        <td style={{ border: '1px solid black', padding: '8px', textAlign: 'left' }}>
                          During your visit to the Platform, cookies are used to remember information you have entered
                          or choices you make (such as your username) on the Platform. They also store your preferences
                          when personalizing the Platform to optimize your use of the Platform. These preferences are
                          remembered, through the use of the persistent cookies, and the next time you visit the
                          Platform you will not have to set them again.
                        </td>
                      </tr>
                      <tr>
                        <td
                          style={{
                            border: '1px solid black',
                            padding: '8px',
                            textAlign: 'left',
                            alignContent: 'flex-start',
                          }}
                        >
                          Targeting or Advertising Cookies
                        </td>
                        <td style={{ border: '1px solid black', padding: '8px', textAlign: 'left' }}>
                          These third-party cookies are placed by third-party advertising platforms or networks in order
                          to, deliver ads and track ad performance, enable advertising networks to deliver ads that may
                          be relevant to you based upon your activities (this is sometimes called “behavioral”
                          “tracking” or “targeted” advertising) on the Platform. They may subsequently use information
                          about your visit to target you with advertising that you may be interested in, on the Platform
                          and other websites.
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <p>
                    <span style={{ fontWeight: 'bold', fontSize: '17px' }}>Third-party cookies</span>
                  </p>
                  <p>
                    The Platform allows third-parties to place cookies on your Internet-connected device in order to
                    deliver advertisements based upon your web-browsing habits and history. Further, in order to provide
                    interoperability and plug-ins from various social media websites (like Facebook), the Platform
                    allows these third-parties to place and collect cookies on your Internet-connected device. However,
                    you can restrict the third-party collection of cookies through the instructions provided in the
                    section How To Opt-Out Of Tracking And Restrict Cookies below.
                  </p>
                  <p>
                    <span style={{ fontWeight: 'bold', fontSize: '17px' }}>Analytics</span>
                  </p>
                  <p>
                    We may use service providers to provide Platform metrics and other analytics services. These service
                    providers may use cookies (see above); web beacons (also called clear GIFs or pixels), which are
                    small blocks of code that allow us to measure the actions of visitors using D1’s Platform; and other
                    technologies to collect information, such as your IP address, identifiers associated with your
                    device, other applications on your device, the browsers you use to access our Platform and Services,
                    webpages viewed, time spent on webpages, links clicked and conversion information (e.g.,
                    transactions entered into). This information may be used by D1 and its service providers on behalf
                    of D1 to analyze and track usage of our Platform and Services to determine the popularity of certain
                    content and to better understand how you use our Platform and Services.
                  </p>
                  <p>
                    We use Google Analytics on the Platform to analyze how individuals use the Platform. We will package
                    up information about how individuals interact with our Platform and send it to Google Analytics to
                    be processed into reports. This information includes basic pageviews and visit data such as device
                    type, operating system, and browser type. When Google Analytics processes data, it aggregates and
                    organizes the data based on particular criteria like whether a user’s device is mobile or desktop,
                    or which browser they’re using. If, at any time, you choose not to participate in the use of
                    analytics software, please use your web browser’s available opt-outs as described in the section How
                    To Opt-Out Of Tracking And Restrict Cookies.
                  </p>
                  <p>
                    <span style={{ fontWeight: 'bold', fontSize: '17px' }}>Advertising</span>
                  </p>
                  <p>
                    We have partnered with third-party advertising networks that collect information about your online
                    web browsing habits to serve you with interest-based advertisements. The information collected about
                    you to serve these ads can include your IP address, unique online identifiers, your activity on our
                    Platforms and information about your operating system, browser and platform and other technology on
                    the devices you use to access the Platform.
                  </p>
                  <p>
                    The Meta Pixel is a snippet of code that sends data back to Meta about visitors who use the Platform
                    and allows us to provide custom advertising on Meta properties (like Facebook and Instagram). The
                    data provided to Meta may include information about how you interact with our Platform, including
                    your search queries.
                  </p>
                  <p>
                    <span style={{ fontWeight: 'bold', fontSize: '17px' }}>Session Recorders</span>
                  </p>
                  <p>
                    Our Platform utilizes session recorders provided by a third-party service provider to understand how
                    users interact with the Platform in order to identify and fix issues, identify areas for improvement
                    and perform optimization updates. These recordings only track how you interact with our Platform,
                    and not any other website, and aren’t used to provide you with advertisements, build profiles about
                    you, do not collect Protected Health Information and are not otherwise connected to your personal
                    identity.
                  </p>
                  <p>
                    We may use Hotjar in order to better understand our users’ needs and to optimize our Platform and
                    Services. Hotjar is a technology service that helps us better understand our users’ experience (e.g.
                    how much time they spend on which pages, which links they choose to click, what users do and don’t
                    like, etc.) and this enables us to build and maintain our service with user feedback. Hotjar uses
                    cookies and other technologies to collect data on our users’ behavior and their devices. This
                    includes a device's IP address (processed during your session and stored in a de-identified form),
                    device screen size, device type (unique device identifiers), browser information, geographic
                    location (country only), and the preferred language used to display our website. Hotjar stores this
                    information on our behalf in a pseudonymized user profile. Hotjar is contractually forbidden to sell
                    any of the data collected on our behalf. For further details, please see the ‘about Hotjar’ section
                    of Hotjar’s support site.
                  </p>
                  <p>
                    We also may use Fullstory to analyze user sessions on the Platform. Fullstory uses first-party
                    cookies and local storage to maintain a coherent scope for a user session across multiple pages on a
                    single website. Specifically, Fullstory collects information about a users interaction with our
                    Platform, including the resources that they access, pages viewed, how much time they spent on a
                    page, and how they reached our Platform. Fullstory also logs the details of user visits to our
                    Platform and information generated in the course of using our Platform, such as mouse movements,
                    clicks, page visits, text entered, how long they spent on a page, and other details of their actions
                    on the Platform. For further details, please see Fullstory’s privacy policy.
                  </p>
                  <p>
                    <span style={{ fontWeight: 'bold', fontSize: '17px' }}>Do Not Track</span>
                  </p>
                  <p>
                    Do Not Track (“DNT”) is an optional browser setting that allows you to express your preferences
                    regarding tracking across websites. Most modern web browsers give you the option to send a Do Not
                    Track signal to the websites you visit, indicating that you do not wish to be tracked. However,
                    there is no accepted standard for how a website should respond to this signal, so we do not take any
                    action in response to this signal. D1 does not have a mechanism in place to respond to DNT signals.
                    Instead, in addition to publicly available external tools, we offer you the choices described in
                    this Policy to manage the collection and use of information about you.
                  </p>
                  <p>
                    <span style={{ fontWeight: 'bold', fontSize: '17px' }}>Web beacons, pixels, tags and scripts</span>
                  </p>
                  <p>
                    In addition to cookies, we use other technologies to recognize and track visitors to D1’s Platform.
                    A web beacon (also known as a “tracking pixel” or “clear GIF”) is a clear graphic image (typically a
                    one-pixel tag) that is delivered through a web browser or HTML email, typically in conjunction with
                    a cookie. Web beacons allows us certain functionality, for example, monitoring how users move from
                    one page within a website to another, to track access to our communications, to understand whether
                    users have come to a website from an online advertisement displayed on a third-party website, to
                    measure how ads have been viewed and to improve site performance.
                  </p>
                  <p>
                    These technologies may be used on Platform or in our emails to help us to deliver cookies, count
                    visits, understand usage of our sites, campaign effectiveness and determine whether an email has
                    been opened.
                  </p>
                  <p>
                    <span style={{ fontWeight: 'bold', fontSize: '17px' }}>
                      Social Media Plug-ins (Linked-In and Instagram).
                    </span>
                  </p>
                  <p>
                    These plug-ins allow us to integrate social media functions into D1’s Platform and may also be used
                    for advertising purposes by those social media companies. If you visit our Platform while logged
                    into your social media accounts, information about your visit to our Platform, your social media
                    identifier and information about your browser may be provided to those third-party social media
                    companies. You should review the privacy policy of any social media entity with whom you have an
                    account to learn about how they may use your data.
                  </p>
                  <p>
                    <span style={{ fontWeight: 'bold', fontSize: '17px' }}>
                      How To Opt-Out Of Tracking And Restrict Cookies
                    </span>
                  </p>
                  <p>You can opt-out of the tracking of your online behavior by:</p>
                  <ul className={styles.list} style={{ marginLeft: '30px' }} data-block-type="core">
                    <li data-block-type="core">
                      visiting the Network Advertising Initiative (NAI) website opt-out page:
                      http://www.networkadvertising.org/choices;
                    </li>
                    <li data-block-type="core">
                      visiting the Digital Advertising Alliance (DAA) opt-out page: http://www.aboutads.info/; and
                    </li>
                    <li data-block-type="core">
                      downloading and installing the Google browser plug-in from the following link:&nbsp;
                      <a style={{ color: 'blue' }} href="http://tools.google.com/dlpage/gaoptout">
                        http://tools.google.com/dlpage/gaoptout
                      </a>
                      .
                    </li>
                  </ul>

                  <p>You can control and delete cookies through your browser settings through the following:</p>
                  <ul className={styles.list} style={{ marginLeft: '30px' }} data-block-type="core">
                    <li data-block-type="core">Google Chrome</li>
                    <li data-block-type="core">Mozilla Firefox</li>
                    <li data-block-type="core">Safari</li>
                    <li data-block-type="core">Opera</li>
                    <li data-block-type="core">Microsoft Internet Explorer</li>
                    <li data-block-type="core">Microsoft Edge</li>
                    <li data-block-type="core">Safari for iOS (iPhone and iPad)</li>
                    <li data-block-type="core">Chrome for Android</li>
                  </ul>
                  <p>
                    Please be aware that restricting cookies may impact the functionality of the Platform. For example,
                    refusing cookies will not allow D1 remember your log-in information. Additional general information
                    about cookies, including how to be notified about the placement of new cookies and how to disable
                    cookies, can be found at www.allaboutcookies.org.
                  </p>
                  <p>
                    Alternatively, you may visit the U.S. Federal Trade Commission’s website www.consumer.ftc.gov to
                    obtain comprehensive general information about cookies and how to adjust the cookie settings on
                    various browsers.
                  </p>
                  <h3
                    className="wp-block-heading"
                    style={{ fontWeight: '400', margin: '10px 0 25px' }}
                    data-block-type="core"
                  >
                    HOW WE PROTECT YOUR INFORMATION
                  </h3>
                  <p>
                    We are committed to making sure your information is protected and have selected third-party vendors
                    that help keep your personal data safe. Unfortunately, we do not control these third parties and
                    therefore cannot guarantee complete security. We employ several commercially reasonable electronic
                    safeguards to keep your information safe where possible. Accordingly, we cannot fully guarantee
                    against the access, disclosure, alteration, or deletion of data through events, including but not
                    limited to hardware or software failure or unauthorized use. Any information that you provide to us
                    is done so entirely at your own risk.
                  </p>
                  <h3
                    className="wp-block-heading"
                    style={{ fontWeight: '400', margin: '10px 0 25px' }}
                    data-block-type="core"
                  >
                    MARKETING
                  </h3>
                  <p>
                    We do not sell your personal data to third parties for their marketing purposes without your
                    explicit consent. We may combine your information with information we collect from other companies
                    and use it to improve and personalize the Services, content and advertising. If you do not wish to
                    receive marketing communications from us, you can send an email to the contact in How to Contact Us.
                  </p>
                  <p>
                    We may give you the opportunity to sign up for informational or promotional text messages. You may
                    opt-in by providing your mobile number and consenting to receive texts. You may opt-out at any time
                    as instructed in the confirmation text or by using the contact in How to Contact Us and including
                    your mobile number (please allow reasonable processing time for a form or a direct email). Standard
                    mobile data rates apply.
                  </p>
                  <h3
                    className="wp-block-heading"
                    style={{ fontWeight: '400', margin: '10px 0 25px' }}
                    data-block-type="core"
                  >
                    DATA RETENTION
                  </h3>
                  <p>
                    We generally retain records only as long as necessary and as required for our business operations,
                    for archival purposes, and/or to satisfy legal requirements. When determining the appropriate
                    retention period for personal information, we take into account various criteria, such as the
                    amount, nature, and sensitivity of the personal information; potential risk of harm from
                    unauthorized use or disclosure; purposes for which we process your personal information; whether we
                    can achieve those purposes through other means; and business operations and legal requirements.
                    Because we maintain our Platform to protect from accidental or malicious loss and destruction,
                    residual copies of your personal information may be retained in our backup and archival systems for
                    a limited period of time, after which the information will be automatically deleted or put beyond
                    use where deletion is not possible.
                  </p>
                  <h3
                    className="wp-block-heading"
                    style={{ fontWeight: '400', margin: '10px 0 25px' }}
                    data-block-type="core"
                  >
                    NOTICE TO INTERNATIONAL USERS
                  </h3>
                  <p>
                    The Platform is controlled and operated by us from the United States and are not intended to subject
                    us to the laws or jurisdiction of any state, country or territory other than those of (and within)
                    the United States. Any information you provide to us through use of our Platform may be stored and
                    processed, transferred between and accessed from the United States and other countries that may not
                    guarantee the same level of protection of personal data as the one in which you reside. However, we
                    will handle your personal information in accordance with this Policy regardless of where your
                    personal information is stored/accessed.
                  </p>
                  <h3
                    className="wp-block-heading"
                    style={{ fontWeight: '400', margin: '10px 0 25px' }}
                    data-block-type="core"
                  >
                    HOW TO CONTACT US
                  </h3>
                  <p>
                    Should you have any questions about our privacy practices or this Policy, please email us at
                    privacy@dentistry.one.
                  </p>
                </div>
              </article>
            </main>
          </div>
        </div>
      </Container>
    </>
  );
};

export default PrivacyPolicy;

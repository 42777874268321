/* eslint-disable import/no-cycle */
/* eslint-disable no-nested-ternary */
import { ICase } from '@brands/services/cases/types/ICase';
import { RegisterMediaPayload } from '@brands/services/forms/registerMediaPayloadType';
import { addOneReportMedia } from '@brands/services/oneReport/addOneReportMedia';
import { confirmOneReportMedia } from '@brands/services/oneReport/confirmOneReportMedia';
import { registerOneReportMedia } from '@brands/services/oneReport/registerOneReportMedia';
import { base64ListToFileList } from '@brands/Utils/base64ToFile';
import { displayErrorDetails } from '@brands/Utils/displayError';
import { getFileMimeType } from '@brands/Utils/truncateString';
import { parsePhoneNumber } from 'libphonenumber-js';
import moment from 'moment';
import { MultiValue } from 'react-select';

import { PaymentProfile } from '../../../../services/identity/types/PaymentProfile';
import {
  Address,
  BasicInfo,
  Insurance,
  Marker,
  Organization,
  UserRoleName,
} from '../../../../services/identity/types/UserProfile';
import { Option } from '../../../../Utils/selectOptions';
import { PatientFormFiveSubmit, PatientNotCoveredInsuranceSubmit } from '../../../PatientForms/PatientForm/utils/types';
import { DependentSubmit } from '../Sections/Dependent/EditDependent';
import { MyProfileSubmit, TokenResult } from './types';

export interface UploadedFile {
  name: string;
  dataURL: string;
}

export const updateData = (data: BasicInfo): BasicInfo => {
  const updatedData = { ...data };

  if (data.date_of_birth) {
    updatedData.date_of_birth = moment(data.date_of_birth).format('YYYY-MM-DD');
  }

  return updatedData;
};

const THREE_MINUTES_IN_MS = 0.5 * 60 * 1000; // 0.5 minutes in milliseconds

export const isAlmostEqualWithTolerance = (date1: string, date2: string): boolean => {
  const d1 = new Date(date1);
  const d2 = new Date(date2);

  const differenceInMs = Math.abs(d1.getTime() - d2.getTime());
  return differenceInMs <= THREE_MINUTES_IN_MS;
};

export const uploadMediaToCase = async (
  newCase: ICase,
  imagesValue: string | number | string[] | UploadedFile[] | undefined | boolean | Insurance,
): Promise<void> => {
  let images: string[] = [];

  if (Array.isArray(imagesValue)) {
    images = imagesValue.map((image) => {
      if (typeof image === 'string') {
        return image;
      }
      return image.dataURL;
    });
  }
  const fileList = base64ListToFileList(images || []);

  if (Array.isArray(imagesValue) && Array.isArray(fileList)) {
    await Promise.all(
      Array.from(fileList).map(async (file, index) => {
        const image = imagesValue[index] as UploadedFile;
        const mimeType = getFileMimeType(image.name);
        const payload: RegisterMediaPayload = {
          files: [
            {
              filename: image.name,
              mimetype: mimeType,
            },
          ],
        };
        try {
          const registeredMedia = await registerOneReportMedia(payload);
          const mediaId = registeredMedia.files[0].upload_id;
          await fetch(registeredMedia.files[0].upload_url, {
            method: 'PUT',
            body: file,
            headers: { 'content-type': mimeType },
          });
          await confirmOneReportMedia(mediaId);

          await addOneReportMedia(
            newCase?.one_report ? newCase?.one_report?.id : newCase?.one_report_id.toString() || '',
            {
              media_id: mediaId,
              note: '',
            },
          );
        } catch (error: unknown) {
          displayErrorDetails(error);
        }
      }),
    );
  }
};

export function convertDateFormat(inputDate: string): string {
  const date = new Date(inputDate);
  const isoDate = date?.toISOString();
  return isoDate;
}

export const areArraysEqual = (arr1: Organization[] | Marker[], arr2: MultiValue<Option>): boolean => {
  // Check the lengths
  if (arr1.length !== arr2.length) {
    return false;
  }

  // Create a set of arr1 ids
  const idSet = new Set(arr1.map((org) => org.id));

  // Check if all arr2.id values exist in arr1
  for (const option of arr2) {
    if (!idSet.has(option.id as string)) {
      return false;
    }
  }

  return true;
};

export const updateDataInsurance = (data: MyProfileSubmit | DependentSubmit | PatientFormFiveSubmit): Insurance => {
  switch (data.insurance_through) {
    case 'none':
      return {
        insured_by: data.insurance_through,
      };
    case 'myself':
      return {
        insured_by: data.insurance_through,
        insurance_membership: {
          member_id: data.member_id,
          insurance_carrier_id: data.insurance_carrier_id,
          phone_number: parsePhoneNumber(data.iPhoneNumber, 'US')?.format('E.164') as string,
          group_number: data.group_number,
          address1: (data as PatientFormFiveSubmit).insurance_address || '',
          city: (data as PatientFormFiveSubmit).insurance_city || '',
          state: (data as PatientFormFiveSubmit).insurance_state || '',
          zip_code: (data as PatientFormFiveSubmit).insurance_zip_code || '',
        },
        state_funded_coverage_status: (data as PatientFormFiveSubmit).state_funded_coverage_status || '',
      };
    case '':
      return {
        insured_by: '',
        insurance_membership: undefined,
        insurance_through: undefined,
      };
    default:
      return {
        insured_by: data.insurance_through,
        insurance_membership: {
          member_id: data.member_id,
          phone_number: parsePhoneNumber(data.iPhoneNumber, 'US')?.format('E.164') as string,
          insurance_carrier_id: data.insurance_carrier_id,
          group_number: data.group_number,
          address1: (data as PatientFormFiveSubmit).insurance_address || '',
          city: (data as PatientFormFiveSubmit).insurance_city || '',
          state: (data as PatientFormFiveSubmit).insurance_state || '',
          zip_code: (data as PatientFormFiveSubmit).insurance_zip_code || '',
        },
        state_funded_coverage_status: (data as PatientFormFiveSubmit).state_funded_coverage_status || '',
        insurance_through: {
          first_name: data.iFirstName,
          middle_name: data.iMiddleName,
          date_of_birth: data.iDateOfBirth ? moment(data.iDateOfBirth).format('YYYY-MM-DD') : '0000-00-00',
          gender_identity: data.iGenderIdentity,
          last_name: data.iLastName,
          address: {
            address1: data.iAddress1,
            address2: data.iAddress2,
            city: data.iCity,
            state: data.iState,
            zip_code: data.iZipCode,
            country: data.country || 'United States of America',
          },
        },
      };
  }
};

export const updateDataSecondaryInsurance = (data: MyProfileSubmit | DependentSubmit): Insurance => {
  switch (data.secondary_insurance_through) {
    case 'none':
      return {
        insured_by: data.secondary_insurance_through,
      };
    case 'myself':
      return {
        insured_by: data.secondary_insurance_through,
        insurance_membership: {
          member_id: data.secondary_member_id,
          insurance_carrier_id: data.secondary_insurance_carrier_id,
          phone_number: parsePhoneNumber(data.secondary_iPhoneNumber, 'US')?.format('E.164') as string,
          group_number: data.secondary_group_number,
          address1: (data as PatientFormFiveSubmit).secondary_insurance_address || '',
          city: (data as PatientFormFiveSubmit).secondary_insurance_city || '',
          state: (data as PatientFormFiveSubmit).secondary_insurance_state || '',
          zip_code: (data as PatientFormFiveSubmit).secondary_insurance_zip_code || '',
        },
        state_funded_coverage_status: (data as PatientFormFiveSubmit).state_funded_coverage_status || '',
      };
    case '':
      return {
        insured_by: '',
        insurance_membership: undefined,
        insurance_through: undefined,
      };
    default:
      return {
        insured_by: data.secondary_insurance_through,
        insurance_membership: {
          member_id: data.secondary_member_id,
          phone_number: parsePhoneNumber(data.secondary_iPhoneNumber, 'US')?.format('E.164') as string,
          insurance_carrier_id: data.secondary_insurance_carrier_id,
          group_number: data.secondary_group_number,
          address1: (data as PatientFormFiveSubmit).secondary_insurance_address || '',
          city: (data as PatientFormFiveSubmit).secondary_insurance_city || '',
          state: (data as PatientFormFiveSubmit).secondary_insurance_state || '',
          zip_code: (data as PatientFormFiveSubmit).secondary_insurance_zip_code || '',
        },
        state_funded_coverage_status: (data as PatientFormFiveSubmit).state_funded_coverage_status || '',
        insurance_through: {
          first_name: data.secondary_iFirstName,
          middle_name: data.secondary_iMiddleName,
          date_of_birth: data.secondary_iDateOfBirth
            ? moment(data.secondary_iDateOfBirth).format('YYYY-MM-DD')
            : '0000-00-00',
          gender_identity: data.secondary_iGenderIdentity,
          last_name: data.secondary_iLastName,
          address: {
            address1: data.secondary_iAddress1,
            address2: data.secondary_iAddress2,
            city: data.secondary_iCity,
            state: data.secondary_iState,
            zip_code: data.secondary_iZipCode,
          },
        },
      };
  }
};

export const updateDataPayment = (
  data: PatientFormFiveSubmit | PatientNotCoveredInsuranceSubmit,
  tokenResult: TokenResult,
  userInfo: any,
  isSameAddress: boolean,
  dependentAddress: Address,
  selectedPatientId: number,
  patientId?: number,
): PaymentProfile => {
  return {
    ...(userInfo.role.name === UserRoleName.Admin ||
    userInfo.role.name === UserRoleName.SuperAdmin ||
    userInfo.role.name === UserRoleName.OrganizationAdmin
      ? { user_id: patientId }
      : {}),
    verification_token: tokenResult.token,
    type: tokenResult.details?.method || '',
    is_default: true,
    credit_card: {
      brand: tokenResult.details?.card.brand,
      exp_month: tokenResult.details?.card.expMonth,
      exp_year: tokenResult.details?.card.expYear,
      last_four: tokenResult.details?.card.last4,
    },
    billing_contact: {
      address1: isSameAddress
        ? data.creditCardAddress1
        : userInfo.id === selectedPatientId
        ? userInfo.address.address1
        : dependentAddress.address1,
      address2: isSameAddress
        ? data.creditCardAddress2
        : userInfo.id === selectedPatientId
        ? userInfo.address.address2
        : dependentAddress.address2,
      city: isSameAddress
        ? data.creditCardCity
        : userInfo.id === selectedPatientId
        ? userInfo.address.city
        : dependentAddress.city,
      state: isSameAddress
        ? data.creditCardState
        : userInfo.id === selectedPatientId
        ? userInfo.address.state
        : dependentAddress.state,
      postal_code: isSameAddress
        ? data.creditCardZipCode
        : userInfo.id === selectedPatientId
        ? userInfo.address.zip_code
        : dependentAddress.zip_code,
      family_name: '',
      given_name: '',
      email: userInfo.email,
      phone: userInfo.phone_number,
    },
  };
};

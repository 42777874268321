import { client } from '../client';
import { UserProfile } from './types/UserProfile';

type UpdateEmailPayload = {
  new_email: string;
};

export const updateUserEmail = async (user_id: number, payload: UpdateEmailPayload): Promise<UserProfile> => {
  const { data } = await client.patch(`/admin/users/${user_id}/email`, payload);

  return data;
};

import React, { useEffect, useState } from 'react';
import { FieldErrors, UseFormRegister, UseFormSetValue, UseFormTrigger } from 'react-hook-form';

import { ICase } from '../../../../services/cases/types/ICase';
import { UserProfile } from '../../../../services/identity/types/UserProfile';
import { checkCollapsibleHeaderColor } from '../../../../Utils/getCollapsibleHeader';
import { OralHealthReportSubmit } from '../../assets/types';
import styles from '../../styles.module.scss';
import { updateOneReport } from '@brands/services/oneReport/updateOneReport';
import { TbExternalLink } from 'react-icons/tb';
import TextField from '@brands/Components/Inputs/TextField/TextField';
import { IEducation } from '@brands/services/oneReport/types/IEducation';
import { IoTrashOutline } from 'react-icons/io5';
import { AiFillPlusCircle } from 'react-icons/ai';
import { addOralHealthCoachingReportObject } from '@brands/services/oralHealthCoachingReport/addOralHealthCoachingReportObject';
import {
  CreateOralHealthCoachingReportCarePlanParams,
  CreateOralHealthCoachingReportEducationParams,
  CreateOralHealthCoachingReportGoalParams,
  ObjectType,
  OralHealthCoachingReport,
} from '@brands/services/oralHealthCoachingReport/types/types';
import { updateOralHealthCoachingReportObject } from '@brands/services/oralHealthCoachingReport/updateOralHealthCoachingReportObject';
import { deleteOralHealthCoachingReportObject } from '@brands/services/oralHealthCoachingReport/deleteOralHealthCoachingReportObject';
import { displayErrorDetails } from '@brands/Utils/displayError';

type OralHealthCoachingCarePlanProps = {
  consultationForm: OralHealthReportSubmit;
  setConsultationForm: React.Dispatch<React.SetStateAction<OralHealthReportSubmit>>;
  thisCase: ICase;
  setCase: React.Dispatch<React.SetStateAction<ICase>>;
  userInfo: UserProfile;
  register: UseFormRegister<OralHealthReportSubmit>;
  setValue: UseFormSetValue<OralHealthReportSubmit>;
  trigger: UseFormTrigger<OralHealthReportSubmit>;
  errors: FieldErrors<OralHealthReportSubmit>;
};

const OralHealthCoachingCarePlan = ({
  consultationForm,
  setConsultationForm,
  thisCase,
  setCase,
  userInfo,
  register,
  setValue,
  trigger,
  errors,
}: OralHealthCoachingCarePlanProps): JSX.Element => {
  const [disabled, setDisabled] = useState<boolean>(false);

  const addNewGoal = async (): Promise<void> => {
    try {
      const res = await addOralHealthCoachingReportObject(
        thisCase?.oral_health_coaching_report?.id.toString() || '',
        'goal',
        {
          goal_text: '',
        } as CreateOralHealthCoachingReportGoalParams,
      );

      setConsultationForm((prevForm) => {
        return {
          ...prevForm,
          care_plan: {
            ...prevForm.care_plan,
            goals: res.care_plan?.goals,
          },
        };
      });
      setCase((prevCase) => ({
        ...prevCase,
        oral_health_coaching_report: res as OralHealthCoachingReport,
      }));
    } catch (error: unknown) {
      displayErrorDetails(error);
    }
  };

  const addNewResourceField = async (goalId: number): Promise<void> => {
    try {
      const res = await addOralHealthCoachingReportObject(
        (thisCase.oral_health_coaching_report?.id.toString() as string) || '',
        'education',
        {
          url: '',
          title: '',
          goal_id: goalId,
        },
      );
      setConsultationForm((prevForm) => {
        return {
          ...prevForm,
          care_plan: {
            ...prevForm.care_plan,
            goals: res.care_plan?.goals?.map((goal) => ({
              ...goal,
              educations: goal.educations.sort((a, b) => Number(a.id) - Number(b.id)),
            })),
          },
        };
      });
      setCase((prevCase) => ({
        ...prevCase,
        oral_health_coaching_report: res as OralHealthCoachingReport,
      }));
    } catch (error: unknown) {
      displayErrorDetails(error);
    }
  };

  const deleteGoal = async (goalId: number): Promise<void> => {
    try {
      if (goalId) {
        const res = await deleteOralHealthCoachingReportObject(
          thisCase?.oral_health_coaching_report?.id.toString() || '',
          goalId.toString(),
          'goal',
        );

        setConsultationForm((prevForm) => {
          return {
            ...prevForm,
            care_plan: {
              ...prevForm.care_plan,
              goals: res.care_plan?.goals,
            },
          };
        });

        setCase((prevCase) => ({
          ...prevCase,
          oral_health_coaching_report: res as OralHealthCoachingReport,
        }));
      }
    } catch (error: unknown) {
      displayErrorDetails(error);
    }
  };

  const deleteEducation = async (educationId: string, goalId: number): Promise<void> => {
    try {
      if (educationId) {
        const res = await deleteOralHealthCoachingReportObject(
          thisCase?.oral_health_coaching_report?.id.toString() || '',
          educationId,
          'education',
        );

        setConsultationForm((prevForm) => {
          const updatedGoals = prevForm.care_plan?.goals?.map((goal) => {
            if (goal.id === goalId) {
              return {
                ...goal,
                educations: goal.educations?.filter((edu) => edu.id !== educationId),
              };
            }
            return goal;
          });

          return {
            ...prevForm,
            care_plan: {
              ...prevForm.care_plan,
              goals: updatedGoals,
            },
          };
        });

        setCase((prevCase) => ({
          ...prevCase,
          oral_health_coaching_report: res as OralHealthCoachingReport,
        }));
      }
    } catch (error: unknown) {
      displayErrorDetails(error);
    }
  };

  useEffect(() => {
    if (checkCollapsibleHeaderColor(thisCase, userInfo, 'OHC') !== 'edit') {
      setDisabled(true);
    } else {
      setDisabled(false);
    }
  }, [thisCase, userInfo]);

  const saveConsultationSummary = async (
    objectType: ObjectType,
    attributeName: string,
    attributeValue: any,
  ): Promise<void> => {
    if (attributeValue || attributeValue === '') {
      const updateData = {
        [attributeName]: attributeValue,
      };
      const response = await updateOralHealthCoachingReportObject(
        thisCase.oral_health_coaching_report?.id.toString() as string,
        objectType,
        thisCase.oral_health_coaching_report?.care_plan?.id ?? '0',
        {
          [attributeName]: attributeValue,
        } as CreateOralHealthCoachingReportCarePlanParams,
      );
    }
  };

  const saveCarePlanGoal = async (
    goalId: number,
    objectType: ObjectType,
    attributeName: string,
    attributeValue: any,
  ): Promise<void> => {
    if (attributeValue || attributeValue === '') {
      const updateData = {
        [attributeName]: attributeValue,
      };
      if (
        goalId &&
        thisCase.oral_health_coaching_report?.care_plan?.goals?.length &&
        thisCase.oral_health_coaching_report.care_plan.goals.some((thisGoal) => thisGoal.id === goalId)
      ) {
        const response = await updateOralHealthCoachingReportObject(
          thisCase.oral_health_coaching_report?.id.toString() as string,
          objectType,
          goalId.toString(),
          {
            [attributeName]: attributeValue,
          } as CreateOralHealthCoachingReportGoalParams,
        );
      } else {
        const response = await addOralHealthCoachingReportObject(
          thisCase.oral_health_coaching_report?.id.toString() as string,
          objectType,
          {
            [attributeName]: attributeValue,
          } as CreateOralHealthCoachingReportGoalParams,
        );
      }
    }
  };

  const handleEducationOnBlur = async (
    goalId: number,
    educationId: number,
    objectType: ObjectType,
    attributeName: keyof IEducation,
    attributeValue: any,
  ): Promise<void> => {
    try {
      if (attributeValue || attributeValue === '') {
        const updateData = {
          [attributeName]: attributeValue,
        };
        let response: OralHealthCoachingReport | undefined;

        const goal = thisCase.oral_health_coaching_report?.care_plan?.goals?.find((goal) => goal.id === goalId);

        const education = goal?.educations?.find((education) => Number(education.id) === educationId);

        if (goalId && education && education[attributeName as keyof IEducation] !== attributeValue) {
          response = await updateOralHealthCoachingReportObject(
            thisCase.oral_health_coaching_report?.id.toString() as string,
            objectType,
            educationId.toString(),
            {
              goal_id: goalId,
              [attributeName]: attributeValue,
            } as CreateOralHealthCoachingReportEducationParams,
          );
        } else if (education === undefined) {
          response = await addOralHealthCoachingReportObject(
            thisCase.oral_health_coaching_report?.id.toString() as string,
            objectType,
            {
              goal_id: goalId,
              [attributeName]: attributeValue,
            } as CreateOralHealthCoachingReportEducationParams,
          );
        }
        if (response) {
          setCase((prevCase) => ({
            ...prevCase,
            oral_health_coaching_report: response as OralHealthCoachingReport,
          }));

          setConsultationForm((prevForm) => {
            return {
              ...prevForm,
              care_plan: {
                ...prevForm.care_plan,
                goals: response?.care_plan?.goals?.map((goal) => ({
                  ...goal,
                  educations: goal.educations.sort((a, b) => Number(a.id) - Number(b.id)),
                })),
              },
            };
          });
        }
      }
    } catch (error: unknown) {
      displayErrorDetails(error);
    }
  };

  return (
    <div className={styles.oralHealthCoachingSection}>
      <div className={styles.oralHealthCoachingConsultationSummary}>
        <span className={styles.oralHealthCoachingSectionSpan}>Consultation Summary</span>
        {checkCollapsibleHeaderColor(thisCase || '', userInfo, 'OHC') === 'edit' ||
        checkCollapsibleHeaderColor(thisCase || '', userInfo, 'OHC') === 'editNotRequired' ? (
          <textarea
            className={styles.subjectiveInput}
            {...register('care_plan.consultation_summary')}
            value={consultationForm.care_plan?.consultation_summary || ''}
            onChange={(e) => {
              setConsultationForm({
                ...consultationForm,
                care_plan: {
                  ...consultationForm.care_plan,
                  consultation_summary: e.target.value,
                },
              });

              setValue('care_plan.consultation_summary', e.target.value, { shouldValidate: true });

              if (errors?.care_plan?.consultation_summary) {
                trigger('care_plan.consultation_summary');
              }
            }}
            onBlur={() => {
              saveConsultationSummary(
                'care_plan',
                'consultation_summary',
                consultationForm.care_plan?.consultation_summary,
              );
            }}
          />
        ) : (
          <textarea
            disabled
            value={thisCase?.oral_health_coaching_report?.care_plan?.consultation_summary || ''}
            className={styles.subjectiveInput}
            style={{ color: errors.care_plan ? '#BA271A' : '' }}
          />
        )}
      </div>
      {consultationForm.care_plan?.goals?.map((goal, goalIndex) => (
        <div key={goal.id} className={styles.oralHealthCoachingGoal}>
          <span className={styles.oralHealthCoachingSectionSpan}>Goal {goalIndex + 1}</span>
          {checkCollapsibleHeaderColor(thisCase || '', userInfo, 'OHC') === 'edit' ||
          checkCollapsibleHeaderColor(thisCase || '', userInfo, 'OHC') === 'editNotRequired' ? (
            <div className={styles.goalDivSection}>
              <textarea
                className={styles.subjectiveInput}
                {...register(`care_plan.goals.${goalIndex}.goal_text`)}
                value={
                  (consultationForm.care_plan?.goals && consultationForm.care_plan?.goals[goalIndex].goal_text) || ''
                }
                onChange={(e) => {
                  const updatedGoals = consultationForm.care_plan?.goals?.map((g, i) =>
                    i === goalIndex ? { ...g, goal_text: e.target.value } : g,
                  );
                  setConsultationForm({
                    ...consultationForm,
                    care_plan: {
                      ...consultationForm.care_plan,
                      goals: updatedGoals,
                    },
                  });
                  setValue(`care_plan.goals.${goalIndex}.goal_text`, e.target.value, { shouldValidate: true });

                  if (errors?.care_plan?.goals) {
                    trigger(`care_plan.goals.${goalIndex}.goal_text`);
                  }
                }}
                onBlur={() => {
                  saveCarePlanGoal(
                    goal.id ?? 0,
                    'goal',
                    'goal_text',
                    consultationForm.care_plan?.goals ? consultationForm.care_plan?.goals[goalIndex].goal_text : '',
                  );
                }}
              />
              {checkCollapsibleHeaderColor(thisCase, userInfo, 'OHC') === 'edit' && (
                <div
                  className={styles.deleteEducation}
                  onClick={() => deleteGoal(goal.id || 0)}
                  title="Delete this entire goal"
                >
                  <IoTrashOutline className="w-100 h-100" />
                </div>
              )}
            </div>
          ) : (
            <textarea
              disabled
              value={
                (thisCase?.oral_health_coaching_report?.care_plan?.goals &&
                  thisCase?.oral_health_coaching_report?.care_plan?.goals?.length > 0 &&
                  thisCase?.oral_health_coaching_report?.care_plan?.goals[goalIndex]?.goal_text) ||
                ''
              }
              className={styles.subjectiveInput}
              style={{ color: errors.care_plan?.goals ? '#BA271A' : '' }}
            />
          )}

          <div className={styles.viewResources}>
            <div className={styles.viewResourcesContent}>
              <a
                href="https://patienthealthcenter.com/mgkOQvgp/home"
                style={{ textDecoration: 'none', color: 'unset', fontSize: 'unset' }}
                className="d-flex gap-2"
                target="_blank"
                rel="noreferrer"
              >
                <span>View Dentistry.One education resources here</span>
                <span className={styles.viewResourcesIcon}>
                  <TbExternalLink />
                </span>
              </a>
            </div>
          </div>
          <>
            <div className={styles.inputsLabel}>Copy and paste education URL and titles below pertaining to the goal</div>
            {goal.educations.map((education, educationIndex) => (
              <div className={styles.educationInputsContainer} key={education.id}>
                <div className={styles.educationInputs} style={{ marginTop: educationIndex !== 0 ? '1.5rem' : '' }}>
                  <div className={styles.educationInput}>
                    <div className={styles.title} style={{ display: educationIndex !== 0 ? 'none' : '' }}>
                      URL
                    </div>
                    <TextField
                      {...register(`care_plan.goals.${goalIndex}.educations.${educationIndex}.url`)}
                      name={`care_plan.goals.${goalIndex}.educations.${educationIndex}.url`}
                      wrapperStyle={{ marginTop: '0', borderBottom: 'none', height: '30px' }}
                      className={styles.input}
                      placeholder=""
                      isUnderlined={false}
                      defaultValue={education.url}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        const updatedUrl = e.target.value;
                        setConsultationForm((prevForm) => {
                          // Update the specific education's URL
                          const updatedGoals = prevForm.care_plan?.goals?.map((goal, index) => {
                            if (index === goalIndex) {
                              const updatedEducations = goal.educations.map((education, eduIndex) =>
                                eduIndex === educationIndex ? { ...education, url: updatedUrl } : education,
                              );
                              return { ...goal, educations: updatedEducations };
                            }
                            return goal;
                          });

                          return {
                            ...prevForm,
                            care_plan: {
                              ...prevForm.care_plan,
                              goals: updatedGoals,
                            },
                          };
                        });

                        setValue(`care_plan.goals.${goalIndex}.educations.${educationIndex}.url`, updatedUrl);
                        if (errors?.care_plan?.goals) {
                          trigger(`care_plan.goals.${goalIndex}.educations.${educationIndex}.url`);
                        }
                      }}
                      onBlur={async (e) => {
                        await handleEducationOnBlur(
                          goal.id ?? 0,
                          Number(education.id) ?? 0,
                          'education',
                          'url',
                          e.target.value,
                        );
                      }}
                      disabled={disabled}
                    />
                  </div>
                  <div className={styles.educationInput}>
                    <div className={styles.title} style={{ display: educationIndex !== 0 ? 'none' : '' }}>
                      Title
                    </div>
                    <TextField
                      {...register(`care_plan.goals.${goalIndex}.educations.${educationIndex}.title`)}
                      name={`care_plan.goals.${goalIndex}.educations.${educationIndex}.title`}
                      wrapperStyle={{ marginTop: '0', borderBottom: 'none', height: '30px' }}
                      className={styles.input}
                      placeholder=""
                      isUnderlined={false}
                      defaultValue={education.title}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        const updatedTitle = e.target.value;
                        setConsultationForm((prevForm) => {
                          const updatedGoals = prevForm.care_plan?.goals?.map((goal, index) => {
                            if (index === goalIndex) {
                              const updatedEducations = goal.educations.map((education, eduIndex) =>
                                eduIndex === educationIndex ? { ...education, title: updatedTitle } : education,
                              );
                              return { ...goal, educations: updatedEducations };
                            }
                            return goal;
                          });

                          return {
                            ...prevForm,
                            care_plan: {
                              ...prevForm.care_plan,
                              goals: updatedGoals,
                            },
                          };
                        });
                        setValue(`care_plan.goals.${goalIndex}.educations.${educationIndex}.title`, e.target.value);
                        if (errors.educations)
                          trigger(`care_plan.goals.${goalIndex}.educations.${educationIndex}.title`);
                      }}
                      onBlur={async (e) => {
                        await handleEducationOnBlur(
                          goal.id ?? 0,
                          Number(education.id) ?? 0,
                          'education',
                          'title',
                          e.target.value,
                        );
                      }}
                      disabled={disabled}
                    />
                  </div>
                </div>
                {checkCollapsibleHeaderColor(thisCase, userInfo, 'OHC') === 'edit' && (
                  <div
                    className={styles.deleteEducation}
                    onClick={() => deleteEducation(education.id || '0', goal.id || 0)}
                    title="Delete this resource"
                  >
                    <IoTrashOutline className="w-100 h-100" />
                  </div>
                )}
              </div>
            ))}
            {!disabled && (
              <div className={styles.addTreatment}>
                <div className={styles.addTreatmentContent} onClick={() => addNewResourceField(goal.id || 0)}>
                  <span>Add Resource</span>
                  <span className={styles.addTreatmentIcon}>
                    <AiFillPlusCircle />
                  </span>
                </div>
              </div>
            )}
          </>
        </div>
      ))}
      {!disabled && (
        <div className={styles.addTreatment}>
          <div className={styles.addTreatmentContent} onClick={addNewGoal}>
            <span>Add Goal</span>
            <span className={styles.addTreatmentIcon}>
              <AiFillPlusCircle />
            </span>
          </div>
        </div>
      )}
    </div>
  );
};
export default React.memo(OralHealthCoachingCarePlan);

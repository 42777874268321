/* eslint-disable no-nested-ternary */
/* eslint-disable no-unsafe-optional-chaining */
import { useLocalTracks, useWindowSize } from '@brands/hooks';
import { selectVideoCallCredentials } from '@brands/store/selectors/videoCallCredentials';
import { setShowVideoThumbnail } from '@brands/store/slices/showVideoThumbnailSlice';
import { setIsVisible, setUploadFileMethod, setVCCaseId } from '@brands/store/slices/videoCallCredentialsSlice';
import VideoApp from '@brands/VideoRoom/VideoApp';
import React, { useEffect, useMemo, useState } from 'react';
import Draggable from 'react-draggable';
import { AiFillCloseCircle } from 'react-icons/ai';
import { GoFilter } from 'react-icons/go';
import { GrDrag } from 'react-icons/gr';
import { IoIosArrowDropleftCircle } from 'react-icons/io';
import Modal from 'react-modal';
import { useNavigate } from 'react-router-dom';
import { ConnectOptions, LocalTrack, LocalVideoTrack } from 'twilio-video';

import { VideoProvider } from '../../../../contexts/VideoProvider';
import { useAppDispatch, useAppSelector } from '../../../../hooks/useReduxHook';
import useConnectionOptions from '../../../../hooks/video-hooks/useConnectionOptions';
import { customStylesDraggable } from '../../../../Utils/customStyles';
import VideoRoom from '../../../../VideoRoom/VideoRoom/VideoRoom';
import styles from '../../styles.module.scss';
import Panel from './Panel';

const VideoModal = (): JSX.Element => {
  const [disabled, setDisabled] = useState(true);
  const [bounds, setBounds] = useState({ left: 0, top: 0, bottom: 0, right: 0 });
  const [showVideoThumbnail, setShowVideoThumbnailFlag] = useState(false);
  const [hasJoinedFlag, setHasJoinedFlag] = useState(false);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const screenSize = useWindowSize();
  const isMobile = screenSize.width < 768;
  const [availableHeight, setAvailableHeight] = useState(0);
  const { is_visible: isVisible, uploadFileMethod, vc_case_id: vcCaseId } = useAppSelector(selectVideoCallCredentials);
  const draggleRef: any = React.createRef();
  const onStart = (uiData: any): void => {
    const { clientWidth, clientHeight } = window?.document?.documentElement;
    const targetRect = draggleRef?.current?.getBoundingClientRect();
    setBounds({
      left: -targetRect?.left + uiData?.x,
      right: clientWidth - (targetRect?.right - uiData?.x),
      top: -targetRect?.top + uiData?.y,
      bottom: clientHeight - (targetRect?.bottom - uiData?.y),
    });
  };
  function calculateUpperMenuBarHeight(): number {
    const initialHeight = window.innerHeight;
    const finalHeight = window.innerHeight;
    return initialHeight - finalHeight;
  }

  function calculateLowerMenuBarHeight(): number {
    const initialHeight = window.innerHeight;
    const finalHeight = window.innerHeight;
    return initialHeight - finalHeight;
  }

  useEffect(() => {
    if (isMobile) {
      const upperMenuBarHeight = calculateUpperMenuBarHeight();
      const lowerMenuBarHeight = calculateLowerMenuBarHeight();

      const screenHeight = window.innerHeight;
      const maxHeight = screenHeight - upperMenuBarHeight - lowerMenuBarHeight;

      setAvailableHeight(maxHeight);
    }
  }, []);

  const [endCurrentCall, setEndCurrentCall] = useState(false);

  const connectionOptions: ConnectOptions = useConnectionOptions();

  useEffect(() => {
    if (!isVisible) {
      setEndCurrentCall(false);
      setHasJoinedFlag(false);
    }
  }, [isVisible]);

  useEffect(() => {
    if (endCurrentCall) {
      setHasJoinedFlag(false);
    }
  }, [endCurrentCall]);

  useEffect(() => {
    setHasJoinedFlag(false);
  }, []);

  const handleKeyDown = (event: any): void => {
    if (event.key === 'Tab' || event.key === ' ') {
      event.preventDefault();
    }
  };

  useEffect(() => {
    const portalDraggable = document.querySelector('.ReactModalPortalDraggable') as HTMLElement;
    if (portalDraggable) {
      if (showVideoThumbnail) {
        portalDraggable.classList.add('hideDraggableWrapper');
      } else {
        portalDraggable.style.height = `${availableHeight}px`;
        portalDraggable.classList.remove('hideDraggableWrapper');
        if (isMobile) {
          const bodyElement = document.querySelector('html') as HTMLElement;
          if (bodyElement) {
            bodyElement.style.overflow = 'hidden';
          }
        }
      }
    }
  }, [showVideoThumbnail, availableHeight]);

  return (
    <div onKeyDown={handleKeyDown}>
      <Modal
        isOpen={isVisible}
        contentLabel="Modal"
        style={customStylesDraggable}
        className={`${styles.wrapper} ${styles.draggableWrapper} ${
          !hasJoinedFlag
            ? styles.draggableWrapperFullHeight
            : showVideoThumbnail
            ? styles.hideDraggableWrapper
            : styles.draggableWrapperNormalHeight
        }`}
        shouldCloseOnOverlayClick={false}
        shouldCloseOnEsc={false}
        portalClassName="ReactModalPortalDraggable"
        onRequestClose={async () => {
          dispatch(setIsVisible(false));
          dispatch(setVCCaseId(''));
          dispatch(setUploadFileMethod(() => Promise.resolve()));
        }}
        overlayElement={(_props, children) => (
          <Draggable disabled={disabled} bounds={bounds} onStart={(_event, uiData) => onStart(uiData)}>
            <div ref={draggleRef}>{children}</div>
          </Draggable>
        )}
      >
        <Panel>
          <span className={styles.modalHeader}>Case Id: {vcCaseId}</span>
          <VideoProvider options={connectionOptions}>
            {!isMobile ? (
              <div
                className={styles.dragIconContainer}
                onMouseEnter={() => setDisabled(false)}
                onMouseLeave={() => setDisabled(true)}
                onTouchStartCapture={() => setDisabled(false)}
                onTouchEndCapture={() => setDisabled(true)}
              >
                <GrDrag size={24} transform="rotate(0)" />
              </div>
            ) : hasJoinedFlag ? (
              <div
                className={`${styles.navBarVideo} ${
                  !showVideoThumbnail ? styles.navBarVideoMini : styles.navBarVideoNormal
                }`}
              >
                <div
                  className={styles.leftSide}
                  onClick={() => {
                    setShowVideoThumbnailFlag(!showVideoThumbnail);
                    dispatch(setShowVideoThumbnail(!showVideoThumbnail));
                    navigate(`/consultation-page/${vcCaseId}`);
                  }}
                >
                  <IoIosArrowDropleftCircle />
                  <span>{showVideoThumbnail ? 'Return to Video' : 'Case Details'}</span>
                </div>
                {showVideoThumbnail && (
                  <div className={styles.rightSide}>
                    <VideoApp
                      endCurrentCall={endCurrentCall}
                      uploadFile={uploadFileMethod}
                      alreadyJoined
                      showVideoThumbnail={showVideoThumbnail}
                    />
                  </div>
                )}
              </div>
            ) : (
              <div />
            )}
            {!showVideoThumbnail && (
              <>
                <AiFillCloseCircle
                  className={`${styles.closeMessagesIcon} ${styles.modalCloseBtn}`}
                  onClick={() => {
                    setEndCurrentCall(true);
                    dispatch(setIsVisible(false));
                    dispatch(setVCCaseId(''));
                    dispatch(setShowVideoThumbnail(false));
                    dispatch(setUploadFileMethod(() => Promise.resolve()));
                    localStorage.setItem('backgroundSettings', JSON.stringify({ type: 'none', index: 0 }));
                  }}
                />
                <VideoRoom
                  endCurrentCall={endCurrentCall}
                  uploadFile={uploadFileMethod}
                  setHasJoinedFlag={setHasJoinedFlag}
                  alreadyJoined={hasJoinedFlag}
                />
              </>
            )}
            {!isMobile && (
              <div className={styles.smallSquare}>
                <GoFilter size={20} />
              </div>
            )}
          </VideoProvider>
        </Panel>
      </Modal>
    </div>
  );
};

Modal.setAppElement('#root');

export default VideoModal;

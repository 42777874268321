import { MyProfileSubmit } from '../../../MyProfile/Patient/utils/types';

export const stateFundedProgramOptions = [
  { id: 1, value: 'yes', label: 'Yes' },
  { id: 2, value: 'no', label: 'No' },
  { id: 3, value: 'not_sure', label: `I'm not sure` },
];

export type SelectNeedsSubmit = {
  dental_emergency: string;
  something_else: string;
};

export interface SelectedAppointment {
  selectedTimeSlot: string;
  selectedProviderId?: number | undefined;
  selectedCareAdvisorId?: number | undefined;
}

export type DescribePainLevelSubmit = {
  inPain: string;
  describePain: string[];
  painLevel: string;
  daysInPain: string;
  anythingElse: string;
};

export type AdditionalInformationSubmit = {
  anythingElse: string;
};

export type PatientInsuranceForm = {
  group_number: string;
  member_id: string;
  iPhoneNumber: string;
  insurance_through: string;
  secondary_group_number: string;
  secondary_member_id: string;
  secondary_iPhoneNumber: string;
  secondary_insurance_through: string;
  secondary_insurance_address: string;
  secondary_insurance_city: string;
  secondary_insurance_state: string;
  secondary_insurance_zip_code: string;
};

export type PatientFormFiveSubmit = {
  iFirstName: string;
  iMiddleName: string;
  iDateOfBirth: string;
  iGenderIdentity: string;
  iLastName: string;
  iAddress1: string;
  iAddress2: string;
  iZipCode: string;
  iState: string;
  iCity: string;
  secondary_iFirstName: string;
  secondary_iMiddleName: string;
  secondary_iDateOfBirth: string;
  secondary_iGenderIdentity: string;
  secondary_iLastName: string;
  secondary_iAddress1: string;
  secondary_iAddress2: string;
  secondary_iZipCode: string;
  secondary_iState: string;
  secondary_iCity: string;
  creditCardFullName: string;
  creditCardNumber: string;
  creditCardAddress1: string;
  creditCardAddress2: string;
  expiryDate: string;
  creditCardZipCode: string;
  cvvNumber: string;
  creditCardState: string;
  creditCardCity: string;
  insurance_address?: string;
  insurance_city?: string;
  insurance_state?: string;
  insurance_zip_code?: string;
} & MyProfileSubmit &
  PatientInsuranceForm;

export type PatientNotCoveredInsuranceSubmit = {
  creditCardFullName?: string;
  creditCardNumber?: string;
  creditCardAddress1?: string;
  creditCardAddress2?: string;
  expiryDate?: string;
  creditCardZipCode?: string;
  cvvNumber?: string;
  creditCardState?: string;
  creditCardCity?: string;
};

/* eslint-disable @typescript-eslint/no-non-null-assertion */
import SecondaryInsuranceFields from '@brands/Dashboard/Dashboard/Components/InsuranceFields/SecondaryInsuranceFields';
import { stateFundedProgramOptions } from '@brands/Dashboard/PatientForms/PatientForm/utils/types';
import { useInsuranceCarriers } from '@brands/hooks';
import { useAppSelector } from '@brands/hooks/useReduxHook';
import { selectAuth } from '@brands/store/selectors/auth';
import { UnifiedProfileSubmit } from '@brands/Utils/types';
import { validateAddress, ValidationModalInterface } from '@brands/Utils/validateAddress';
import { yupResolver } from '@hookform/resolvers/yup';
import cn from 'classnames';
import { E164Number, parsePhoneNumber } from 'libphonenumber-js';
import React, { FC, useEffect, useMemo, useState } from 'react';
import { useForm, UseFormGetValues } from 'react-hook-form';
import Modal, { Styles } from 'react-modal';
import { MultiValue, SingleValue } from 'react-select';
import { toast } from 'react-toastify';

import closeModalIcon from '../../../../../assets/icons/close.svg';
import Button from '../../../../../Components/Button/Button';
import CustomCheckbox from '../../../../../Components/Inputs/CustomCheckbox/CustomCheckbox';
import MultiselectInput from '../../../../../Components/Inputs/MultiselectInput/MultiselectInput';
import PhoneInput from '../../../../../Components/Inputs/PhoneInput/PhoneInput';
import SelectInput from '../../../../../Components/Inputs/SelectInput/SelectInput';
import TextField from '../../../../../Components/Inputs/TextField/TextField';
import ProfileImage from '../../../../../Components/ProfileImage/ProfileImage';
import SelectButtons from '../../../../../Components/ProfileSelectButtons/SelectButtons';
import { RegisterMediaPayload } from '../../../../../services/forms/registerMediaPayloadType';
import { confirmAvatarMedia } from '../../../../../services/identity/confirmAvatarMedia';
import { createBasicInformation } from '../../../../../services/identity/createBasicInformation';
import { createNewUser } from '../../../../../services/identity/createNewUser';
import { createPatientInsurance } from '../../../../../services/identity/createPatientInsurance';
import { deleteAvatar } from '../../../../../services/identity/deleteAvatar';
import { listAllLanguages } from '../../../../../services/identity/listAllLanguages';
import { listAllOrganizations } from '../../../../../services/identity/listAllOrganizations';
import { registerAvatarMedia } from '../../../../../services/identity/registerAvatarMedia';
import {
  Address,
  Division,
  Insurance,
  Language,
  Organization,
  Practice,
  UserProfile,
  UserRoleName,
} from '../../../../../services/identity/types/UserProfile';
import { updateUserDivision } from '../../../../../services/identity/updateUserDivision';
import { updateUserOrganization } from '../../../../../services/identity/updateUserOrganization';
import { updateUserPractice } from '../../../../../services/identity/updateUserPractice';
import { checkAndSetDate, dateAutoSlashes } from '../../../../../Utils/checkAndSetDate';
import {
  customStylesDashboard,
  multiSelectCommonCustomStylesLarge,
  profileSelectStyles,
  selectCommonCustomStylesLarge,
} from '../../../../../Utils/customStyles';
import { displayErrorDetails } from '../../../../../Utils/displayError';
import { genderIdentity, genderOptions, pronounsOptions } from '../../../../../Utils/selectButtonOptions';
import { insuranceThroughOptions, Option, OptionLanguage, stateOptions } from '../../../../../Utils/selectOptions';
import { defaultFormValues } from '../../../../MyProfile/Patient/utils/defaultFormValues';
import { MyProfileSubmit } from '../../../../MyProfile/Patient/utils/types';
import {
  areArraysEqual,
  updateData,
  updateDataInsurance,
  updateDataSecondaryInsurance,
} from '../../../../MyProfile/Patient/utils/utilsFunctions';
import AddressSection from '../../Components/AddressSection/AddressSection';
import styles from '../styles.module.scss';
import { validationSchema } from './validationSchema';

interface CustomStylesDashboard {
  overlay: Styles['overlay'];
  content: Styles['content'];
}

interface ProfileCardModalProps {
  isOpen: boolean;
  toggleModal: (bool: boolean) => void;
}

const AddPatientUserModal: FC<ProfileCardModalProps> = ({ isOpen, toggleModal }) => {
  const { userInfo } = useAppSelector(selectAuth);
  const [myProfileForm, setMyProfileForm] = useState<MyProfileSubmit>(defaultFormValues as MyProfileSubmit);
  const [currentAvatarURL, setCurrentAvatarURL] = useState<string>('');
  const [avatarFile, setAvatarFile] = useState<File | null>(null);
  const [avatarPayload, setAvatarPayload] = useState<RegisterMediaPayload | null>(null);
  const [selection, setSelection] = React.useState<[number | null, number | null] | null>(null);
  const ref = React.useRef<HTMLInputElement>(null);
  const [validationResponseId, setValidationResponseId] = useState('');
  const [languageOptions, setLanguageOptions] = useState<OptionLanguage[]>([]);
  const [organizationOptions, setOrganizationOptions] = useState<Option[]>([]);
  const [selectedOrganizationOptions, setSelectedOrganizationOptions] = useState<Option[]>([]);
  const [avatarAction, setAvatarAction] = useState<string>('');
  const [image, setImage] = React.useState<string>();
  const [isSavingProfile, setIsSavingProfile] = useState(false);
  const [isSendingInvitation, setIsSendingInvitation] = useState(false);
  const [allOrganization, setAllOrganization] = useState<Organization[]>([]);
  const [addSecondaryInsurance, setAddSecondaryInsurance] = useState(false);
  const [inputChanges, setInputChanges] = useState(0);
  const [isSelected, setIsSelected] = React.useState<number | null>(null);

  const userRoleCustomStylesDashboard: CustomStylesDashboard = {
    ...customStylesDashboard,
    overlay: {
      ...customStylesDashboard.overlay,
      backgroundColor: '#1f2b38f0',
      overflowY: 'scroll',
    },
    content: {
      ...customStylesDashboard.content,
      width: '1166px',
      height: 'auto',
      backgroundColor: '#F0F0F0',
      borderRadius: '11px',
      opacity: '1',
      padding: '40px 100px',
      transform: 'translate(-50%, -16%)',
    },
  };
  const [showValidationAddressModal, setShowValidationAddressModal] = useState<ValidationModalInterface>({
    isOpen: false,
    title: '',
    whatYouEntered: '',
    recommended: false,
    recommendedAddress: '',
    onClose: undefined,
    onConfirmChange: undefined,
    onKeepCurrentAddress: undefined,
  });
  const insuranceCarriers = useInsuranceCarriers();
  const insuranceCarrierOptions = useMemo(() => {
    return insuranceCarriers.map((insuranceCarrier) => ({
      value: insuranceCarrier.id,
      label: insuranceCarrier.name,
    }));
  }, [insuranceCarriers]);
  useEffect(() => {
    if (isOpen) {
      document.body.classList.add('modal-open');
    } else if (document.body.classList.contains('modal-open') && !isOpen) {
      document.body.classList.remove('modal-open');
    }
  }, [isOpen]);
  const closeModal = (): void => {
    toggleModal(false);
  };

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
    getValues,
  } = useForm<MyProfileSubmit>({
    mode: 'onChange',
    resolver: yupResolver(validationSchema(addSecondaryInsurance)),
  });
  const triggerProfileAvatar = React.useCallback((avatarUrl: string) => {
    setValue('profile_img', avatarUrl, { shouldValidate: true });
    setMyProfileForm({
      ...getValues(),
      profile_img: avatarUrl,
    });
  }, []);
  const onCloseConfirmationModal = (): void => {
    setShowValidationAddressModal({
      isOpen: false,
      title: '',
      whatYouEntered: '',
      recommended: false,
      recommendedAddress: '',
      onClose: undefined,
      onConfirmChange: undefined,
      onKeepCurrentAddress: undefined,
    });
  };

  const validateInvitationDomainInput = (): boolean => {
    return (
      getValues('organizations')?.every((organization) => {
        return organization.divisions.length > 0 || organization.practices.length > 0;
      }) || false
    );
  };

  const fetchSelectedOrganizationOptions = (): void => {
    const selectedOptions =
      getValues('organizations')?.map((org) => ({
        id: org.id,
        value: org.divisions.length > 0 ? org.divisions[0]?.id : org.practices[0]?.id,
        label: `${org?.name} - ${org.divisions.length > 0 ? org.divisions[0]?.name : org.practices[0]?.name}`,
      })) || [];

    setSelectedOrganizationOptions(selectedOptions);
  };

  useEffect(() => {
    if (validateInvitationDomainInput()) fetchSelectedOrganizationOptions();
  }, [getValues('organizations'), validateInvitationDomainInput(), inputChanges]);

  const fetchLanguageOptions = async (): Promise<void> => {
    try {
      const data = await listAllLanguages();
      const transformedData = data.map((language) => ({
        value: language.locale,
        label: language.name,
        id: language.id,
      }));
      setLanguageOptions(transformedData);
    } catch (error: unknown) {
      displayErrorDetails(error);
    }
  };

  const fetchOrganizationOptions = async (): Promise<void> => {
    try {
      const data = await listAllOrganizations();
      if (userInfo.role.name === UserRoleName.OrganizationAdmin) {
        data.nodes = data.nodes.filter((org) => org.id === userInfo.organizations[0].id);
      }
      setAllOrganization(data.nodes);
      const transformedData = data.nodes.map((organization) => ({
        value: organization.id,
        label: organization.name,
        id: organization.id,
      }));
      setOrganizationOptions(transformedData);
    } catch (error: unknown) {
      displayErrorDetails(error);
    }
  };

  useEffect(() => {
    fetchLanguageOptions();
    fetchOrganizationOptions();
  }, []);

  const setTextValue = (e: React.ChangeEvent<HTMLInputElement>, name: string): void => {
    const { value } = e.target;
    setValue(name as keyof MyProfileSubmit, value, { shouldValidate: true });
    setSelection([e.target.selectionStart, e.target.selectionEnd]);
  };

  React.useLayoutEffect(() => {
    if (selection && ref.current) {
      [ref.current.selectionStart, ref.current.selectionEnd] = selection;
    }
  }, [selection]);

  const onInvalid = (errorsList: any): void => {
    const error = Object.values(errorsList)[0] as any;
    if (error) {
      toast.error(error.message as string);
    }
  };

  const onCancelEditProfile = (): void => {
    toggleModal(false);
    setImage(currentAvatarURL);
  };

  const getInvitationDomain = (): string => {
    const selectedOrganizations = getValues('organizations') || [];
    if (selectedOrganizations.length > 1 && validateInvitationDomainInput()) {
      const selectedOrganization = allOrganization.find(
        (selectedOrg) => selectedOrg.id === getValues('invitationDomain')?.id,
      );
      return (
        (selectedOrganization?.divisions?.length
          ? selectedOrganization?.divisions.find((division) => division.id === getValues('invitationDomain')?.value)
              ?.domain
          : selectedOrganization?.practices.find((practice) => practice.id === getValues('invitationDomain')?.value)!
              .domain) || ''
      );
    }

    const selectedOrganization = getValues('organization')?.[0];
    if (selectedOrganization) {
      const matchedOrganization = allOrganization.find((selectedOrg) => selectedOrg.id === selectedOrganization.id);
      return (
        (matchedOrganization?.divisions?.length
          ? matchedOrganization?.divisions.find(
              (division) =>
                division.id ===
                getValues('organizations')?.find((organization) => organization.id === matchedOrganization.id)
                  ?.divisions[0].id,
            )!.domain
          : matchedOrganization?.practices.find(
              (practice) =>
                practice.id ===
                getValues('organizations')?.find((organization) => organization.id === matchedOrganization.id)
                  ?.practices[0].id,
            )!.domain) || ''
      );
    }
    return '';
  };

  const callSaveProfile = async (profileData: UnifiedProfileSubmit): Promise<void> => {
    setIsSavingProfile(true);
    let newUser: UserProfile | undefined;

    try {
      newUser = await createNewUser({
        email: profileData.email?.toLowerCase(),
        ...((profileData as MyProfileSubmit).phone_number && {
          phone_number: parsePhoneNumber((profileData as MyProfileSubmit).phone_number, 'US')?.format(
            'E.164',
          ) as string,
        }),
        ...(profileData.address1 !== '' ||
        profileData.address2 !== '' ||
        profileData.city !== '' ||
        profileData.state !== '' ||
        profileData.zip_code !== ''
          ? {
              address: {
                ...(profileData.address1 !== '' && { address1: profileData.address1 }),
                ...(profileData.address2 !== '' && { address2: profileData.address2 }),
                ...(profileData.city !== '' && { city: profileData.city }),
                ...(profileData.state !== '' && { state: profileData.state }),
                ...(profileData.zip_code !== '' && { zip_code: profileData.zip_code }),
              } as Address,
            }
          : {}),

        type: UserRoleName.Patient,
        ...((profileData as MyProfileSubmit).organization && {
          organization_ids: (profileData as MyProfileSubmit).organization
            ?.map((org) => org.id)
            .filter((id) => id !== undefined) as string[],
        }),
        send_invitation: isSendingInvitation,
        full_name: isSendingInvitation ? `${profileData.first_name} ${profileData.last_name}` : undefined,
        frontend_website: `https://${getInvitationDomain()}`,
      });
    } catch (error: unknown) {
      displayErrorDetails(error);
      setIsSavingProfile(false);
    }
    if (newUser) {
      try {
        if (avatarPayload && avatarFile && avatarAction === 'Upload') {
          const res = await registerAvatarMedia(avatarPayload);
          const mediaId = res.files[0].upload_id;
          await fetch(res.files[0].upload_url, {
            method: 'PUT',
            body: avatarFile,
            headers: { 'content-type': avatarFile.type },
          });
          await confirmAvatarMedia(mediaId, newUser.id);
          triggerProfileAvatar(res.files[0].upload_url);
        } else if (avatarAction === 'Remove') {
          await deleteAvatar(newUser.id);
          triggerProfileAvatar('');
        }

        const dataWithUpdatedDOB = updateData(profileData);
        if (
          dataWithUpdatedDOB.first_name !== '' ||
          dataWithUpdatedDOB.middle_name !== '' ||
          dataWithUpdatedDOB.last_name !== '' ||
          dataWithUpdatedDOB.gender !== '' ||
          dataWithUpdatedDOB.date_of_birth !== 'Invalid date' ||
          dataWithUpdatedDOB.pronouns !== '' ||
          (Array.isArray(dataWithUpdatedDOB.languages) &&
            dataWithUpdatedDOB.languages.length > 0 &&
            (dataWithUpdatedDOB.languages[0] as Language).name !== '')
        ) {
          await createBasicInformation(
            {
              first_name: dataWithUpdatedDOB.first_name ?? '',
              ...(dataWithUpdatedDOB.middle_name && { middle_name: dataWithUpdatedDOB.middle_name }),
              last_name: dataWithUpdatedDOB.last_name ?? '',
              ...(dataWithUpdatedDOB.gender && { gender: dataWithUpdatedDOB.gender }),
              ...(dataWithUpdatedDOB.date_of_birth && { date_of_birth: dataWithUpdatedDOB.date_of_birth }),
              ...(dataWithUpdatedDOB.pronouns && { pronouns: dataWithUpdatedDOB.pronouns }),
              ...(dataWithUpdatedDOB.gender_identity && { gender_identity: dataWithUpdatedDOB.gender_identity }),
              ...((profileData as MyProfileSubmit).languages.length > 0 && {
                languages: (profileData as MyProfileSubmit).language?.map((language) => language.id),
              }),
            },
            newUser.id,
          );
        }

        const dataWithUpdatedInsurance = updateDataInsurance(profileData as MyProfileSubmit);
        const insuranceArray: Insurance[] = [];
        if (
          dataWithUpdatedInsurance.insured_by !== '' ||
          dataWithUpdatedInsurance.insurance_membership !== undefined ||
          dataWithUpdatedInsurance.insurance_through !== undefined
        )
          insuranceArray.push(dataWithUpdatedInsurance);
        if (addSecondaryInsurance) {
          const dataWithUpdatedSecondaryInsurance = updateDataSecondaryInsurance(profileData as MyProfileSubmit);
          insuranceArray.push(dataWithUpdatedSecondaryInsurance);
        }
        await createPatientInsurance({
          user_id: newUser.id,
          insurance: insuranceArray,
        });
        // Create an array to hold the promises for API calls
        const promises: Promise<UserProfile>[] = [];

        if (
          (profileData as MyProfileSubmit).organization &&
          !areArraysEqual(newUser.organizations, (profileData as MyProfileSubmit).organization!)
        ) {
          const updatedOrganizations = (profileData as MyProfileSubmit)?.organization;
          const organizationIdList: string[] = [];

          if (updatedOrganizations) {
            for (const updatedOrganization of updatedOrganizations) {
              organizationIdList.push(updatedOrganization.id as string);
            }
          }

          if (organizationIdList.length > 0) {
            // License exists in the current list, update it
            promises.push(
              updateUserOrganization(newUser.id, {
                organization_ids: organizationIdList,
              }),
            );
          }
          if (organizationIdList.length === 0) {
            // License exists in the current list, update it
            promises.push(
              updateUserOrganization(newUser.id, {
                organization_ids: ['dentistry-one'],
              }),
              updateUserDivision(newUser.id, {
                division_ids: [],
              }),
              updateUserPractice(newUser.id, {
                practice_ids: [],
              }),
            );
          }
        }
        // Execute all promises concurrently
        await Promise.all(promises);

        if ((profileData as MyProfileSubmit).organizations) {
          const matchingDivisionIds: string[] = [];
          const matchingPracticeIds: string[] = [];

          (profileData as MyProfileSubmit)?.organizations?.forEach((selectedOrganization) => {
            selectedOrganization.divisions.forEach((division) => {
              if (division.id) {
                matchingDivisionIds.push(division.id.toString());
              }
            });

            selectedOrganization.practices.forEach((practice) => {
              if (practice.id) {
                matchingPracticeIds.push(practice.id.toString());
              }
            });
          });

          if (matchingDivisionIds.length > 0) {
            promises.push(
              updateUserDivision(newUser.id, {
                division_ids: matchingDivisionIds,
              }),
            );
          }
          if (matchingPracticeIds.length > 0) {
            promises.push(
              updateUserPractice(newUser.id, {
                practice_ids: matchingPracticeIds,
              }),
            );
          }
        }
        // Execute all promises concurrently
        await Promise.all(promises);
        setIsSavingProfile(false);
        toggleModal(false);
      } catch (error: unknown) {
        displayErrorDetails(error);
        setIsSavingProfile(false);
      }
    }
  };

  const updateCurrentAddress = (standardizedAddress: any, postalAddress: any, addressComponents: any): void => {
    const streetNumber = addressComponents.find(
      (addressComponent: any) => addressComponent.componentType === 'street_number',
    );
    const address1 = addressComponents.find((addressComponent: any) => addressComponent.componentType === 'route');
    if (postalAddress) setValue('city', postalAddress.locality);
    if (streetNumber || address1) {
      setValue('address1', `${streetNumber.componentName.text} ${address1.componentName.text}`);
    }
    if (standardizedAddress?.state) setValue('state', standardizedAddress.state);
    if (postalAddress) setValue('zip_code', postalAddress.postalCode);
  };

  const onSubmitProfile = async (profileData: MyProfileSubmit): Promise<void> => {
    if (
      profileData.address1 !== '' ||
      profileData.address2 !== '' ||
      profileData.city !== '' ||
      profileData.state !== '' ||
      profileData.zip_code !== ''
    ) {
      await validateAddress(
        profileData,
        getValues as UseFormGetValues<UnifiedProfileSubmit>,
        validationResponseId,
        callSaveProfile,
        setValidationResponseId,
        setShowValidationAddressModal,
        onCloseConfirmationModal,
        updateCurrentAddress,
      );
    } else callSaveProfile(profileData);
  };

  const onChange = (key: any, value: string | number): void => {
    setValue(key, value, { shouldValidate: true });
  };

  const handleSelectChange = (
    value: SingleValue<Option>,
    orgId: string,
    divisionOrPractice: 'divisions' | 'practices',
  ): void => {
    const currentValue = {
      id: value?.id as string,
      name: value?.label || '',
      organization_id: value?.organization_id,
    };
    const divisionsArray: Division[] = [];
    const practicesArray: Practice[] = [];

    const getSelectedOrganizationIndex = getValues('organizations')?.findIndex(
      (organization) => organization.id === orgId,
    );

    if (divisionOrPractice === 'divisions') {
      divisionsArray.push(currentValue);
      setValue(`organizations.${getSelectedOrganizationIndex!}.divisions`, divisionsArray, { shouldValidate: true });
    }

    if (divisionOrPractice === 'practices') {
      practicesArray.push(currentValue);

      setValue(`organizations.${getSelectedOrganizationIndex!}.practices`, practicesArray, { shouldValidate: true });
    }
  };

  useEffect(() => {
    if (Object.keys(myProfileForm).length > 0) {
      Object.keys(myProfileForm).forEach((key: unknown) => {
        setValue(key as keyof MyProfileSubmit, myProfileForm[key as keyof MyProfileSubmit]);
      });
    }
  }, [myProfileForm, setValue]);

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={closeModal}
      style={userRoleCustomStylesDashboard}
      contentLabel="Modal"
      ariaHideApp={false}
      shouldCloseOnOverlayClick={false}
    >
      <button
        type="button"
        className={styles.modalClose}
        aria-label="Close modal"
        onClick={closeModal}
        style={{ backgroundImage: `url(${closeModalIcon})` }}
      />
      <div className={styles.profileContainer}>
        <div className={styles.contentContainer}>
          <div className={styles.contentContainerInner}>
            <div className={styles.editProfileContainer}>
              <div className={styles.titleContainer}>
                <span className={styles.title}>Create Patient Profile</span>
              </div>
              <div className={styles.editContainer}>
                <form className={styles.formContainer} onSubmit={handleSubmit(onSubmitProfile, onInvalid)}>
                  <div className={styles.headerActionDiv}>
                    <ProfileImage
                      reviewProfile
                      setCurrentAvatarURL={setCurrentAvatarURL}
                      setAvatarFile={setAvatarFile}
                      setAvatarPayload={setAvatarPayload}
                      setAvatarAction={setAvatarAction}
                      triggerProfileAvatar={triggerProfileAvatar}
                      setImage={setImage}
                      image={image}
                      errors={errors}
                      size="80px"
                      isNewDependent
                    />
                  </div>
                  <p className={styles.requiredIndicator}>
                    Required <span>*</span>
                  </p>

                  <div className={styles.row}>
                    <div className={cn(styles.rowItem, styles.details)}>
                      <div className={styles.caption}>First Name</div>
                      <TextField
                        {...register('first_name')}
                        errors={errors}
                        name="first_name"
                        value={getValues('first_name')}
                        onChange={(e) => setTextValue(e, 'first_name')}
                        wrapperClassName={styles.inputWrapper}
                        inputClass={styles.input}
                      />
                    </div>
                    <div className={cn(styles.rowItem, styles.details)}>
                      <div className={styles.caption}>Middle Name</div>
                      <TextField
                        {...register('middle_name')}
                        errors={errors}
                        name="middle_name"
                        value={getValues('middle_name')}
                        onChange={(e) => setTextValue(e, 'middle_name')}
                        wrapperClassName={styles.inputWrapper}
                        inputClass={styles.input}
                      />
                    </div>
                    <div className={cn(styles.rowItem, styles.details)}>
                      <div className={styles.caption}>Last Name</div>
                      <TextField
                        {...register('last_name')}
                        errors={errors}
                        name="last_name"
                        value={getValues('last_name')}
                        onChange={(e) => setTextValue(e, 'last_name')}
                        wrapperClassName={styles.inputWrapper}
                        inputClass={styles.input}
                      />
                    </div>
                  </div>
                  <div className={styles.row}>
                    <div className={cn(styles.rowItem, styles.gender)}>
                      <div className={styles.caption}>Gender assigned at birth</div>
                      <SelectButtons
                        errors={errors}
                        setValue={setValue}
                        form={myProfileForm}
                        value="gender"
                        options={genderOptions}
                      />
                    </div>
                  </div>
                  <div className={styles.row}>
                    <div className={cn('col-md-6', styles.rowItem, styles.details, styles.rowItemHalf)}>
                      <div className={styles.caption}>Gender identity</div>
                      <SelectInput
                        {...register('gender_identity')}
                        name="gender_identity"
                        options={genderIdentity}
                        containerClass={styles.inputWrapper}
                        selectorClass={styles.selector}
                        style={selectCommonCustomStylesLarge}
                        errors={errors}
                        selectedValue={getValues('gender_identity')}
                        onChange={(value) => {
                          if (value) {
                            setValue('gender_identity', value.value, { shouldValidate: true });
                          }
                        }}
                      />
                    </div>
                    <div className={cn('col-md-6', styles.rowItem, styles.details, styles.rowItemHalf)}>
                      <div className={styles.caption}>Pronouns</div>
                      <SelectInput
                        {...register('pronouns')}
                        name="pronouns"
                        options={pronounsOptions}
                        containerClass={styles.inputWrapper}
                        selectorClass={styles.selector}
                        style={selectCommonCustomStylesLarge}
                        errors={errors}
                        selectedValue={getValues('pronouns')}
                        onChange={(value) => {
                          if (value) {
                            setValue('pronouns', value.value, { shouldValidate: true });
                          }
                        }}
                      />
                    </div>
                  </div>
                  <div className={styles.row} style={{ flexWrap: 'nowrap' }}>
                    <div className={cn(styles.rowItem, styles.details)}>
                      <div className={styles.caption}>Date of Birth</div>
                      <TextField
                        {...register('date_of_birth')}
                        errors={errors}
                        name="date_of_birth"
                        placeholder="MM / DD / YYYY"
                        value={getValues('date_of_birth')}
                        onChange={(e) => dateAutoSlashes(e, 'date_of_birth', setValue)}
                        maxLength={10}
                        wrapperClassName={styles.inputWrapper}
                        inputClass={styles.input}
                        enableCursorPos={false}
                      />
                    </div>
                    <div className={cn(styles.rowItem, styles.details, styles.languages)}>
                      <div className={styles.caption}>Languages</div>
                      <MultiselectInput
                        {...register('language')}
                        name="language"
                        placeholder="Please add any languages you are comfortable speaking during the consult"
                        options={languageOptions}
                        style={multiSelectCommonCustomStylesLarge}
                        containerClass={styles.inputWrapper}
                        selectorClass={styles.selector}
                        errors={errors}
                        selectedValue={getValues('language')}
                        onChange={(value) => {
                          if (value) {
                            setValue('language', value as MultiValue<OptionLanguage>, {
                              shouldValidate: true,
                            });
                          }
                        }}
                      />
                    </div>
                  </div>
                  <div className={styles.separator} />
                  <div className={styles.row}>
                    <AddressSection
                      register={register}
                      errors={errors}
                      getValues={getValues}
                      setTextValue={setTextValue}
                      setValue={setValue}
                      showValidationAddressModal={showValidationAddressModal}
                      required={false}
                    />
                  </div>
                  <div className={styles.separator} />
                  <div className={styles.row}>
                    <div className={styles.rowItem}>
                      <div className={styles.caption}>
                        Email Address <span>*</span>
                      </div>
                      <TextField
                        {...register('email')}
                        errors={errors}
                        name="email"
                        value={getValues('email')}
                        onChange={(e) => setTextValue(e, 'email')}
                        wrapperClassName={styles.inputWrapper}
                        inputClass={styles.input}
                      />
                    </div>
                    <div className={styles.rowItem}>
                      <div className={styles.caption}>Phone Number</div>
                      <div className={styles.inputWrapper}>
                        <PhoneInput
                          {...register('phone_number')}
                          id="phone_number"
                          value={getValues('phone_number') as E164Number}
                          onChange={(val) => {
                            setValue('phone_number', val as string, {
                              shouldValidate: true,
                            });
                          }}
                          inputClass={styles.input}
                          errors={errors}
                          wrapperClassName={styles.inputWrapper}
                        />
                      </div>
                    </div>
                  </div>
                  <div className={styles.separator} />
                  <div className={styles.editProfileContainer}>
                    <div className={styles.titleContainer}>
                      <span className={styles.title}>Insurance Information</span>
                    </div>
                    <div className={styles.editContainer}>
                      <div className={styles.row}>
                        <div className={styles.rowItem}>
                          <div className={styles.caption}>I'm Insured By</div>
                          <SelectInput
                            {...register('insurance_through')}
                            name="insurance_through"
                            options={insuranceThroughOptions}
                            containerClass={styles.inputWrapper}
                            selectorClass={styles.selector}
                            style={selectCommonCustomStylesLarge}
                            errors={errors}
                            selectedValue={getValues('insurance_through')}
                            onChange={(value) => {
                              if (value) {
                                setValue('insurance_through', value.value, { shouldValidate: true });
                              }
                            }}
                          />
                        </div>
                      </div>
                      {getValues('insurance_through') &&
                        getValues('insurance_through') !== 'none' &&
                        getValues('insurance_through') !== undefined && (
                          <>
                            <div className={styles.row}>
                              <div className={styles.rowItem}>
                                <div className={styles.caption}>
                                  Insurance company <span>*</span>
                                </div>
                                <SelectInput
                                  {...register('insurance_carrier_id')}
                                  name="insurance_carrier_id"
                                  options={insuranceCarrierOptions}
                                  containerClass={styles.inputWrapper}
                                  selectorClass={styles.selector}
                                  style={selectCommonCustomStylesLarge}
                                  errors={errors}
                                  selectedValue={getValues('insurance_carrier_id')}
                                  onChange={(value) => {
                                    if (value) {
                                      setValue('insurance_carrier_id', value.value, { shouldValidate: true });
                                    }
                                  }}
                                />
                              </div>
                            </div>
                            <div className={styles.row}>
                              <div className={styles.rowItem}>
                                <div className={styles.caption}>
                                  Member ID <span>*</span>
                                </div>
                                <TextField
                                  {...register('member_id')}
                                  errors={errors}
                                  name="member_id"
                                  value={getValues('member_id')}
                                  onChange={(e) => {
                                    setValue('member_id', e.target.value, { shouldValidate: true });
                                    setSelection([e.target.selectionStart, e.target.selectionEnd]);
                                  }}
                                  wrapperClassName={styles.inputWrapper}
                                  inputClass={styles.input}
                                />
                              </div>
                            </div>
                            <div className={styles.row}>
                              <div className={styles.rowItem}>
                                <div className={styles.caption}>
                                  Group Number <span>*</span>
                                </div>
                                <TextField
                                  {...register('group_number')}
                                  errors={errors}
                                  name="group_number"
                                  value={getValues('group_number')}
                                  onChange={(e) => {
                                    setValue('group_number', e.target.value, { shouldValidate: true });
                                    setSelection([e.target.selectionStart, e.target.selectionEnd]);
                                  }}
                                  wrapperClassName={styles.inputWrapper}
                                  inputClass={styles.input}
                                />
                              </div>
                            </div>
                            <div className={styles.row}>
                              <div className={styles.rowItem}>
                                <div className={styles.caption}>
                                  Phone number at the back of your insurance card (if multiple, use the provider number)
                                  <span> *</span>
                                </div>
                                <PhoneInput
                                  {...register('iPhoneNumber')}
                                  value={getValues('iPhoneNumber') as E164Number}
                                  id="iPhoneNumber"
                                  onChange={(val) => {
                                    setValue('iPhoneNumber', val as string, {
                                      shouldValidate: true,
                                    });
                                  }}
                                  inputClass={styles.input}
                                  errors={errors}
                                  wrapperClassName={styles.inputWrapper}
                                />
                              </div>
                            </div>
                            <div className={styles.row}>
                              <div className={styles.rowItem}>
                                <div className={styles.caption}>
                                  Insurance Address
                                  <span className={styles.redText}> *</span>
                                </div>
                                <TextField
                                  {...register('insurance_address')}
                                  errors={errors}
                                  name="insurance_address"
                                  value={getValues('insurance_address')}
                                  onChange={(e) => {
                                    const { value } = e.target;
                                    onChange('insurance_address', value);
                                  }}
                                  wrapperClassName={styles.inputWrapper}
                                  inputClass={styles.input}
                                />
                              </div>
                            </div>
                            <div className={styles.row}>
                              <div className={styles.rowItem}>
                                <div className={styles.caption}>
                                  Insurance City
                                  <span className={styles.redText}> *</span>
                                </div>
                                <TextField
                                  {...register('insurance_city')}
                                  errors={errors}
                                  name="insurance_city"
                                  value={getValues('insurance_city')}
                                  onChange={(e) => {
                                    const { value } = e.target;
                                    onChange('insurance_city', value);
                                  }}
                                  wrapperClassName={styles.inputWrapper}
                                  inputClass={styles.input}
                                />
                              </div>
                              <div className={styles.rowItem}>
                                <div className={styles.caption}>
                                  Insurance State
                                  <span className={styles.redText}> *</span>
                                </div>
                                <SelectInput
                                  {...register('insurance_state')}
                                  name="insurance_state"
                                  options={stateOptions}
                                  containerClass={styles.inputWrapper}
                                  selectorClass={styles.selector}
                                  style={selectCommonCustomStylesLarge}
                                  errors={errors}
                                  selectedValue={getValues('insurance_state')}
                                  onChange={(value) => {
                                    if (value) {
                                      onChange('insurance_state', value.value);
                                    }
                                  }}
                                />
                              </div>
                              <div className={styles.rowItem}>
                                <div className={styles.caption}>
                                  Insurance Zip Code
                                  <span className={styles.redText}> *</span>
                                </div>
                                <TextField
                                  {...register('insurance_zip_code')}
                                  errors={errors}
                                  name="insurance_zip_code"
                                  value={getValues('insurance_zip_code')}
                                  onChange={(e) => {
                                    const { value } = e.target;
                                    onChange('insurance_zip_code', value);
                                  }}
                                  wrapperClassName={styles.inputWrapper}
                                  inputClass={styles.input}
                                />
                              </div>
                            </div>
                          </>
                        )}
                      {getValues('insurance_through') &&
                        getValues('insurance_through') !== 'myself' &&
                        getValues('insurance_through') !== 'none' &&
                        getValues('insurance_through') !== undefined && (
                          <div className={styles.insuranceThroughContainer}>
                            <div className={styles.row}>
                              <div className={`${styles.rowItem} ${styles.midRow}`}>
                                <div className={styles.caption}>Insurance Holder’s First Name</div>
                                <TextField
                                  {...register('iFirstName')}
                                  errors={errors}
                                  name="iFirstName"
                                  value={getValues('iFirstName')}
                                  onChange={(e) => {
                                    setValue('iFirstName', e.target.value, { shouldValidate: true });
                                    setSelection([e.target.selectionStart, e.target.selectionEnd]);
                                  }}
                                  wrapperClassName={styles.inputWrapper}
                                  inputClass={styles.input}
                                />
                              </div>
                              <div className={`${styles.rowItem} ${styles.midRow}`}>
                                <div className={styles.caption}>Insurance Holder’s Middle Name</div>
                                <TextField
                                  {...register('iMiddleName')}
                                  errors={errors}
                                  name="iMiddleName"
                                  value={getValues('iMiddleName')}
                                  onChange={(e) => {
                                    setValue('iMiddleName', e.target.value, { shouldValidate: true });
                                    setSelection([e.target.selectionStart, e.target.selectionEnd]);
                                  }}
                                  wrapperClassName={styles.inputWrapper}
                                  inputClass={styles.input}
                                />
                              </div>
                              <div className={`${styles.rowItem} ${styles.midRow}`}>
                                <div className={styles.caption}>Insurance Holder’s Last Name</div>
                                <TextField
                                  {...register('iLastName')}
                                  errors={errors}
                                  name="iLastName"
                                  value={getValues('iLastName')}
                                  onChange={(e) => {
                                    setValue('iLastName', e.target.value, { shouldValidate: true });
                                    setSelection([e.target.selectionStart, e.target.selectionEnd]);
                                  }}
                                  wrapperClassName={styles.inputWrapper}
                                  inputClass={styles.input}
                                />
                              </div>
                            </div>
                            <div className={`row ${styles.row}`}>
                              <div className={styles.rowItem}>
                                <div className={styles.caption}>Insurance Holder’s Date of Birth</div>
                                <TextField
                                  {...register('iDateOfBirth')}
                                  errors={errors}
                                  name="iDateOfBirth"
                                  value={getValues('iDateOfBirth')}
                                  onChange={(e) => {
                                    setValue('iDateOfBirth', checkAndSetDate(e), {
                                      shouldValidate: true,
                                    });
                                    setSelection([e.target.selectionStart, e.target.selectionEnd]);
                                  }}
                                  maxLength={10}
                                  wrapperClassName={styles.inputWrapper}
                                  inputClass={styles.input}
                                  isUnderlined={false}
                                />
                              </div>
                              <div className={styles.rowItem}>
                                <div className={styles.caption}>Insurance Holder’s Gender Identity</div>
                                <SelectInput
                                  {...register('iGenderIdentity')}
                                  name="iGenderIdentity"
                                  options={genderIdentity}
                                  containerClass={styles.inputWrapper}
                                  selectorClass={styles.selector}
                                  style={selectCommonCustomStylesLarge}
                                  errors={errors}
                                  selectedValue={getValues('iGenderIdentity')}
                                  onChange={(value) => {
                                    if (value) {
                                      onChange('iGenderIdentity', value.value);
                                    }
                                  }}
                                />
                              </div>
                            </div>
                            <div className={`row ${styles.row}`}>
                              <div className={styles.rowItem}>
                                <div className={styles.caption}>Insurance Holder’s Street Address</div>
                                <TextField
                                  {...register('iAddress1')}
                                  errors={errors}
                                  name="iAddress1"
                                  value={getValues('iAddress1')}
                                  onChange={(e) => {
                                    setValue('iAddress1', e.target.value, { shouldValidate: true });
                                    setSelection([e.target.selectionStart, e.target.selectionEnd]);
                                  }}
                                  wrapperClassName={styles.inputWrapper}
                                  inputClass={styles.input}
                                  isUnderlined={false}
                                />
                              </div>
                            </div>
                            <div className={`row ${styles.row}`}>
                              <div className={styles.rowItem}>
                                <div className={styles.caption}>Apt, suite, building (optional)</div>
                                <TextField
                                  {...register('iAddress2')}
                                  errors={errors}
                                  name="iAddress2"
                                  value={getValues('iAddress2')}
                                  onChange={(e) => {
                                    setValue('iAddress2', e.target.value, { shouldValidate: true });
                                    setSelection([e.target.selectionStart, e.target.selectionEnd]);
                                  }}
                                  wrapperClassName={styles.inputWrapper}
                                  inputClass={styles.input}
                                  isUnderlined={false}
                                />
                              </div>
                            </div>
                            <div className={`row ${styles.row}`}>
                              <div className={`${styles.rowItem} ${styles.trimesterRow}`}>
                                <div className={styles.caption}>City</div>
                                <TextField
                                  {...register('iCity')}
                                  errors={errors}
                                  name="iCity"
                                  value={getValues('iCity')}
                                  onChange={(e) => {
                                    setValue('iCity', e.target.value, { shouldValidate: true });
                                    setSelection([e.target.selectionStart, e.target.selectionEnd]);
                                  }}
                                  wrapperClassName={styles.inputWrapper}
                                  inputClass={styles.input}
                                  isUnderlined={false}
                                />
                              </div>
                              <div className={`${styles.rowItem} ${styles.trimesterRow}`}>
                                <div className={styles.caption}>State</div>
                                <SelectInput
                                  {...register('iState')}
                                  containerClass="mt-0"
                                  name="iState"
                                  options={stateOptions}
                                  style={profileSelectStyles}
                                  errors={errors}
                                  selectedValue={getValues('iState')}
                                  onChange={(value) => {
                                    setValue('iState', value ? value.value : '', { shouldValidate: true });
                                  }}
                                />
                              </div>
                              <div className={`${styles.rowItem} ${styles.trimesterRow}`}>
                                <div className={styles.caption}>Zip Code</div>
                                <TextField
                                  {...register('iZipCode')}
                                  errors={errors}
                                  name="iZipCode"
                                  value={getValues('iZipCode')}
                                  onChange={(e) => {
                                    setValue('iZipCode', e.target.value, { shouldValidate: true });
                                    setSelection([e.target.selectionStart, e.target.selectionEnd]);
                                  }}
                                  wrapperClassName={styles.inputWrapper}
                                  inputClass={styles.input}
                                  isUnderlined={false}
                                />
                              </div>
                            </div>
                          </div>
                        )}
                      {getValues('insurance_through') !== 'none' &&
                        getValues('insurance_through') !== '' &&
                        getValues('insurance_through') !== undefined && (
                          <div className={`${styles.row}`} style={{ flexDirection: 'row' }}>
                            <div className={`${styles.rowItem}`}>
                              <div className={styles.caption}>
                                Do you currently have insurance coverage through a state-funded program such as
                                Medicaid?
                                <span> *</span>
                              </div>
                              <div className={styles.optionsDiv}>
                                {stateFundedProgramOptions.map((selection) => (
                                  <button
                                    className={
                                      isSelected === selection.id
                                        ? `${styles.multiSelectorInsuranceCoverage} ${styles.buttonSelectedInsuranceCoverage}`
                                        : `${styles.multiSelectorInsuranceCoverage}`
                                    }
                                    value={getValues('state_funded_coverage_status')}
                                    {...register('state_funded_coverage_status')}
                                    type="button"
                                    onClick={() => {
                                      setIsSelected(selection.id);
                                      setValue('state_funded_coverage_status', selection.value, {
                                        shouldValidate: true,
                                      });
                                    }}
                                    key={selection.id}
                                  >
                                    <span>{selection.label}</span>
                                    <div className={styles.checkInput} />
                                  </button>
                                ))}
                              </div>
                            </div>
                          </div>
                        )}
                      {getValues('insurance_through') !== 'none' &&
                        getValues('insurance_through') !== '' &&
                        getValues('insurance_through') !== undefined && (
                          <div className={`${styles.addSecondaryInsurance} form-check`}>
                            <input
                              className="form-check-input"
                              type="checkbox"
                              id="isAdditionalInsurance"
                              checked={addSecondaryInsurance}
                              onChange={(event) => {
                                setAddSecondaryInsurance(event.target.checked);
                              }}
                            />
                            <label
                              className={`form-check-label ${styles.additionalPeopleCheckLabel}`}
                              htmlFor="isAdditionalInsurance"
                            >
                              Add Secondary Insurance
                            </label>
                          </div>
                        )}
                      {addSecondaryInsurance &&
                        getValues('insurance_through') !== 'none' &&
                        getValues('insurance_through') !== undefined && (
                          <SecondaryInsuranceFields
                            register={register}
                            errors={errors}
                            getValues={getValues}
                            onChange={onChange}
                          />
                        )}
                    </div>
                  </div>
                  <div className={styles.separator} />
                  <div className={styles.editProfileContainer}>
                    <div className={styles.editContainer}>
                      <div className={styles.row}>
                        <div className={cn(styles.rowItem, styles.details, styles.big)}>
                          <div className={styles.caption}>
                            Organization <span>*</span>
                          </div>
                          <MultiselectInput
                            {...register('organization')}
                            name="organization"
                            options={organizationOptions as { value: string; label: string }[]}
                            containerClass={styles.inputWrapper}
                            selectorClass={styles.selector}
                            style={selectCommonCustomStylesLarge}
                            selectedValue={getValues('organization')}
                            errors={errors}
                            onChange={(selectedOptions) => {
                              if (selectedOptions) {
                                const selectedOrganizations = selectedOptions as MultiValue<Option>;
                                const lastSelectedOption = (selectedOptions as MultiValue<Option>).at(-1);
                                const organizationsArray = getValues('organizations') || [];

                                if (
                                  lastSelectedOption &&
                                  organizationsArray &&
                                  selectedOrganizations.length > organizationsArray.length
                                ) {
                                  organizationsArray.push({
                                    id: lastSelectedOption?.id?.toString() as string,
                                    name: lastSelectedOption?.label as string,
                                    divisions: [],
                                    practices: [],
                                    payment_options: [],
                                  });
                                  setValue('organizations', organizationsArray, { shouldValidate: true });
                                } else {
                                  const updatedOrganizations = organizationsArray.filter((org) =>
                                    selectedOrganizations.some((selectedOrg) => selectedOrg.id === org.id),
                                  );

                                  setValue('organizations', updatedOrganizations, { shouldValidate: true });
                                }

                                setValue('organization', selectedOrganizations, { shouldValidate: true });
                              }
                            }}
                          />
                        </div>
                      </div>
                      {getValues('organizations') &&
                        getValues('organizations')?.map((org, index) => {
                          const divisionOrPractice = (() => {
                            const relatedOrg = allOrganization.find((selectedOrg) => selectedOrg.id === org.id);
                            return relatedOrg && relatedOrg.divisions?.length > 0 ? 'divisions' : 'practices';
                          })();

                          return (
                            <div className={styles.row} key={org.id}>
                              <div className={cn(styles.rowItem, styles.details, styles.big)}>
                                <div className={styles.caption}>
                                  {org.name} - {divisionOrPractice === 'divisions' ? 'Division' : 'Practice'}{' '}
                                  <span>*</span>
                                </div>
                                <SelectInput
                                  {...register(`organizations.${index}.${divisionOrPractice}`)}
                                  name="additionalSelect"
                                  options={
                                    allOrganization.some((selectedOrg) => selectedOrg.id === org.id)
                                      ? (() => {
                                          const relatedOrg = allOrganization.find(
                                            (selectedOrg) => selectedOrg.id === org.id,
                                          );
                                          if (relatedOrg) {
                                            if (relatedOrg[divisionOrPractice]?.length > 0) {
                                              return relatedOrg[divisionOrPractice].map((item) => ({
                                                id: item.id,
                                                value: item.id,
                                                label: item.name,
                                                organization_id: item?.organization_id,
                                              }));
                                            }
                                          }
                                          return [];
                                        })()
                                      : []
                                  }
                                  containerClass={styles.inputWrapper}
                                  selectorClass={styles.selector}
                                  style={selectCommonCustomStylesLarge}
                                  selectedValue={(() => {
                                    const getSelectedOrganizationIndex = getValues('organizations')?.findIndex(
                                      (organization) => organization.id === org.id!,
                                    );
                                    const option = getValues(
                                      `organizations.${getSelectedOrganizationIndex!}.${divisionOrPractice}`,
                                    )[0];
                                    if (option) {
                                      return {
                                        id: option.id,
                                        value: option.id,
                                        label: option.name,
                                      };
                                    }
                                    return null;
                                  })()}
                                  errors={errors}
                                  onChange={(value) => {
                                    handleSelectChange(value, org.id! as string, divisionOrPractice);
                                    setInputChanges(inputChanges + 1);
                                  }}
                                />
                              </div>
                            </div>
                          );
                        })}
                      {getValues('organizations') &&
                        (getValues('organizations')?.length || 0) > 1 &&
                        validateInvitationDomainInput() && (
                          <div className={styles.row}>
                            <div className={cn(styles.rowItem, styles.details, styles.big)}>
                              <div className={styles.caption}>
                                Invitation Domain <span>*</span>
                              </div>
                              <SelectInput
                                {...register('invitationDomain')}
                                name="invitationDomain" // Set a name for this input
                                options={selectedOrganizationOptions}
                                containerClass={styles.inputWrapper}
                                selectorClass={styles.selector}
                                style={selectCommonCustomStylesLarge}
                                selectedValue={getValues('invitationDomain')}
                                errors={errors}
                                onChange={(value) => {
                                  setValue('invitationDomain', value, { shouldValidate: true });
                                }}
                              />
                            </div>
                          </div>
                        )}
                    </div>
                  </div>
                  <div className={styles.actionBtnContainer} style={{ marginTop: '20px' }}>
                    <CustomCheckbox
                      label="Invite user"
                      checked={isSendingInvitation}
                      onChange={(e) => {
                        setIsSendingInvitation(e.target.checked);
                      }}
                      containerClassName={styles.checkboxContainer}
                    />
                    <div className={styles.btnContainer}>
                      <Button type="button" className={styles.cancel} onClick={onCancelEditProfile}>
                        Cancel
                      </Button>
                      <Button type="submit" className={styles.submit} disabled={isSavingProfile}>
                        Save
                      </Button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

Modal.setAppElement('#root');

export default AddPatientUserModal;

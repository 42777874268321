import { TimeSlot } from '@brands/Dashboard/PatientForms/PatientForm/utils/ProviderAvailabilityCard/Components/TimeSlotComponent';
import qs from 'qs';

import { client } from '../client';
import { Meta } from '../communication/types/Meta';
import { UserProfile } from './types/UserProfile';

export type ProviderFilterAvailability = 'CaseType.video_call_instant' | 'CaseType.one_report';
export interface IListAvailableProviderParams {
  date: string;
  tz: string;
  limit?: number;
  carrier_id?: number;
  language?: string[];
  sort_by?: 'full_name' | 'email' | 'status' | 'type' | 'created_at';
  sort_direction?: 'asc' | 'desc';
  cursor?: string;
  cursor_direction?: 'next' | 'previous';
  state?: string;
  synchronous?: boolean;
  asynchronous?: boolean;
  provider_id?: number;
  care_advisor_id?: number;
  patient_id?: number;
  license_states?: string[];
  out_of_network?: boolean;
  case_id?: number;
}
interface IListAvailableProvider {
  created_at: string;
  id: number;
  sync_time_slots: string[];
  sync_time_intervals: TimeSlot[];
  async_time_slots: string[];
  async_time_intervals: TimeSlot[];
  updated_at: string;
  user: UserProfile;
}

export interface IListAvailableProviders {
  nodes: IListAvailableProvider[];
  total: number;
  meta: Meta;
}

export const listAvailableProvidersDate = async (
  user_type: string,
  params?: IListAvailableProviderParams,
): Promise<IListAvailableProviders> => {
  const { data } = await client.get<IListAvailableProviders>(
    `/${user_type}/available?${qs.stringify(params, { skipNulls: true, arrayFormat: 'repeat' })}`,
  );

  return data;
};

/* eslint-disable react/no-array-index-key */
/* eslint-disable consistent-return */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-unsafe-optional-chaining */
import SmileScanMobile from '@brands/Dashboard/Dashboard/Patient/utils/smile_scan_mobile.svg';
import { useAppSelector } from '@brands/hooks/useReduxHook';
import { selectAuth } from '@brands/store/selectors/auth';
import { getSecondary } from '@brands/Utils/getPrimaryColor';
import { DebouncedFunc } from 'lodash';
import queryString from 'query-string';
import React, { Fragment, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { HiChevronDown, HiChevronUp } from 'react-icons/hi';
import { TiMessages } from 'react-icons/ti';
import Select, { CSSObjectWithLabel } from 'react-select';

import Button from '../../../../Components/Button/Button';
import { Loading } from '../../../../Components/LoadingSpinner/Loading';
import { useWindowSize } from '../../../../hooks';
import useSubscribe, { Subscriber } from '../../../../hooks/useSubscribe';
import { CaseType, ICase, Status } from '../../../../services/cases/types/ICase';
import { UserRoleName } from '../../../../services/identity/types/UserProfile';
import { calculateCaseDue, formatDateWithAddedDay } from '../../../../Utils/calculateCaseDue';
import { calculateDueDate } from '../../../../Utils/calculateDueDate';
import { selectSortByStyles } from '../../../../Utils/customStyles';
import { displayErrorDetails } from '../../../../Utils/displayError';
import { formatDate } from '../../../../Utils/formatDate';
import { getColorForStatus } from '../../../../Utils/getColorForStatus';
import { getFriendlyName } from '../../../../Utils/getFriendlyName';
import { getTextForStatus } from '../../../../Utils/getTextForStatus';
import { sortData, SortKeys, SortOrder } from '../../../../Utils/sortData';
import careCoordination from '../assets/careCoordination.svg';
import video from '../assets/liveVideo.svg';
import ohCoaching from '../assets/oralHealthCoaching.svg';
import report from '../assets/oralHealthReport.svg';
import phone from '../assets/phone.svg';
import sVideo from '../assets/scheduledVideo.svg';
import secondOpinion from '../assets/secondOpinion.svg';
import ProfileComponent from '../Component/ProfileComponent';
import styles from './openCases.module.scss';

type AllCasesProps = {
  acceptThisCase: (selectedCase: ICase) => void;
  setLoadingUntillAccept: (isLoading: boolean) => void;
  cases: ICase[];
  reloadAll: () => Promise<void>;
  loadNextPage: () => Promise<void>;
  dataLoading: boolean;
  searchByCaseId: DebouncedFunc<(caseId: number | undefined) => void>;
};

const OpenCases: React.FC<AllCasesProps> = ({
  acceptThisCase,
  setLoadingUntillAccept,
  cases,
  reloadAll,
  loadNextPage,
  dataLoading,
  searchByCaseId,
}): JSX.Element => {
  const screenSize = useWindowSize();
  const isMobile = screenSize.width < 768;
  const queryParams = useMemo(() => queryString.parse(location.search), [location.search]);
  const [query, setQuery] = useState<string>(queryParams.q ? (queryParams.q as string) : '');
  const [isLoading, setIsLoading] = useState(false);
  const observer = useRef<IntersectionObserver>();
  const lastOpenCasesItemRef = useRef<HTMLTableRowElement>(null);
  const [loadingStates, setLoadingStates] = useState<{ [key: number]: boolean }>({});
  const [sortKey, setSortKey] = useState<SortKeys>('created_at');
  const [sortOrder, setSortOrder] = useState<SortOrder>('desc');

  const { userInfo } = useAppSelector(selectAuth);
  const subscriber: Subscriber = useMemo(
    () => ({
      onMessage: (message: Record<string, any>) => {
        if (['case_assigned', 'case_created', 'case_updated', 'case_cancelled'].includes(message.command)) {
          reloadAll();
        }
      },
      commands: ['case_assigned', 'case_created', 'case_updated', 'case_cancelled'],
    }),
    [reloadAll],
  );
  const { subscribe, unsubscribe } = useSubscribe();

  useEffect(() => {
    const subscriptionId = subscribe(subscriber);

    return () => unsubscribe(subscriptionId);
  }, [subscribe, unsubscribe, subscriber]);

  const loadMore = (): void => {
    setIsLoading(true);
    loadNextPage().then(() => {
      setIsLoading(false);
    });
  };

  const handleIntersection = (entries: IntersectionObserverEntry[]): void => {
    const lastItem = entries[0];
    if (lastItem.isIntersecting && !isLoading) {
      loadMore();
    }
  };

  const handleAcceptClick = async (selectedCase: ICase): Promise<void> => {
    setIsLoading(true);
    setLoadingUntillAccept(true);
    setLoadingStates((prevLoadingStates) => ({
      ...prevLoadingStates,
      [selectedCase.id]: true,
    }));

    try {
      await acceptThisCase(selectedCase);
    } catch (error: unknown) {
      displayErrorDetails(error);
    }

    setLoadingStates((prevLoadingStates) => ({
      ...prevLoadingStates,
      [selectedCase.id]: false,
    }));
  };

  const selectSortByStylesCustomized = {
    ...selectSortByStyles,
    valueContainer: (provided: any) => ({
      ...provided,
      ...selectSortByStyles.valueContainer,
      height: 'auto',
      padding: '0',
    }),
    indicatorsContainer: () => ({ display: 'none' }),
    menu: (provided: CSSObjectWithLabel) => ({
      ...provided,
      width: '100%',
      paddingTop: '0px',
      paddingBottom: '0px',
      position: 'fixed' as const,
      left: '0',
      right: '0',
      top: '50px',
      zIndex: '9999',
      borderColor: getSecondary(),
    }),
  };

  useEffect(() => {
    if (observer.current) observer.current.disconnect();
    observer.current = new IntersectionObserver(handleIntersection);
    if (lastOpenCasesItemRef.current) observer.current.observe(lastOpenCasesItemRef.current);
  }, [cases]);

  const headers: { key: SortKeys; label: string; isSorted: boolean; sortDirection: 'ascn' | 'desc' }[] = [
    { key: 'created_at', label: 'Requested Date', isSorted: sortKey === 'created_at', sortDirection: sortOrder },
    { key: 'due_date', label: 'Due', isSorted: sortKey === 'due_date', sortDirection: sortOrder },
    { key: 'type', label: 'Consult Type', isSorted: sortKey === 'type', sortDirection: sortOrder },
    { key: 'id', label: 'Case ID', isSorted: sortKey === 'id', sortDirection: sortOrder },
    { key: 'patient', label: 'Patient', isSorted: sortKey === 'patient', sortDirection: sortOrder },
    { key: 'brandAssoc', label: 'Organization', isSorted: sortKey === 'brandAssoc', sortDirection: sortOrder },
    { key: 'markers', label: 'Client Tags', isSorted: sortKey === 'markers', sortDirection: sortOrder },
    { key: 'status', label: 'Status', isSorted: sortKey === 'status', sortDirection: sortOrder },
    { key: 'status', label: '', isSorted: sortKey === 'status', sortDirection: sortOrder },
  ];

  const [headersState, setHeadersState] = useState(headers);

  const sortByCCOpenCases: { value: SortKeys; label: string }[] = [
    { value: 'patient', label: 'Patient' },
    { value: 'brandAssoc', label: 'Organization' },
    { value: 'markers', label: 'Client Tags' },
    { value: 'type', label: 'Consult Type' },
    { value: 'id', label: 'Case ID' },
    { value: 'due_date', label: 'Due' },
    { value: 'status', label: 'Status' },
    { value: 'created_at', label: 'Requested Date' },
  ];

  const sortedData = useCallback(
    () => sortData({ tableData: cases, sortKey, reverse: sortOrder === 'desc', userRole: userInfo.role.name }),
    [cases, sortKey, sortOrder],
  );

  const changeSort = (key: SortKeys): void => {
    const selectedHeader = headers.find((header) => header.key === key);
    const headerIndex = headers.findIndex((header) => header.key === key);
    setSortOrder(selectedHeader?.sortDirection === 'ascn' ? 'desc' : 'ascn');
    setSortKey(key);
    const updatedHeadersState = headersState.map((header, index) => {
      if (index === headerIndex) {
        return {
          ...header,
          isSorted: true,
          sortDirection: selectedHeader?.sortDirection === 'ascn' ? 'desc' : ('ascn' as 'ascn' | 'desc'),
        };
      }
      return {
        ...header,
        isSorted: false,
      };
    });

    setHeadersState(updatedHeadersState);
  };

  const [hoverText, setHoverText] = useState(0);

  return (
    <>
      {isMobile && (
        <div className={styles.sortBy}>
          <span>Sort by</span>
          <div className={`${styles.wrapInput100}`} data-validate="Choose Sort by">
            <Select
              className="input100 p-0 select"
              styles={selectSortByStylesCustomized}
              placeholder={sortByCCOpenCases[0].label}
              onChange={(value) => changeSort(value?.value as SortKeys)}
              menuPortalTarget={document.body}
              options={sortByCCOpenCases}
            />
          </div>
          <span className={styles.sortIndicator}>{sortOrder === 'ascn' ? <HiChevronDown /> : <HiChevronUp />}</span>
        </div>
      )}
      <div className={styles.ccDashboardBody}>
        <div className={styles.filterContainer}>
          <div className={styles.searchBox}>
            <input
              type="number"
              className={styles.inputSearch}
              placeholder="Search by case ID"
              onChange={(e) => {
                const inputValue = e.target.value;
                setQuery(inputValue);
                if (inputValue === '') {
                  searchByCaseId(undefined);
                } else if (!isNaN(Number(inputValue))) {
                  searchByCaseId(Number(inputValue));
                }
              }}
              value={query}
            />
          </div>
        </div>
        {!dataLoading ? (
          cases.length > 0 ? (
            <>
              <table className={styles.openCasesTable}>
                <thead className={`${styles.providerListTableHeader} ${styles.dashboardHeaderName} ${styles.titles}`}>
                  <tr>
                    {headersState.map((row, index) => {
                      return (
                        <th key={`${row.key}-${index}`} onClick={() => changeSort(row.key)}>
                          <span className={`${sortKey === row.key ? styles.bold : ''}`}>
                            {row.label}
                            {row.isSorted && row.sortDirection === 'ascn' ? <HiChevronDown /> : <HiChevronUp />}
                          </span>
                        </th>
                      );
                    })}
                  </tr>
                </thead>
                <tbody>
                  {sortedData().map((caseRow, index) => {
                    return (
                      <Fragment key={`${caseRow.id}-${index}`}>
                        <tr className={`${styles.providerListTableBody}`}>
                          <td className={styles.mobileDue}>
                            <div className={`${styles.due}`}>
                              <span className={styles.mobileHeader}>Requested Date</span>
                              <span className={styles.mobileDueDate}>{formatDate(caseRow.created_at)}</span>
                            </div>
                          </td>
                          <td className={`${styles.mobileDue} ${styles.duaOnMobile}`}>
                            <div className={`${styles.due}`}>
                              <span className={styles.mobileHeader}>Due</span>
                              <span className={styles.mobileDueDate}>
                              {caseRow.type === CaseType.oral_health_coaching ||
                                caseRow.type === CaseType.phone_call_scheduled ||
                                caseRow.type === CaseType.video_call_scheduled
                                  ? formatDateWithAddedDay(caseRow?.video_conference_date || '', true)
                                  : calculateCaseDue(
                                      caseRow?.type || '',
                                      caseRow?.started_at || '',
                                      caseRow?.video_conference_date || '',
                                      true,
                                    )}
                              </span>
                            </div>
                          </td>
                          <td className={styles.mobileCType}>
                            <div className={`${styles.availability}`}>
                              <span className={styles.mobileHeader}>Type</span>
                              {caseRow.type === 'messaging' && (
                                <TiMessages
                                  onMouseEnter={() => setHoverText(caseRow.id)}
                                  onMouseLeave={() => setHoverText(0)}
                                />
                              )}
                              {caseRow.type === 'video_call_instant' && (
                                <img
                                  className={styles.video}
                                  src={video}
                                  alt="video"
                                  onMouseEnter={() => setHoverText(caseRow.id)}
                                  onMouseLeave={() => setHoverText(0)}
                                />
                              )}
                              {caseRow.type === CaseType.oral_health_coaching && (
                                <img
                                  className={styles.oralHealthCoachingIcon}
                                  src={ohCoaching}
                                  alt="ohCoaching"
                                  onMouseEnter={() => setHoverText(caseRow.id)}
                                  onMouseLeave={() => setHoverText(0)}
                                />
                              )}
                              {caseRow.type === CaseType.smile_scan && (
                                <img
                                  className={styles.smileScan}
                                  src={SmileScanMobile}
                                  alt="SmileScan"
                                  onMouseEnter={() => setHoverText(caseRow.id)}
                                  onMouseLeave={() => setHoverText(0)}
                                />
                              )}
                              {caseRow.type === 'video_call_scheduled' && (
                                <img
                                  className={styles.sVideo}
                                  src={sVideo}
                                  alt="video"
                                  onMouseEnter={() => setHoverText(caseRow.id)}
                                  onMouseLeave={() => setHoverText(0)}
                                />
                              )}
                              {caseRow.type === CaseType.phone_call_scheduled && (
                                <img
                                  className={styles.sVideo}
                                  src={phone}
                                  alt="Phone"
                                  onMouseEnter={() => setHoverText(caseRow.id)}
                                  onMouseLeave={() => setHoverText(0)}
                                />
                              )}
                              {caseRow.type === 'one_report' && (
                                <img
                                  className={styles.cReport}
                                  src={report}
                                  alt="video"
                                  onMouseEnter={() => setHoverText(caseRow.id)}
                                  onMouseLeave={() => setHoverText(0)}
                                />
                              )}
                              {caseRow.type === CaseType.care_coordination && (
                                <img
                                  className={styles.careCoordination}
                                  src={careCoordination}
                                  alt="video"
                                  onMouseEnter={() => setHoverText(caseRow.id)}
                                  onMouseLeave={() => setHoverText(0)}
                                />
                              )}
                              {caseRow.type === 'second_opinion' && (
                                <img
                                  className={styles.cReport}
                                  src={secondOpinion}
                                  alt="video"
                                  onMouseEnter={() => setHoverText(caseRow.id)}
                                  onMouseLeave={() => setHoverText(0)}
                                />
                              )}
                              {hoverText === caseRow.id && (
                                <div className={styles.hoverText}>{getFriendlyName(caseRow.type)}</div>
                              )}
                            </div>
                          </td>
                          <td className={`${styles.mobileDue} ${styles.caseIDMobile}`}>
                            <div className={`${styles.due}`}>
                              <span className={styles.mobileHeader}>Case ID</span>
                              <span className={styles.mobileDueDate}>{caseRow.id}</span>
                            </div>
                          </td>
                          <td className={styles.mobilePatient} aria-label="Patient">
                            <ProfileComponent caseRow={caseRow} role="Patient" />
                          </td>
                          <td className={styles.mobileBrand}>
                            <div>
                              <span className={styles.mobileHeader}>Organization</span>
                              <span style={{ whiteSpace: 'nowrap' }}>{caseRow.organization.name}</span>
                            </div>
                          </td>
                          <td className={styles.mobileTags}>
                            <div>
                              <span className={styles.mobileHeader}>Client Tags</span>
                              <span style={{ whiteSpace: 'nowrap' }}>
                                {caseRow.patient.markers?.map((marker) => marker.value).join(', ')}
                              </span>
                            </div>
                          </td>
                          <td className={styles.mobileStatus}>
                            <div>
                              <span className={styles.mobileHeader}>Status</span>
                              {(() => {
                                for (const key in caseRow) {
                                  if (key === 'status') {
                                    return (
                                      <span
                                        className={styles.statusSpan}
                                        style={{ backgroundColor: getColorForStatus(caseRow[key]) }}
                                      >
                                        {getTextForStatus(UserRoleName.CareAdvisor, caseRow.type, caseRow[key])}
                                      </span>
                                    );
                                  }
                                }
                              })()}
                            </div>
                          </td>
                          <td className={styles.mobileBtn}>
                            {caseRow.type !== CaseType.smile_scan && (
                              <div className={`${styles.viewBtn}`}>
                                <Button
                                  type="button"
                                  disabled={loadingStates[caseRow.id]}
                                  onClick={() => handleAcceptClick(caseRow)}
                                >
                                  Accept
                                </Button>
                              </div>
                            )}
                          </td>
                        </tr>
                        {caseRow.type === 'video_call_scheduled' &&
                        caseRow.video_conference_date &&
                        calculateDueDate(caseRow.video_conference_date) ? (
                          <tr className={styles.startTime}>
                            <div>
                              <span>
                                Consult starts in{' '}
                                <span style={{ fontWeight: 'bold' }}>
                                  {calculateDueDate(caseRow.video_conference_date)}
                                </span>
                              </span>
                            </div>
                          </tr>
                        ) : (
                          <tr className={styles.noStartTime}>
                            <div>
                              <span />
                            </div>
                          </tr>
                        )}
                      </Fragment>
                    );
                  })}
                  <tr className={styles.lastItemRef} ref={lastOpenCasesItemRef} />
                </tbody>
              </table>
              {isLoading && <Loading dotted />}
            </>
          ) : (
            <span className={styles.noData}>No data to display</span>
          )
        ) : (
          <span>
            <Loading dotted />
          </span>
        )}
      </div>
    </>
  );
};
export default OpenCases;

/* eslint-disable max-len */
import { ProviderCheckboxes } from './types';

export const Checkboxes: ProviderCheckboxes[] = [
  {
    value: 'checkbox1',
    label:
      'Provider identity, jurisdiction, and licensure status reviewed in my profile page and made visible to the patient in the provider card',
    isSelected: false,
  },
  {
    value: 'checkbox2',
    label: 'Patient full name, address, and birth date verified in the patient information section on this page',
    isSelected: false,
  },
  {
    value: 'checkbox3',
    label:
      'Patient location, available in the location field in the patient information section on this page, verified to be within provider’s licensure jurisdiction',
    isSelected: false,
  },
  {
    value: 'checkbox4',
    label:
      'Informed consent obtained verbally from the patient/guardian present OR Dentistry.One platform was utilized, and consent was received upon entering the platform      ',
    isSelected: false,
  },
  {
    value: 'checkbox5',
    label:
      'Procedures, Alternatives, Risks discussed, and Questions answered with the patient verbally OR in writing within the Dentistry.One platform',
    isSelected: false,
  },
  {
    value: 'checkbox6',
    label: 'Medical History reviewed with the patient/guardian and any necessary updates added',
    isSelected: false,
  },
];

export const consultationFormDefaultValues = {
  isAdditionalPeople: false,
  additionalPeople: '',
  checkboxes: Checkboxes,
  selectedImages: [],
  cdt: [],
  assessment: '',
  subjective: {
    chief_complaint_problem: '',
    pain_scale: '',
    medical_history: '',
  },
  plan: {
    disposition: '',
    prescription_in_icore: '',
    pharmacy: '',
    need_for_oral_health_coaching: '',
  },
  objective: {
    is_media_utilized: '',
    is_intra_oral_utilized: '',
    is_extra_oral_utilized: '',
  },
  treatments: [],
  prescriptions: [],
  followUp: {
    care_coordination: false,
    oral_health_coaching: false,

    care_coordination_type: '',
    care_coordination_urgency: '',
    care_coordination_reason: '',

    oral_health_coaching_type: '',
    oral_health_coaching_urgency: '',
    oral_health_coaching_reason: '',
  },
  educations: [],
  careCoordinationFindings: {
    benefits: '',
    scheduled_dental_appointment: '',
  },
  caseNotes: '',
  additionalCondition: [
    {
      label: '',
      value: '',
    },
  ],
  additionalInfo: [
    {
      label: '',
      value: '',
    },
  ],
  pharmacies: {
    pharmacy_name: '',
    pharmacy_street_address: '',
    pharmacy_city: '',
    pharmacy_state: '',
    pharmacy_zip_code: '',
    pharmacy_phone_number: '',
  },
  oralHealthCoachingInternalNote: '',
  care_plan: {
    consultation_summary: '',
    goals: [
      {
        id: 0,
        goal_text: '',
        educations: [],
      },
    ],
  },
};

export const editImageNoteDefaultValues = {
  isOpen: false,
  media_id: '',
  media_src: '',
  oneReportId: '',
  readOnly: false,
};

export const removeImageDefaultValues = {
  isOpen: false,
  media_id: '',
  media_src: '',
  oneReportId: '',
};

export const removeAttachmentDefaultValues = {
  isOpen: false,
  media_id: '',
  media_name: '',
  oneReportId: '',
};

export const unassignProviderModalDefaultValues = {
  isOpen: false,
  caseId: 0,
  providerName: '',
};

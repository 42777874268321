/* eslint-disable react/no-array-index-key */
/* eslint-disable no-nested-ternary */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable new-cap */
/* eslint-disable react/no-unstable-nested-components */
import careCoordination from '@brands/Dashboard/Dashboard/CareAdvisor/assets/careCoordination.svg';
import { isAlmostEqualWithTolerance } from '@brands/Dashboard/MyProfile/Patient/utils/utilsFunctions';
import { getForm } from '@brands/services/forms/getForm';
import { FormQuestion } from '@brands/services/forms/types/FormQuestion';
import { IEducation } from '@brands/services/oneReport/types/IEducation';
import { selectAuth } from '@brands/store/selectors/auth';
import {
  careCoordinationTypeOptions,
  careCoordinationUrgencyOptions,
  oneReportTreatmentsCategory,
  oneReportTreatmentsToothNumber,
  oneReportTreatmentsUrgency,
  oralHealthCoachingTypeOptions,
} from '@brands/Utils/selectOptions';
import * as html2pdf from 'html2pdf.js';
import { parsePhoneNumber } from 'libphonenumber-js';
import moment from 'moment';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { IoClose, IoDownloadOutline } from 'react-icons/io5';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import ReactToPrint from 'react-to-print';

import Button from '../../../Components/Button/Button';
import { Loading } from '../../../Components/LoadingSpinner/Loading';
import { useAppSelector } from '../../../hooks/useReduxHook';
import { getOneCase } from '../../../services/cases/getCaseById';
import { defaultICaseValues } from '../../../services/cases/types/defaultICaseValues';
import { CaseType, ICase } from '../../../services/cases/types/ICase';
import { getFormAnswer } from '../../../services/forms/getFormAnswer';
import { listForms } from '../../../services/forms/listForms';
import { ImageWithNotes } from '../../../services/forms/types/ImageWithNotes';
import { getUserAvatar } from '../../../services/identity/getUserAvatar';
import { License, UserRoleName } from '../../../services/identity/types/UserProfile';
import { getOneReportMedia } from '../../../services/oneReport/getOneReportMedia';
import { IOneReportMedia } from '../../../services/oneReport/types/IOneReportMedia';
import { getMyTags } from '../../../services/tags/getMyTags';
import { getTagsByUserId } from '../../../services/tags/getTagsByUserId';
import { formatDate } from '../../../Utils/formatDate';
import { getFriendlyName, getPainTypeText } from '../../../Utils/getFriendlyName';
import { mapPainLevelLabel } from '../../../Utils/mapPainLevelLabel';
import Logo from '../../Appointments/assets/oHRLogo.svg';
import printer from '../../Appointments/assets/printer.svg';
import video from '../../Dashboard/CareAdvisor/assets/liveVideo.svg';
import phone from '../../Dashboard/CareAdvisor/assets/phone.svg';
import photos from '../../Dashboard/CareAdvisor/assets/photos.svg';
import sVideo from '../../Dashboard/CareAdvisor/assets/scheduledVideo.svg';
import secondOpinion from '../../Dashboard/CareAdvisor/assets/secondOpinion.svg';
import styles from './oralHealthReport.module.scss';

interface Option {
  readonly label: string;
  readonly value: string;
  readonly isNew?: boolean;
}

const OralHealthReportComponent = (): JSX.Element => {
  const [loading, setLoading] = useState(false);
  const routeCaseId = useParams().caseId as string;
  const [thisCase, setCase] = useState<ICase>(defaultICaseValues);
  const [profileCardTags, setProfileCardTags] = React.useState<Option[]>([]);
  const { userInfo } = useAppSelector(selectAuth);

  const navigate = useNavigate();
  const location = useLocation();

  const goToPreviousPage = useCallback(() => {
    if (location.key === 'default') {
      navigate('/appointments');
    } else {
      navigate(-1);
    }
  }, [navigate]);

  const getCurrentCase = async (caseId: string): Promise<void> => {
    setLoading(true);
    const currentCase = await getOneCase(Number(caseId));
    setCase(currentCase);
    setLoading(false);
  };

  const licenseNumberDisplay = useMemo(() => {
    if (thisCase.provider?.provider_profile?.licenses && thisCase.provider?.provider_profile?.licenses.length > 0) {
      return thisCase.provider.provider_profile.licenses.find(
        (license: License) => license.state === thisCase.patient_current_state,
      )?.number;
    }
    return ' ';
  }, [thisCase]);

  const providerNPIDisplay = useMemo(() => {
    if (thisCase.provider?.provider_profile?.national_provider_id) {
      return thisCase.provider.provider_profile?.national_provider_id;
    }
    return ' ';
  }, [thisCase]);

  useEffect(() => {
    const fetchUserTags = async (): Promise<void> => {
      if (thisCase?.patient_id) {
        const response =
          userInfo.role.name === UserRoleName.Patient
            ? await getMyTags(thisCase?.patient?.role?.name === 'Dependent' ? thisCase?.patient?.id : undefined)
            : await getTagsByUserId(
                thisCase?.patient?.role?.name === 'Dependent' ? thisCase?.patient?.id : thisCase?.patient_id,
              );
        const options: Option[] = response
          .filter((tag: any) => tag.answer)
          .map((item: any) => ({
            value: item.tag.description,
            label: item.note === 'yes' ? item.tag.name : item.note,
          }));
        setProfileCardTags(options);
      }
    };
    fetchUserTags();
  }, [thisCase?.patient_id]);

  useEffect(() => {
    getCurrentCase(routeCaseId);
  }, [routeCaseId]);

  const componentRef = useRef<HTMLDivElement>(null);

  const [images, setImages] = useState<ImageWithNotes[]>([]);
  const loadImages = async (): Promise<void> => {
    if (thisCase?.one_report.media && thisCase?.one_report.media.length > 0) {
      // const imagePromises = thisCase.one_report.media.map(async (mediaOneReport: IOneReportMedia) => {
      const imagePromises = thisCase?.one_report.media.map(async (mediaOneReport: IOneReportMedia) => {
        const mediaData = await getOneReportMedia(mediaOneReport.media_id!);
        return {
          src: mediaData.url,
          width: 4,
          height: 3,
          notes: mediaOneReport.note,
          isSelected: mediaOneReport.is_selected,
          created_at: mediaOneReport.created_at,
        };
      });
      setImages(await Promise.all(imagePromises));
    }
  };

  const [medications, setMedications] = useState<string>('');
  const [allergies, setAllergies] = useState<string>('');

  const [pharmacyName, setPharmacyName] = useState<string>('');
  const [pharmacyAddress, setPharmacyAddress] = useState<string>('');
  const [pharmacyPhoneNumber, setPharmacyPhoneNumber] = useState<string>('');

  const findQuestionIdByTitle = (questions: FormQuestion[], title: string): number | undefined => {
    const question = questions.find((q) => q.title === title);
    return question ? question.id : undefined;
  };

  const displayPharmacyPhoneNumber = (pharmacyPhoneNum: string): string => {
    if (!pharmacyPhoneNum) {
      return '';
    }
    const phoneNumber = parsePhoneNumber(pharmacyPhoneNum, 'US');
    if (phoneNumber) {
      return phoneNumber.formatNational();
    }
    return '';
  };

  const getFormAnswers = async (): Promise<void> => {
    const { forms: formList } = await listForms();
    const formId = formList.find((form) => form.title === 'Patient Medical Information Form')?.id ?? 0;
    if (thisCase?.patient.id) {
      const formAnswers = await getFormAnswer({ form_id: formId, created_by_id: thisCase?.patient.id });

      const newFormId = formList.find((form) => form.title === 'Patient Medical Information Form')?.id ?? 0;
      const { steps } = await getForm(newFormId.toString());
      const { questions } = steps[0];
      const medicationsQuestionId = findQuestionIdByTitle(
        questions,
        'Are you taking, have you recently taken (within the last month), or are you supposed to be taking any medications? (Prescription, over-the-counter, diet supplements, vitamins, natural, or herbal)',
      );
      const allergiesQuestionId = findQuestionIdByTitle(
        questions,
        'Are you allergic to any medications, metal, latex, or certain materials?',
      );
      const pharmacyNameQuestionId = findQuestionIdByTitle(questions, 'Pharmacy Name');
      const pharmacyStreetAddressQuestionId = findQuestionIdByTitle(questions, 'Pharmacy Street Address');
      const pharmacyCityQuestionId = findQuestionIdByTitle(questions, 'Pharmacy City');
      const pharmacyStateQuestionId = findQuestionIdByTitle(questions, 'Pharmacy State');
      const pharmacyZipCodeQuestionId = findQuestionIdByTitle(questions, 'Pharmacy Zip Code');
      const pharmacyPhoneNumberQuestionId = findQuestionIdByTitle(questions, 'Pharmacy Phone Number');

      const sortedFormAnswers = Object.values(formAnswers.form_answers).sort((a, b) => a.id - b.id);
      const medicationFormAnswer = sortedFormAnswers.find((answer) =>
        answer.values.some((value) => value.form_question_id === medicationsQuestionId),
      );

      setMedications(
        medicationFormAnswer?.values.find((value) => value.form_question_id === medicationsQuestionId)?.value.data ||
          '',
      );
      setAllergies(
        medicationFormAnswer?.values.find((value) => value.form_question_id === allergiesQuestionId)?.value.data || '',
      );
      setPharmacyName(
        medicationFormAnswer?.values.find((value) => value.form_question_id === pharmacyNameQuestionId)?.value.data ||
          '',
      );
      const pharmacyAddressValues = [
        medicationFormAnswer?.values.find((value) => value.form_question_id === pharmacyStreetAddressQuestionId)?.value
          .data || '',
        medicationFormAnswer?.values.find((value) => value.form_question_id === pharmacyCityQuestionId)?.value.data ||
          '',
        medicationFormAnswer?.values.find((value) => value.form_question_id === pharmacyStateQuestionId)?.value.data ||
          '',
        medicationFormAnswer?.values.find((value) => value.form_question_id === pharmacyZipCodeQuestionId)?.value
          .data || '',
      ];

      const filteredPharmacyAddressValues = pharmacyAddressValues.filter(
        (value) => value !== undefined && value !== null && value !== '',
      );

      setPharmacyAddress(filteredPharmacyAddressValues.join(', '));
      setPharmacyPhoneNumber(
        displayPharmacyPhoneNumber(
          medicationFormAnswer?.values.find((value) => value.form_question_id === pharmacyPhoneNumberQuestionId)?.value
            .data || '',
        ),
      );
    }
  };

  const downloadModal = (): void => {
    if (componentRef && componentRef.current) {
      const element = componentRef.current as HTMLElement;
      const options = {
        filename: 'OralHealthReport.pdf',
        image: { type: 'jpeg', quality: 0.98 },
        html2canvas: { scale: 2, useCORS: true },
        jsPDF: { unit: 'pt', format: 'a4', orientation: 'portrait' },
        pagebreak: { mode: ['avoid', 'css', 'legacy'], before: '.page-break', avoid: 'image' },
      };

      // Wait for all images to load
      const selectedImages = element.getElementsByTagName('img');
      const numImages = selectedImages.length;
      let imagesLoaded = 0;
      const checkImagesLoaded = (): void => {
        imagesLoaded++;
        if (imagesLoaded === numImages) {
          // All images are loaded, generate the PDF
          html2pdf().set(options).from(element).save();
        }
      };
      for (let i = 0; i < numImages; i++) {
        const image = selectedImages[i];
        if (!image.complete) {
          // Image is not yet loaded, add a load event listener
          image.addEventListener('load', checkImagesLoaded);
        } else {
          // Image is already loaded, increment the loaded count
          checkImagesLoaded();
        }
      }
    }
  };

  useEffect(() => {
    loadImages();
    getFormAnswers();
  }, [thisCase]);
  if (!thisCase || loading) return <Loading fullScreen />;

  return (
    <div className={styles.modalBackground}>
      <div>
        <div className={styles.modalCloseBtn} onClick={goToPreviousPage}>
          <span>Close</span>
          <div className={styles.circle}>
            <IoClose />
          </div>
        </div>
        <ReactToPrint
          trigger={() => {
            return (
              <Button type="button" className={styles.print}>
                <img src={printer} alt="Print" />
                Print
              </Button>
            );
          }}
          content={() => componentRef.current}
        />
        <div className={styles.download}>
          <Button type="button" className={styles.print} onClick={downloadModal}>
            <IoDownloadOutline />
            Download
          </Button>
        </div>
      </div>
      <div className={styles.modalDiv}>
        <div className={styles.modalContainer}>
          <div className={styles.printableDiv} ref={componentRef}>
            <div className={`${styles.underLinedDiv} ${styles.header}`}>
              <div className={styles.logo}>
                <img crossOrigin="anonymous" className={styles.logo} src={Logo} alt="Logo" />
              </div>
              <span>Oral Health Report</span>
            </div>
            <div className={`row ${styles.infoDiv} ${styles.underLinedDiv}`}>
              <div className={`col-md-7 ${styles.rightBorder} ${styles.leftSide} pl-0`}>
                <div className={styles.underLinedDiv}>
                  <div className={`${styles.patientInfo}`}>
                    <img
                      alt="Avatar"
                      onError={(event: any) => {
                        const img = event.target;
                        img.src =
                          'https://media.istockphoto.com/id/1130884625/tr/vekt%C3%B6r/kullan%C4%B1c%C4%B1-%C3%BCyesi-vekt%C3%B6r-simgesi-ui-kullan%C4%B1c%C4%B1-arabirimi-veya-profil-face-avatar-uygulamas%C4%B1.jpg?s=612x612&w=0&k=20&c=jAf5nq1ebnZo8TJbjaB9dMHMvgff7uOk67NkF5CpgB0=';
                        img.onError = null;
                      }}
                      className={styles.providerImage}
                      src={getUserAvatar(Number(thisCase?.patient_id))}
                    />
                    <div className={styles.wrapDiv}>
                      <div className={styles.mobileHeader}>Patient</div>
                      <span className={styles.patientName}>{thisCase.patient.full_name}</span>
                    </div>
                  </div>
                  <div className={styles.info}>
                    <div>
                      <span>DOB:</span>
                      <span> {moment(thisCase?.patient?.basic_info?.date_of_birth).format('MM/DD/YYYY')}</span>
                    </div>
                    <div>
                      <span>Insurance:</span>
                      <span>{thisCase?.insurance?.insurance_membership?.insurance_carrier?.name}</span>
                    </div>
                  </div>
                </div>
                <div className={styles.moreInfo}>
                  <div>
                    <span>Medical Information</span>
                    <span>{profileCardTags.map((tag) => tag.label).join(', ')}</span>
                  </div>
                  <div>
                    <div>
                      <span>Medications</span>
                      <span>{medications}</span>
                    </div>
                    <div>
                      <span>Allergies</span>
                      <span>{allergies}</span>
                    </div>
                  </div>
                </div>
                {((pharmacyName !== '' && pharmacyName !== 'no') ||
                  (pharmacyAddress !== '' && !pharmacyAddress.includes('no')) ||
                  (pharmacyPhoneNumber !== '' && pharmacyPhoneNumber !== 'no')) && (
                  <div className={styles.pharmacyDiv}>
                    <div className={styles.pharmacyTitle}>Pharmacy</div>
                    <div className={styles.pharmacyDetails}>
                      {pharmacyName !== '' && <span>{pharmacyName}</span>}
                      {pharmacyAddress !== '' && <span>{pharmacyAddress}</span>}
                      {pharmacyPhoneNumber !== '' && <span>{pharmacyPhoneNumber}</span>}
                    </div>
                  </div>
                )}
              </div>
              <div className={`col-md-5 ${styles.rightSec}`}>
                <div className={styles.underLinedDiv}>
                  <div className={`${styles.patientInfo}`}>
                    <div className={styles.providerWrap}>
                      <div className={styles.providerInfo}>
                        <img
                          alt="Avatar"
                          onError={(event: any) => {
                            const img = event.target;
                            img.src =
                              'https://media.istockphoto.com/id/1130884625/tr/vekt%C3%B6r/kullan%C4%B1c%C4%B1-%C3%BCyesi-vekt%C3%B6r-simgesi-ui-kullan%C4%B1c%C4%B1-arabirimi-veya-profil-face-avatar-uygulamas%C4%B1.jpg?s=612x612&w=0&k=20&c=jAf5nq1ebnZo8TJbjaB9dMHMvgff7uOk67NkF5CpgB0=';
                            img.onError = null;
                          }}
                          className={styles.providerImage}
                          src={getUserAvatar(Number(thisCase?.provider_id))}
                        />
                        <div className={styles.wrapDiv}>
                          <div className={styles.mobileHeader}>Provider</div>
                          <span className={styles.patientName}>
                            {thisCase?.provider?.full_name}
                            {thisCase?.provider?.provider_profile?.credentials ? ', ' : ''}
                            {thisCase?.provider?.provider_profile?.credentials || ''}
                          </span>
                        </div>
                      </div>
                      <div className={styles.providerDetails}>
                        <div className={styles.readOnlyItem}>
                          <div className={styles.caption}>License #</div>
                          <div className={styles.value}>{licenseNumberDisplay}</div>
                        </div>
                        <div className={styles.readOnlyItem}>
                          <div className={styles.caption}>Provider NPI #</div>
                          <div className={styles.value}>{providerNPIDisplay}</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className={`${styles.underLinedDiv} ${styles.cc}`}>
                  <div className={`${styles.patientInfo}`}>
                    <img
                      alt="Avatar"
                      onError={(event: any) => {
                        const img = event.target;
                        img.src =
                          'https://media.istockphoto.com/id/1130884625/tr/vekt%C3%B6r/kullan%C4%B1c%C4%B1-%C3%BCyesi-vekt%C3%B6r-simgesi-ui-kullan%C4%B1c%C4%B1-arabirimi-veya-profil-face-avatar-uygulamas%C4%B1.jpg?s=612x612&w=0&k=20&c=jAf5nq1ebnZo8TJbjaB9dMHMvgff7uOk67NkF5CpgB0=';
                        img.onError = null;
                      }}
                      className={styles.providerImage}
                      src={getUserAvatar(Number(thisCase?.care_advisor_id))}
                    />
                    <div className={styles.wrapDiv}>
                      <div className={styles.mobileHeader}>Care Advisor</div>
                      <span className={styles.patientName}>{thisCase?.care_advisor?.full_name}</span>
                    </div>
                  </div>
                </div>
                <div className={styles.moreInfoDet}>
                  <div>
                    <span>Consult Type</span>
                    <span>
                      {thisCase.type === 'video_call_instant' && (
                        <img className={styles.video} src={video} alt="video" />
                      )}
                      {thisCase?.type === 'video_call_scheduled' && (
                        <img className={styles.sVideo} src={sVideo} alt="video" />
                      )}
                      {thisCase?.type === CaseType.phone_call_scheduled && (
                        <img className={styles.sVideo} src={phone} alt="Phone" />
                      )}
                      {thisCase.type === 'one_report' && <img className={styles.cReport} src={photos} alt="video" />}
                      {thisCase.type === 'second_opinion' && (
                        <img className={styles.cReport} src={secondOpinion} alt="secondOpinion" />
                      )}
                      {thisCase.type === CaseType.care_coordination && (
                        <img className={styles.careCoordination} src={careCoordination} alt="video" />
                      )}
                      <span className={styles.typeName}>{getFriendlyName(thisCase.type)}</span>
                    </span>
                  </div>
                  <div>
                    <span>Service Requested</span>
                    <span>{formatDate(thisCase?.created_at || '')}</span>
                  </div>
                  {thisCase.type !== 'one_report' && thisCase.type !== 'second_opinion' && (
                    <div>
                      <span>Video Consultation</span>
                      <span>
                        {thisCase?.type === 'video_call_scheduled' || thisCase.type === CaseType.phone_call_scheduled
                          ? formatDate(thisCase?.video_conference_date || '')
                          : formatDate(thisCase?.created_at || '')}
                      </span>
                    </div>
                  )}
                  <div>
                    <span>Report Completed</span>
                    <span>{formatDate(thisCase.completed_at || thisCase.updated_at || '')}</span>
                  </div>
                </div>
              </div>
            </div>
            <div className={`row ${styles.underLinedDiv} ${styles.flexDiv}`}>
              <div className={styles.detailsWrapper}>
                <div className="pl-0">
                  <span>Emergency Case</span>
                  <p>{thisCase?.purpose && thisCase?.purpose[0]?.name === 'dental_emergency' ? 'Yes' : 'No'}</p>
                </div>
                <div>
                  <span>Pain Level</span>
                  <p className={styles.painLevel}>{mapPainLevelLabel(thisCase?.pain_level || '')}</p>
                </div>
                <div>
                  <span>Time in Pain</span>
                  <p className={styles.painLevel}>{thisCase?.pain_time || '-'}</p>
                </div>
                <div>
                  <span>Pain Type</span>
                  <p className={styles.painLevel}>
                    {thisCase?.pain_description?.map((pain) => getPainTypeText(pain)).join(', ') || '-'}
                  </p>
                </div>
              </div>
              <div className={styles.comments}>
                <span className={styles.headerComment}>Other Comments to Provider</span>
                <div className={`${styles.commentsContent} ${styles.additionalInfoDiv}`}>
                  {thisCase?.additional_info
                    ? thisCase.additional_info.map((info, index) => (
                        <div key={index} className={styles.additionalInfoDiv}>
                          {info.created_at !== thisCase.created_at &&
                            !isAlmostEqualWithTolerance(info.created_at, thisCase.created_at) && (
                              <span className={styles.infoDate}>{formatDate(info.created_at)}</span>
                            )}
                          <span>{info.additional_info}</span>
                        </div>
                      ))
                    : '-'}
                </div>
              </div>
              <div className={styles.comments}>
                <span className={styles.headerComment}>
                  Additional Comments from Provider on Patient’s Chief Complaint/Problem
                </span>
                <div className={`${styles.commentsContent} ${styles.additionalInfoDiv}`}>
                  <span>{thisCase.one_report.medical_history}</span>
                </div>
              </div>
            </div>
            <div className={`row ${styles.underLinedDiv} page-break`}>
              <div className={styles.assessment}>
                <span className={styles.headerComment}>Assessment</span>
                <p style={{ whiteSpace: 'pre-line' }}>{thisCase?.one_report?.assessments}</p>
                <br />
                <span className={styles.headerComment}>CDT codes:</span>
                {thisCase?.one_report?.cdts.map((cdt) => (
                  <p>
                    <span>{cdt.code}: </span>
                    <span>{cdt.nomenclature}</span>
                  </p>
                ))}
                {(thisCase.one_report?.disposition ||
                  thisCase.one_report?.prescriptions.length > 0 ||
                  thisCase.one_report?.pharmacies ||
                  thisCase.one_report?.follow_ups) && (
                  <>
                    <span className={styles.headerComment}>Plan</span>
                    {thisCase.one_report?.disposition && (
                      <div className={styles.planInfo}>
                        <span className={styles.planHeader}>Disposition/Intervention or recommendations provided:</span>
                        <p className={styles.planValue}>{thisCase.one_report?.disposition}</p>
                      </div>
                    )}
                    {thisCase.one_report?.prescriptions.length > 0 && (
                      <>
                        <span className={styles.headerComment}>Prescriptions</span>
                        <div className={styles.planInfo}>
                          {thisCase.one_report?.prescriptions.map((prescription) => (
                            <div style={{ marginBottom: '15px' }}>
                              <div className={styles.prescriptionInfo}>
                                <span className={styles.planHeader}>Drug Name:</span>
                                <p className={styles.planValue}>{prescription.drug}</p>
                              </div>

                              <div className={styles.prescriptionInfo}>
                                <p className={styles.planValue}>{prescription.sig}</p>
                              </div>

                              <div className={styles.prescriptionInfo}>
                                <span className={styles.planHeader}>Prescription Date:</span>
                                <p className={styles.planValue}>{prescription.prescription_date}</p>
                              </div>

                              <div className={styles.prescriptionInfo}>
                                <span className={styles.planHeader}>Dispense:</span>
                                <p className={styles.planValue}>{prescription.dispense}</p>
                              </div>

                              <div className={styles.prescriptionInfo}>
                                <span className={styles.planHeader}>Refills:</span>
                                <p className={styles.planValue}>{prescription.refills}</p>
                              </div>
                            </div>
                          ))}
                        </div>
                      </>
                    )}

                    {thisCase.one_report?.pharmacies && (
                      <div className={styles.pharmacyDiv}>
                        <div className={styles.pharmacyTitle}>Pharmacy</div>
                        <div className={styles.pharmacyDetails}>
                          <span>{thisCase.one_report?.pharmacies.pharmacy_name}</span>
                          <span>{`${thisCase.one_report.pharmacies.pharmacy_street_address}, ${thisCase.one_report.pharmacies.pharmacy_city}, 
                            ${thisCase.one_report.pharmacies.pharmacy_state}, ${thisCase.one_report.pharmacies.pharmacy_zip_code}`}</span>
                          <span>
                            {displayPharmacyPhoneNumber(thisCase.one_report.pharmacies.pharmacy_phone_number)}
                          </span>
                        </div>
                      </div>
                    )}
                    {thisCase.one_report?.follow_ups && (
                      <div className={styles.pharmacyDiv}>
                        <div className={styles.pharmacyTitle} style={{ marginTop: '15px' }}>
                          Follow-up Care
                        </div>
                        {thisCase.one_report.follow_ups.care_coordination && (
                          <>
                            <span className={styles.headerComment}>Care Coordination</span>
                            <div className={styles.pharmacyDetails}>
                              <span>
                                {careCoordinationTypeOptions.find(
                                  (careCoordinationTypeOption) =>
                                    careCoordinationTypeOption.value ===
                                    thisCase.one_report?.follow_ups.care_coordination_type,
                                )?.label || thisCase.one_report?.follow_ups.care_coordination_type}
                              </span>
                              <span>
                                {
                                  careCoordinationUrgencyOptions.find(
                                    (careCoordinationUrgencyOption) =>
                                      careCoordinationUrgencyOption.value ===
                                      thisCase.one_report?.follow_ups.care_coordination_urgency,
                                  )?.label
                                }
                              </span>
                              <span>{thisCase.one_report?.follow_ups.care_coordination_reason}</span>
                            </div>
                          </>
                        )}
                        <br />
                        {thisCase.one_report.follow_ups.oral_health_coaching && (
                          <>
                            <span className={styles.headerComment}>Oral Health Coaching</span>
                            <div className={styles.pharmacyDetails}>
                              <span>
                                {oralHealthCoachingTypeOptions.find(
                                  (oralHealthCoachingTypeOption) =>
                                    oralHealthCoachingTypeOption.value ===
                                    thisCase.one_report?.follow_ups.oral_health_coaching_type,
                                )?.label || thisCase.one_report?.follow_ups.oral_health_coaching_type}
                              </span>
                              <span>
                                {
                                  careCoordinationUrgencyOptions.find(
                                    (careCoordinationUrgencyOption) =>
                                      careCoordinationUrgencyOption.value ===
                                      thisCase.one_report?.follow_ups.oral_health_coaching_urgency,
                                  )?.label
                                }
                              </span>
                              <span>{thisCase.one_report?.follow_ups.oral_health_coaching_reason}</span>
                            </div>
                          </>
                        )}
                      </div>
                    )}
                  </>
                )}
                <div className={styles.imgGrid}>
                  {images.map(
                    (media) =>
                      media.isSelected && (
                        <div className={styles.flexContainer}>
                          {media.created_at && !isAlmostEqualWithTolerance(media.created_at, thisCase.created_at) ? (
                            <div className={styles.imageDate}>{formatDate(media.created_at)}</div>
                          ) : (
                            <div className={styles.imageDate} />
                          )}
                          <div className={styles.imgContainer}>
                            <img
                              crossOrigin="anonymous"
                              alt="Avatar"
                              onError={(event: any) => {
                                const img = event.target;
                                img.src = media.src;
                                img.onError = null;
                              }}
                              className={styles.patientImage}
                              src={media.src}
                            />
                          </div>
                          <p>{media.notes}</p>
                        </div>
                      ),
                  )}
                </div>
                <div>
                  <span className={styles.headerComment}>Treatment</span>
                  {thisCase?.one_report?.treatments?.map((treatment) => (
                    <div className={styles.treatment}>
                      <div className={styles.treatmentWrap}>
                        <div>
                          <span>Category</span>
                          <span>
                            {
                              oneReportTreatmentsCategory.find((category) => category.value === treatment.category)
                                ?.label
                            }
                          </span>
                        </div>
                        <div>
                          <span>Tooth # / Area</span>
                          <span>
                            {
                              oneReportTreatmentsToothNumber.find(
                                (toothNumber) => toothNumber.value === treatment.tooth_number,
                              )?.label
                            }
                          </span>
                        </div>
                        <div>
                          <span>Urgency</span>
                          <span>
                            {oneReportTreatmentsUrgency.find((urgency) => urgency.value === treatment.urgency)?.label}
                          </span>
                        </div>
                      </div>
                      <div className={styles.description}>
                        <span>Description</span>
                        <p>{treatment.description}</p>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
            <div className={`row ${styles.underLinedDiv}`}>
              <div className={styles.eduResources}>
                <span>Educational Resources</span>
                {thisCase?.one_report?.educations
                  ?.sort((a: IEducation, b: IEducation) => Number(a.id) - Number(b.id))
                  .map((education) => (
                    <div key={education.id}>
                      <span>{education.title}</span>
                      <a href={education.url}>{education.url}</a>
                    </div>
                  ))}
              </div>
            </div>
            <div className={`row ${styles.footer}`}>
              <div className={styles.footerLogoDiv}>
                <img crossOrigin="anonymous" className={styles.footerLogo} src={Logo} alt="Logo" />
              </div>
              <div>DISCLAIMER</div>
              <p>
                This platform connects users with dental providers, but does not provide dental care or services
                directly. Users establish relationships with individual providers, who are solely responsible for the
                advice and treatment they provide. The platform does not control the advice given by providers, and
                users are responsible for their own dental health data and for following their provider's
                recommendations.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default OralHealthReportComponent;

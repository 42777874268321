import { defaultFormValues } from '@brands/Dashboard/MyProfile/Patient/utils/defaultFormValues';
import { MyProfileSubmit } from '@brands/Dashboard/MyProfile/Patient/utils/types';
import { Insurance, UserProfile } from '@brands/services/identity/types/UserProfile';
import { createAction } from '@reduxjs/toolkit';
import moment from 'moment';

export const fetchPatientMyProfileStart = createAction('patientMyProfile/fetchStart');
export const fetchPatientMyProfileSuccess = createAction(
  'patientMyProfile/fetchSuccess',
  (profile: MyProfileSubmit) => ({ payload: profile }),
);
export const fetchPatientMyProfileError = createAction('patientMyProfile/fetchError', (error: string) => ({
  payload: error,
}));

export const mapProfileToMyProfileSubmit = (
  patientProfile: UserProfile | undefined,
  patientInsurance?: Insurance[],
  newDependent?: boolean,
): MyProfileSubmit => {
  if (patientProfile === undefined) return defaultFormValues;
  const mappedProfile: MyProfileSubmit = {
    ...defaultFormValues,
    patientId: patientProfile.id,
    date_of_birth: patientProfile.basic_info?.date_of_birth
      ? moment(patientProfile.basic_info?.date_of_birth).format('MM/DD/YYYY')
      : '',
    first_name: patientProfile.basic_info?.first_name || '',
    middle_name: patientProfile.basic_info?.middle_name || '',
    last_name: patientProfile.basic_info?.last_name || '',
    phone_number:
      patientProfile?.phone_number === null
        ? ''
        : patientProfile?.phone_number.replace(/^(\+\d)(\d{3})(\d{3})(\d{4})$/, '$1 ($2) $3 $4'),
    email: patientProfile?.email || '',
    address1: patientProfile.address?.address1 || '',
    address2: patientProfile.address?.address2 || '',
    zip_code: patientProfile.address?.zip_code || '',
    state: patientProfile.address?.state || '',
    city: patientProfile.address?.city || '',
    languages: patientProfile.basic_info?.languages || [''],
    language: [],
    gender: patientProfile.basic_info?.gender || '',
    pronouns: patientProfile.basic_info?.pronouns || '',
    state_funded_coverage_status:
      patientInsurance && patientInsurance[0] ? patientInsurance[0].state_funded_coverage_status : '',
    gender_identity: patientProfile.basic_info?.gender_identity || '',
    insurance_through: patientInsurance && patientInsurance[0]?.insured_by ? patientInsurance[0].insured_by : 'none',
    member_id:
      patientInsurance && patientInsurance[0]?.insurance_membership
        ? patientInsurance[0]?.insurance_membership.member_id
        : '',
    group_number:
      patientInsurance && patientInsurance[0]?.insurance_membership
        ? patientInsurance[0]?.insurance_membership.group_number
        : '',
    insurance_address:
      patientInsurance && patientInsurance[0]?.insurance_membership
        ? patientInsurance[0]?.insurance_membership.address?.address1
        : '',
    insurance_city:
      patientInsurance && patientInsurance[0]?.insurance_membership
        ? patientInsurance[0]?.insurance_membership.address?.city
        : '',
    insurance_state:
      patientInsurance && patientInsurance[0]?.insurance_membership
        ? patientInsurance[0]?.insurance_membership.address?.state
        : '',
    insurance_zip_code:
      patientInsurance && patientInsurance[0]?.insurance_membership
        ? patientInsurance[0]?.insurance_membership.address?.zip_code
        : '',
    iFirstName: (patientInsurance && patientInsurance[0]?.insurance_through?.first_name) || '',
    iMiddleName: (patientInsurance && patientInsurance[0]?.insurance_through?.middle_name) || '',
    iLastName: (patientInsurance && patientInsurance[0]?.insurance_through?.last_name) || '',
    iDateOfBirth:
      patientInsurance && patientInsurance[0]?.insurance_through
        ? moment(patientInsurance[0]?.insurance_through?.date_of_birth).format('MM/DD/YYYY')
        : '',
    iGenderIdentity: (patientInsurance && patientInsurance[0]?.insurance_through?.gender_identity) || '',
    iAddress1: (patientInsurance && patientInsurance[0]?.insurance_through?.address?.address1) || '',
    iAddress2: (patientInsurance && patientInsurance[0]?.insurance_through?.address?.address2) || '',
    iZipCode: (patientInsurance && patientInsurance[0]?.insurance_through?.address?.zip_code) || '',
    iState: (patientInsurance && patientInsurance[0]?.insurance_through?.address?.state) || '',
    iCity: (patientInsurance && patientInsurance[0]?.insurance_through?.address?.city) || '',
    iPhoneNumber:
      patientInsurance && patientInsurance[0]?.insurance_membership
        ? patientInsurance[0]?.insurance_membership.phone_number
        : '',
    insurance_carrier_id: patientInsurance && patientInsurance[0]?.insurance_membership?.insurance_carrier_id,

    secondary_insurance_through:
      patientInsurance && patientInsurance[1]?.insured_by ? patientInsurance[1].insured_by : 'none',
    secondary_member_id:
      patientInsurance && patientInsurance[1]?.insurance_membership
        ? patientInsurance[1].insurance_membership.member_id
        : '',
    secondary_group_number:
      patientInsurance && patientInsurance[1]?.insurance_membership
        ? patientInsurance[1].insurance_membership.group_number
        : '',
    secondary_iFirstName: (patientInsurance && patientInsurance[1]?.insurance_through?.first_name) || '',
    secondary_iMiddleName: (patientInsurance && patientInsurance[1]?.insurance_through?.middle_name) || '',
    secondary_iLastName: (patientInsurance && patientInsurance[1]?.insurance_through?.last_name) || '',
    secondary_iDateOfBirth:
      patientInsurance && patientInsurance[1]?.insurance_through
        ? moment(patientInsurance[1]?.insurance_through?.date_of_birth).format('MM/DD/YYYY')
        : '',
    secondary_iGenderIdentity: (patientInsurance && patientInsurance[1]?.insurance_through?.gender_identity) || '',
    secondary_iAddress1: (patientInsurance && patientInsurance[1]?.insurance_through?.address?.address1) || '',
    secondary_iAddress2: (patientInsurance && patientInsurance[1]?.insurance_through?.address?.address2) || '',
    secondary_iZipCode: (patientInsurance && patientInsurance[1]?.insurance_through?.address?.zip_code) || '',
    secondary_iState: (patientInsurance && patientInsurance[1]?.insurance_through?.address?.state) || '',
    secondary_iCity: (patientInsurance && patientInsurance[1]?.insurance_through?.address?.city) || '',
    secondary_iPhoneNumber:
      patientInsurance && patientInsurance[1]?.insurance_membership
        ? patientInsurance[1].insurance_membership.phone_number
        : '',
    secondary_insurance_carrier_id: patientInsurance && patientInsurance[1]?.insurance_membership?.insurance_carrier_id,
    secondary_insurance_address:
      patientInsurance && patientInsurance[1]?.insurance_membership
        ? patientInsurance[1]?.insurance_membership.address?.address1
        : '',
    secondary_insurance_city:
      patientInsurance && patientInsurance[1]?.insurance_membership
        ? patientInsurance[1]?.insurance_membership.address?.city
        : '',
    secondary_insurance_state:
      patientInsurance && patientInsurance[1]?.insurance_membership
        ? patientInsurance[1]?.insurance_membership.address?.state
        : '',
    secondary_insurance_zip_code:
      patientInsurance && patientInsurance[1]?.insurance_membership
        ? patientInsurance[1]?.insurance_membership.address?.zip_code
        : '',
    organizations:
      patientProfile.organizations?.map((org) => {
        const matchingDivision = patientProfile.patient_profile?.divisions?.find(
          (division) => division?.organization_id === org.id,
        );

        const matchingPractice = patientProfile.patient_profile?.practices?.find(
          (practice) => practice?.organization_id === org.id,
        );

        return {
          ...org,
          divisions: matchingDivision ? [matchingDivision] : [],
          practices: matchingPractice ? [matchingPractice] : [],
        };
      }) || [],
    organization: [],
    ...(newDependent && { dependent_address1: patientProfile.address?.address1 }),
    ...(newDependent && { dependent_address2: patientProfile.address?.address2 }),
    ...(newDependent && { dependent_zip_code: patientProfile.address?.zip_code }),
    ...(newDependent && { dependent_state: patientProfile.address?.state }),
    ...(newDependent && { dependent_city: patientProfile.address?.city }),
  };
  return mappedProfile;
};

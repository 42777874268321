import { client } from '../client';
import { OralHealthCoachingReport, ObjectType } from './types/types';

export const deleteOralHealthCoachingReportObject = async (
  oral_health_coaching_report_id: string,
  object_id: string,
  object_type: ObjectType,
): Promise<OralHealthCoachingReport> => {
  const { data } = await client.delete<OralHealthCoachingReport>(
    `/care_advisor/oral_health_coaching_reports/${oral_health_coaching_report_id}/${object_type}/${object_id}`,
  );

  return data;
};

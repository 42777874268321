/* eslint-disable max-len */
import { selectNeedsQuestions } from '../Dashboard/PatientForms/PatientForm/utils/questions';
import { CaseType, ICase, Status } from '../services/cases/types/ICase';
import { UserRoleName } from '../services/identity/types/UserProfile';
import { calculateCaseDueInMinutesForPatient } from './calculateCaseDue';
import { getPrimaryColor } from './getPrimaryColor';

export const getFriendlyName = (caseType: string): string => {
  switch (caseType) {
    case CaseType.messaging:
      return 'Messaging';
    case CaseType.video_call_instant:
      return 'Live Video Call';
    case CaseType.video_call_scheduled:
      return 'Scheduled Video Call';
    case CaseType.one_report:
      return 'Photo Review';
    case CaseType.second_opinion:
      return 'Second Opinion';
    case CaseType.smile_scan:
      return 'SmileScan';
    case CaseType.phone_call_scheduled:
      return 'Scheduled Phone Call';
    case CaseType.care_coordination:
      return 'Care Coordination';
    case CaseType.oral_health_coaching:
      return 'Oral Health Coaching';
    default:
      return '';
  }
};

export const getFriendlyNameOfConsultationType = (
  caseType: string,
  completedCase: boolean,
  justCaseType?: boolean,
): string => {
  if (justCaseType) {
    switch (caseType) {
      case CaseType.video_call_instant:
        return 'Live Consultation';
      case CaseType.video_call_scheduled:
        return 'A Scheduled Video Call Consultation';
      case CaseType.phone_call_scheduled:
        return 'A Scheduled Phone Call Consultation';
      case CaseType.one_report:
        return 'A Photo Review Case';
      case CaseType.second_opinion:
        return 'Second Opinion Case';
      case CaseType.care_coordination:
        return 'Care Coordination case';
      case CaseType.oral_health_coaching:
        return 'Oral Health Coaching Case ';
      case CaseType.smile_scan:
        return 'SmileScan Case';
      default:
        return '';
    }
  } else if (!completedCase) {
    switch (caseType) {
      case CaseType.video_call_instant:
        return 'Live consultation with';
      case CaseType.video_call_scheduled:
        return 'A Scheduled Video Call consultation with';
      case CaseType.phone_call_scheduled:
        return 'A Scheduled Phone Call consultation with';
      case CaseType.one_report:
        return 'A Photo Review is in progress with';
      case CaseType.second_opinion:
        return 'Second Opinion is in progress with';
      case CaseType.care_coordination:
        return 'Care Coordination Case in progress with';
      case CaseType.oral_health_coaching:
        return 'Oral Health Coaching Case in progress with';
      case CaseType.smile_scan:
        return 'SmileScan Case';
      default:
        return '';
    }
  } else {
    switch (caseType) {
      case CaseType.video_call_instant:
        return 'Live consultation with';
      case CaseType.video_call_scheduled:
        return 'A Scheduled Video Call consultation with';
      case CaseType.phone_call_scheduled:
        return 'A Scheduled Phone Call consultation with';
      case CaseType.one_report:
        return 'Photo Review by';
      case CaseType.second_opinion:
        return 'Second Opinion by';
      case CaseType.care_coordination:
        return 'Care Coordination Case by';
      case CaseType.oral_health_coaching:
        return 'Oral Health Coaching by';
      case CaseType.smile_scan:
        return 'AI SmileScan';
      default:
        return '';
    }
  }
};

export const getFriendlyNameForPricing = (caseType: string): string => {
  switch (caseType) {
    case CaseType.messaging:
      return 'Messaging';
    case CaseType.video_call_instant:
      return 'video_conference_with_dentist';
    case CaseType.video_call_scheduled:
      return 'video_conference_with_dentist';
    case CaseType.one_report:
      return 'oral_health_report';
    case CaseType.second_opinion:
      return 'second_opinion';
    case CaseType.smile_scan:
      return 'SmileScan';
    default:
      return '';
  }
};

export const getColorForStatus = (status: string): string => {
  switch (status) {
    case 'SignedUp':
    case 'SelfSignedUp':
      return '#4DB546';
    case 'InvitationSent':
      return getPrimaryColor();
    case 'InvitationNotSent':
      return 'red';
    default:
      return getPrimaryColor();
  }
};

export const getPainLevelColor2 = (painLevel: string): string => {
  switch (painLevel) {
    case 'nopain':
      return '#74B551';
    case 'mildPain':
      return '#88BC50';
    case 'moderatePain':
      return '#C0CA43';
    case 'severePain':
      return '#EBB231';
    case 'verySeverePain':
      return '#DC802D';
    case 'worstPainPossible':
      return '#CD2F2C';
    default:
      return 'black';
  }
};

export const getPainLevelColor = (painLevel: string): string => {
  switch (painLevel.toLowerCase()) {
    case 'no\npain':
      return '#74B551';
    case 'mild\npain':
      return '#88BC50';
    case 'moderate\npain':
      return '#C0CA43';
    case 'severe\npain':
      return '#EBB231';
    case 'very severe\npain':
      return '#DC802D';
    case 'worst pain\npossible':
      return '#CD2F2C';
    default:
      return 'black';
  }
};

export const getRelationshipName = (relationshipName: string, gender: string): string => {
  switch (relationshipName) {
    case 'Parent':
      return gender === 'female' ? 'Daughter, ' : 'Son, ';
    case 'Stepparent':
      return gender === 'female' ? 'Stepdaughter, ' : 'Stepson, ';
    case 'Sibling':
      return gender === 'female' ? 'Sister, ' : 'Brother, ';
    case 'Aunt/Uncle':
      return gender === 'female' ? 'Niece, ' : 'Nephew, ';
    case 'Grandparent':
      return gender === 'female' ? 'Granddaughter, ' : 'Grandson, ';
    case 'Appointed Guardian':
      return gender === 'female' ? 'Ward, ' : 'Ward, ';
    case 'Other':
      return gender === 'female' ? 'Relative, ' : 'Relative, ';
    default:
      return gender === 'female' ? 'Relative, ' : 'Relative, ';
  }
};

export const getFriendlyNameInsuredBy = (insuredBy: string): string => {
  switch (insuredBy) {
    case 'none':
      return 'No insurance';
    case 'myself':
      return 'Myself';
    case 'spouse':
      return 'Spouse';
    case 'parent':
      return 'Parent';
    case 'foster_parent':
      return 'Foster parent';
    case 'step_parent':
      return 'Stepparent';
    case 'grand_parent':
      return 'Grandparent';
    case 'legal_guardian':
      return 'Legal guardian';
    default:
      return '';
  }
};

export const cancelCaseAvailability = (thisCase: ICase): boolean => {
  if (
    thisCase.status === Status.Canceled ||
    (thisCase.type === CaseType.video_call_instant && thisCase.provider !== null) ||
    ((thisCase.type === CaseType.video_call_scheduled ||
      thisCase.type === CaseType.phone_call_scheduled ||
      thisCase.type === CaseType.oral_health_coaching) &&
      thisCase.video_conference_date &&
      calculateCaseDueInMinutesForPatient(thisCase.type, thisCase.created_at, thisCase.video_conference_date || '') <=
        0)
  ) {
    return false;
  }
  if (
    ((thisCase.type === CaseType.video_call_scheduled ||
      thisCase.type === CaseType.phone_call_scheduled ||
      thisCase.type === CaseType.oral_health_coaching) &&
      thisCase?.video_conference_date === null) ||
    ((thisCase.type === CaseType.video_call_scheduled ||
      thisCase.type === CaseType.phone_call_scheduled ||
      thisCase.type === CaseType.oral_health_coaching) &&
      calculateCaseDueInMinutesForPatient(thisCase.type, thisCase.created_at, thisCase.video_conference_date || '') >
        60) ||
    (thisCase.type === CaseType.video_call_instant && thisCase.provider === null)
  ) {
    return true;
  }
  return false;
};

export const rescheduleCaseAvailability = (thisCase: ICase): boolean => {
  if (
    thisCase.status === Status.Canceled ||
    (thisCase.type !== CaseType.video_call_scheduled &&
      thisCase.type !== CaseType.phone_call_scheduled &&
      thisCase.type !== CaseType.oral_health_coaching) ||
    ((thisCase.type === CaseType.video_call_scheduled ||
      thisCase.type === CaseType.phone_call_scheduled ||
      thisCase.type === CaseType.oral_health_coaching) &&
      thisCase.video_conference_date &&
      calculateCaseDueInMinutesForPatient(thisCase.type, thisCase.created_at, thisCase.video_conference_date || '') <=
        0)
  ) {
    return false;
  }
  if (
    (thisCase.type === CaseType.video_call_scheduled ||
      thisCase.type === CaseType.phone_call_scheduled ||
      thisCase.type === CaseType.oral_health_coaching) &&
    thisCase?.video_conference_date &&
    calculateCaseDueInMinutesForPatient(thisCase.type, thisCase.created_at, thisCase.video_conference_date || '') > 60
  ) {
    return true;
  }
  return false;
};

export const addAdditionalInformationAvailability = (thisCase: ICase): boolean => {
  if (
    thisCase.type !== CaseType.oral_health_coaching &&
    thisCase.type !== CaseType.care_coordination &&
    (thisCase.status === Status.New ||
      thisCase.status === Status.AcceptedProvider ||
      thisCase.status === Status.AcceptedCareAdvisor)
  ) {
    return true;
  }
  return false;
};

export const updatePaymentAvailability = (thisCase: ICase): boolean => {
  if (thisCase.type !== CaseType.video_call_scheduled && thisCase.type !== CaseType.phone_call_scheduled) {
    return false;
  }

  if (thisCase.status === Status.Canceled) {
    return false;
  }

  if (
    thisCase.video_conference_date &&
    (calculateCaseDueInMinutesForPatient(thisCase.type, thisCase.created_at, thisCase.video_conference_date) <= 60 ||
      thisCase?.insurance?.id)
  ) {
    return false;
  }

  return true;
};

export const getCaseDisclaimer = (caseType: string): string => {
  switch (caseType) {
    case CaseType.video_call_instant:
      return 'Live Video Consults with a provider can only be canceled up until a provider has been assigned to your case. This time period may vary based on provider availability in your state.';
    case CaseType.video_call_scheduled:
      return 'Scheduled video conferences can only be canceled up to one hour before your scheduled appointment.';
    case CaseType.phone_call_scheduled:
      return 'Scheduled call can only be canceled up to one hour before your scheduled appointment.';
    case CaseType.one_report:
      return 'You will not be able to cancel this case';
    case CaseType.second_opinion:
    case CaseType.care_coordination:
      return 'You will not be able to cancel this case';
    case CaseType.oral_health_coaching:
      return 'Oral Health Coaching cases can only be canceled up to one hour before your scheduled appointment.';
    default:
      return '';
  }
};

export const selectPainLevel = [
  {
    value: 'sharp',
    text: 'Sharp',
  },
  {
    value: 'off_and_on',
    text: 'Off and On',
  },
  {
    value: 'dull',
    text: 'Dull',
  },
  {
    value: 'constant',
    text: 'Constant',
  },
  {
    value: 'throbbing',
    text: 'Throbbing',
  },
  {
    value: 'burning',
    text: 'Burning',
  },
  {
    value: 'sensitive_to_hot',
    text: 'Sensitive to hot',
  },
  {
    value: 'sensitive_to_cold',
    text: 'Sensitive to cold',
  },
  {
    value: 'sensitive_to_sweet',
    text: 'Sensitive to sweet',
  },
];

export const getComplaintText = (value: string, purposeOther?: string): string => {
  if (value === 'something_else') {
    return purposeOther || 'Something Else';
  }
  const selected = selectNeedsQuestions.find((item) => item.value === value);
  return selected ? selected.question : '';
};

export const getPainTypeText = (value: string): string => {
  const selected = selectPainLevel.find((item) => item.value === value);
  return selected ? selected.text : '';
};

export const getUserRoleName = (userRole: string): string => {
  switch (userRole) {
    case UserRoleName.CareAdvisor:
      return 'Care Advisor';
    case UserRoleName.Provider:
      return UserRoleName.Provider;
    case UserRoleName.Patient:
      return UserRoleName.Patient;
    default:
      return '';
  }
};

/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable max-len */
import SelectInput from '@brands/Components/Inputs/SelectInput/SelectInput';
import ProfileImage from '@brands/Components/ProfileImage/ProfileImage';
import AddressSection from '@brands/Dashboard/Dashboard/SuperAdmin/Components/AddressSection/AddressSection';
import DependentAddressSection from '@brands/Dashboard/Dashboard/SuperAdmin/Components/AddressSection/DependentAddressSection';
import {
  DependentSubmit,
  relationshipOptions,
} from '@brands/Dashboard/MyProfile/Patient/Sections/Dependent/EditDependent';
import { RegisterMediaPayload } from '@brands/services/forms/registerMediaPayloadType';
import { confirmAvatarMedia } from '@brands/services/identity/confirmAvatarMedia';
import { createDependent } from '@brands/services/identity/createDependent';
import { deleteAvatar } from '@brands/services/identity/deleteAvatar';
import { getUserById } from '@brands/services/identity/getUserById';
import { registerAvatarMedia } from '@brands/services/identity/registerAvatarMedia';
import { mapProfileToMyProfileSubmit } from '@brands/store/actions/patientAction';
import { selectAuth } from '@brands/store/selectors/auth';
import { selectPageState } from '@brands/store/selectors/pageState';
import { selectPatientForm } from '@brands/store/selectors/patientForm';
import { selectPatientMyProfile } from '@brands/store/selectors/patientMyProfile';
import { setUser } from '@brands/store/slices/authSlice';
import { addDependent } from '@brands/store/slices/dependentsSlice';
import { setPatientMyProfile } from '@brands/store/slices/patientMyProfileSlice';
import { UnifiedProfileSubmit } from '@brands/Utils/types';
import { validateAddress, ValidationModalInterface } from '@brands/Utils/validateAddress';
import { yupResolver } from '@hookform/resolvers/yup';
import { parsePhoneNumber } from 'libphonenumber-js';
import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import { useForm, UseFormGetValues } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { MultiValue } from 'react-select';
import { toast } from 'react-toastify';

import Button from '../../../../Components/Button/Button';
import Container from '../../../../Components/Container/Container';
import MultiselectInput from '../../../../Components/Inputs/MultiselectInput/MultiselectInput';
import PhoneInput from '../../../../Components/Inputs/PhoneInput/PhoneInput';
import TextField from '../../../../Components/Inputs/TextField/TextField';
import SelectButtons from '../../../../Components/ProfileSelectButtons/SelectButtons';
import ProgressBar from '../../../../Components/ProgressBar/ProgressBar';
import { useAppDispatch, useAppSelector } from '../../../../hooks/useReduxHook';
import useWindowSize from '../../../../hooks/useWindowSize';
import { createBasicInformation } from '../../../../services/identity/createBasicInformation';
import { getCurrentProfile } from '../../../../services/identity/getCurrentProfile';
import { listAllLanguages } from '../../../../services/identity/listAllLanguages';
import { saveBasicInformation } from '../../../../services/identity/saveBasicInformation';
import {
  Address,
  BasicInfo,
  Language,
  Organization,
  UserProfile,
  UserRoleName,
} from '../../../../services/identity/types/UserProfile';
import { UpdateMe, updateMe } from '../../../../services/identity/updateMe';
import { dateAutoSlashes } from '../../../../Utils/checkAndSetDate';
import { multiSelectCommonCustomStylesLarge, selectCommonCustomStylesLarge } from '../../../../Utils/customStyles';
import { displayErrorDetails } from '../../../../Utils/displayError';
import { genderIdentity, genderOptions, pronounsOptions } from '../../../../Utils/selectButtonOptions';
import { OptionLanguage } from '../../../../Utils/selectOptions';
import { defaultFormValues } from '../../../MyProfile/Patient/utils/defaultFormValues';
import { updateData } from '../../../MyProfile/Patient/utils/utilsFunctions';
import styles from './styles.module.scss';
import { CreateProfileSubmit } from './utils/types';
import { validationSchema } from './utils/validationSchema';

const CreateYourProfile = (): JSX.Element => {
  const screenSize = useWindowSize();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [key, setKey] = useState(0);
  const { userInfo } = useAppSelector(selectAuth);
  const { myProfile } = useAppSelector(selectPatientMyProfile);
  const [loading, setLoading] = useState(false);

  const { selectedPatient, values } = useAppSelector(selectPatientForm);
  const [currentUser, setCurrentUser] = useState<UserProfile | null>(null);
  const [validationResponseId, setValidationResponseId] = useState('');
  const [languageOptions, setLanguageOptions] = useState<OptionLanguage[]>([]);
  const { isFromSelectPatient } = useAppSelector(selectPageState);
  const { selectedDependentId } = useAppSelector(selectPageState);

  const [avatarFile, setAvatarFile] = useState<File | null>(null);
  const [avatarPayload, setAvatarPayload] = useState<RegisterMediaPayload | null>(null);
  const [avatarAction, setAvatarAction] = useState<string>('');
  const [image, setImage] = React.useState<string>();

  const [avatarDependentFile, setAvatarDependentFile] = useState<File | null>(null);
  const [avatarDependentPayload, setAvatarDependentPayload] = useState<RegisterMediaPayload | null>(null);
  const [avatarDependentAction, setAvatarDependentAction] = useState<string>('');
  const [dependentImage, setDependentImage] = React.useState<string>();

  const [sameAsGuardian, setSameAsGuardian] = useState(!!selectedDependentId);

  const [showValidationAddressModal, setShowValidationAddressModal] = useState<ValidationModalInterface>({
    isOpen: false,
    title: '',
    whatYouEntered: '',
    recommended: false,
    recommendedAddress: '',
    onClose: undefined,
    onConfirmChange: undefined,
    onKeepCurrentAddress: undefined,
  });
  const [myProfileForm, setMyProfileForm] = useState<CreateProfileSubmit>(() => {
    if (
      myProfile.patientId &&
      (userInfo.role.name === UserRoleName.Admin ||
        userInfo.role.name === UserRoleName.SuperAdmin ||
        userInfo.role.name === UserRoleName.OrganizationAdmin)
        ? selectedPatient?.value
        : userInfo.id
    ) {
      return { ...defaultFormValues, ...myProfile };
    }
    return defaultFormValues;
  });

  function isLanguageArray(value: Language[] | string[] | Organization[]): value is Language[] {
    return Array.isArray(value) && value.length > 0 && value[0] instanceof Object && 'id' in value[0];
  }

  useEffect(() => {
    if (currentUser) {
      const mappedProfile = mapProfileToMyProfileSubmit(currentUser!, undefined, !!selectedDependentId);

      let selectedLanguage: MultiValue<OptionLanguage> = [];
      if (isLanguageArray(mappedProfile.languages)) {
        for (let i = 0; i < languageOptions.length; i++) {
          for (let j = 0; j < mappedProfile.languages.length; j++) {
            if (Number(languageOptions[i].id) === Number(mappedProfile.languages[j].id)) {
              selectedLanguage = [...selectedLanguage, languageOptions[i]];
            }
          }
        }
      }
      dispatch(
        setPatientMyProfile({
          ...(mappedProfile as CreateProfileSubmit),
          language: selectedLanguage,
        }),
      );
      setMyProfileForm({
        ...(mappedProfile as CreateProfileSubmit),
        language: selectedLanguage,
      });
    }
  }, [currentUser, dispatch, languageOptions]);

  const onInvalid = (errorsList: any): void => {
    const error = Object.values(errorsList)[0] as any;
    if (error) {
      toast.error(error.message as string);
    }
  };

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
    getValues,
  } = useForm<CreateProfileSubmit>({
    mode: 'onChange',
    resolver: yupResolver(validationSchema(!!selectedDependentId)),
  });

  const triggerProfileAvatar = React.useCallback(
    (avatarUrl: string) => {
      setValue('profile_img', avatarUrl, { shouldValidate: true });
      setMyProfileForm({
        ...getValues(),
        profile_img: avatarUrl,
      });
    },
    [getValues, setMyProfileForm, setValue],
  );

  const callSaveProfile = async (profileData: UnifiedProfileSubmit): Promise<void> => {
    setLoading(true);
    try {
      let updatedUser;

      const dataWithUpdatedDOB = updateData(profileData);
      const { address1, address2, city, state, zip_code } = profileData;
      const address: Address = { address1, address2, city, state, zip_code };

      updatedUser = await updateMe(
        {
          ...(profileData as UpdateMe),
          phone_number: parsePhoneNumber((profileData as CreateProfileSubmit).phone_number, 'US')?.format(
            'E.164',
          ) as string,
          address,
        },
        Number(
          userInfo.role.name === UserRoleName.Admin ||
            userInfo.role.name === UserRoleName.SuperAdmin ||
            userInfo.role.name === UserRoleName.OrganizationAdmin
            ? selectedPatient?.value
            : userInfo.id,
        ),
      );

      if (avatarPayload && avatarFile && avatarAction === 'Upload') {
        const res = await registerAvatarMedia(avatarPayload);
        const mediaId = res.files[0].upload_id;
        await fetch(res.files[0].upload_url, {
          method: 'PUT',
          body: avatarFile,
          headers: { 'content-type': avatarFile.type },
        });
        await confirmAvatarMedia(mediaId, Number(userInfo.id));
        triggerProfileAvatar(res.files[0].upload_url);
      } else if (avatarAction === 'Remove') {
        await deleteAvatar(userInfo.id);
        triggerProfileAvatar('');
      }

      if (currentUser && currentUser.basic_info) {
        updatedUser = await saveBasicInformation(
          {
            ...(dataWithUpdatedDOB as BasicInfo),
            languages: (profileData as CreateProfileSubmit).language.map((language) => language.id.toString()),
          },
          Number(
            userInfo.role.name === UserRoleName.Admin ||
              userInfo.role.name === UserRoleName.SuperAdmin ||
              userInfo.role.name === UserRoleName.OrganizationAdmin
              ? selectedPatient?.value
              : userInfo.id,
          ),
        );
      } else {
        updatedUser = await createBasicInformation(
          {
            ...(dataWithUpdatedDOB as BasicInfo),
            languages: (profileData as CreateProfileSubmit).language.map((language) => language.id.toString()),
          },
          Number(
            userInfo.role.name === UserRoleName.Admin ||
              userInfo.role.name === UserRoleName.SuperAdmin ||
              userInfo.role.name === UserRoleName.OrganizationAdmin
              ? selectedPatient?.value
              : userInfo.id,
          ),
        );
      }

      if (selectedDependentId) {
        const res = await createDependent(
          userInfo.role.name === UserRoleName.Admin ||
            userInfo.role.name === UserRoleName.SuperAdmin ||
            userInfo.role.name === UserRoleName.OrganizationAdmin
            ? selectedPatient?.id
            : userInfo.id,
          {
            guardian_relationship: (profileData as DependentSubmit).guardian_relationship!,
            email: (profileData as DependentSubmit).dependent_email || null,
          },
        );
        const { id } = res;

        if (avatarDependentPayload && avatarDependentFile && avatarDependentAction === 'Upload') {
          const response = await registerAvatarMedia(avatarDependentPayload);
          const mediaId = response.files[0].upload_id;
          await fetch(response.files[0].upload_url, {
            method: 'PUT',
            body: avatarDependentFile,
            headers: { 'content-type': avatarDependentFile.type },
          });
          await confirmAvatarMedia(mediaId, id);
          triggerProfileAvatar(response.files[0].upload_url);
        } else if (avatarAction === 'Remove') {
          await deleteAvatar(id);
          triggerProfileAvatar('');
        }

        await updateMe(
          {
            ...(profileData as UpdateMe),
            phone_number: null,
            address: {
              address1: (profileData as CreateProfileSubmit).dependent_address1,
              address2: (profileData as CreateProfileSubmit).dependent_address2,
              city: (profileData as CreateProfileSubmit).dependent_city,
              state: (profileData as CreateProfileSubmit).dependent_state,
              zip_code: (profileData as CreateProfileSubmit).dependent_zip_code,
            },
          },
          id || 0,
        );

        const setPayload = (data: CreateProfileSubmit): BasicInfo => {
          const {
            dependent_date_of_birth,
            dependent_first_name,
            dependent_last_name,
            dependent_middle_name,
            dependent_gender,
            dependent_pronouns,
            dependent_genderIdentity,
          } = data;

          const basicInfoPayload: BasicInfo = {
            date_of_birth: moment(dependent_date_of_birth).format('YYYY-MM-DD'),
            first_name: dependent_first_name as string,
            last_name: dependent_last_name as string,
            ...(dependent_middle_name && { middle_name: dependent_middle_name }),
            gender: dependent_gender as string,
            pronouns: dependent_pronouns as string,
            gender_identity: dependent_genderIdentity as string,
            languages: (profileData as CreateProfileSubmit).dependent_language?.map((language) => language.id) || [],
          };
          return basicInfoPayload;
        };

        await createBasicInformation(setPayload(profileData as CreateProfileSubmit), id);

        dispatch(
          addDependent({
            value: id.toString(),
            label: `${profileData.first_name} ${profileData.last_name}`,
            status: 'Active',
          }),
        );
      }

      if (
        userInfo.role.name !== UserRoleName.Admin &&
        userInfo.role.name !== UserRoleName.SuperAdmin &&
        userInfo.role.name !== UserRoleName.OrganizationAdmin
      ) {
        dispatch(setUser({ userInfo: updatedUser }));
        navigate('/medical-history', {
          state: {
            caseType: values.find((item) => item.form_question_id === 20)?.value.caseType as string,
            isPatientForm: true,
            shouldSeeLastModal: state || '',
            patientId: selectedPatient?.value ? selectedPatient?.value : '',
          },
        });
      } else {
        navigate(`${state ? '/medical-information-summary' : '/payment-information'}`, {
          state: {
            caseType: state ? (values.find((item) => item.form_question_id === 20)?.value.caseType as string) : '',
            shouldSeeLastModal: state,
            patientId: selectedPatient?.value ? selectedPatient?.value : '',
          },
        });
      }
      navigate('/select-patient', {
        state: { caseType: values.find((item) => item.form_question_id === 20)?.value.caseType as string },
      });
    } catch (error: unknown) {
      displayErrorDetails(error);
    } finally {
      setLoading(false);
    }
  };

  const selectSameAsGuardian = (isSelect = true): void => {
    if ((getValues('address1'), getValues('address2'), getValues('city'), getValues('state'), getValues('zip_code'))) {
      const inputList = ['address1', 'address2', 'city', 'state', 'zip_code'];
      if (isSelect) {
        setSameAsGuardian(true);
        inputList.forEach((input) => {
          setValue(
            `dependent_${input}` as keyof CreateProfileSubmit,
            getValues(input as keyof CreateProfileSubmit) || '',
            {
              shouldValidate: true,
            },
          );
        });
      } else {
        inputList.forEach((input) => {
          setValue(`dependent_${input}` as keyof CreateProfileSubmit, input === 'state' ? undefined : '');
          setKey((prev) => prev + 1);
        });
      }
    }
  };

  useEffect(() => {
    if (sameAsGuardian) selectSameAsGuardian(true);
  }, [getValues('address1'), getValues('address2'), getValues('city'), getValues('state'), getValues('zip_code')]);

  useEffect(() => {
    if (
      sameAsGuardian &&
      (getValues('dependent_address1') !== getValues('address1') ||
        getValues('dependent_address2') !== getValues('address2') ||
        getValues('dependent_city') !== getValues('city') ||
        getValues('dependent_state') !== getValues('state') ||
        getValues('dependent_zip_code') !== getValues('zip_code'))
    )
      setSameAsGuardian(false);
  }, [
    getValues('dependent_address1'),
    getValues('dependent_address2'),
    getValues('dependent_city'),
    getValues('dependent_state'),
    getValues('dependent_zip_code'),
  ]);

  const onCloseConfirmationModal = (): void => {
    setShowValidationAddressModal({
      isOpen: false,
      title: '',
      whatYouEntered: '',
      recommended: false,
      recommendedAddress: '',
      onClose: undefined,
      onConfirmChange: undefined,
      onKeepCurrentAddress: undefined,
    });
  };

  const updateCurrentAddress = (standardizedAddress: any, postalAddress: any, addressComponents: any): void => {
    const streetNumber = addressComponents.find(
      (addressComponent: any) => addressComponent.componentType === 'street_number',
    );
    const address1 = addressComponents.find((addressComponent: any) => addressComponent.componentType === 'route');
    if (postalAddress) setValue('city', postalAddress.locality);
    if (streetNumber || address1) {
      setValue('address1', `${streetNumber.componentName.text} ${address1.componentName.text}`);
    }
    if (standardizedAddress?.state) setValue('state', standardizedAddress.state);
    if (postalAddress) setValue('zip_code', postalAddress.postalCode);
  };

  const onSubmit = async (profileData: CreateProfileSubmit): Promise<void> => {
    await validateAddress(
      profileData,
      getValues as UseFormGetValues<UnifiedProfileSubmit>,
      validationResponseId,
      callSaveProfile,
      setValidationResponseId,
      setShowValidationAddressModal,
      onCloseConfirmationModal,
      updateCurrentAddress,
    );
  };

  const setTextValue = (e: React.ChangeEvent<HTMLInputElement>, name: string): void => {
    const { value } = e.target;
    setValue(name as keyof CreateProfileSubmit, value, { shouldValidate: true });
  };

  const fetchLanguageOptions = async (): Promise<void> => {
    const data = await listAllLanguages();
    const transformedData = data.map((language) => ({
      value: language.locale,
      label: language.name,
      id: language.id,
    }));
    setLanguageOptions(transformedData);
  };

  useEffect(() => {
    fetchLanguageOptions();
  }, []);

  useEffect(() => {
    const fetchUserDetails = async (): Promise<void> => {
      let userDetails;
      if (
        userInfo.role.name === UserRoleName.Admin ||
        userInfo.role.name === UserRoleName.SuperAdmin ||
        userInfo.role.name === UserRoleName.OrganizationAdmin
      ) {
        userDetails = await getUserById(selectedPatient?.value);
      } else {
        userDetails = await getCurrentProfile();
      }
      setCurrentUser(userDetails);
    };
    fetchUserDetails();
  }, [languageOptions]);

  useEffect(() => {
    Object.keys(myProfileForm).forEach((k: unknown) => {
      setValue(k as keyof CreateProfileSubmit, myProfileForm[k as keyof CreateProfileSubmit]);
    });
    setValue('email', currentUser?.email || '');
  }, [myProfileForm, setValue, currentUser]);

  const phoneInputRef = useRef<HTMLInputElement>(null);
  return (
    <Container
      isBGEclipse
      isVisibleOnMobile
      containerStyle={{
        justifyContent: screenSize.width >= 564 ? 'center' : 'start',
      }}
      eclipseStyle={{ opacity: 0.2, display: screenSize.width <= 768 ? 'none' : '' }}
      childrenStyle={{ zIndex: '2' }}
    >
      <div className={styles.createProfileContainer}>
        <div className={`${styles.formTitle} d-flex mt-3`}>Create your profile</div>
        <ProgressBar currentStep={1} totalSteps={4} hideStepNumbers />
        {isFromSelectPatient && selectedDependentId && (
          <div className={`${styles.formTitle} d-flex mt-3`}>Your Information</div>
        )}
        <form className={styles.formContainer} onSubmit={handleSubmit(onSubmit, onInvalid)}>
          <div className={styles.wrapper}>
            <div className={styles.formContent}>
              <ProfileImage
                reviewProfile={false}
                id={userInfo.id}
                setCurrentAvatarURL={undefined}
                setAvatarFile={setAvatarFile}
                setAvatarPayload={setAvatarPayload}
                setAvatarAction={setAvatarAction}
                triggerProfileAvatar={triggerProfileAvatar}
                setImage={setImage}
                image={image}
                errors={errors}
                size="80px"
              />
              <div className={styles.requiredText}>
                Required <span className={styles.redText}> *</span>
              </div>
              <div className="row mt-2">
                <div className="col-md-4">
                  <div className={styles.inputWrap}>
                    <span className={styles.inputWrapTitle}>First Name</span>
                    <span className={styles.redText}> *</span>
                    <div>
                      <TextField
                        {...register('first_name')}
                        errors={errors}
                        name="first_name"
                        value={getValues('first_name')}
                        onChange={(e) => {
                          setValue('first_name', e.target.value, { shouldValidate: true });
                        }}
                        wrapperStyle={{ marginTop: '0' }}
                        inputClass={styles.profileInputClass}
                        isUnderlined={false}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className={styles.inputWrap}>
                    <span className={styles.inputWrapTitle}>Middle Name</span>
                    <div>
                      <TextField
                        {...register('middle_name')}
                        errors={errors}
                        name="middle_name"
                        value={getValues('middle_name')}
                        onChange={(e) => {
                          setValue('middle_name', e.target.value, { shouldValidate: true });
                        }}
                        wrapperStyle={{ marginTop: 'unset' }}
                        inputClass={styles.profileInputClass}
                        isUnderlined={false}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className={styles.inputWrap}>
                    <span className={styles.inputWrapTitle}>Last Name</span>
                    <span className={styles.redText}> *</span>
                    <div>
                      <TextField
                        {...register('last_name')}
                        errors={errors}
                        name="last_name"
                        value={getValues('last_name')}
                        onChange={(e) => {
                          setValue('last_name', e.target.value, { shouldValidate: true });
                        }}
                        wrapperStyle={{ marginTop: 'unset' }}
                        inputClass={styles.profileInputClass}
                        isUnderlined={false}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="row mt-4">
                <div>
                  <span style={{ opacity: '0.5' }}>Gender assigned at birth</span>
                  <span className={styles.redText}> *</span>
                </div>
                <SelectButtons
                  errors={errors}
                  setValue={setValue}
                  form={myProfileForm}
                  value="gender"
                  options={genderOptions}
                />
              </div>
              <div className="row mt-2">
                <div className="col-md-6">
                  <span style={{ opacity: '0.5' }}>Gender identity</span>
                  <span className={styles.redText}> *</span>
                  <SelectInput
                    {...register('gender_identity')}
                    name="gender_identity"
                    options={genderIdentity}
                    containerClass={styles.inputWrapper}
                    selectorClass={styles.selector}
                    style={selectCommonCustomStylesLarge}
                    errors={errors}
                    selectedValue={getValues('gender_identity')}
                    onChange={(value) => {
                      if (value) {
                        setValue('gender_identity', value.value, { shouldValidate: true });
                      }
                    }}
                  />
                </div>
                <div className="col-md-6">
                  <span style={{ opacity: '0.5' }}>Pronouns</span>
                  <SelectInput
                    {...register('pronouns')}
                    name="pronouns"
                    options={pronounsOptions}
                    containerClass={styles.inputWrapper}
                    selectorClass={styles.selector}
                    style={selectCommonCustomStylesLarge}
                    errors={errors}
                    selectedValue={getValues('pronouns')}
                    onChange={(value) => {
                      if (value) {
                        setValue('pronouns', value.value, { shouldValidate: true });
                      }
                    }}
                  />
                </div>
              </div>
              <div className="row mt-2">
                <div className="col-md-4">
                  <div className={styles.inputWrap}>
                    <span className={styles.inputWrapTitle}>Date of Birth</span>
                    <span className={styles.redText}> *</span>
                    <TextField
                      {...register('date_of_birth')}
                      errors={errors}
                      name="date_of_birth"
                      placeholder="MM / DD / YYYY"
                      value={getValues('date_of_birth')}
                      onChange={(e) => dateAutoSlashes(e, 'date_of_birth', setValue)}
                      maxLength={10}
                      wrapperStyle={{ marginTop: 'unset' }}
                      inputClass={styles.profileInputClass}
                      isUnderlined={false}
                      enableCursorPos={false}
                    />
                  </div>
                </div>
                <div className="col-md-8">
                  <div className={`${styles.inputWrap} ${styles.languageSpan}`}>
                    <span className={styles.inputWrapTitle}>Languages</span>
                    <span className={styles.redText}> *</span>
                    <MultiselectInput
                      {...register('language')}
                      name="language"
                      placeholder="Please add any languages you are comfortable speaking during the consult"
                      containerClass="mt-0"
                      options={languageOptions}
                      style={multiSelectCommonCustomStylesLarge}
                      errors={errors}
                      selectedValue={getValues('language')}
                      onChange={(value) => {
                        if (value) {
                          setValue('language', value as MultiValue<OptionLanguage>, {
                            shouldValidate: true,
                          });
                        }
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className={styles.row}>
              <AddressSection
                register={register}
                errors={errors}
                getValues={getValues}
                setTextValue={setTextValue}
                setValue={setValue}
                showValidationAddressModal={showValidationAddressModal}
                required
              />
            </div>
            <div className={styles.formContent} style={{ border: 'none' }}>
              <div className="row mt-2">
                <div className={styles.inputWrap}>
                  <span className={styles.inputWrapTitle}>Email Address</span>
                  <span className={styles.redText}> *</span>
                  <TextField
                    {...register('email')}
                    errors={errors}
                    name="email"
                    value={getValues('email')}
                    onChange={(e) => {
                      setValue('email', e.target.value, { shouldValidate: true });
                    }}
                    wrapperStyle={{ marginTop: 'unset' }}
                    inputClass={styles.profileInputClass}
                    isUnderlined={false}
                  />
                </div>
              </div>
              <div className="row mt-2">
                <div className={styles.inputWrap}>
                  <span className={styles.inputWrapTitle}>Phone Number</span>
                  <span className={styles.redText}> *</span>
                  <PhoneInput
                    {...register('phone_number')}
                    value={getValues('phone_number')}
                    id="phone_number"
                    onChange={(val) => {
                      setValue('phone_number', val as string, {
                        shouldValidate: true,
                      });
                    }}
                    errors={errors}
                    wrapperStyle={{ marginTop: 'unset' }}
                    inputClass={styles.profileInputClass}
                    isUnderlined={false}
                    ref={phoneInputRef}
                  />
                </div>
              </div>
            </div>
            {isFromSelectPatient && selectedDependentId && (
              <>
                <div className={styles.separator} />
                <div className={`${styles.formTitle} d-flex mt-3`}>Dependent Information</div>
                <div className={styles.editContainer}>
                  <div className={`d-flex justify-content-between ${styles.mobileFlexCol}`}>
                    <ProfileImage
                      id={undefined}
                      setAvatarFile={setAvatarDependentFile}
                      setAvatarPayload={setAvatarDependentPayload}
                      setAvatarAction={setAvatarDependentAction}
                      setImage={setDependentImage}
                      image={dependentImage}
                      size="80px"
                      isNewDependent
                    />
                    <div className={styles.relationshipToGuardian}>
                      <span>Guardian's relationship to Dependent </span>
                      <span className={styles.redText}> *</span>
                      <SelectInput
                        {...register('guardian_relationship')}
                        name="guardian_relationship"
                        options={relationshipOptions}
                        containerClass={`${styles.inputWrapper} mt-1`}
                        selectorClass={styles.selector}
                        style={selectCommonCustomStylesLarge}
                        errors={errors}
                        selectedValue={
                          relationshipOptions.find((option) => option.label === getValues('guardian_relationship'))
                            ?.value
                        }
                        onChange={(value) => {
                          if (value) {
                            setValue('guardian_relationship', value.label, { shouldValidate: true });
                          }
                        }}
                      />
                    </div>
                  </div>
                  <p className={styles.requiredIndicator}>
                    Required <span>*</span>
                  </p>
                  <div className="row mt-2">
                    <div className="col-md-4">
                      <div className={styles.inputWrap}>
                        <span className={styles.inputWrapTitle}>First Name</span>
                        <span className={styles.redText}> *</span>
                        <div>
                          <TextField
                            {...register('dependent_first_name')}
                            errors={errors}
                            name="dependent_first_name"
                            value={getValues('dependent_first_name')}
                            onChange={(e) => {
                              setValue('dependent_first_name', e.target.value, { shouldValidate: true });
                            }}
                            wrapperStyle={{ marginTop: '0' }}
                            inputClass={styles.profileInputClass}
                            isUnderlined={false}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className={styles.inputWrap}>
                        <span className={styles.inputWrapTitle}>Middle Name</span>
                        <div>
                          <TextField
                            {...register('dependent_middle_name')}
                            errors={errors}
                            name="dependent_middle_name"
                            value={getValues('dependent_middle_name')}
                            onChange={(e) => {
                              setValue('dependent_middle_name', e.target.value, { shouldValidate: true });
                            }}
                            wrapperStyle={{ marginTop: 'unset' }}
                            inputClass={styles.profileInputClass}
                            isUnderlined={false}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className={styles.inputWrap}>
                        <span className={styles.inputWrapTitle}>Last Name</span>
                        <span className={styles.redText}> *</span>
                        <div>
                          <TextField
                            {...register('dependent_last_name')}
                            errors={errors}
                            name="dependent_last_name"
                            value={getValues('dependent_last_name')}
                            onChange={(e) => {
                              setValue('dependent_last_name', e.target.value, { shouldValidate: true });
                            }}
                            wrapperStyle={{ marginTop: 'unset' }}
                            inputClass={styles.profileInputClass}
                            isUnderlined={false}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row mt-4">
                    <div>
                      <span style={{ opacity: '0.5' }}>Gender assigned at birth</span>
                      <span className={styles.redText}> *</span>
                    </div>
                    <SelectButtons
                      errors={errors}
                      setValue={setValue}
                      form={myProfileForm}
                      value="dependent_gender"
                      options={genderOptions}
                    />
                  </div>
                  <div className="row mt-2">
                    <div className="col-md-6">
                      <span style={{ opacity: '0.5' }}>Gender identity</span>
                      <span className={styles.redText}> *</span>
                      <SelectInput
                        {...register('dependent_genderIdentity')}
                        name="dependent_genderIdentity"
                        options={genderIdentity}
                        containerClass={styles.inputWrapper}
                        selectorClass={styles.selector}
                        style={selectCommonCustomStylesLarge}
                        errors={errors}
                        selectedValue={getValues('dependent_genderIdentity')}
                        onChange={(value) => {
                          if (value) {
                            setValue('dependent_genderIdentity', value.value, { shouldValidate: true });
                          }
                        }}
                      />
                    </div>
                    <div className="col-md-6">
                      <span style={{ opacity: '0.5' }}>Pronouns</span>
                      <SelectInput
                        {...register('dependent_pronouns')}
                        name="dependent_pronouns"
                        options={pronounsOptions}
                        containerClass={styles.inputWrapper}
                        selectorClass={styles.selector}
                        style={selectCommonCustomStylesLarge}
                        errors={errors}
                        selectedValue={getValues('dependent_pronouns')}
                        onChange={(value) => {
                          if (value) {
                            setValue('dependent_pronouns', value.value, { shouldValidate: true });
                          }
                        }}
                      />
                    </div>
                  </div>
                  <div className="row mt-2">
                    <div className="col-md-4">
                      <div className={styles.inputWrap}>
                        <span className={styles.inputWrapTitle}>Date of Birth</span>
                        <span className={styles.redText}> *</span>
                        <TextField
                          {...register('dependent_date_of_birth')}
                          errors={errors}
                          name="dependent_date_of_birth"
                          placeholder="MM / DD / YYYY"
                          value={getValues('dependent_date_of_birth')}
                          onChange={(e) => dateAutoSlashes(e, 'dependent_date_of_birth', setValue)}
                          maxLength={10}
                          wrapperStyle={{ marginTop: 'unset' }}
                          inputClass={styles.profileInputClass}
                          isUnderlined={false}
                          enableCursorPos={false}
                        />
                      </div>
                    </div>
                    <div className="col-md-8">
                      <div className={styles.inputWrap}>
                        <span className={styles.inputWrapTitle}>Languages</span>
                        <span className={styles.redText}> *</span>
                        <MultiselectInput
                          {...register('dependent_language')}
                          name="dependent_language"
                          placeholder="Please add any languages you are comfortable speaking during the consult"
                          containerClass="mt-0"
                          options={languageOptions}
                          style={multiSelectCommonCustomStylesLarge}
                          errors={errors}
                          selectedValue={getValues('dependent_language')}
                          onChange={(value) => {
                            if (value) {
                              setValue('dependent_language', value as MultiValue<OptionLanguage>, {
                                shouldValidate: true,
                              });
                            }
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  <div className={styles.optional} key={key}>
                    <div className={styles.checkInput}>
                      <div className="form-check m-0">
                        <input
                          type="checkbox"
                          className="form-check-input"
                          checked={
                            (getValues('dependent_address1') === getValues('address1') &&
                              getValues('dependent_address2') === getValues('address2') &&
                              getValues('dependent_city') === getValues('city') &&
                              getValues('state') !== undefined &&
                              getValues('dependent_state') !== undefined &&
                              getValues('dependent_state') === getValues('state') &&
                              getValues('dependent_zip_code') === getValues('zip_code')) ||
                            sameAsGuardian
                          }
                          onChange={(e) => selectSameAsGuardian(e.target.checked)}
                          id="optionalInput"
                        />
                        <label className={styles.optionalLabel} htmlFor="optionalInput">
                          Same as Guardian
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className={styles.row}>
                    <DependentAddressSection
                      register={register}
                      errors={errors}
                      getValues={getValues}
                      setTextValue={setTextValue}
                      setValue={setValue}
                      showValidationAddressModal={showValidationAddressModal}
                      required
                    />
                  </div>
                </div>
              </>
            )}

            <div className={styles.btnContainer}>
              <Button type="submit" disabled={loading} style={{ width: '245px', height: '46px' }}>
                Next
              </Button>
            </div>
          </div>
        </form>
      </div>
    </Container>
  );
};
export default CreateYourProfile;

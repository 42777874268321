import { selectShowVideoThumbnail } from '@brands/store/selectors/showVideoThumbnail';
import throttle from 'lodash/throttle';
import React, { useMemo, useState } from 'react';
import { IoIosArrowDropleftCircle, IoIosSearch } from 'react-icons/io';
import { IoDownloadOutline } from 'react-icons/io5';
import { MdOutlinePersonAddAlt } from 'react-icons/md';
import { useSelector } from 'react-redux';

import { useIdentityContext } from '../../hooks';
import { IGMConversation } from '../../services/communication/types/IGMConversation';
import { UserRoleName } from '../../services/identity/types/UserProfile';
import AddParticipantModal from '../ChatModal/AddParticipantModal';
import DownloadModal from '../ChatModal/DownloadModal';
import LeaveModal from '../ChatModal/LeaveModal';
import styles from './case-styles.module.scss';

interface MessageBodyProps {
  setOpenModal: (isOpen: boolean) => void;
  conversation: IGMConversation | null;
  isProfileCard?: boolean;
  setLeaveModalOpen: (isOpen: boolean) => void;
  leaveModalOpen: boolean;
  query: string;
  setQuery: (query: string) => void;
  setSearch: (search: string) => void;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
}

const CaseMobileOptionsModal: React.FC<MessageBodyProps> = ({
  setOpenModal,
  conversation,
  isProfileCard,
  leaveModalOpen,
  setLeaveModalOpen,
  query,
  setQuery,
  setSearch,
  setLoading,
}) => {
  const contextValues = useIdentityContext();
  const [addParticipantModalOpen, setAddParticipantModalOpen] = useState(false);
  const [downloadModalOpen, setDownloadModalOpen] = useState(false);
  const showVideoThumbnail = useSelector(selectShowVideoThumbnail);

  const userRole = useMemo(() => {
    if (!contextValues.userInfo) {
      return null;
    }

    return contextValues.userInfo.role.name;
  }, [contextValues]);

  const throttledSearch = throttle(setSearch, 100, { trailing: true });

  const addParticipant = (): void => {
    setAddParticipantModalOpen(true);
  };
  return (
    <>
      <div className={styles.optionsModal}>
        <div
          className={`${styles.messageSearch} ${styles.mobileMessageSearch} ${
            showVideoThumbnail && styles.addMarginTop
          }`}
          style={{ display: isProfileCard ? 'none' : 'flex' }}
        >
          <span className={`${styles.backArrow} ${styles.mobileBackArrow}`} onClick={() => setOpenModal(false)}>
            <IoIosArrowDropleftCircle />
          </span>
          <div className={styles.contentContainer}>
            <div className={styles.searchBox}>
              <input
                type="text"
                className={styles.inputSearch}
                placeholder="Search"
                onChange={(e) => {
                  setQuery(e.target.value);
                  throttledSearch(e.target.value);
                }}
                value={query}
              />
              <button
                aria-label="Search"
                type="button"
                className={styles.btnSearch}
                onClick={() => {
                  setSearch(query);
                  setOpenModal(false);
                }}
              >
                <IoIosSearch className={styles.searchIcon} />
              </button>
            </div>
            {false && (userRole === UserRoleName.Provider || userRole === UserRoleName.CareAdvisor) && (
              <div onClick={() => addParticipant()} className={styles.listItem}>
                Add Participant
                <MdOutlinePersonAddAlt className={styles.dropDownIcon} />
              </div>
            )}
            <div onClick={() => setDownloadModalOpen(true)} className={styles.listItem}>
              Download Chat
              <IoDownloadOutline className={styles.dropDownIcon} />
            </div>
          </div>
          {leaveModalOpen && <LeaveModal setOpenModal={setLeaveModalOpen} conversationSid={conversation?.sid} />}
          {downloadModalOpen && (
            <DownloadModal
              setOpenModal={setDownloadModalOpen}
              conversationSid={conversation?.sid}
              setLoading={setLoading}
            />
          )}
        </div>
      </div>
      {addParticipantModalOpen && (
        <AddParticipantModal setOpenModal={setAddParticipantModalOpen} conversationSid={conversation?.sid ?? ''} />
      )}
    </>
  );
};

CaseMobileOptionsModal.defaultProps = {
  isProfileCard: false,
};

export default CaseMobileOptionsModal;

/* eslint-disable no-nested-ternary */
/* eslint-disable react/require-default-props */
import { useWindowSize } from '@brands/hooks';
import { useAppSelector } from '@brands/hooks/useReduxHook';
import { CaseType, ICase } from '@brands/services/cases/types/ICase';
import { selectAuth } from '@brands/store/selectors/auth';
import { selectPatient } from '@brands/store/selectors/selectedPatient';
import { customStylesDashboard } from '@brands/Utils/customStyles';
import { getFormatDate } from '@brands/Utils/formatDate';
import { getFriendlyName } from '@brands/Utils/getFriendlyName';
import moment from 'moment';
import React, { FC } from 'react';
import { AiOutlineCloseCircle } from 'react-icons/ai';
import Modal from 'react-modal';
import { useNavigate } from 'react-router-dom';

import styles from './styles.module.scss';

interface OpenCaseListModalProps {
  openCases: ICase[];
  isOpen: string | null;
  toggleModal: (value: string | null) => void;
  startPatientForm?: (getCases?: boolean) => Promise<void>;
  startOneReport?: (getCases?: boolean) => Promise<void>;
  startSecondOpinionCase?: (getCases?: boolean) => Promise<void>;
  handleOptionClick?: (
    organizationId: string,
    option: {
      selectedCaseType: string;
      selectedCasePrice: string;
    },
    getCases?: boolean,
  ) => Promise<void>;
  startCareCoordinationCase?: (getCases?: boolean) => Promise<void>;
  startOralHealthCoachingCase?: (getCases?: boolean) => Promise<void>;
  orgId?: string;
  selectedCase: {
    selectedCaseType: string;
    selectedCasePrice: string;
  };
}

const mobileCustomStylesDashboard = {
  ...customStylesDashboard,
  overlay: {
    ...customStylesDashboard.overlay,
    overflowY: 'scroll',
  },
  content: {
    ...customStylesDashboard.content,
    transform: `translate(-50%, -40%)`,
    width: '90%',
  },
};

const OpenCaseListModal: FC<OpenCaseListModalProps> = ({
  openCases,
  isOpen,
  toggleModal,
  startPatientForm,
  startOneReport,
  startSecondOpinionCase,
  startCareCoordinationCase,
  startOralHealthCoachingCase,
  handleOptionClick,
  orgId,
  selectedCase,
}) => {
  const navigate = useNavigate();
  const isMobile = useWindowSize().width < 992;
  const { userInfo } = useAppSelector(selectAuth);
  const { selectedPatient } = useAppSelector(selectPatient);

  const closeModal = (): void => {
    if (userInfo.role.name !== 'Patient') {
      navigate(`/admin/review-profile/Patient/${selectedPatient.id}`);
    }
    toggleModal(null);
  };

  const SYNC_CASES = ['video_call_scheduled', 'video_cal_instant', 'phone_call_scheduled'];

  const continueModal = async (): Promise<void> => {
    if (
      startPatientForm &&
      startOneReport &&
      startSecondOpinionCase &&
      startCareCoordinationCase &&
      startOralHealthCoachingCase
    ) {
      switch (isOpen) {
        case 'video_call_scheduled':
          await startPatientForm(false);
          break;
        case 'one_report':
          await startOneReport(false);
          break;
        case 'second_opinion':
          await startSecondOpinionCase(false);
          break;
        case CaseType.care_coordination:
          await startCareCoordinationCase(false);
          break;
        case CaseType.oral_health_coaching:
          await startOralHealthCoachingCase(false);
          break;
        default:
          break;
      }
    } else if (handleOptionClick) {
      await handleOptionClick(
        orgId || '',
        {
          selectedCaseType: selectedCase.selectedCaseType || '',
          selectedCasePrice: selectedCase.selectedCasePrice,
        },
        false,
      );
    } else {
      console.error('No function provided to continue modal');
    }
    toggleModal(null);
  };

  const viewCase = (caseId: number): void => {
    if (userInfo.role.name === 'Patient') {
      navigate('/appointments');
    } else {
      navigate(`/admin/consultation-page/${caseId}`);
    }
    toggleModal(null);
  };

  const getConsultationMessage = (): string => {
    const { length: openCaseCount } = openCases;
    const isPatient = userInfo.role.name === 'Patient';

    if (openCaseCount > 1) {
      return isPatient
        ? 'It looks like you have active consultations'
        : 'It looks like the patient has active consultations';
    }

    if (openCaseCount === 1) {
      return isPatient
        ? 'It looks like you have an active consultation'
        : 'It looks like the patient has an active consultation';
    }

    return '';
  };

  const renderOpenCases = (cases: ICase[]): JSX.Element => {
    return (
      <div className={styles.openCasesCaseContainer}>
        {cases.map((item) => {
          const date = SYNC_CASES.includes(item.type) ? item.video_conference_date || '' : item.created_at;
          const { timeZone } = Intl.DateTimeFormat().resolvedOptions();
          return (
            <div className={styles.openCasesCaseBody}>
              <div className={styles.openCasesCaseContent}>
                {getFriendlyName(item.type)} Consultation
                <br />
                {`${SYNC_CASES.includes(item.type) ? 'Scheduled:' : 'Submitted:'} ${getFormatDate(
                  date,
                  timeZone,
                )}(${moment(date).tz(timeZone).format('z')})`}
              </div>
              <button type="button" className={styles.viewButton} onClick={() => viewCase(item.id)}>
                View/Edit
              </button>
            </div>
          );
        })}
      </div>
    );
  };

  return (
    <Modal
      isOpen={typeof isOpen === 'string'}
      onRequestClose={closeModal}
      style={isMobile ? mobileCustomStylesDashboard : customStylesDashboard}
      contentLabel="Modal"
      ariaHideApp={false}
    >
      <button type="button" className={styles.openCasesCloseBtn} aria-label="Close modal" onClick={closeModal}>
        <AiOutlineCloseCircle style={{ fontSize: '24px' }} />
      </button>
      {openCases && openCases.length > 0 && (
        <div className={styles.openCasesContainer}>
          <div className={styles.openCasesTitle}>{getConsultationMessage()}</div>
          <div className={styles.openCasesBody}>{renderOpenCases(openCases)}</div>
          <button type="button" className={styles.openCasesCreateButton} onClick={continueModal}>
            Create a new consultation
          </button>
          {userInfo.role.name === 'Patient' && <hr />}
          {userInfo.role.name === 'Patient' && (
            <div className={styles.openCasesFooter}>
              <div className={styles.openCasesFooterPrimary}>Need help?</div>
              <div className={styles.openCasesFooterSecondary}>
                Give us a call at <a href="tel:1-800-123-4567">1-800-123-4567</a> to speak with a Care Advisor.
              </div>
            </div>
          )}
        </div>
      )}
    </Modal>
  );
};

Modal.setAppElement('#root');

export default OpenCaseListModal;

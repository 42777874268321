/* eslint-disable no-nested-ternary */
/* eslint-disable consistent-return */
import InstantConsultationConfirmationModal from '@brands/Dashboard/Dashboard/DashboardModals/InstantConsultationConfirmationModal';
import OralHealthCoachingModal from '@brands/Dashboard/Dashboard/DashboardModals/OralHealthCoachingModal';
import SubmitSOConfirmationModal from '@brands/Dashboard/Dashboard/DashboardModals/SubmitSOConfirmationModal';
import UnavailableTimeSlotModal from '@brands/Dashboard/Dashboard/DashboardModals/UnavailableTimeSlotModal';
import { convertDateFormat, uploadMediaToCase } from '@brands/Dashboard/MyProfile/Patient/utils/utilsFunctions';
import { defaultICaseValues } from '@brands/services/cases/types/defaultICaseValues';
import { completeMobileHandoff } from '@brands/services/communication/completeMobileHandover';
import { Pricings } from '@brands/services/identity/getPricings';
import { UserRoleName } from '@brands/services/identity/types/UserProfile';
import { selectAuth } from '@brands/store/selectors/auth';
import { selectCurrentPatientState } from '@brands/store/selectors/currentPatientState';
import { selectDependents } from '@brands/store/selectors/dependents';
import { selectIsFromHandOff } from '@brands/store/selectors/isFromHandOff';
import { selectOrganization } from '@brands/store/selectors/organization';
import { selectOrganizationPrices } from '@brands/store/selectors/organizationPrices';
import { selectPatientForm } from '@brands/store/selectors/patientForm';
import { selectPatient } from '@brands/store/selectors/selectedPatient';
import { setIsFromHandoff } from '@brands/store/slices/isFromHandOffSlice';
import { resetForm, resetSelectedPatient, setSelectedPatient } from '@brands/store/slices/patientFormSlice';
import { setVCCaseId } from '@brands/store/slices/videoCallCredentialsSlice';
import { Option } from '@brands/Utils/selectOptions';
import { parsePhoneNumber } from 'libphonenumber-js';
import React, { useCallback, useEffect, useState } from 'react';
import { BiEdit } from 'react-icons/bi';
import { MdKeyboardArrowLeft } from 'react-icons/md';
import { useLocation, useNavigate } from 'react-router-dom';
import { SingleValue } from 'react-select';

import Button from '../../../Components/Button/Button';
import Container from '../../../Components/Container/Container';
import PatientSelector from '../../../Components/Inputs/SelectPatientInput/SelectPatientInput';
import { Loading } from '../../../Components/LoadingSpinner/Loading';
import { useAppDispatch, useAppSelector } from '../../../hooks/useReduxHook';
import useWindowSize from '../../../hooks/useWindowSize';
import { CasePayload, createNewCase } from '../../../services/cases/createNewCase';
import { CaseType, ICase } from '../../../services/cases/types/ICase';
import { getForm } from '../../../services/forms/getForm';
import { getFormAnswer } from '../../../services/forms/getFormAnswer';
import { listForms } from '../../../services/forms/listForms';
import { IFormDetails } from '../../../services/forms/types/IFormDetails';
import { displayErrorDetails } from '../../../Utils/displayError';
import { DEFAULT_PATIENT_LOCATION_STATE } from '../../../Utils/localStorage';
import ScheduleLiveConsultation from '../../Dashboard/DashboardModals/ScheduleLiveConsultation';
import SubmitConfirmationModal from '../../Dashboard/DashboardModals/SubmitConfirmationModal';
import { SelectNeedsSubmit } from '../../PatientForms/PatientForm/utils/types';
import styles from './styles.module.scss';
import { questionToAnswer } from './utils/questionToAnswer';

interface FormValues {
  medicalHistory: Record<string, { type: string; value: string }>;
  medicalConditions: Record<string, { type: string; value: string }>;
  dentalHistory: Record<string, { type: string; value: string }>;
}

const MedicalInformationSummary = ({
  isProfileCard,
  isModal,
  createdById,
}: {
  isProfileCard?: boolean;
  isModal?: boolean;
  createdById?: number | undefined;
}): JSX.Element => {
  const dispatch = useAppDispatch();
  const screenSize = useWindowSize();
  const [formValues, setFormValues] = useState<FormValues>({
    medicalHistory: {},
    medicalConditions: {},
    dentalHistory: {},
  });
  const navigate = useNavigate();
  const { state } = useLocation();
  const currentState: string = useAppSelector(selectCurrentPatientState);
  const isFromHandoffMobile = useAppSelector(selectIsFromHandOff);
  const { userInfo } = useAppSelector(selectAuth);
  const { currentOrganization } = useAppSelector(selectOrganization);
  const { currentOrganizationPrices } = useAppSelector(selectOrganizationPrices);
  const { dependents: listOfDependents } = useAppSelector(selectDependents);
  const { selectedPatient: currentPatient } = useAppSelector(selectPatient);
  const [pharmacyName, setPharmacyName] = useState('');
  const [pharmacyAddress, setPharmacyAddress] = useState('');
  const [pharmacyPhoneNumber, setPharmacyPhoneNumber] = useState('');
  const [loading, setLoading] = useState<boolean>(false);
  const [instantModal, setInstantModal] = useState(false);
  const [displayUnavailableTimeSlotModal, setDisplayUnavailableTimeSlotModal] = useState(false);
  const [scheduleModal, setScheduleModal] = useState<{
    openModal: boolean;
    currentCase: ICase;
  }>({
    openModal: false,
    currentCase: defaultICaseValues,
  });
  const [oralHealthCoachingModal, setOralHealthCoachingModal] = useState<{
    openModal: boolean;
    currentCase: ICase;
  }>({
    openModal: false,
    currentCase: defaultICaseValues,
  });
  const [submitConfirmation, setSubmitConfirmation] = useState<boolean>(false);
  const [submitSOConfirmation, setSubmitSOConfirmation] = useState(false);
  const { values, selectedPatient, checkExternalForms } = useAppSelector(selectPatientForm);
  const [dependents, setDependents] = useState<SingleValue<Option>[]>([]);
  const patientSelectorRef = React.useRef<HTMLDivElement>(null);

  const getFormAnswers = useCallback(async (createdByPatientId: number | undefined) => {
    setLoading(true);
    const { forms: formList } = await listForms();
    const newFormId = formList.find((form) => form.title === 'Patient Medical Information Form')?.id ?? 0;
    let questionIdsForMedicalHistory: number[] = [];
    let questionIdsForMedicalConditions: number[] = [];
    let questionIdsForDentalHistory: number[] = [];
    let questions: IFormDetails;

    const formDetails = await getForm(newFormId.toString());
    if (formDetails) {
      questions = formDetails;
      questionIdsForMedicalHistory = questions.steps[0].questions.map((question) => question.id);
      questionIdsForMedicalConditions = questions.steps[1].questions.map((question) => question.id);
      questionIdsForDentalHistory = questions.steps[2].questions.map((question) => question.id);
    }
    const formAnswers = await getFormAnswer({ form_id: newFormId, created_by_id: createdByPatientId });

    const medicalHistoryValues: {
      title: string;
      type: string;
      value: string;
    }[] = [];
    const medicalConditionsValues: {
      title: string;
      type: string;
      value: string;
    }[] = [];
    const dentalHistoryValues: {
      title: string;
      type: string;
      value: string;
    }[] = [];
    if (formAnswers.form_answers.length > 0) {
      formAnswers.form_answers.forEach((answer) => {
        answer.values
          .sort((a, b) => a.form_question_id - b.form_question_id)
          .forEach((value) => {
            if (questionIdsForMedicalHistory.includes(value.form_question_id) && value.value.data !== 'no') {
              medicalHistoryValues.push({
                title:
                  questions.steps[0].questions.find((question) => question.id === value.form_question_id)?.title || '',
                type:
                  questions.steps[0].questions.find((question) => question.id === value.form_question_id)?.type || '',
                value: value.value.data,
              });
            }
            if (questionIdsForMedicalConditions.includes(value.form_question_id) && value.value.data !== 'no') {
              medicalConditionsValues.push({
                title:
                  questions.steps[1].questions.find((question) => question.id === value.form_question_id)?.title || '',
                type:
                  questions.steps[1].questions.find((question) => question.id === value.form_question_id)?.type || '',
                value: value.value.data,
              });
            }
            if (questionIdsForDentalHistory.includes(value.form_question_id) && value.value.data !== 'no') {
              dentalHistoryValues.push({
                title:
                  questions.steps[2].questions.find((question) => question.id === value.form_question_id)?.title || '',
                type:
                  questions.steps[2].questions.find((question) => question.id === value.form_question_id)?.type || '',
                value: value.value.data,
              });
            }
          });
      });
    }
    setFormValues({
      medicalHistory: medicalHistoryValues.reduce((acc, cur) => {
        acc[cur.title] = { type: cur.type, value: cur.value };
        return acc;
      }, {} as Record<string, { type: string; value: string }>),
      medicalConditions: medicalConditionsValues.reduce((acc, cur) => {
        acc[cur.title] = { type: cur.type, value: cur.value };
        return acc;
      }, {} as Record<string, { type: string; value: string }>),
      dentalHistory: dentalHistoryValues.reduce((acc, cur) => {
        acc[cur.title] = { type: cur.type, value: cur.value };
        return acc;
      }, {} as Record<string, { type: string; value: string }>),
    });

    setLoading(false);
  }, []);

  useEffect(() => {
    if ((state && !state.fromPatientIntake) || false) {
      setLoading(true);
      if (userInfo.id) {
        if (userInfo.id && dependents.filter((dependent) => dependent?.status === 'Active').length > 0) {
          const selectedDependent = dependents.find((d) => d?.value === userInfo.id);
          if (
            userInfo.role.name !== UserRoleName.Admin &&
            userInfo.role.name !== UserRoleName.SuperAdmin &&
            userInfo.role.name !== UserRoleName.OrganizationAdmin
          ) {
            dispatch(
              setSelectedPatient(
                selectedDependent || {
                  label: 'Myself',
                  value: userInfo.id.toString(),
                },
              ),
            );
          } else {
            dispatch(
              setSelectedPatient(
                selectedDependent || {
                  value: userInfo.id,
                  label: 'Current Patient',
                },
              ),
            );
          }
        }
      }
      setDependents(listOfDependents);
      setLoading(false);
    }
  }, [dependents]);
  // useEffect(() => {
  //   const payload = [...values!];
  //   const filteredPayload = payload.filter((item) => item.form_question_id !== 14);
  //   filteredPayload.push({
  //     form_question_id: 14,
  //     value: {
  //       selectedTimeSlot: `Friday, December 13, 2024 2:00 pm`,
  //       selectedProviderId: values.find((item) => item.form_question_id === 14)?.value?.selectedProviderId ,
  //       selectedCarrier: values.find((item) => item.form_question_id === 14)?.value?.selectedCarrier,
  //       customInsurance: values.find((item) => item.form_question_id === 14)?.value?.customInsurance,
  //     },
  //   });
  //   dispatch(setForm(filteredPayload));
  // },[])
  useEffect(() => {
    (async () => {
      setLoading(true);
      setPharmacyAddress('');
      setPharmacyName('');
      setPharmacyPhoneNumber('');
      await getFormAnswers(Number(createdById ?? selectedPatient?.value));
      setLoading(false);
    })();
  }, [createdById, selectedPatient?.value, userInfo.id]);

  useEffect(() => {
    if (selectedPatient?.value && dependents.filter((dependent) => dependent?.status === 'Active').length > 0) {
      const selectedDependent = dependents.find((d) => d?.value === selectedPatient?.value);
      if (
        userInfo.role.name !== UserRoleName.Admin &&
        userInfo.role.name !== UserRoleName.SuperAdmin &&
        userInfo.role.name !== UserRoleName.OrganizationAdmin
      ) {
        dispatch(
          setSelectedPatient(
            selectedDependent || {
              label: 'Myself',
              value: userInfo.id.toString(),
            },
          ),
        );
      }
    }
  }, [selectedPatient?.value, dependents]);

  const isValidPhoneNumberFormat = (phoneNumber: string): boolean => {
    const phoneNumberPattern = /^\+?1?[-.\s]?\(?(\d{3})\)?[-.\s]?(\d{3})[-.\s]?(\d{4})$/;

    return phoneNumberPattern.test(phoneNumber);
  };

  const displayPhoneNumber = (pharmacyPhoneNumberValue: string): string => {
    const phoneNumber = parsePhoneNumber(pharmacyPhoneNumberValue, 'US');
    if (phoneNumber) {
      return phoneNumber.formatNational();
    }
    return '';
  };

  useEffect(() => {
    if (formValues.medicalHistory) {
      setPharmacyAddress('');
      setPharmacyName('');
      setPharmacyPhoneNumber('');
      let address = '';
      Object.keys(formValues.medicalHistory).forEach((key) => {
        const { value } = formValues.medicalHistory[key];
        if (key === 'Pharmacy Name') {
          setPharmacyName(value);
        } else if (
          key === 'Pharmacy Street Address' ||
          key === 'Pharmacy City' ||
          key === 'Pharmacy State' ||
          key === 'Pharmacy Zip Code'
        ) {
          if (value) {
            address += `${value}, `;
          }
        } else if (key === 'Pharmacy Phone Number') {
          setPharmacyPhoneNumber(isValidPhoneNumberFormat(value) ? displayPhoneNumber(value) : value);
        }
      });

      setPharmacyAddress(address.slice(0, -2));
    }
  }, [formValues.medicalHistory]);

  const handleReset = (): void => {
    dispatch(resetForm());
    dispatch(resetSelectedPatient());
    dispatch(setIsFromHandoff(false));
  };

  const dispatchSuccessEvent = (serviceType: string): void => {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: 'thank-you-screen-shown',
      service: serviceType,
      userId: userInfo.id,
    });
  };

  const selectedValues = values.find((item) => item.form_question_id === 15)?.value
    ? Object.entries(values.find((item) => item.form_question_id === 15)?.value as SelectNeedsSubmit)
        .filter(([, value]) => value !== 'no')
        .map(([k]) => k)
    : null;
  const isPaymentRequired = currentOrganization?.payment_options?.length > 0;
  const createCaseForThisRequirement = async (): Promise<ICase> => {
    const payload: CasePayload = {
      type: (values.find((item) => item.form_question_id === 20)?.value.caseType as string) || '',
      status: 'new',
      patient_id:
        userInfo.role.name === UserRoleName.Admin ||
        userInfo.role.name === UserRoleName.SuperAdmin ||
        userInfo.role.name === UserRoleName.OrganizationAdmin
          ? currentPatient.guardian_id === null
            ? currentPatient?.id
            : currentPatient.guardian_id!
          : Number(userInfo.id),
      organization_id: currentOrganization?.id || 'dentistry-one',
      ...((values.find((item) => item.form_question_id === 20)?.value.caseType as string) !==
        CaseType.oral_health_coaching && {
        provider_id:
          (values.find((item) => item.form_question_id === 20)?.value.caseType as string) === 'video_call_scheduled' ||
          (values.find((item) => item.form_question_id === 20)?.value.caseType as string) ===
            CaseType.phone_call_scheduled
            ? Number(values.find((item) => item.form_question_id === 14)?.value?.selectedProviderId)
            : undefined,
      }),
      ...((values.find((item) => item.form_question_id === 20)?.value.caseType as string) ===
        CaseType.oral_health_coaching && {
        care_advisor_id: Number(values.find((item) => item.form_question_id === 14)?.value?.selectedCareAdvisorId),
      }),
      purpose: selectedValues,
      purpose_other: selectedValues?.includes('something_else')
        ? (values.find((item) => item.form_question_id === 15)?.value?.something_else as string)
        : null,
      in_pain: (values.find((item) => item.form_question_id === 16)?.value.pain_level as string) !== 'noPain',
      pain_description: (values.find((item) => item.form_question_id === 16)?.value.describePain as string[]) || null,
      pain_level: (values.find((item) => item.form_question_id === 16)?.value.painLevel as string) || null,
      pain_time: (values.find((item) => item.form_question_id === 16)?.value.daysInPain as string) || null,
      additional_info: (values.find((item) => item.form_question_id === 18)?.value.anythingElse as string) || null,
      payment_profile_id: isPaymentRequired
        ? values.find((item) => item.form_question_id === 19)?.value?.paymentId
          ? Number(values.find((item) => item.form_question_id === 19)?.value?.paymentId)
          : null
        : null,
      video_conference_date: (values.find((item) => item.form_question_id === 14)?.value?.selectedTimeSlot as string)
        ? convertDateFormat(values.find((item) => item.form_question_id === 14)?.value?.selectedTimeSlot as string)
        : null,
      patient_current_state:
        userInfo.role.name === UserRoleName.Admin ||
        userInfo.role.name === UserRoleName.SuperAdmin ||
        userInfo.role.name === UserRoleName.OrganizationAdmin
          ? currentState[currentPatient.id] || ''
          : currentState[userInfo.id] || DEFAULT_PATIENT_LOCATION_STATE,
      ...(isPaymentRequired && {
        insurance_id: values.find((item) => item.form_question_id === 19)?.value?.insuranceId
          ? Number(values.find((item) => item.form_question_id === 19)?.value?.insuranceId)
          : null,
      }),
      ...(isPaymentRequired &&
        values.find((item) => item.form_question_id === 19)?.value?.addSecondaryInsurance &&
        values.find((item) => item.form_question_id === 19)?.value?.secondaryInsuranceId !== '' && {
          second_insurance_id: values.find((item) => item.form_question_id === 19)?.value?.addSecondaryInsurance
            ? Number(values.find((item) => item.form_question_id === 19)?.value?.secondaryInsuranceId)
            : undefined,
        }),
      dependent_id:
        userInfo.role.name === UserRoleName.Admin ||
        userInfo.role.name === UserRoleName.SuperAdmin ||
        userInfo.role.name === UserRoleName.OrganizationAdmin
          ? currentPatient.guardian_id === null
            ? null
            : Number(currentPatient?.id)
          : Number(selectedPatient?.value) !== Number(userInfo.id)
          ? Number(selectedPatient?.value)
          : null,
      ...(values.find((item) => item.form_question_id === 14)?.value?.customInsurance !== '' &&
      Number(values.find((item) => item.form_question_id === 14)?.value?.selectedCarrier) === 0
        ? { custom_carrier: values.find((item) => item.form_question_id === 14)?.value?.customInsurance }
        : Number(values.find((item) => item.form_question_id === 14)?.value?.selectedInsurancePlan) === 0
        ? {
            custom_plan: values.find((item) => item.form_question_id === 14)?.value?.customInsurance,
            custom_carrier: values.find((item) => item.form_question_id === 14)?.value?.selectedCarrier,
          }
        : {}),
      ...(((values.find((item) => item.form_question_id === 20)?.value.caseType as string) === CaseType.one_report ||
        (values.find((item) => item.form_question_id === 20)?.value.caseType as string) === CaseType.second_opinion ||
        (values.find((item) => item.form_question_id === 20)?.value.caseType as string) ===
          CaseType.video_call_scheduled) && {
        tz: Intl.DateTimeFormat().resolvedOptions().timeZone,
      }),
      ...(((values.find((item) => item.form_question_id === 20)?.value.caseType as string) === CaseType.one_report ||
        (values.find((item) => item.form_question_id === 20)?.value.caseType as string) ===
          CaseType.second_opinion) && {
        license_states:
          userInfo.role.name === UserRoleName.Admin ||
          userInfo.role.name === UserRoleName.SuperAdmin ||
          userInfo.role.name === UserRoleName.OrganizationAdmin
            ? [currentState[currentPatient.id] || '']
            : [currentState[userInfo.id]],
      }),
    };
    const caseRes = await createNewCase(payload);
    return caseRes;
  };
  const completeMobileHandoffFn = async (): Promise<void> => {
    try {
      await completeMobileHandoff();
      dispatch(setIsFromHandoff(false));
    } catch (error: unknown) {
      displayErrorDetails(error);
    }
  };

  function isSelectedAppointmentTimeIsEnough(selectedTimeSlot: string): boolean {
    const selectedTime = new Date(selectedTimeSlot);
    const currentTime = new Date();

    const timeDifferenceInMinutes = (selectedTime.getTime() - currentTime.getTime()) / (1000 * 60);

    return timeDifferenceInMinutes >= 20;
  }

  const onSubmit = async (): Promise<any> => {
    if (
      (values.find((item) => item.form_question_id === 20)?.value.caseType === CaseType.video_call_scheduled ||
        values.find((item) => item.form_question_id === 20)?.value.caseType === CaseType.phone_call_scheduled) &&
      !isSelectedAppointmentTimeIsEnough(
        values.find((item) => item.form_question_id === 14)?.value?.selectedTimeSlot as string,
      )
    ) {
      setDisplayUnavailableTimeSlotModal(true);
    } else if (state?.fromPatientIntake) {
      if (values.find((item) => item.form_question_id === 20)?.value.caseType) {
        setLoading(true);
        try {
          const res = await createCaseForThisRequirement();
          if (isFromHandoffMobile) {
            completeMobileHandoffFn();
          }
          if (res) {
            handleReset();
          }
          const imagesValue = values.find((item) => item.form_question_id === 17)?.value.images;
          uploadMediaToCase(res, imagesValue);

          if (res && res.type === 'video_call_instant') {
            dispatch(setVCCaseId(res.id.toString()));
            dispatchSuccessEvent('talk-live-now');
            if (
              userInfo.role.name === UserRoleName.Admin ||
              userInfo.role.name === UserRoleName.SuperAdmin ||
              userInfo.role.name === UserRoleName.OrganizationAdmin
            ) {
              return setInstantModal(true);
            }
            return navigate(`/video-room/${res.id}`, {
              state: {
                status: res.status,
              },
            });
          }
          if (res && (res.type === 'video_call_scheduled' || res.type === CaseType.phone_call_scheduled)) {
            dispatchSuccessEvent('talk-live-scheduled');
            return setScheduleModal({
              openModal: true,
              currentCase: res,
            });
          }
          if (res && res.type === CaseType.oral_health_coaching) {
            dispatchSuccessEvent('talk-live-scheduled');
            return setOralHealthCoachingModal({
              openModal: true,
              currentCase: res,
            });
          }
          if (res && res.type === 'one_report') {
            dispatchSuccessEvent('send-photos');
            return setSubmitConfirmation(true);
          }
          if (res && res.type === 'second_opinion') {
            dispatchSuccessEvent('second-opinion');
            return setSubmitSOConfirmation(true);
          }
        } catch (error: unknown) {
          displayErrorDetails(error);
        } finally {
          setLoading(false);
        }
      }
    } else {
      return navigate('/dashboard');
    }
  };

  const SummaryContent = React.memo(
    (): JSX.Element => (
      <>
        {checkExternalForms.length === 0 && state?.fromPatientIntake && (
          <span>We already have your history on file. You may review and edit your history or continue. </span>
        )}
        {userInfo.role.name !== UserRoleName.Admin &&
          userInfo.role.name !== UserRoleName.SuperAdmin &&
          userInfo.role.name !== UserRoleName.OrganizationAdmin &&
          dependents.filter((dependent) => dependent?.status === 'Active').length > 0 &&
          !isModal &&
          !state?.fromPatientIntake && (
            <div className={styles.choosePatientContainer}>
              <PatientSelector name="service_for" ref={patientSelectorRef} />
            </div>
          )}
        <div className={`${styles.section} ${styles.borderBottom}`}>
          <div className={styles.sectionHeader} style={{ padding: isModal ? '20px 0' : '' }}>
            <div className={styles.sectionTitle}>Medical History</div>
            {!isModal && (
              <div
                className={styles.sectionEdit}
                onClick={() =>
                  navigate('/medical-history', {
                    state: {
                      fromPatientIntake: state?.fromPatientIntake ?? false,
                      isPatientForm: state?.isPatientForm || false,
                      caseType: values.find((item) => item.form_question_id === 20)?.value.caseType || '',
                      shouldSeeLastModal: checkExternalForms.length === 0 || false,
                      fromSummary: true,
                      patientId:
                        Number(selectedPatient?.value) !==
                        Number(
                          userInfo.role.name === UserRoleName.Admin ||
                            userInfo.role.name === UserRoleName.SuperAdmin ||
                            userInfo.role.name === UserRoleName.OrganizationAdmin
                            ? selectedPatient?.value
                            : userInfo.id,
                        )
                          ? selectedPatient?.value
                          : undefined,
                    },
                  })
                }
              >
                Edit <BiEdit size="16px" />
              </div>
            )}
          </div>
          {checkExternalForms.length !== 0 &&
            checkExternalForms.includes('patient_medical_history') &&
            state?.fromPatientIntake && (
              <span style={{ color: 'red', fontSize: '12px' }}>
                *Please fill in all required information under Medical History section to proceed.
              </span>
            )}
          <div className={styles.sectionContent}>
            {formValues.medicalHistory && (
              <>
                {Object.keys(formValues.medicalHistory)
                  .filter((key) => !key.startsWith('Pharmacy'))
                  .filter((key) => {
                    const { value } = formValues.medicalHistory[key];
                    return (
                      (typeof value === 'string' && value.trim() !== '') || (Array.isArray(value) && value.length > 0)
                    );
                  })
                  .map((key) => {
                    const { value } = formValues.medicalHistory[key];
                    return (
                      <div className={styles.sectionContentRow} key={key}>
                        <div className={styles.sectionContentRowValue}>
                          {questionToAnswer(key, setLoading, value, formValues.medicalHistory[key].type)}
                        </div>
                        <div className={styles.sectionContentRowDetails}>
                          {Array.isArray(value) ? value.join(', ') : value}
                        </div>
                      </div>
                    );
                  })}

                {(pharmacyName || pharmacyAddress || pharmacyPhoneNumber) && (
                  <div className={styles.sectionContentRow}>
                    <div className={styles.sectionContentRowValue}>Pharmacy</div>
                    <div className={styles.sectionContentRowDetails}>{pharmacyName}</div>
                    <div className={styles.sectionContentRowDetails}>{pharmacyAddress}</div>
                    <div className={styles.sectionContentRowDetails}>{pharmacyPhoneNumber}</div>
                  </div>
                )}
              </>
            )}
          </div>
        </div>
        <div className={`${styles.section} ${styles.borderBottom}`}>
          <div className={styles.sectionHeader} style={{ padding: isModal ? '20px 0' : '' }}>
            <div className={styles.sectionTitle}>Medical Conditions</div>
            {!isModal && (
              <div
                className={styles.sectionEdit}
                onClick={() =>
                  navigate('/medical-condition', {
                    state: {
                      fromPatientIntake: state?.fromPatientIntake ?? false,
                      isPatientForm: state?.isPatientForm || false,
                      caseType: values.find((item) => item.form_question_id === 20)?.value.caseType || '',
                      shouldSeeLastModal: checkExternalForms.length === 0 || false,
                      fromSummary: true,
                      patientId:
                        Number(selectedPatient?.value) !== Number(userInfo?.id) ? selectedPatient?.value : undefined,
                    },
                  })
                }
              >
                Edit <BiEdit size="16px" />
              </div>
            )}
          </div>
          {checkExternalForms.length !== 0 &&
            checkExternalForms.includes('patient_medical_conditions') &&
            state?.fromPatientIntake && (
              <span style={{ color: 'red', fontSize: '12px' }}>
                *Please fill in all required information under Medical Conditions section to proceed.
              </span>
            )}
          <div className={styles.sectionContent}>
            <div className={styles.sectionContentRow}>
              <div className={styles.sectionContentRowValue}>
                {formValues.medicalConditions &&
                  Object.keys(formValues.medicalConditions).map((key, index) => {
                    const currentValue = formValues.medicalConditions[key];
                    const nextKey = Object.keys(formValues.medicalConditions)[index + 1];
                    const nextValue = nextKey ? formValues.medicalConditions[nextKey].value : null;

                    if (currentValue.type === 'textarea') {
                      return `${currentValue.value}${nextValue && nextValue.trim() ? ',' : ''} `;
                    }

                    return `${key}${nextValue && nextValue.trim() ? ',' : ''} `;
                  })}
              </div>
            </div>
          </div>
        </div>
        <div className={styles.section}>
          <div className={styles.sectionHeader} style={{ padding: isModal ? '20px 0' : '' }}>
            <div className={styles.sectionTitle}>Dental History</div>
            {!isModal && (
              <div
                className={styles.sectionEdit}
                onClick={() =>
                  navigate('/dental-history', {
                    state: {
                      fromPatientIntake: state?.fromPatientIntake ?? false,
                      isPatientForm: state?.isPatientForm || false,
                      caseType: values.find((item) => item.form_question_id === 20)?.value.caseType || '',
                      shouldSeeLastModal: checkExternalForms.length === 0 || false,
                      fromSummary: true,
                      patientId:
                        Number(selectedPatient?.value) !==
                        Number(
                          userInfo.role.name === UserRoleName.Admin ||
                            userInfo.role.name === UserRoleName.SuperAdmin ||
                            userInfo.role.name === UserRoleName.OrganizationAdmin
                            ? selectedPatient?.value
                            : userInfo.id,
                        )
                          ? selectedPatient?.value
                          : undefined,
                    },
                  })
                }
              >
                Edit <BiEdit size="16px" />
              </div>
            )}
          </div>
          {checkExternalForms.length !== 0 &&
            checkExternalForms.includes('patient_dental_history') &&
            state?.fromPatientIntake && (
              <span style={{ color: 'red', fontSize: '12px' }}>
                *Please fill in all required information under Dental History section to proceed.
              </span>
            )}
          <div className={styles.sectionContent}>
            {formValues.dentalHistory &&
              Object.keys(formValues.dentalHistory)
                .filter((key) => {
                  const value = formValues.dentalHistory[key]?.value;
                  return (
                    (typeof value === 'string' && value.trim() !== '') || (Array.isArray(value) && value.length > 0)
                  );
                })
                .map((key) => {
                  return (
                    <div className={styles.sectionContentRow} key={key}>
                      <div className={styles.sectionContentRowValue}>
                        {questionToAnswer(
                          key,
                          setLoading,
                          formValues.dentalHistory[key].value,
                          formValues.dentalHistory[key].type,
                        )}
                      </div>
                      {(formValues.dentalHistory[key].type === 'textarea' ||
                        formValues.dentalHistory[key].type === 'select') && (
                        <div className={styles.sectionContentRowDetails}>
                          {formValues.dentalHistory[key].value === 'dontKnow'
                            ? `Don't Know`
                            : formValues.dentalHistory[key].value}
                        </div>
                      )}
                    </div>
                  );
                })}
          </div>
        </div>
      </>
    ),
  );

  if (loading) {
    return <Loading fullScreen />;
  }

  if (isModal) {
    return <SummaryContent />;
  }

  return (
    <Container
      isBGEclipse
      isVisibleOnMobile
      containerStyle={{
        justifyContent: screenSize.width >= 564 ? 'center' : 'start',
        width: isProfileCard ? '100%' : '',
      }}
      isProfileCard={isProfileCard}
      eclipseStyle={{ opacity: 0.2, display: screenSize.width <= 768 ? 'none' : '' }}
      childrenStyle={{ zIndex: '2', paddingTop: '40px' }}
    >
      <div className={styles.medicalSummaryContainer}>
        {state?.fromPatientIntake && (
          <section className={styles.backButtonContainer}>
            <button
              type="button"
              className={styles.backButton}
              onClick={() => {
                navigate(-1);
              }}
            >
              <MdKeyboardArrowLeft />
              Back
            </button>
          </section>
        )}
        <div className={styles.medicalSummaryWrapper}>
          <SummaryContent />
          {state?.fromPatientIntake && (
            <div className={styles.btnContainer}>
              <Button
                style={{ width: '245px', height: '46px' }}
                disabled={state?.fromPatientIntake ? checkExternalForms.length !== 0 : false}
                onClick={onSubmit}
              >
                {currentOrganizationPrices?.find((price: Pricings) =>
                  price.case_types.includes(
                    values.find((item) => item.form_question_id === 20)?.value.caseType as string,
                  ),
                )?.amount === 0
                  ? 'Finish'
                  : 'Finish and Pay'}
              </Button>
            </div>
          )}
        </div>
      </div>
      {instantModal && <InstantConsultationConfirmationModal />}
      {scheduleModal.openModal && <ScheduleLiveConsultation newCase={scheduleModal.currentCase} />}
      {submitConfirmation && <SubmitConfirmationModal />}
      {submitSOConfirmation && <SubmitSOConfirmationModal />}
      {oralHealthCoachingModal.openModal && <OralHealthCoachingModal newCase={oralHealthCoachingModal.currentCase} />}
      {displayUnavailableTimeSlotModal && (
        <UnavailableTimeSlotModal setDisplayUnavailableTimeSlotModal={setDisplayUnavailableTimeSlotModal} />
      )}
    </Container>
  );
};
MedicalInformationSummary.defaultProps = {
  isProfileCard: false,
  isModal: false,
  createdById: undefined,
};
export default React.memo(MedicalInformationSummary);

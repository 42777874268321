import Container from '@brands/Components/Container/Container';
import Sidenav from '@brands/Dashboard/Sidenav/Sidenav';
import useWindowSize from '@brands/hooks/useWindowSize';
import React, { FC } from 'react';
import { AiOutlineQuestionCircle } from 'react-icons/ai';

import styles from './styles.module.scss';

const AffiliatedPracticeList: FC = () => {
  const screenSize = useWindowSize();
  return (
    <>
      <Sidenav isVisibleOnMobile hideDefaultOptions authLoog>
        <a
          href="https://dentistryone.zendesk.com/hc/en-us/articles/11914427760151-How-to-change-your-password"
          target="_blank"
          className={styles.sidenavContainer}
          rel="noreferrer"
          style={{ textDecoration: 'none' }}
        >
          <div className={styles.sidenavToggleText}>Help</div>
          <div className={styles.sidenavToggle}>
            <AiOutlineQuestionCircle />
          </div>
        </a>
      </Sidenav>
      <Container
        isBGEclipse={false}
        patientDashboard
        childClassName={styles.dashboard}
        containerStyle={{
          justifyContent: screenSize.width >= 1289 ? 'center' : 'start',
          overflowY: screenSize.width >= 1289 ? 'hidden' : 'scroll',
        }}
      >
        <div className={styles.tOSBody}>
          <div id="content" className={styles.tOSBodySiteContent}>
            <main id="primary" className="site-main">
              <article id="post-889" className="post-889 page type-page status-publish hentry">
                <div className={styles.entryContent}>
                  <p>Dentistry.One of Alabama, P.C.</p>
                  <p>Aurora Alva, DMD Dental Corporation</p>
                  <p>Dentistry.One of Colorado, P.C.</p>
                  <p>Dentistry.One of Connecticut, Derek Weigand, P.C.</p>
                  <p>Dentistry.One of Florida, P.A.</p>
                  <p>Dentistry.One of Georgia, P.C.</p>
                  <p>Dentistry.One of Idaho, P.C.</p>
                  <p>Dentistry.One of Illinois, P.C.</p>
                  <p>Dentistry.One of Indiana, P.C.</p>
                  <p>Dentistry.One of Maine, Derek Weigand, P.C.</p>
                  <p>Dentistry.One of Massachusetts, Aurora Alva, P.C.</p>
                  <p>Dentistry.One of Michigan, P.C.</p>
                  <p>Dentistry.One of Minnesota, P.C.</p>
                  <p>Dentistry.One of Missouri, P.C.</p>
                  <p>Dentistry.One of Montana, P.C.</p>
                  <p>Dentistry.One of Nebraska, P.C.</p>
                  <p>Dentistry.One of New Hampshire, P.C.</p>
                  <p>Dentistry.One of New Jersey, P.C.</p>
                  <p>Dentistry.One of New Mexico, P.C.</p>
                  <p>Jermone Herman DDS NY, P.C.</p>
                  <p>Dentistry.One of North Dakota, P.C.</p>
                  <p>Dentistry.One of Ohio, Derek Weigand, Inc.</p>
                  <p>Dentistry.One of Oregon, P.C.</p>
                  <p>Dentistry.One of Pennsylvania, P.C.</p>
                  <p>Dentistry.One of South Dakota, Derek Weigand, P.C.</p>
                  <p>Dentistry.One of Texas, P.C.</p>
                  <p>Dentistry.One of Utah, P.C.</p>
                  <p>Dentistry.One of Vermont, P.C.</p>
                  <p>Dentistry.One of Virginia, P.C.</p>
                  <p>Dr. Hillary A. Berry DMD P.C.</p>
                  <p>Dentistry.One of Wisconsin, S.C.</p>
                  <p>Dentistry.One of Wyoming, P.C.</p>
                </div>
              </article>
            </main>
          </div>
        </div>
      </Container>
    </>
  );
};

export default AffiliatedPracticeList;

/* eslint-disable import/no-cycle */
import { TimeSlot } from '@brands/Dashboard/PatientForms/PatientForm/utils/ProviderAvailabilityCard/Components/TimeSlotComponent';
import { Meta } from '@brands/services/communication/types/Meta';
import { uniqBy } from 'lodash';
import { useEffect, useState } from 'react';

import {
  IListAvailableProviderParams,
  listAvailableProvidersDate,
} from '../services/identity/listAvailableProvidersDate';
import { UserProfile } from '../services/identity/types/UserProfile';
import { isValidDateFormat } from '../Utils/validation';

interface IUseUsers {
  providers: IListAvailableProvider[];
  dataLoading: boolean;
  loadNextPage: () => Promise<void>;
  refreshProviders: () => void;
}

export interface IListAvailableProvider {
  created_at: string;
  id: number;
  sync_time_slots: string[];
  sync_time_intervals: TimeSlot[];
  async_time_slots: string[];
  async_time_intervals: TimeSlot[];
  updated_at: string;
  user: UserProfile;
  in_network?: boolean;
}

export default function useAvailableProviders(
  user_type: string,
  params: Pick<
    IListAvailableProviderParams,
    | 'sort_by'
    | 'sort_direction'
    | 'limit'
    | 'date'
    | 'tz'
    | 'carrier_id'
    | 'language'
    | 'state'
    | 'license_states'
    | 'patient_id'
    | 'out_of_network'
  >,
): IUseUsers {
  const [providers, setProviders] = useState<IListAvailableProvider[]>([]);
  const [dataLoading, setDataLoading] = useState<boolean>(false);
  const [firstPageMeta, setFirstPageMeta] = useState<Meta | null>(null);
  const [lastPageMeta, setLastPageMeta] = useState<Meta | null>(null);

  const loadNextPage = async (): Promise<void> => {
    if (!lastPageMeta?.has_next_page) {
      return;
    }
    const { meta, nodes } = await listAvailableProvidersDate(user_type, {
      cursor: lastPageMeta.end_cursor,
      cursor_direction: 'next',
      ...params,
    });
    setLastPageMeta(meta);
    if (!firstPageMeta) {
      setFirstPageMeta(meta);
    }
    setProviders((prevProviders) => uniqBy([...prevProviders, ...nodes], 'id'));
  };

  useEffect(() => {
    let canceled = false;
    if (params.date !== '' && isValidDateFormat(params.date)) {
      setDataLoading(true);
      listAvailableProvidersDate(user_type, params).then((usersList) => {
        if (!canceled) {
          setProviders(usersList.nodes);
          setFirstPageMeta(usersList.meta);
          setLastPageMeta(usersList.meta);
          setDataLoading(false);
        }
      });
    }
    return (): void => {
      canceled = true;
    };
  }, [params]);

  const refreshProviders = (): void => {
    if (params.date !== '') {
      setDataLoading(true);
      listAvailableProvidersDate(user_type, params).then((usersList) => {
        setProviders(usersList.nodes);
        setDataLoading(false);
      });
    }
  };

  return {
    providers,
    dataLoading,
    loadNextPage,
    refreshProviders,
  };
}

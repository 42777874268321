import { CaseType, Status } from '../services/cases/types/ICase';
import { UserRoleName } from '../services/identity/types/UserProfile';

export const getTextForStatus = (userRole: string, caseType: string, status: Status): string => {
  if (userRole === UserRoleName.CareAdvisor) {
    if (
      caseType === 'one_report' ||
      caseType === 'video_call_instant' ||
      caseType === 'video_call_scheduled' ||
      caseType === CaseType.phone_call_scheduled ||
      caseType === 'second_opinion' ||
      caseType === 'smile_scan' ||
      caseType === CaseType.care_coordination ||
      caseType === CaseType.oral_health_coaching
    ) {
      switch (status) {
        case Status.New:
          return 'New';
        case Status.AcceptedCareAdvisor:
          return 'Accepted';
        case Status.AcceptedProvider:
          return 'Provider Assigned';
        case Status.CompletedByProvider:
          return 'Completed By Provider';
        case Status.InProgressProvider:
          return 'With Provider';
        case Status.InProgressCareAdvisor:
          return 'In Progress';
        case Status.Completed:
          return 'Completed';
        case Status.Canceled:
          return 'Canceled';
        default:
          return '';
      }
    } else if (caseType === 'messaging') {
      switch (status) {
        case Status.New:
          return 'New';
        case Status.AcceptedCareAdvisor:
          return 'Accepted';
        case Status.InProgressCareAdvisor:
          return 'In Progress';
        case Status.Completed:
          return 'Completed';
        case Status.Canceled:
          return 'Canceled';
        default:
          return '';
      }
    }
  } else if (userRole === UserRoleName.Provider) {
    if (
      caseType === 'one_report' ||
      caseType === 'video_call_instant' ||
      caseType === 'second_opinion' ||
      caseType === 'smile_scan'
    ) {
      switch (status) {
        case Status.New:
          return 'New';
        case Status.AcceptedCareAdvisor:
          return 'Accepted Care Advisor';
        case Status.AcceptedProvider:
          return 'Assigned';
        case Status.InProgressProvider:
          return 'In Progress';
        case Status.InProgressCareAdvisor:
          return 'With Care Advisor';
        case Status.CompletedByProvider:
          return 'Completed By Provider';
        case Status.Completed:
          return 'Completed';
        case Status.Canceled:
          return 'Canceled';
        default:
          return '';
      }
    } else if (caseType === 'video_call_scheduled' || caseType === CaseType.phone_call_scheduled) {
      switch (status) {
        case Status.New:
          return 'New';
        case Status.AcceptedCareAdvisor:
          return 'Accepted Care Advisor';
        case Status.AcceptedProvider:
          return 'Scheduled';
        case Status.InProgressProvider:
          return 'In Progress';
        case Status.CompletedByProvider:
          return 'Completed By Provider';
        case Status.InProgressCareAdvisor:
          return 'With Care Advisor';
        case Status.Completed:
          return 'Completed';
        case Status.Canceled:
          return 'Canceled';
        default:
          return '';
      }
    }
  } else if (userRole === UserRoleName.Patient) {
    if (caseType === 'one_report' || caseType === 'second_opinion' || caseType === 'smile_scan') {
      switch (status) {
        case Status.New:
          return 'Requested';
        case Status.AcceptedCareAdvisor:
          return 'Dentist Pending';
        case Status.AcceptedProvider:
          return 'Dentist Assigned';
        case Status.InProgressProvider:
          return 'Report In Progress';
        case Status.CompletedByProvider:
          return 'Completed By Provider';
        case Status.InProgressCareAdvisor:
          return 'Report In Progress';
        case Status.Completed:
          return 'Appointment completed';
        case Status.Canceled:
          return 'Appointment canceled';
        default:
          return '';
      }
    } else if (caseType === CaseType.care_coordination) {
      switch (status) {
        case Status.New:
          return 'Requested';
        case Status.AcceptedCareAdvisor:
          return 'In Progress';
        case Status.AcceptedProvider:
          return 'Dentist Assigned';
        case Status.InProgressProvider:
          return 'Report In Progress';
        case Status.CompletedByProvider:
          return 'Completed By Provider';
        case Status.InProgressCareAdvisor:
          return 'In Progress';
        case Status.Completed:
          return 'Appointment completed';
        case Status.Canceled:
          return 'Appointment canceled';
        default:
          return '';
      }
    } else if (caseType === CaseType.oral_health_coaching) {
      switch (status) {
        case Status.New:
          return 'Requested';
        case Status.AcceptedCareAdvisor:
          return 'In Progress';
        case Status.AcceptedProvider:
          return 'Dentist Assigned';
        case Status.InProgressProvider:
          return 'Report In Progress';
        case Status.CompletedByProvider:
          return 'Completed By Provider';
        case Status.InProgressCareAdvisor:
          return 'In Progress';
        case Status.Completed:
          return 'Appointment completed';
        case Status.Canceled:
          return 'Appointment canceled';
        default:
          return '';
      }
    } else if (caseType === 'video_call_instant') {
      switch (status) {
        case Status.New:
          return 'Requested';
        case Status.AcceptedCareAdvisor:
          return 'Dentist Pending';
        case Status.AcceptedProvider:
          return 'Dentist Assigned';
        case Status.InProgressProvider:
          return 'Dentist Assigned';
        case Status.CompletedByProvider:
          return 'Completed By Provider';
        case Status.InProgressCareAdvisor:
          return 'Report In Progress';
        case Status.Completed:
          return 'Appointment completed';
        case Status.Canceled:
          return 'Appointment canceled';
        default:
          return '';
      }
    } else if (caseType === 'video_call_scheduled' || caseType === CaseType.phone_call_scheduled) {
      switch (status) {
        case Status.New:
          return 'Requested';
        case Status.AcceptedCareAdvisor:
          return 'Dentist Pending';
        case Status.AcceptedProvider:
          return 'Scheduled';
        case Status.InProgressProvider:
          return 'In Progress';
        case Status.CompletedByProvider:
          return 'Completed By Provider';
        case Status.InProgressCareAdvisor:
          return 'Report In Progress';
        case Status.Completed:
          return 'Appointment completed';
        case Status.Canceled:
          return 'Appointment canceled';
        default:
          return '';
      }
    }
  } else if (
    userRole === UserRoleName.Admin ||
    userRole === UserRoleName.SuperAdmin ||
    userRole === UserRoleName.OrganizationAdmin
  ) {
    if (
      caseType === 'one_report' ||
      caseType === 'video_call_instant' ||
      caseType === 'video_call_scheduled' ||
      caseType === CaseType.phone_call_scheduled ||
      caseType === 'second_opinion' ||
      caseType === 'smile_scan' ||
      caseType === CaseType.care_coordination ||
      caseType === CaseType.oral_health_coaching
    ) {
      switch (status) {
        case Status.New:
          return 'New';
        case Status.AcceptedCareAdvisor:
          return 'Accepted';
        case Status.AcceptedProvider:
          return 'Provider Assigned';
        case Status.InProgressProvider:
          return 'With Provider';
        case Status.CompletedByProvider:
          return 'Completed By Provider';
        case Status.InProgressCareAdvisor:
          return 'In Progress';
        case Status.Completed:
          return 'Completed';
        case Status.Canceled:
          return 'Canceled';
        default:
          return '';
      }
    } else if (caseType === 'messaging') {
      switch (status) {
        case Status.New:
          return 'New';
        case Status.AcceptedCareAdvisor:
          return 'Accepted';
        case Status.InProgressCareAdvisor:
          return 'In Progress';
        case Status.Completed:
          return 'Completed';
        case Status.Canceled:
          return 'Canceled';
        default:
          return '';
      }
    }
  }
  return '';
};

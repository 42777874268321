import { client } from '../client';
import {
  OralHealthCoachingReport,
  CreateOralHealthCoachingReportInternalNoteParams,
  ObjectType,
  CreateOralHealthCoachingReportCarePlanParams,
  CreateOralHealthCoachingReportEducationParams,
  CreateOralHealthCoachingReportGoalParams,
} from './types/types';

export const updateOralHealthCoachingReportObject = async (
  oral_health_coaching_report_id: string,
  object_type: ObjectType,
  object_id: string,
  payload:
    | CreateOralHealthCoachingReportInternalNoteParams
    | CreateOralHealthCoachingReportEducationParams
    | CreateOralHealthCoachingReportGoalParams
    | CreateOralHealthCoachingReportCarePlanParams,
): Promise<OralHealthCoachingReport> => {
  const { data } = await client.put<OralHealthCoachingReport>(
    `/care_advisor/oral_health_coaching_reports/${oral_health_coaching_report_id}/${object_type}/${object_id}`,
    payload,
  );

  return data;
};
